<template>
  <div class="account-update-page">
    <div class="container">
      <Navbar />
      <AccountPageUpdate :userInfo="user" />
    </div>
  </div>
</template>

<script>
import Navbar from "@/components/Header/Navbar.vue";
import AccountPageUpdate from "@/components/Account/AccountPageUpdate.vue";
export default {
  name: "AccountUpdate",
  components: {
    Navbar,
    AccountPageUpdate,
  },
  data() {
    return {
      user: {
        uId: "",
        uUserId: "",
        uName: "",
        uEmail: "",
        uPassword: "",
        uMemberSince: "",
        uAddress: "",
        uPhone: "",
        uRole: "",
        uRoleIs: "",
      },
    };
  },
  mounted() {
    let user = localStorage.getItem("current-user-data");
    if (user) {
      this.user.uName = JSON.parse(user).name;
    } else {
      this.$router.push({ name: "Login" });
    }
  },
  methods: {},
};
</script>
