<template>
  <div class="forgot-password-page">
    <ForgetPasswordPage />
  </div>
</template>

<script>
import ForgetPasswordPage from "@/components/Password/ForgetPasswordPage.vue";
export default {
  name: "ForgetPassword",
  components: {
    ForgetPasswordPage,
  },
};
</script>
