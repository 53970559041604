<template>
  <div>
    <div class="container" v-if="showOrderData">
      <div
        class="col-auto d-block mx-auto col-12 col-sm-12 main-order-select-date-custom"
      >
        <div v-if="customerData != ''">
          <div class="m-3">
            <button class="btn btn-primary" @click="goBackOrderReports()">
              <faIcons
                class="d-print-none pointerHand"
                :icon="['fas', 'file-invoice']"
              />
              Reports
            </button>
            &nbsp;
            <button class="btn btn-primary" @click="goBackOrders()">
              <faIcons
                class="d-print-none pointerHand"
                :icon="['fas', 'store']"
              />
              Orders
            </button>
            &nbsp;
            <button class="btn btn-secondary" @click="exportToPDF()">
              <faIcons
                class="d-print-none pointerHand"
                :icon="['fas', 'file-pdf']"
              />
              Export
            </button>
          </div>
          <div id="detailedReport">
            <div class="m-3">
              <h1 class="">
                {{ capitalizeFisrtLetterOfEachWord(businessName) }}
              </h1>
              <address>
                {{ capitalizeFisrtLetterOfEachWord(pickUpAddress) }}<br />
                {{ formatPhoneNumber(pickUpPhoneNumber) }}
              </address>
              <br />
            </div>
            <h4 class="text-center">
              {{ capitalizeFisrtLetterOfEachWord(customerData[0].cust_name) }}
              Orders ({{ customerData.length }})
            </h4>
            <div class="d-flex align-items-center m-3">
              <faIcons
                class="d-print-none pointerHand"
                :icon="['fas', 'chalkboard-teacher']"
                style="width: 45px; height: 45px"
              />
              <div class="ms-3">
                <p class="text-muted mb-0">{{ customerData[0].cust_email }}</p>
                <p class="text-muted mb-0">
                  {{ formatPhoneNumber(customerData[0].cust_phone) }}
                </p>
              </div>
            </div>
            <div class="d-flex align-items-center m-3">
              <faIcons
                class="d-print-none pointerHand"
                :icon="['fas', 'qrcode']"
                style="width: 45px; height: 45px"
              />
              <div class="ms-3">
                <p class="text-muted mb-0">Customer Code</p>
                <p class="text-muted mb-0">{{ customerData[0].fingerprint }}</p>
              </div>
            </div>
            <div class="clearfix"></div>
            <div class="table-responsive">
              <table class="table">
                <thead style="position: sticky; top: 0" class="table-success">
                  <tr>
                    <th class="header" scope="col" v-if="showOrderNO">
                      Order No.
                    </th>
                    <th class="header" scope="col">Transaction ID</th>
                    <th class="header" scope="col">Date</th>
                    <th class="header" scope="col">Total</th>
                    <th class="header" scope="col" v-if="showOrderTime">
                      Tips
                    </th>
                    <th class="header" scope="col">Taxes</th>
                    <th class="header" scope="col" v-if="showOrderTime">
                      Refunds
                    </th>
                    <th class="header" scope="col">Subtotal</th>
                  </tr>
                </thead>
                <tbody>
                  <tr
                    v-for="(o, index) in customerData"
                    :key="index"
                    class="my-2"
                    :id="o.order_id"
                  >
                    <td v-if="showOrderNO">
                      <router-link
                        tag="li"
                        :to="{
                          name: 'viewEachOrderDetails',
                          params: { restId: restId, orderNo: o.order_id },
                        }"
                      >
                        <a>{{ parseFloat(o.id) }}</a>
                      </router-link>
                    </td>
                    <td>
                      {{ o.trans_id }}
                    </td>
                    <td>
                      {{ new Date(o.order_placed_on).toLocaleDateString() }}
                      {{
                        removeSecondsFromTime(
                          new Date(o.order_placed_on).toLocaleTimeString()
                        )
                      }}
                    </td>
                    <td>
                      {{ number_format(o.order_total) }}
                    </td>
                    <td
                      :class="o.has_tips == 1 ? 'greenColor' : ''"
                      v-if="showOrderTime"
                    >
                      {{ number_format(o.total_tips_added) }}
                    </td>
                    <td>
                      {{ number_format(o.order_taxes_calculated) }}
                    </td>
                    <td
                      :class="o.has_refund == 1 ? 'redColor' : ''"
                      v-if="showOrderTime"
                    >
                      {{ number_format(o.amount_refunded) }}
                    </td>
                    <td>
                      <span v-if="o.has_refund == 1" class="redColor">
                        {{
                          number_format(
                            (
                              parseFloat(o.order_subtotal) +
                              parseFloat(o.total_tips_added) -
                              parseFloat(o.amount_refunded)
                            ).toFixed(2)
                          )
                        }}
                      </span>
                      <span v-if="o.has_refund == 0">
                        {{
                          number_format(
                            (
                              parseFloat(o.order_subtotal) +
                              parseFloat(o.total_tips_added)
                            ).toFixed(2)
                          )
                        }}
                      </span>
                    </td>
                  </tr>
                </tbody>
                <tfoot>
                  <tr>
                    <th colspan="3" v-if="showOrderTime">Total</th>
                    <th colspan="2" v-if="!showOrderTime">Total</th>
                    <td colspan="1">
                      {{ number_format(salesTotalFun("order_total")) }}
                    </td>
                    <td colspan="1" v-if="showOrderTime">
                      {{ number_format(salesTotalFun("total_tips_added")) }}
                    </td>
                    <td colspan="1">
                      {{
                        number_format(salesTotalFun("order_taxes_calculated"))
                      }}
                    </td>
                    <td colspan="1" class="redColor" v-if="showOrderTime">
                      {{ number_format(salesTotalFun("amount_refunded")) }}
                    </td>
                    <td colspan="1" class="fb">
                      {{ number_format(subtotalFun()) }}
                    </td>
                  </tr>
                  <tr>
                    <td colspan="6" v-if="showOrderTime" class="fb"></td>
                    <td colspan="2" v-if="!showOrderTime" class="fb"></td>
                    <td colspan="1" v-if="showOrderTime" class="fb">Net</td>
                    <td colspan="2" v-if="!showOrderTime" class="fb">Net</td>
                    <td colspan="1" class="fb">
                      {{ number_format(subtotalFun()) }}
                    </td>
                  </tr>
                </tfoot>
              </table>
            </div>
          </div>
        </div>
        <div v-if="errorMessageFound" class="alert alert-danger m-3">
          {{ errorMessageFound }}
        </div>
      </div>
    </div>
    <div class="container" v-else>
      <div class="d-flex aligns-items-center justify-content-center">
        <div class="m-5 alert alert-danger">
          You don't have an access this page!
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import * as html2pdf from "html2pdf.js";
// import * as html2canvas from "html2canvas";
export default {
  name: "ViewEachCustomer",
  data() {
    return {
      errorMessageFound: "",
      restId: this.$route.params.restId,
      finderPrint: this.$route.params.custId,
      customerData: "",
      currentOrder: [],
      OrdersAPI: this.$store.getters.OrdersAPI,
      pickUpAddress: "",
      pickUpPhoneNumber: "",
      businessName: "",
      custName: "",
      orderData: "",
      custom_order_date_or_now: "",
      custEmail: "",
      showOrderData: true,
      showCustomer: true,
      showOrderTime: true,
      showOrderNO: true,
      showCustomerData: false,
      tips: 0,
      customerUrl: this.$store.getters.CustomerUrl,
      orderSequenceNumber: 0,
      custom_date_time: "",
      currentRealOrder: [],
      currentOrderInDetails: [],
      doesUrlLookGood: false,
    };
  },
  mounted() {
    this.canUserAccessPageNow();
    this.getCustomerDataNow(this.finderPrint, this.restId);
    let currentRestaurants = JSON.parse(
      localStorage.getItem("current_rest_details")
    );
    if (currentRestaurants.length >= 1) {
      console.table(currentRestaurants);
      this.pickUpAddress = currentRestaurants[0].rest_address;
      this.pickUpPhoneNumber = currentRestaurants[0].rest_phone;
      this.businessName = currentRestaurants[0].rest_name;
    }
  },
  methods: {
    async getUserName(userId, appendTo) {
      let fd = new FormData();
      fd.append("userId", userId);
      let rst = await axios.post(
        this.OrdersAPI + `?action=currentUserName`,
        fd
      );
      if (rst.status == 200) {
        const resultData = rst.data;
        if (!resultData.error) {
          console.log("current user name is " + resultData.user_name_is);
          let name = resultData.user_name_is;
          document.getElementById(appendTo).innerHTML =
            "<br />By " + this.capitalizeFisrtLetterOfEachWord(name);
        }
      }
    },
    // removeSecondsFromTime(timeIs) {
    //   //assuming time in 12:34:00 AM/PM format
    //   return timeIs.replace(/(\d{1,2}:\d{2}):\d{2}/, "$1");
    // },
    formatPhoneNumber(number) {
      //remove . + -  from any number
      let cleaned = ("" + number).replace(/\D/g, "");
      let match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
      if (match) {
        return "(" + match[1] + ") " + match[2] + "-" + match[3];
      }
      return null;
    },
    exportToPDF() {
      this.showAddressPrint = true;
      this.showCustomer = false;
      this.showOrderNO = false;
      this.showOrderTime = false;
      setTimeout(() => {
        //https://pspdfkit.com/blog/2022/how-to-generate-a-pdf-with-vuejs/
        //https://www.youtube.com/watch?v=V0gMajxp990
        //good explanation for https://www.youtube.com/watch?v=zmJV6VN2h2E
        var element = document.getElementById("detailedReport");
        var opt = {
          margin: 10, //margin is better when printing
          filename:
            this.businessName +
            " - Customer Report - " +
            this.capitalizeFisrtLetterOfEachWord(
              this.customerData[0].cust_name
            ) +
            ".pdf",
          image: { type: "jpeg", quality: 0.98 },
          html2canvas: {
            scale: 2,
            logging: true,
            dpi: 600,
            letterRendering: true,
          },
          // pagebreak: { mode: ['avoid-all', 'css', 'legacy'] },
          // pagebreak: { before: '.beforeClass', after: ['#after1', '#after2'], avoid: 'img' },
          // pagebreak: { mode: "avoid-all", before: "#page2el" },
          jsPDF: { unit: "mm", format: "a4", orientation: "portrait" },
        };
        html2pdf().set(opt).from(element).save();
        // html2pdf(document.getElementById("detailedReport"), {
        //     margin: 1,
        //     filename: "i-was-html.pdf",
        // });
        // this.showCustomer = true;
        // this.showOrderNO = true;
        this.showCustomer = true;
        this.showOrderNO = true;
        this.showOrderTime = true;
      }, 2000);
    },
    async getCustomerDataNow(customerId, restId) {
      let fd = new FormData();
      fd.append("customerId", customerId);
      fd.append("restId", restId);
      let rst = await axios.post(
        this.OrdersAPI + `?action=getCurrentCustomerAllData`,
        fd
      );
      if (rst.status == 200) {
        const resultData = rst.data;
        if (!resultData.error) {
          console.log(resultData);
          //   console.log("current user name is " + resultData.user_name_is);
          //   let name = resultData.user_name_is;
          this.errorMessageFound = "";
          this.customerData = resultData.current_customer_orders_data;
        } else {
          // this.errorMessageFound = "No such search found. Make sure you clicked on the right link";
          this.errorMessageFound = resultData.message;
        }
      }
    },
    salesTotalFun(getData) {
      console.log("get sales total for " + getData);
      let currentRes = this.customerData;
      console.table(currentRes);
      let total = 0.0;
      for (let i = 0; i < this.customerData.length; i++) {
        if (this.customerData[i][getData] != "") {
          //don't count empty values
          total = (
            parseFloat(total) + parseFloat(this.customerData[i][getData])
          ).toFixed(2);
          console.table("Total Order is " + this.customerData[i][getData]);
        }
      }
      return parseFloat(total).toFixed(2);
    },
    number_format(amount) {
      const price = amount;
      // Format the price above to USD, INR, EUR using their locales.
      let dollarUS = Intl.NumberFormat("en-US", {
        style: "currency",
        currency: "USD",
      });
      return dollarUS.format(price);
    },
    removeSecondsFromTime(timeIs) {
      //assuming time in 12:34:00 AM/PM format
      return timeIs.replace(/(\d{1,2}:\d{2}):\d{2}/, "$1");
    },
    subtotalFun() {
      let orderTotal = this.salesTotalFun("order_total");
      let orderTips = this.salesTotalFun("total_tips_added");
      let orderTaxes = this.salesTotalFun("order_taxes_calculated");
      let orderRefund = this.salesTotalFun("amount_refunded");
      let subtotal = (
        parseFloat(orderTotal) +
        parseFloat(orderTips) +
        parseFloat(orderTaxes) -
        parseFloat(orderRefund)
      ).toFixed(2);
      return parseFloat(subtotal).toFixed(2);
    },
    canUserAccessPageNow() {
      let currentLoggedInUserAccessData = localStorage.getItem(
        "current_logged_user_access_data"
      );
      if (currentLoggedInUserAccessData === null) {
        console.log("no log in");
        this.canUserAccessThis = false;
      } else {
        //now only admin or manager
        let currentLoggedInUserData = JSON.parse(currentLoggedInUserAccessData);
        if (
          currentLoggedInUserData[0].user_role == "admn" ||
          currentLoggedInUserData[0].user_role == "mngr"
        ) {
          console.log(
            "current user logged is " + currentLoggedInUserData[0].user_role
          );
          //   this.getCurrentCustomerDetails(this.restId, this.finderPrint);
          //   this.showOrderData = true;
        }
      }
    },
    printSection(id) {
      // Get HTML to print from element
      const prtHtml = document.getElementById(id).innerHTML;
      // Get all stylesheets HTML
      let stylesHtml = "";
      for (const node of [
        ...document.querySelectorAll('link[rel="stylesheet"], style'),
      ]) {
        stylesHtml += node.outerHTML;
      }
      // Open the print window
      const WinPrint = window.open(
        "",
        "",
        "left=0,top=0,width=800,height=900,toolbar=0,scrollbars=0,status=0"
      );

      WinPrint.document.write(`<!DOCTYPE html>
            <html>
            <head>
                ${stylesHtml}
            </head>
            <body>
                ${prtHtml}
            </body>
            </html>`);

      WinPrint.document.close();
      WinPrint.focus();
      WinPrint.print();
      WinPrint.close();
    },
    timeFormat12Hours(time) {
      // Check correct time format and split into components
      time = time
        .toString()
        .match(/^([01]\d|2[0-3])(:)([0-5]\d)(:[0-5]\d)?$/) || [time];

      if (time.length > 1) {
        // If time format correct
        time = time.slice(1); // Remove full string match value
        time[5] = +time[0] < 12 ? " AM" : " PM"; // Set AM/PM
        time[0] = +time[0] % 12 || 12; // Adjust hours
      }
      return time.join(""); // return adjusted time or original string
    },
    capitalizeFisrtLetterOfEachWord(text) {
      return text
        .toLowerCase()
        .split(" ")
        .map((s) => s.charAt(0).toUpperCase() + s.substring(1))
        .join(" ");
    },
    displayCustomDateTime(dateIs, timeIs) {
      const [year, month, day] = dateIs.split("-");
      const result = [month, day, year].join("/");
      let n = result + " at " + this.timeFormat12Hours(timeIs);
      return n;
    },
    goBackOrderReports() {
      this.$router.push({
        name: "ViewOrdersReports",
        params: { restId: this.restId },
      });
    },
    goBackOrders() {
      this.$router.push({
        name: "ViewOrders",
        params: { restId: this.restId },
      });
    },
  },
};
</script>

<style>
.addressStyle {
  font-style: italic;
  padding: 0;
  margin: 0;
  line-height: 0.5;
}

.itemDataDetails {
  padding: 10px;
  margin-bottom: 10px;
  border: 10px solid #eee;
}

.itemDataDetails img {
  width: 320px;
  height: 215px;
  margin-bottom: 10px;
}

.bw {
  overflow-wrap: break-word;
}

.darkred {
  color: darkred;
}

.order-placed {
  padding: 10px;
}

.order-placed h2 {
  color: green;
}

.addressInfo {
  display: block;
  font-style: italic;
  font-weight: bold;
}

.card-header {
  background-color: #008c5d !important;
  font-weight: bold;
}

.mb5 {
  margin-bottom: 5px;
}

.text-bold {
  font-weight: bold;
}

.card-header a,
.card-header a:hover,
.card-header a:visited,
.card-header a:active,
.card-header a:focus {
  color: white;
  display: block;
  text-align: left;
  border: none;
}

.card-header .btn-check:focus + .btn,
.card-header .btn:focus {
  box-shadow: none !important;
}

.fb {
  font-weight: bold;
}

.custom-order {
  margin-bottom: 10px;
}

.custom-name {
  padding-left: 20px;
  margin-bottom: -5px;
  clear: both;
  margin-top: -14px;
}

.total-end {
  font-weight: bold;
  margin-top: 5px;
}

.slidecontainer {
  width: 100%;
}

.slider {
  -webkit-appearance: none;
  width: 100%;
  height: 15px;
  border-radius: 5px;
  background: #d3d3d3;
  outline: none;
  opacity: 0.7;
  -webkit-transition: 0.2s;
  transition: opacity 0.2s;
}

.slider:hover {
  opacity: 1;
}

.slider::-webkit-slider-thumb {
  -webkit-appearance: none;
  appearance: none;
  width: 25px;
  height: 25px;
  border-radius: 50%;
  background: #04aa6d;
  cursor: pointer;
}

.slider::-moz-range-thumb {
  width: 25px;
  height: 25px;
  border-radius: 50%;
  background: #04aa6d;
  cursor: pointer;
}

.btns-danger,
.btns-danger:hover,
.btns-danger:active {
  color: red !important;
  background-color: white !important;
  border-color: red !important;
  font-weight: bold !important;
}

.redColor {
  color: red;
}
@media print {
  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  p,
  div,
  body,
  hr,
  .redColor,
  button,
  .btn,
  .biggerFont,
  .alert-danger,
  .btns-danger {
    font-size: 10pt;
    color: black !important;
    font-weight: bold !important;
    font-family: monospace !important;
    font-variant-caps: normal !important;
    font-optical-sizing: auto !important;
    font-style: normal !important;
    font-variant: proportional-nums !important;
    background-color: white !important;
  }
  .btns-danger,
  .btns-danger:hover,
  .btns-danger:active {
    color: black !important;
    background-color: white !important;
    border-color: black !important;
    font-weight: bold !important;
  }
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    font-size: 15pt;
    font-family: monospace !important;
  }

  .alert-danger {
    background-color: none;
    border-radius: 5px;
    border: 1px solid #e2e2e2;
  }

  .each-order-item {
    border: none !important;
  }
}
</style>
