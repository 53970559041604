<template>
  <div class="container">
    <div
      v-if="this.isRestExists"
      class="add-new-restaurant-page d-block d-xs-none"
    >
      <button
        class="d-print-none btn btn-outline-secondary m-2"
        v-if="currentRestaurantChecks.length > 0"
        @click="printSection2('printCheck')"
      >
        <faIcons :icon="['fas', 'print']" /> Print</button
      >&nbsp;

      <button
        class="d-print-none btn btn-outline-secondary"
        @click="exportToPDF()"
      >
        <faIcons class="pointerHand" :icon="['fas', 'file-pdf']" />
        Save</button
      >&nbsp;&nbsp;
      <router-link
        :to="{
          name: 'MyRestaurantChecks',
          params: { id: restNum, restId: restId },
        }"
      >
        <button
          class="d-print-none btn btn-outline-success"
          title="Delete Check"
        >
          <faIcons :icon="['fas', 'money-check']" /> All Checks
        </button>
      </router-link>
      <div v-if="resultErr" class="d-print-none alert alert-danger m-2">
        {{ resultErrMsg }}
      </div>
      <div
        v-if="currentRestaurantChecks.length > 0"
        id="detailedCheckReport"
        ref="document"
        class="table-responsive checkPrintSection"
      >
        <div
          v-for="each in currentRestaurantChecks"
          v-once
          :key="each.id + '-print'"
          class="container currentPage align-items-start"
          id="printCheck"
        >
          <div class="heightThirdPage mPos30">
            <div class="row">
              <div class="col mb-3">
                <h5>
                  <b>{{ capitalizeFisrtLetterOfEachWord(businessName) }}</b>
                </h5>
                <p class="m-0">
                  {{ capitalizeFisrtLetterOfEachWord(businessAddress) }}
                </p>
                <p class="m-0">{{ formatPhoneNumber(businessPhone) }}</p>
              </div>
              <div class="col text-center">
                <h5>
                  <b>{{ capitalizeFisrtLetterOfEachWord(bank_name_is) }}</b>
                </h5>
                <p>{{ formatDateFormulas(each.added_on) }}</p>
              </div>
              <div class="col h6 d-print-block">
                <b class="securityCode float-end mr-2">{{ each.check_num }}</b>
              </div>
            </div>
            <div class="clearfix"></div>
            <div class="row">
              <div class="col">
                <p>
                  <span class="smallLineHeight"><b>PAY TO THE</b></span
                  ><br /><span><b>ORDER OF</b></span>
                  <b class="ml-3">{{ each.pay_to.toUpperCase() }}</b>
                </p>
              </div>
              <!-- <div class="col"></div> -->
              <div class="col">
                <p class="text-center h5">
                  <b>{{ number_format(each.amount) }}</b>
                </p>
              </div>
            </div>
            <div class="clearfix"></div>
            <div class="row">
              <div class="col">
                <p>
                  <b class="addBorderBottom">{{
                    convertNumberToWords(each.amount)
                  }}</b>
                </p>
              </div>
            </div>
            <div class="clearfix"></div>
            <div class="row">
              <div class="col">
                <p>
                  <b>MEMO</b> {{ capitalizeFisrtLetterOfEachWord(each.memo) }}
                </p>
              </div>
              <!-- <div class="col"></div> -->
              <div class="col">
                <p class="text-center">
                  <b>________________________________________</b
                  ><br />AUTHORIZED SIGNATURE
                </p>
              </div>
            </div>
            <div class="clearfix"></div>
            <div class="row mt-1">
              <div class="col text-center d-print-block">
                <b class="securityCode"
                  >A{{ bank_routing_number_is }}A {{ bank_number_is }}C
                  {{ each.check_num }}</b
                >
              </div>
            </div>
            <div class="clearfix"></div>
          </div>
          <div class="heightThirdPage mPos30 mPos30">
            <div class="row mPos30">
              <div class="col mb-3 mPos30">
                <h5>
                  <b>{{ capitalizeFisrtLetterOfEachWord(businessName) }}</b>
                </h5>
                <p class="m-0">
                  {{ capitalizeFisrtLetterOfEachWord(businessAddress) }}
                </p>
                <p class="m-0">{{ formatPhoneNumber(businessPhone) }}</p>
              </div>
              <div class="col text-center mPos30">
                <h5>
                  <b>{{ capitalizeFisrtLetterOfEachWord(bank_name_is) }}</b>
                </h5>
                <p>{{ formatDateFormulas(each.added_on) }}</p>
              </div>
              <div class="col h6 d-print-block mPos30">
                <b class="securityCode float-end">{{ each.check_num }}</b>
              </div>
            </div>
            <div class="clearfix"></div>
            <div class="row">
              <div class="col">
                <p>
                  <span class="smallLineHeight"><b>PAY TO THE</b></span
                  ><br /><span><b>ORDER OF</b></span>
                  <b class="ml-3">{{ each.pay_to.toUpperCase() }}</b>
                </p>
              </div>
              <!-- <div class="col"></div> -->
              <div class="col">
                <p class="text-center">
                  <b>{{ number_format(each.amount) }}</b>
                </p>
              </div>
            </div>
            <div class="clearfix"></div>
            <div class="row">
              <div class="col">
                <p>
                  <b class="addBorderBottom">{{
                    convertNumberToWords(each.amount)
                  }}</b>
                </p>
              </div>
            </div>
            <div class="clearfix"></div>
            <div class="row">
              <div class="col m-3">
                <p>
                  <b>MEMO</b> {{ capitalizeFisrtLetterOfEachWord(each.memo) }}
                </p>
              </div>
            </div>
            <div class="clearfix"></div>
            <!-- <div class="row">
                            <div class="col text-center d-print-block"><b class="securityCode">A{{
                                bank_routing_number_is
                            }}A {{
    bank_number_is
}}C {{ each.check_num }}</b></div>
                        </div> -->
            <span class="mPos30"></span>
            <div class="clearfix"></div>
          </div>
          <div class="heightThirdPage mPos30 mPos30">
            <div class="row mPos30">
              <div class="col mPos30">
                <h5 class="mPos30">
                  <b>{{ capitalizeFisrtLetterOfEachWord(businessName) }}</b>
                </h5>
                <p class="m-0">
                  {{ capitalizeFisrtLetterOfEachWord(businessAddress) }}
                </p>
                <p class="m-0">{{ formatPhoneNumber(businessPhone) }}</p>
              </div>
              <div class="col text-center mPos30">
                <h5 class="mPos30">
                  <b>{{ capitalizeFisrtLetterOfEachWord(bank_name_is) }}</b>
                </h5>
                <p>{{ formatDateFormulas(each.added_on) }}</p>
              </div>
              <div class="col h6 d-print-block mPos30">
                <b class="securityCode float-end mPos30">{{
                  each.check_num
                }}</b>
              </div>
            </div>
            <div class="clearfix"></div>
            <div class="row">
              <div class="col">
                <p>
                  <span class="smallLineHeight"><b>PAY TO THE</b></span
                  ><br /><span><b>ORDER OF</b></span>
                  <b class="ml-3">{{ each.pay_to.toUpperCase() }}</b>
                </p>
              </div>
              <!-- <div class="col"></div> -->
              <div class="col">
                <p class="text-center">
                  <b>{{ number_format(each.amount) }}</b>
                </p>
              </div>
            </div>
            <div class="clearfix"></div>
            <div class="row">
              <div class="col">
                <p>
                  <b class="addBorderBottom">{{
                    convertNumberToWords(each.amount)
                  }}</b>
                </p>
              </div>
            </div>
            <div class="clearfix"></div>
            <div class="row">
              <div class="col m-3">
                <p>
                  <b>MEMO</b> {{ capitalizeFisrtLetterOfEachWord(each.memo) }}
                </p>
              </div>
            </div>
            <div class="clearfix"></div>
            <!-- <div class="row">
                            <div class="col text-center d-print-block"><b class="securityCode">A{{
        bank_routing_number_is
}}A {{
        bank_number_is
}}C {{ each.check_num }}</b></div>
                        </div> -->
            <div class="clearfix"></div>
          </div>
          <div class="clearfix"></div>
          <!-- <div class="align-items-start">
                    </div> -->
        </div>
      </div>
      <!-- <div v-else class="alert alert-danger m-2">
                This is not a valid page.
            </div> -->
    </div>
    <div v-else>
      <!-- Show error if no restaurant matches this Criteria -->
      <div class="alert alert-dark d-print-none" role="alert">
        <p>
          <faIcons :icon="['fas', 'store']" /> No restaurant found. Make sure
          you click in the right restaurant name.
        </p>
      </div>
      <router-link tag="li" :to="{ name: 'MyRestaurants' }">
        <a class="btn btn-success d-print-none">
          <faIcons :icon="['fas', 'store']" /> My restaurant(s)
        </a>
      </router-link>
    </div>
  </div>
</template>

<script>
import axios from "axios";
// import * as Printjs from "print-js";
// import printJS from "print-js";
import { mapState, mapMutations, mapGetters } from "vuex";
// import Navbar from "@/components/Header/Navbar.vue";
import jsPDF from "jspdf";
import * as html2pdf from "html2pdf.js";
import * as html2canvas from "html2canvas";
export default {
  name: "ViewAndPrintEachCheck",
  // components: {
  //     Navbar,
  // },
  data() {
    return {
      user: {
        uId: "",
        userId: "",
        uName: "",
        uEmail: "",
        uPassword: "",
        uMemberSince: "",
        uAddress: "",
        uPhone: "",
        uRole: "",
        uRoleIs: "",
      },
      restAcceptingOrderStatus: "",
      //cat
      catPrice: "",
      catPriceErr: false,
      showViewTimeHours: false,
      setUpPaymentAccount: false,
      catPriceMsg: "",
      iscatPriceValidated: false,
      //is my restaurant accepting online orders
      isMenuActive: "",
      isMenuActiveErr: false,
      isMenuActiveSuccess: false,
      isMenuActiveMsg: "Restaurant is now accepting online orders",
      isMenuActiveValidated: false,
      //Restaurant Info from URL
      restId: this.$route.params.restId,
      checkId: this.$route.params.checkId,
      restNum: this.$route.params.id,
      //phone
      end: new Date(
        new Date().getTime() - new Date().getTimezoneOffset() * 60000
      )
        .toISOString()
        .split("T")[0],
      endErr: false,
      endMsg: "",
      isendValidated: false,
      //name
      start: new Date(
        new Date().getTime() - new Date().getTimezoneOffset() * 60000
      )
        .toISOString()
        .split("T")[0],
      startErr: false,
      startMsg: "",
      isstartValidated: false,
      //email
      notes: "",
      notesErr: false,
      notesMsg: "",
      isnotesValidated: false,
      //address
      totalPayment: "",
      totalPaymentErr: false,
      totalPaymentMsg: "",
      istotalPaymentValidated: false,
      //show result
      resultSuccess: false,
      resultSuccessMsg: "",
      resultErr: false,
      resultErrMsg: "",
      //show onlnie hours result
      onlineHoursResultSuccess: false,
      onlineHoursResultSuccessMsg: "",
      onlineHoursResultErr: false,
      onlineHoursResultErrMsg: "",
      image: "",
      is_DB_Connected: "",
      db_connection_msg: "",
      // apiMainUrl: "http://localhost/php/login-system-php-vue-2021/myData.php",
      apiMainUrl: this.$store.getters.MainUrl, //call global variable
      signUpBtn: true,
      acceptOrdersBtn: true,
      onlineHoursBtn: true,
      isRestExists: true,
      isBankSetup: false,
      businessPhone: "",
      businessName: "",
      businessAddress: "",
      restaurant_added_by: "",
      currentRestaurantChecks: [],
      currentYear: new Date().getUTCFullYear(),
      bank_name_is: "",
      bank_number_is: "",
      bank_routing_number_is: "",
    };
  },
  mounted() {
    // const fontUrl = require('https://order.hooksfishnchips.com/fonts/micr-encoding.regular.ttf').default;
    // new FontFace('numbers', `url(${fontUrl})`);
    let user = localStorage.getItem("current-user-data");
    let currentRestaurants = localStorage.getItem("current_restaurants_data");
    let currentRestaurant = JSON.parse(currentRestaurants).filter(
      (restInfo) =>
        restInfo.id == this.restNum && restInfo.rest_id == this.restId
    );
    let restaurant_bank_details = localStorage.getItem(
      "restaurant_bank_details"
    );
    let currentRestaurantBankDetails = JSON.parse(
      restaurant_bank_details
    ).filter((restInfo) => restInfo.rest_id == this.restId);
    if (currentRestaurant.length > 0) {
      if (currentRestaurantBankDetails[0].bank_name.length > 0) {
        this.isBankSetup = true;
        this.bank_name_is = currentRestaurantBankDetails[0].bank_name;
        this.bank_number_is = currentRestaurantBankDetails[0].bank_number;
        this.bank_routing_number_is =
          currentRestaurantBankDetails[0].routing_number;
        // console.log("Is bank setup? length: ", currentRestaurantBankDetails.length);
        // console.table("Current bank name: ", currentRestaurantBankDetails[0].bank_name);
      } else {
        this.isBankSetup = false;
      }
      //set rest info
      this.businessPhone = currentRestaurant[0].rest_phone;
      this.businessName = currentRestaurant[0].rest_name;
      this.businessAddress = currentRestaurant[0].rest_address;
      this.restaurant_added_by = currentRestaurant[0].added_by;
      this.isRestExists = true;
      this.user.uId = JSON.parse(user).id;
      this.user.userId = JSON.parse(user).user_id;
      this.user.uName = JSON.parse(user).name;
      this.user.uEmail = JSON.parse(user).email;
      this.user.uPassword = JSON.parse(user).password;
      this.user.uMemberSince = JSON.parse(user).member_since;
      this.user.uAddress = JSON.parse(user).full_address;
      this.user.uPhone = JSON.parse(user).phone_number;
      this.user.uRole = JSON.parse(user).role;
      //if role isn't manager, redirect
      if (JSON.parse(user).role == "mngr" || JSON.parse(user).role == "admn") {
        this.getCurrentRestaurantChecks();
      } else {
        this.$router.push({ name: "MyRestaurants" });
      }
    } else {
      this.$router.push({ name: "MyRestaurants" });
    }
  },
  computed: {
    ...mapState([
      "__RestaurantId",
      "__RestaurantEmail",
      "__RestaurantRole",
      "__RestaurantName",
      "__MainUrl",
    ]),
    ...mapGetters(["MainUrl"]),
  },
  methods: {
    ...mapMutations(["setRestaurantInfo"]),
    exportToPDF() {
      this.showAddressPrint = true;
      this.showCustomer = false;
      this.showOrderNO = false;
      this.showOrderTime = false;
      setTimeout(() => {
        //https://pspdfkit.com/blog/2022/how-to-generate-a-pdf-with-vuejs/
        //https://www.youtube.com/watch?v=V0gMajxp990
        //good explanation for https://www.youtube.com/watch?v=zmJV6VN2h2E
        var element = document.getElementById("detailedCheckReport");
        var opt = {
          margin: 10, //margin is better when printing
          filename: this.businessName + "-Print Checks" + ".pdf",
          image: { type: "jpeg", quality: 0.98 },
          html2canvas: {
            scale: 4,
            width: 1100,
            height: 900,
            logging: true,
            dpi: 600,
            letterRendering: true,
            // useCORS: true,
            // scale: 2.5,
            // windowHeight: win_height * 96 - 3,
          },
          // pagebreak: { mode: ['avoid-all', 'css', 'legacy'] },
          // pagebreak: { before: '.beforeClass', after: ['#after1', '#after2'], avoid: 'img' },
          // pagebreak: { mode: "avoid-all", before: "#page2el" },
          jsPDF: { unit: "mm", format: "a4", orientation: "portrait" },
        };
        html2pdf().set(opt).from(element).save();
        // html2pdf(document.getElementById("detailedCheckReport"), {
        //     margin: 1,
        //     filename: "i-was-html.pdf",
        // });
        // this.showCustomer = true;
        // this.showOrderNO = true;
        this.showCustomer = true;
        this.showOrderNO = true;
        this.showOrderTime = true;
      }, 2000);
    },
    downLoadPdf() {
      setTimeout(() => {
        let pdfRef = this.$refs.detailedCheckReport;
        html2canvas(pdfRef).then((canvas) => {
          let pdfImage = canvas.toDataURL();
          //   let pdf = new jsPDF("landscape");
          let pdf = new jsPDF("p", "mm", "a4");
          let width = pdf.internal.pageSize.getWidth();
          let height = pdf.internal.pageSize.getHeight();
          //           var doc = new jsPDF("p", "mm", "a4");

          // var width = doc.internal.pageSize.getWidth();
          // var height = doc.internal.pageSize.getHeight();
          pdf.addImage(pdfImage, "PNG", width, height);
          pdf.save("Hooks-Print-Check.pdf");
        });
      }, 2000);
      // this.showCustomer = true;
      // this.showOrderNO = true;
    },
    // print(){
    //     printJS({
    //         printable: "printCheck",
    //         type: "HTML"
    //     });
    // },
    capitalizeFisrtLetterOfEachWord(text) {
      return text
        .toLowerCase()
        .split(" ")
        .map((s) => s.charAt(0).toUpperCase() + s.substring(1))
        .join(" ");
    },
    printSection(id) {
      // Get HTML to print from element
      const prtHtml = document.getElementById(id).innerHTML;
      // Get all stylesheets HTML
      let stylesHtml = "";
      for (const node of [
        ...document.querySelectorAll('link[rel="stylesheet"], style'),
      ]) {
        stylesHtml += node.outerHTML;
      }
      // Open the print window
      const WinPrint = window.open(
        "",
        "",
        "left=0,top=0,width=960,height=auto,toolbar=0,scrollbars=0,status=0"
      );

      WinPrint.document.write(`<!DOCTYPE html>
              <html>
              <head>
                  ${stylesHtml}
              </head>
              <body>
                  ${prtHtml}
              </body>
              </html>`);

      WinPrint.document.close();
      WinPrint.focus();
      WinPrint.print();
      WinPrint.close();
    },
    printSection2(id) {
      var restorepage = document.body.innerHTML;
      var printcontent = document.getElementById(id).innerHTML;
      document.body.innerHTML = printcontent;
      window.print();
      document.body.innerHTML = restorepage;
      setTimeout(() => {
        // window.open("", "_self").close();
        location.reload();
        // this.$router.push({ name: 'MyRestaurantChecks', params: { id: this.restNum, restId: this.restId } });
      }, 2000);
    },
    formatDateFormulas(dateIs) {
      const [year, month, day] = dateIs.split("-");
      const result = [month, day, year].join("/");
      let n = result;
      return n;
    },
    async getCurrentRestaurantChecks() {
      this.resultErr = false;
      this.showViewTimeHours = false;
      //this.currentRestaurantChecks
      let fd = new FormData();
      fd.append("userId", this.user.userId);
      fd.append("restId", this.restId);
      fd.append("checkId", this.checkId);
      fd.append("year", this.currentYear);
      try {
        let rst = await axios.post(
          this.apiMainUrl + `?action=getCurrentRestaurantCheck`,
          fd
        );
        if (rst.status == 200) {
          const resultData = rst.data;
          if (!resultData.error) {
            this.currentRestaurantChecks = resultData.currentRestaurantChecks;
            this.resultErr = false;
            this.showViewTimeHours = true;
          } else {
            //if error
            this.showViewTimeHours = false;
            this.resultErr = true;
            this.resultErrMsg = resultData.message;
          }
        }
      } catch (error) {
        setTimeout(() => {
          this.getCurrentRestaurantChecks();
        }, "1000");
      }
    },
    formatPhoneNumber(number) {
      //remove . + -  from any number
      let cleaned = ("" + number).replace(/\D/g, "");
      let match = "";
      if (number.length == 10) {
        match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
      } else if (number.length == 9) {
        match = cleaned.match(/^(\d{3})(\d{3})(\d{3})$/);
      } else if (number.length == 11) {
        match = cleaned.match(/^(\d{3})(\d{4})(\d{4})$/);
      } else if (number.length == 8) {
        match = cleaned.match(/^(\d{2})(\d{3})(\d{3})$/);
      }
      if (match) {
        return "(" + match[1] + ") " + match[2] + "-" + match[3];
      }
      return null;
    },
    number_format(amount) {
      const price = amount;
      // Format the price above to USD, INR, EUR using their locales.
      let dollarUS = Intl.NumberFormat("en-US", {
        style: "currency",
        currency: "USD",
      });
      return dollarUS.format(price);
    },
    // convertNumsToWords(number) {
    //     const first = ['', 'one ', 'two ', 'three ', 'four ', 'five ', 'six ', 'seven ', 'eight ', 'nine ', 'ten ', 'eleven ', 'twelve ', 'thirteen ', 'fourteen ', 'fifteen ', 'sixteen ', 'seventeen ', 'eighteen ', 'nineteen '];
    //     const tens = ['', '', 'twenty', 'thirty', 'forty', 'fifty', 'sixty', 'seventy', 'eighty', 'ninety'];
    //     const mad = ['', 'thousand', 'million', 'billion', 'trillion'];
    //     let word = '';

    //     for (let i = 0; i < mad.length; i++) {
    //         let tempNumber = number % (100 * Math.pow(1000, i));
    //         if (Math.floor(tempNumber / Math.pow(1000, i)) !== 0) {
    //             if (Math.floor(tempNumber / Math.pow(1000, i)) < 20) {
    //                 word = first[Math.floor(tempNumber / Math.pow(1000, i))] + mad[i] + ' ' + word;
    //             } else {
    //                 word = tens[Math.floor(tempNumber / (10 * Math.pow(1000, i)))] + '-' + first[Math.floor(tempNumber / Math.pow(1000, i)) % 10] + mad[i] + ' ' + word;
    //             }
    //         }

    //         tempNumber = number % (Math.pow(1000, i + 1));
    //         if (Math.floor(tempNumber / (100 * Math.pow(1000, i))) !== 0) word = first[Math.floor(tempNumber / (100 * Math.pow(1000, i)))] + 'hunderd ' + word;
    //     }
    //     return word;
    // },
    // convertNumToWords(numIs) {
    //     var a = ['', 'one ', 'two ', 'three ', 'four ', 'five ', 'six ', 'seven ', 'eight ', 'nine ', 'ten ', 'eleven ', 'twelve ', 'thirteen ', 'fourteen ', 'fifteen ', 'sixteen ', 'seventeen ', 'eighteen ', 'nineteen '];
    //     var b = ['', '', 'twenty', 'thirty', 'forty', 'fifty', 'sixty', 'seventy', 'eighty', 'ninety'];

    //     const convertNumberToString = num => {
    //         if ((num = num.toString()).length > 9) return 'overflow';
    //         n = ('000000000' + num).substr(-9).match(/^(\d{2})(\d{2})(\d{2})(\d{1})(\d{2})$/);
    //         if (!n) return; var str = '';
    //         str += (n[1] != 0) ? (a[Number(n[1])] || b[n[1][0]] + ' ' + a[n[1][1]]) + 'crore ' : '';
    //         str += (n[2] != 0) ? (a[Number(n[2])] || b[n[2][0]] + ' ' + a[n[2][1]]) + 'lakh ' : '';
    //         str += (n[3] != 0) ? (a[Number(n[3])] || b[n[3][0]] + ' ' + a[n[3][1]]) + 'thousand ' : '';
    //         str += (n[4] != 0) ? (a[Number(n[4])] || b[n[4][0]] + ' ' + a[n[4][1]]) + 'hundred ' : '';
    //         str += (n[5] != 0) ? ((str != '') ? 'and ' : '') + (a[Number(n[5])] || b[n[5][0]] + ' ' + a[n[5][1]]) + 'only ' : '';
    //         return str;
    //     }
    //     return convertNumberToString(numIs);
    // },
    convertNumberToWords(numIs) {
      const numbers = {
        0: "Zero",
        1: "One",
        2: "Two",
        3: "Three",
        4: "Four",
        5: "Five",
        6: "Six",
        7: "Seven",
        8: "Eight",
        9: "Nine",
        10: "Ten",
        11: "Eleven",
        12: "Twelve",
        13: "Thirteen",
        14: "Fourteen",
        15: "Fifteen",
        16: "Sixteen",
        17: "Seventeen",
        18: "Eighteen",
        19: "Nineteen",
        20: "Twenty",
        30: "Thirty",
        40: "Forty",
        50: "Fifty",
        60: "Sixty",
        70: "Seventy",
        80: "Eighty",
        90: "Ninety",
      };
      const numberScales = [
        "",
        "",
        "Thousand",
        "Million",
        "Billion",
        "Trillion",
        "Quadrillion",
        "Quintillion",
        "Sextillion",
        "Septillion",
        "Octillion",
        "Nonillion",
        "Decillion",
        "Undecillion",
        "Duodecilion",
        "Tredecilion",
        "Quattuordecilion",
        "Quindecilion",
        "Sexdecillion",
        "Septendecilion",
        "Octodecilion",
        "Novemdecilion",
        "Vigintilion",
      ];

      const convertNumberToString = (amount) => {
        let result = "";
        let isString = typeof amount === "string" || amount instanceof String;

        if (isNaN(amount)) {
          return `'${amount}' is not a valid number.`;
        }
        if (!isNaN(amount) && !isString && amount > Number.MAX_SAFE_INTEGER) {
          return `'${amount}' is past the bounds of MAX_SAFE_INTEGER. Please pass in the number wrapped in quotes (ex: '954.34') for an accurate conversion.`;
        }

        const amountString = amount.toString();
        const splitAmountArray = amountString.split(".");
        const amountIntegerString = splitAmountArray[0];
        const amountDecimalString = splitAmountArray[1] || "00";
        if (amountDecimalString > 99) {
          return `'${amount}' contains an invalid decimal amount. Please re-enter a value of 0-99 cents.`;
        }

        const tripleStack = [];

        for (let i = amountIntegerString.length; i > 0; i -= 3) {
          const startIndex = i - 3 < 0 ? 0 : i - 3;
          const tripleString = amountIntegerString.slice(startIndex, i);
          const tripleNum = parseInt(tripleString, 10);

          tripleStack.push(tripleNum);
        }

        tripleStack.reverse();
        tripleStack.forEach(function (triple, index) {
          const scalePosition = tripleStack.length - index;

          if (triple < 20) {
            result += ` ${numbers[triple]}`;
          } else if (triple < 100) {
            const tensValue = Math.floor(triple / 10) * 10;
            const onesValue = triple - tensValue;
            if (onesValue === 0) {
              result += ` ${numbers[tensValue]}`;
            } else {
              result += ` ${numbers[tensValue]}-${numbers[onesValue]}`;
            }
          } else {
            const hundredsValue = Math.floor(triple / 100) * 100;
            const realTensValue = triple - hundredsValue;
            console.log(
              "triple " +
                triple +
                " hundreds: " +
                hundredsValue +
                " realTens: " +
                realTensValue
            );
            const tensValue = Math.floor(realTensValue / 10) * 10;
            const onesValue = realTensValue - tensValue;

            if (hundredsValue > 0) {
              result += ` ${numbers[hundredsValue / 100]} Hundred`;
            }
            if (realTensValue < 20) {
              result += ` ${numbers[realTensValue]}`;
            } else {
              if (onesValue === 0) {
                result += ` ${numbers[tensValue]}`;
              } else {
                result += ` ${numbers[tensValue]}-${numbers[onesValue]}`;
              }
            }
          }
          let scale = numberScales[scalePosition];
          if (scale === undefined) scale = "bajillion";
          result += ` ${scale}`;
        });

        if (result) {
          // result += ` Dollar(s) & ${amountDecimalString}/100 Cents Only.`;
          result += ` & ${amountDecimalString}/100 U.S. Dollars Only.`;
        }

        return result;
      };
      return convertNumberToString(numIs);
    },
  },
};
</script>
<style lang="scss" scoped>
$navColor: #3f51b6;

// @font-face {
//     font-family: "numbers";
//     // src: url("https://order.hooksfishnchips.com/fonts/micr-encoding.regular.ttf") format("font-truetype");
//     src: url('https://order.hooksfishnchips.com/fonts/micr-encoding.regular.ttf') format("truetype");
// }

.heightThirdPage {
  min-height: 310px;
}

.securityCode {
  font-family: numbers !important;
  font-size: 30px !important;
  font-weight: bold !important;
  display: initial !important;
}
.currentPage {
  min-width: 960px !important;
}
/*
	https://support.idautomation.com/MICR-OCR-Fonts/How-to-enter-MICR-Special-Characters/_1893
	download font from : https://www.1001fonts.com/
	Use this font: to print checks font : 
	also make sure to download and save fonts/micr-encoding.regular.ttf
*/
//call check ttf file
// @import url('https://order.hooksfishnchips.com/micr-encoding.regular.ttf');
.form-data-success {
  color: darkgreen;
}

.mNeg20 {
  margin-top: -20px 0 20px 0;
}

.mNeg30 {
  margin-top: -40px 0 10px 0;
}

.mPos30 {
  margin-top: 20px 0 10px 0;
}

.smallerSize {
  font-size: 0.75em;
}

nav a {
  text-decoration: none;
  color: $navColor;
  font-weight: bold;
}

.pointerClick {
  cursor: pointer;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

.ml-3 {
  margin: 0 0 0 50px;
  border-bottom: 2px solid black;
}

.checkPrintSection {
  min-width: 1320px;
  width: 1320px;
  overflow-x: auto;
}

.addBorderBottom {
  border-bottom: 2px solid black;
}

.w100 {
  width: 100px;
}

.bgBlue {
  background-color: #0d6efd;
  color: white;
  padding: 10px;
  border-radius: 7px;
}

.addBorder {
  border-bottom: 1px solid #ced4da;
}

@media print {
  .mPos30 {
    margin-top: 20px !important;
  }

  .mr-2 {
    margin-right: 2px !important;
  }

  @page {
    margin: 20px !important;
    padding: 5px;
    font-weight: bold;
    color: black;
  }
  body {
    margin: 0;
    padding: 0;
  }
  .currentPage {
    width: 960px;
    height: auto;
    page-break-after: always;
  }
}

.smallLineHeight {
  line-height: 1px;
}

.p4 {
  padding: 4px;
}

@media only screen and (max-width: 600px) {
  .mPos30 {
    margin-top: 20px !important;
  }

  html,
  body {
    height: 100%;
  }

  .container {
    overflow-y: auto;
    height: 100%;
  }

  @page {
    margin: 20px !important;
    padding: 5px !important;
    font-weight: bold !important;
    color: black !important;
  }

  .checkPrintSection {
    min-width: auto !important;
    width: auto !important;
    overflow-x: auto !important;
  }

  .heightThirdPage {
    min-height: 315px !important;
  }
}
</style>
