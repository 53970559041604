<template>
  <div class="container">
    <Navbar />
    <nav style="--bs-breadcrumb-divider: '>'" aria-label="breadcrumb">
      <ol class="breadcrumb">
        <li class="breadcrumb-item">
          <router-link tag="li" :to="{ name: 'MyRestaurants' }">
            <a>My Restaurant(s)</a>
          </router-link>
        </li>
        <li
          v-if="this.isRestExists"
          class="breadcrumb-item active"
          aria-current="page"
        >
          {{ this.userName }}
        </li>
        <li
          v-if="this.isRestExists"
          class="breadcrumb-item active"
          aria-current="page"
        >
          Menu
        </li>
      </ol>
    </nav>
    <div v-if="this.isRestExists" class="add-new-restaurant-page">
      <p>
        {{ capitalizeFisrtLetterOfEachWord(userName) }}
        <faIcons :icon="['fas', 'map-pin']" />
        {{ capitalizeFisrtLetterOfEachWord(userAddress) }}
      </p>
      <!-- Add new category -->
      <div class="alert alert-info" role="alert">
        <p>
          <faIcons :icon="['fas', 'exclamation-circle']" />
          Categories can be used to customize/categorize your restaurant menu.
          Ex: Breakfast, Brunch, Lunch, Desserts, Specials
        </p>
      </div>
      <div class="clearfix"></div>
      <div class="float-start">
        <router-link
          :to="{
            name: 'AddNewCategory',
            params: { id: restNum, restId: restId },
          }"
        >
          <button class="btn btn-outline-success">
            <faIcons :icon="['fas', 'list']" /> New Category
          </button>
        </router-link>
      </div>
      <div class="clearfix"></div>
      <br />
      <!--View Menu Here -->
      <div class="restaurant-menu">
        <div
          v-if="menuCategories.length > 0"
          class="panel-group"
          id="accordion"
        >
          <div
            v-for="(i, iii) in menuCategories"
            :key="iii + i.id"
            class="menu-categories"
          >
            <div class="row menu-category-title">
              <div class="col-sm-6">
                <h3
                  class="catName"
                  data-bs-toggle="collapse"
                  :href="'#section' + iii + i.cat_id"
                >
                  <faIcons :icon="['fas', 'caret-right']" />
                  {{ capitalizeFisrtLetterOfEachWord(i.cat_name) }}
                </h3>
              </div>
              <div class="col-sm-6">
                <span class="float-end">
                  <router-link
                    class="btn btn-outline-danger"
                    :to="{
                      name: 'RemoveMenuCategory',
                      params: {
                        id: restNum,
                        restId: i.rest_id,
                        catId: i.cat_id,
                      },
                    }"
                  >
                    <faIcons :icon="['fas', 'trash']" />
                  </router-link>
                  &nbsp;
                  <router-link
                    class="btn btn-outline-success"
                    :to="{
                      name: 'UpdateMenuCategory',
                      params: {
                        id: restNum,
                        restId: i.rest_id,
                        catId: i.cat_id,
                      },
                    }"
                  >
                    <faIcons :icon="['fas', 'marker']" />
                  </router-link>
                  &nbsp;
                  <!-- target="_blank" -->
                  <router-link
                    :to="{
                      name: 'AddMenuItem',
                      params: {
                        id: restNum,
                        restId: restId,
                        menuCategoryId: i.cat_id,
                      },
                    }"
                  >
                    <button class="btn btn-outline-success">
                      <faIcons :icon="['fas', 'list']" /> New Item
                    </button>
                  </router-link>
                </span>

                <div class="clearfix"></div>
              </div>
            </div>
            <div class="clearfix"></div>
            <div
              class="row justify-content-around collapse"
              :id="'section' + iii + i.cat_id"
              data-bs-parent="#accordion"
            >
              <div
                v-for="n in currentMenuItem(i.cat_id)"
                :key="n"
                class="col-12 col-sm-3 each-category-list"
              >
                <!-- <hr /> -->
                <div v-if="n.item_image.length > 0" class="h250">
                  <!-- :src="getImageUrl(n.item_image)" -->
                  <img
                    :src="
                      'https://order.hooksfishnchips.com/restaurants/' +
                      n.item_image
                    "
                    class="rounded d-block mx-auto img-fluid w200"
                    :title="
                      n.item_name + ' | ' + userName + ' | ' + userAddress
                    "
                    :alt="n.item_name + ' | ' + userName + ' | ' + userAddress"
                  />
                </div>
                <div calss="h250" v-else>
                  <img
                    :src="getImageUrl('no-image.png')"
                    class="rounded d-block mx-auto img-fluid w200"
                    :title="userName + ' | ' + userAddress"
                    :alt="userName + ' | ' + userAddress"
                  />
                </div>
                <!-- <h5 class="price-tag">${{ n.item_price }}</h5> -->
                <!-- <p class="addressDisplay" v-if="n.item_description.length > 0">
                  Description<br />
                  <span>{{ n.item_description }}</span>
                </p> -->
                <!-- <p class="addressDisplay" v-else>
                  Description<br />
                  <span></span>
                </p> -->
                <div class="p3 each-menu-title-top">
                  <h4 class="c-brown">
                    {{ capitalizeFisrtLetterOfEachWord(n.item_name)
                    }}<span class="float-end">{{
                      number_format(n.item_price)
                    }}</span>
                  </h4>
                  <p class="text-muted fs90">
                    {{
                      this.capitalizeFisrtLetterOfEachWord(n.item_description)
                    }}
                  </p>
                </div>
                <div
                  class="p3 d-flex justify-content-evenly each-menu-buttons-bottom m-2"
                >
                  <span class="text-start" v-if="i.active == 1">
                    <!-- target="_blank" -->
                    <router-link
                      :to="{
                        name: 'UpdateMenuItem',
                        params: {
                          id: restNum,
                          restId: i.rest_id,
                          catId: i.cat_id,
                          menuItemId: n.item_rand_id,
                        },
                      }"
                    >
                      <button class="btn btn-success">
                        <faIcons :icon="['fas', 'edit']" />
                      </button>
                    </router-link>
                  </span>
                  <span class="text-end" v-if="i.active == 1">
                    <router-link
                      :to="{
                        name: 'RemoveMenuItem',
                        params: {
                          id: restNum,
                          restId: i.rest_id,
                          catId: i.cat_id,
                          menuItemId: n.item_rand_id,
                        },
                      }"
                    >
                      <button class="btn btn-danger">
                        <faIcons :icon="['fas', 'trash']" />
                      </button>
                    </router-link>
                  </span>
                  <span class="text-start" v-if="i.active == 1">
                    <router-link
                      :to="{
                        name: 'AssignMenuItemsModifier',
                        params: {
                          id: restNum,
                          restId: i.rest_id,
                          catId: i.cat_id,
                          menuItemId: n.item_rand_id,
                        },
                      }"
                    >
                      <button class="btn btn-info">
                        <faIcons :icon="['fas', 'plus']" /> Assign Modifier(s)
                      </button>
                    </router-link>
                  </span>
                </div>
                <div class="d-flex justify-content-evenly"></div>
              </div>
            </div>
          </div>
        </div>
        <div v-else>
          <div class="alert alert-info" role="alert">
            <p>
              <faIcons :icon="['fas', 'exclamation-circle']" /> No Menu items
              added yet! Start by adding menu categories first
            </p>
          </div>
        </div>
        <!-- View Modifiers -->
        <div class="clearfix"></div>
        <div class="alert alert-info" role="alert">
          <p>
            <faIcons :icon="['fas', 'exclamation-circle']" />
            Modifiers Groups are groups of items/options that are assigned to a
            product or category to add more details on an order
          </p>
          <p>
            <faIcons :icon="['fas', 'exclamation-circle']" />
            To help customers and yourself setup correct modifiers. You must
            create the main modifiers first, then create the other modifiers at
            the end. Ex: create first modifiers like select one of these plates
            modifiers, then add sauces modifiers
          </p>
          <p>
            <faIcons :icon="['fas', 'exclamation-circle']" />
            Modifier items/options are items that can be added to each Modifier
            Group to modify a food or drink item. <br />
            For example, if you serve steak you would have modifiers like rare,
            medium, and well done or add extra sauce $1.49, extra meat $9.99...
          </p>
        </div>
        <div class="clearfix"></div>
        <div class="float-start">
          <router-link
            :to="{
              name: 'AddNewMenuModifier',
              params: { id: restNum, restId: restId },
            }"
          >
            <button class="btn btn-outline-success">
              <faIcons :icon="['fas', 'list']" /> Add Modifier Group
            </button>
          </router-link>
        </div>
        <div class="clearfix"></div>
        <br />
      </div>
      <div class="clearfix"></div>
      <div class="" v-if="menuModifiers.length > 0">
        <div
          v-for="(i, nn) in menuModifiers"
          :key="i.id"
          class="menu-categories panel-group"
          id="accordion2"
        >
          <div class="each-modifier mb-10 menu-category-title">
            <div class="row">
              <div class="col-sm-6">
                <h4
                  class="catName"
                  data-bs-toggle="collapse"
                  :href="'#section' + nn + i.id"
                >
                  <faIcons :icon="['fas', 'caret-right']" />
                  {{ capitalizeFisrtLetterOfEachWord(i.group_name) }}
                </h4>
              </div>
              <div class="col-sm-6">
                <div class="clearfix"></div>
                <div class="float-end">
                  <span class="">
                    <router-link
                      class="btn btn-outline-danger"
                      :to="{
                        name: 'RemoveMenuModifier',
                        params: {
                          id: restNum,
                          restId: i.rest_id,
                          modifierId: i.group_name_rand_id,
                        },
                      }"
                    >
                      <faIcons :icon="['fas', 'trash']" />
                    </router-link>
                    &nbsp;
                    <router-link
                      class="btn btn-outline-success"
                      :to="{
                        name: 'UpdateMenuModifier',
                        params: {
                          id: restNum,
                          restId: i.rest_id,
                          modifierId: i.group_name_rand_id,
                        },
                      }"
                    >
                      <faIcons :icon="['fas', 'marker']" />
                    </router-link>
                  </span>
                  &nbsp;
                  <router-link
                    :to="{
                      name: 'AddMenuModifierItem',
                      params: {
                        id: restNum,
                        restId: i.rest_id,
                        modifierId: i.group_name_rand_id,
                      },
                    }"
                  >
                    <button class="btn btn-outline-success">
                      <faIcons :icon="['fas', 'list']" /> New Item
                    </button>
                  </router-link>
                  <br />
                </div>
                <div class="clearfix"></div>
              </div>
            </div>
            <div class="clearfix"></div>
            <div
              class="table-responsive m-2 collapse"
              :id="'section' + nn + i.id"
              data-bs-parent="#accordion2"
            >
              <table class="table">
                <thead class="table-success">
                  <tr>
                    <th class="header" scope="col">Item Name</th>
                    <th class="header" scope="col">Price</th>
                    <th class="header" scope="col">Actions</th>
                  </tr>
                </thead>
                <tbody>
                  <tr
                    v-for="x in currentModifierItem(i.group_name_rand_id)"
                    :key="x"
                  >
                    <td>{{ capitalizeFisrtLetterOfEachWord(x.item_name) }}</td>
                    <td>{{ number_format(x.item_price) }}</td>
                    <td>
                      <span class="">
                        <router-link
                          class="btn btn-outline-danger"
                          :to="{
                            name: 'RemoveMenuModifierItem',
                            params: {
                              id: restNum,
                              restId: i.rest_id,
                              modifierId: i.group_name_rand_id,
                              modifierItemId: x.group_items_names_rand_id,
                            },
                          }"
                        >
                          <faIcons :icon="['fas', 'trash']" />
                        </router-link>
                        &nbsp;
                        <router-link
                          class="btn btn-outline-success"
                          :to="{
                            name: 'UpdateMenuModifierItem',
                            params: {
                              id: restNum,
                              restId: i.rest_id,
                              modifierId: i.group_name_rand_id,
                              modifierItemId: x.group_items_names_rand_id,
                            },
                          }"
                        >
                          <faIcons :icon="['fas', 'marker']" />
                        </router-link>
                      </span>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
      <div v-else>
        <div class="alert alert-info" role="alert">
          <p>
            <faIcons :icon="['fas', 'exclamation-circle']" /> No Modifiers added
            yet! Start by adding modifiers now
          </p>
        </div>
      </div>
      <div class="clearfix"></div>
      <!-- View Categories -->
    </div>
    <div v-else>
      <!-- Show error if no restaurant matches this Criteria -->
      <div class="alert alert-dark" role="alert">
        <p>
          <faIcons :icon="['fas', 'store']" /> No restaurant found. Make sure
          you click in the right restaurant name.
        </p>
      </div>
      <router-link tag="li" :to="{ name: 'MyRestaurants' }">
        <a class="btn btn-success">
          <faIcons :icon="['fas', 'store']" /> Go back to my restaurant(s)
        </a>
      </router-link>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import { mapState, mapMutations, mapGetters } from "vuex";
import Navbar from "@/components/Header/Navbar.vue";
export default {
  name: "CurrentRestaurantMenu",
  components: {
    Navbar,
  },
  data() {
    return {
      user: {
        uId: "",
        userId: "",
        uName: "",
        uEmail: "",
        uPassword: "",
        uMemberSince: "",
        uAddress: "",
        uPhone: "",
        uRole: "",
        uRoleIs: "",
      },
      //current Menu Categroies
      menuCategories: [],
      menuModifiers: [],
      //Restaurant Info from URL
      restId: this.$route.params.restId,
      restNum: this.$route.params.id,
      //address
      userAddress: "",
      userAddressErr: false,
      userAddressMsg: "",
      isuserAddressValidated: false,
      //each modifier Items
      eachModifierContent: [],
      //phone
      userPhone: "",
      userPhoneErr: false,
      userPhoneMsg: "",
      isuserPhoneValidated: false,
      //name
      userName: "",
      userNameErr: false,
      userNameMsg: "",
      isuserNameValidated: false,

      resultSuccess: false,
      resultSuccessMsg: "",
      resultErr: false,
      resultErrMsg: "",
      image: "",
      is_DB_Connected: "",
      db_connection_msg: "",
      // apiMainUrl: "http://localhost/php/login-system-php-vue-2021/myData.php",
      apiMainUrl: this.$store.getters.MainUrl, //call global variable
      signUpBtn: true,
      isRestExists: false,
      currentMenuModifiersItems: "",
    };
  },
  mounted() {
    let user = localStorage.getItem("current-user-data");
    let currentRestaurants = localStorage.getItem("current_restaurants_data");
    let currentMenuCategories = localStorage.getItem(
      "current_user_menu_categories_data"
    );
    let currentMenuModifiers = localStorage.getItem(
      "current_user_menu_modifiers_data"
    );
    // currentMenuCategoriesList = this.menuCategories;
    if (user != null && currentRestaurants != null) {
      this.menuCategories = JSON.parse(currentMenuCategories).filter(
        (restInfo) => restInfo.rest_id == this.restId
      );
      this.menuModifiers = JSON.parse(currentMenuModifiers).filter(
        (restInfo) => restInfo.rest_id == this.restId
      );
      this.currentMenuModifiersItems = JSON.parse(
        localStorage.getItem("current_user_menu_modifiers_items_data")
      );
      console.table(this.menuCategories);
      console.table(this.menuModifiers);
      console.table(currentRestaurant);
      //set rest info
      let currentRestaurant = JSON.parse(currentRestaurants).filter(
        (restInfo) =>
          restInfo.id == this.restNum && restInfo.rest_id == this.restId
      );
      // if (currentRestaurant.length > 0) {
      //   this.isRestExists = true;
      // }
      this.userPhone = currentRestaurant[0].rest_phone;
      this.userName = currentRestaurant[0].rest_name;
      this.userAddress = currentRestaurant[0].rest_address;
      //get users info
      this.user.uId = JSON.parse(user).id;
      this.user.userId = JSON.parse(user).user_id;
      this.user.uName = JSON.parse(user).name;
      this.user.uEmail = JSON.parse(user).email;
      this.user.uPassword = JSON.parse(user).password;
      this.user.uMemberSince = JSON.parse(user).member_since;
      this.user.uAddress = JSON.parse(user).full_address;
      this.user.uPhone = JSON.parse(user).phone_number;
      this.user.uRole = JSON.parse(user).role;
      //if role isn't manager or admin, redirect
      if (currentRestaurant[0].active == 0) {
        //if restaurant isn't active
        this.$router.push({ name: "MyRestaurants" });
      } else if (
        JSON.parse(user).role !== "mngr" &&
        JSON.parse(user).role !== "admn"
      ) {
        this.$router.push({ name: "Login" });
      }
    } else {
      this.$router.push({ name: "Login" });
    }
  },
  computed: {
    ...mapState([
      "__RestaurantId",
      "__RestaurantEmail",
      "__RestaurantRole",
      "__RestaurantName",
      "__MainUrl",
    ]),
    ...mapGetters(["MainUrl"]),
  },
  methods: {
    ...mapMutations(["setRestaurantInfo"]),
    getImageUrl(imgName) {
      let img = require.context("@/../restaurants/");
      console.log(img);
      return img("./" + imgName);
    },
    currentModifierItem(modifierIdIs) {
      let currentModifiersItemsData = JSON.parse(
        localStorage.getItem("current_user_menu_modifiers_items_data")
      );
      let newData = currentModifiersItemsData.filter(
        (restInfo) => restInfo.group_rand_id == modifierIdIs
      );
      return newData;
    },
    currentMenuItem(catIdIs) {
      let currentMenuItemsData = JSON.parse(
        localStorage.getItem("current_user_menu_items_data")
      );
      let newData = currentMenuItemsData.filter(
        (restInfo) => restInfo.cat_id == catIdIs
      );
      return newData;
    },
    //validate Restaurant Name
    validateRestaurantName(e) {
      let val = e.target.value;
      this.validateRestaurantNameInput(val);
    },
    validateRestaurantNameInput(val) {
      // let userNameRegex = /^[a-zA-Z ]+$/; //allow spaces
      // let userNameRegex = /[^A-Za-z ]/g; //allow spaces, international letters
      // console.log("Is Restaurant Name Validated? " + userNameRegex.test(val));
      if (val == "") {
        this.userNameErr = true;
        this.isuserNameValidated = false;
        this.userNameMsg = "Must Enter Restaurant Name ..";
      } else {
        this.userNameErr = false;
        this.isuserNameValidated = true;
        this.userNameMsg = "";
      }
    },
    //validate Restaurant Name
    validateRestaurantPhone(e) {
      let val = e.target.value;
      this.validateRestaurantPhoneInput(val);
    },
    validateRestaurantPhoneInput(val) {
      // let userPhoneRegex = /^\d{10}$/;
      console.log("Restaurant Phone: " + val);
      if (val == "") {
        this.userPhoneErr = true;
        this.isuserPhoneValidated = false;
        this.userPhoneMsg = "Must Enter Restaurant Phone ..";
      } else if (val !== "" && (val.length > 11 || val.length < 10)) {
        this.userPhoneErr = true;
        this.isuserPhoneValidated = false;
        this.userPhoneMsg = "Must Only Enter 9-11 Digits ..";
      } else {
        this.userPhoneErr = false;
        this.isuserPhoneValidated = true;
        this.userPhoneMsg = "";
      }
    },
    //validate Restaurant Address
    validateRestaurantAddress(e) {
      let val = e.target.value;
      this.validateRestaurantAddressInput(val);
    },
    validateRestaurantAddressInput(val) {
      // let userAddressRegex = /^[a-zA-Z0-9 \n]+$/;
      // console.log("Is Restaurant Address Validated? " + userAddressRegex.test(val));
      console.log("Restaurant Address Is: " + val);
      if (val == "") {
        this.userAddressErr = true;
        this.isuserAddressValidated = false;
        this.userAddressMsg = "Must Enter Restaurant Address ..";
      } else {
        this.userAddressErr = false;
        this.isuserAddressValidated = true;
        this.userAddressMsg = "";
      }
    },
    number_format(amount) {
      const price = amount;
      // Format the price above to USD, INR, EUR using their locales.
      let dollarUS = Intl.NumberFormat("en-US", {
        style: "currency",
        currency: "USD",
      });
      return dollarUS.format(price);
    },
    capitalizeFisrtLetterOfEachWord(text) {
      return text
        .toLowerCase()
        .split(" ")
        .map((s) => s.charAt(0).toUpperCase() + s.substring(1))
        .join(" ");
    },
    async UpdateCurrentRestaurant() {
      this.validateRestaurantNameInput(this.userName);
      this.validateRestaurantPhoneInput(this.userPhone);
      this.validateRestaurantAddressInput(this.userAddress);
      if (
        this.isuserNameValidated &&
        this.isuserPhoneValidated &&
        this.isuserAddressValidated
      ) {
        this.signUpBtn = false;
        //show Result
        let fd = new FormData();
        fd.append("userId", this.user.userId);
        fd.append("name", this.userName);
        fd.append("phone", this.userPhone);
        fd.append("address", this.userAddress);
        fd.append("restId", this.restId);
        fd.append("restNum", this.restNum);
        let rst = await axios.post(
          this.apiMainUrl + `?action=UpdateCurrentRestaurant`,
          fd
        );
        console.log(rst);
        const resultData = rst.data;
        if (rst.status == 200) {
          if (resultData.error) {
            console.warn("Connection Error Happened on Adding New Restaurant");
            this.resultErrMsg = resultData.message;
            this.resultErr = true;
            this.resultSuccessMsg = "";
            this.resultSuccess = false;
            this.signUpBtn = true;
          } else {
            this.signUpBtn = false;
            console.log("Good Job, resrautant has been added successfully..");
            //if everything is okay
            this.resultErrMsg = "";
            this.resultErr = false;
            this.resultSuccess = true;
            //set restaurant to local host: current_restaurants_data
            localStorage.setItem(
              "current_restaurants_data",
              JSON.stringify(resultData.current_restaurants_data)
            );
            //redirect only if customer
            this.resultSuccessMsg = resultData.message;
            setTimeout(() => {
              this.$router.push({ name: "MyRestaurants" });
              console.log("Restaurant Name: " + this.userName);
            }, 2000);
          }
        }
      } else {
        this.signUpBtn = true;
        this.validateRestaurantNameInput(this.userName);
        this.validateRestaurantPhoneInput(this.userPhone);
        this.validateRestaurantAddressInput(this.userAddress);
        this.resultSuccessMsg = "";
        this.resultSuccess = false;

        this.resultErrMsg = "Must enter all required information";
        this.resultErr = true;
      }
    },
  },
};
</script>
<style lang="scss" scoped>
$navColor: #3f51b6;

nav a {
  text-decoration: none;
  color: $navColor;
  font-weight: bold;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

.catName {
  color: #ad0505;
  background-color: #03a56f12;
  padding: 10px;
  width: 100%;
  // margin: 0 5%;
  cursor: pointer;
}

.each-category-list {
  position: relative;
  padding: 0;
  border-radius: 0;
  box-shadow: 2px 2px 4px 0px #525a5a;
  margin: 8px;
  border-radius: 5px;
  margin-bottom: 20px;
}

.each-menu-title-top {
  position: absolute;
  top: 0;
  background-color: #fffffff2;
  display: block;
  width: 100%;
}

.each-menu-buttons-bottom {
  position: absolute;
  bottom: 1px;
  background-color: #ffffff5e;
  display: block;
  width: 96%;
}

.price-tag {
  text-align: center;
  padding: 10px;
  font-weight: bold;
}

.title-tag {
  text-align: left;
  padding: 10px;
  font-weight: bold;
}

.addressDisplay {
  white-space: pre-wrap;
}

.menu-category-title {
  background-color: #ffffff;
  padding: 10px;
  color: black;
  font-weight: bold;
  box-shadow: 2px 2px 7px #4c95af;
  margin-bottom: 9px;
}

.menu-category-title h4 {
  color: darkblue;
  font-weight: bold;
}

.mb-10 {
  margin-bottom: 10px;
}

.fs90 {
  font-size: 0.9em;
}

.h250 {
  height: 250px;
  max-height: 250px;
}

.w200 {
  //   width: 200px;
  //   height: 200px;
  //   max-width: 200px;
  height: 100%;
  width: 100%;
}

.c-brown {
  color: #bf301a;
}

.p3 {
  padding: 5px;
}
</style>
