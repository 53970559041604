<template>
  <div class="view-account-info">
    <div class="row g-3 align-items-center mb-3">
      <div class="col-auto d-block mx-auto">
        <h3>Update Your Account</h3>
      </div>
    </div>
    <div class="row g-3 align-items-center mb-3">
      <div class="col-auto d-block mx-auto">
        <div class="form-floating" :class="{ 'form-data-error': userEmailErr }">
          <input type="email" id="userEmailInput" placeholder="User Email" @keyup="validateUserEmail($event)"
            @change="validateUserEmail($event)" class="form-control w300" v-model.trim="user.uEmail" />
          <label for="userEmailInput">
            <faIcons :icon="['fas', 'envelope']" /> User Email
          </label>
          <span class="error-feedback" v-if="userEmailErr">
            {{ userEmailMsg }}
          </span>
        </div>
      </div>
    </div>

    <div class="row g-3 align-items-center mb-3">
      <div class="col-auto d-block mx-auto">
        <div class="form-floating" :class="{ 'form-data-error': userNameErr }">
          <input type="text" id="userNameInput" placeholder="User Name" class="form-control w300"
            @keyup="validateUserName($event)" @change="validateUserName($event)" v-model.trim="user.uName" />
          <label for="userNameInput">
            <faIcons :icon="['fas', 'user-circle']" /> User Name
          </label>
          <span class="error-feedback" v-if="userNameErr">
            {{ userNameMsg }}
          </span>
        </div>
      </div>
    </div>

    <div class="row g-3 align-items-center mb-3">
      <div class="col-auto d-block mx-auto">
        <div class="form-floating" :class="{ 'form-data-error': userPhoneErr }">
          <input type="number" min="0" id="userPhoneInput" placeholder="User Phone" @keyup="validateUserPhone($event)"
            @change="validateUserPhone($event)" class="form-control w300" v-model.trim="user.uPhone" />
          <label for="userPhoneInput">
            <faIcons :icon="['fas', 'phone']" /> User Phone
          </label>
          <span class="error-feedback" v-if="userPhoneErr">
            {{ userPhoneMsg }}
          </span>
        </div>
      </div>
    </div>
    <div class="row g-3 align-items-center mb-3">
      <div class="col-auto d-block mx-auto">
        <div class="form-floating" :class="{ 'form-data-error': userAddressErr }">
          <textarea id="userAddressInput" placeholder="User Full Address" @input="validateUserAddress($event)"
            class="noResize form-control w300 h100" v-model.trim="user.uAddress" />
          <label for="userAddressInput">
            <faIcons :icon="['fas', 'address-book']" /> User Address
          </label>
          <span class="error-feedback" v-if="userAddressErr">
            {{ userAddressMsg }}
          </span>
        </div>
      </div>
    </div>
    <div class="row d-grid gap-2 w300 col-auto d-block mx-auto m-1">
      <button class="btn btn-outline-success" @click.prevent="accountInfoUpdate()" v-if="signUpBtn">
        <faIcons :icon="['fas', 'user-edit']" /> Update Now
      </button>
    </div>
    <div class="row d-grid gap-2 w300 col-auto d-block mx-auto m-1">
      <div class="alert alert-success" role="alert" v-if="resultSuccess">
        {{ resultSuccessMsg }}
      </div>
      <div class="alert alert-danger" role="alert" v-if="resultErr">
        {{ resultErrMsg }}
      </div>
    </div>
    <div class="row d-grid gap-2 w300 col-auto d-block mx-auto m-2">
      <button class="btn btn-outline-primary" @click.prevent="this.$router.go(-1)">
        <faIcons :icon="['fas', 'undo']" /> Go Back
      </button>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import { mapState, mapGetters } from "vuex";
export default {
  name: "AccountPageUpdate",
  data() {
    return {
      user: {
        uId: "",
        uUserId: "",
        uName: "",
        uEmail: "",
        uPassword: "",
        uMemberSince: "",
        uAddress: "",
        uPhone: "",
        uRole: "",
        uRoleIs: "",
      },
      //email
      userEmail: "",
      userEmailErr: false,
      userEmailMsg: "",
      isuserEmailValidated: false,
      //address
      userAddress: "",
      userAddressErr: false,
      userAddressMsg: "",
      isuserAddressValidated: false,
      //phone
      userPhone: "",
      userPhoneErr: false,
      userPhoneMsg: "",
      isuserPhoneValidated: false,
      //name
      userName: "",
      userNameErr: false,
      userNameMsg: "",
      isuserNameValidated: false,

      resultSuccess: false,
      resultSuccessMsg: "",
      resultErr: false,
      resultErrMsg: "",
      image: "",
      is_DB_Connected: "",
      db_connection_msg: "",
      // apiMainUrl: "http://localhost/php/login-system-php-vue-2021/myData.php",
      apiMainUrl: this.$store.getters.MainUrl, //call global variable
      signUpBtn: true,
    };
  },
  mounted() {
    let user = localStorage.getItem("current-user-data");
    if (user) {
      this.user.uId = JSON.parse(user).id;
      this.user.uUserId = JSON.parse(user).user_id;
      this.user.uName = JSON.parse(user).name;
      this.user.uEmail = JSON.parse(user).email;
      this.user.uPassword = JSON.parse(user).password;
      this.user.uMemberSince = JSON.parse(user).member_since;
      this.user.uAddress = JSON.parse(user).full_address;
      this.user.uPhone = JSON.parse(user).phone_number;
      this.user.uRole = JSON.parse(user).role;
      if (JSON.parse(user).role == "mngr") {
        this.user.uRoleIs = "Manager";
      } else if (JSON.parse(user).role == "empl") {
        this.user.uRoleIs = "Employee";
      } else if (JSON.parse(user).role == "cstmr") {
        this.user.uRoleIs = "Customer";
      } else if (JSON.parse(user).role == "admn") {
        this.user.uRoleIs = "IT/Admin";
      }
      //set current name, email, phone and address to current user val
      this.userName = this.user.uName;
      this.userAddress = this.user.uAddress;
      this.userPhone = this.user.uPhone;
      this.userEmail = this.user.uEmail;
    } else {
      this.$router.push({ name: "Login" });
    }
  },
  computed: {
    ...mapState([
      "__UserId",
      "__UserEmail",
      "__UserRole",
      "__UserName",
      "__MainUrl",
    ]),
    ...mapGetters(["MainUrl"]),
  },
  methods: {
    getRole(role) {
      let roleIs = "";
      if (role == "mngr") {
        roleIs == "Manager";
      } else if (role == "empl") {
        roleIs == "Employee";
      } else if (role == "cstmr") {
        roleIs == "Customer";
      } else if (role == "admn") {
        roleIs == "IT/Admin";
      }
      return roleIs;
    },
    validateUserEmail(e) {
      let val = e.target.value;
      this.validateUserEmailInput(val);
    },
    validateUserEmailInput(val) {
      let emailIs = /\w+([-+.']\w+)*@\w+([-.]\w+)*\.\w+([-.]\w+)*/.test(val);
      if (val == "") {
        this.userEmailErr = true;
        this.isuserEmailValidated = false;
        this.userEmailMsg = "Must Enter User Email ..";
      } else if (!emailIs && val != "") {
        this.userEmailErr = true;
        this.isuserEmailValidated = false;
        this.userEmailMsg = "Must Enter Valid Email ..";
      } else {
        this.userEmailErr = false;
        this.isuserEmailValidated = true;
        this.userEmailMsg = "";
      }
    },
    //validate User Name
    validateUserName(e) {
      let val = e.target.value;
      this.validateUserNameInput(val);
    },
    validateUserNameInput(val) {
      // let userNameRegex = /^[a-zA-Z ]+$/; //allow spaces
      // let userNameRegex = /[^A-Za-z ]/g; //allow spaces, international letters
      // console.log("Is User Name Validated? " + userNameRegex.test(val));
      if (val == "") {
        this.userNameErr = true;
        this.isuserNameValidated = false;
        this.userNameMsg = "Must Enter User Name ..";
      } else {
        this.userNameErr = false;
        this.isuserNameValidated = true;
        this.userNameMsg = "";
      }
    },
    //validate User Name
    validateUserPhone(e) {
      let val = e.target.value;
      this.validateUserPhoneInput(val);
    },
    validateUserPhoneInput(val) {
      // let userPhoneRegex = /^\d{10}$/;
      console.log("User Phone: " + val);
      if (val == "") {
        this.userPhoneErr = true;
        this.isuserPhoneValidated = false;
        this.userPhoneMsg = "Must Enter User Phone ..";
      } else if (val !== "" && (val.length > 11 || val.length < 8)) {
        this.userPhoneErr = true;
        this.isuserPhoneValidated = false;
        this.userPhoneMsg = "Must Only Enter 9-11 Digits ..";
      } else {
        this.userPhoneErr = false;
        this.isuserPhoneValidated = true;
        this.userPhoneMsg = "";
      }
    },
    //validate User Role
    validateUserRole(e) {
      let val = e.target.value;
      this.validateUserRoleInput(val);
    },
    validateUserRoleInput(val) {
      const roles = ["manager", "employee", "customer"];
      console.log("Is User Role Validated? " + roles.includes(val));
      if (val == "") {
        this.userRoleErr = true;
        this.isuserRoleValidated = false;
        this.userRoleMsg = "Must Select Your Role ..";
      } else if (roles.includes(val) == false) {
        this.userRoleErr = true;
        this.isuserRoleValidated = false;
        this.userRoleMsg = "Must Enter Valid User Role ..";
      } else {
        this.userRoleErr = false;
        this.isuserRoleValidated = true;
        this.userRoleMsg = "";
      }
    },
    //validate User Address
    validateUserAddress(e) {
      let val = e.target.value;
      this.validateUserAddressInput(val);
    },
    validateUserAddressInput(val) {
      // let userAddressRegex = /^[a-zA-Z0-9 \n]+$/;
      // console.log("Is User Address Validated? " + userAddressRegex.test(val));
      console.log("User Address Is: " + val);
      if (val == "") {
        this.userAddressErr = true;
        this.isuserAddressValidated = false;
        this.userAddressMsg = "Must Enter Your Address ..";
      } else {
        this.userAddressErr = false;
        this.isuserAddressValidated = true;
        this.userAddressMsg = "";
      }
    },
    async accountInfoUpdate() {
      this.resultErr = false;
      this.resultErrMsg = "";
      this.resultSuccess = true;
      this.resultSuccessMsg =
        "Please wait while we're updating your account...";
      this.validateUserEmailInput(this.user.uEmail);
      this.validateUserNameInput(this.user.uName);
      this.validateUserPhoneInput(this.user.uPhone);
      this.validateUserAddressInput(this.user.uAddress);
      if (
        this.isuserEmailValidated &&
        this.isuserNameValidated &&
        this.isuserPhoneValidated &&
        this.isuserAddressValidated
      ) {
        //show Result
        this.signUpBtn = false;
        let fd = new FormData();
        fd.append("userId", this.user.uUserId);
        fd.append("i", this.user.uId);
        fd.append("email", this.user.uEmail);
        fd.append("name", this.user.uName);
        fd.append("phone", this.user.uPhone);
        fd.append("address", this.user.uAddress);
        let rst = await axios.post(
          this.apiMainUrl + `?action=updateUserAccountInfo`,
          fd
        );
        console.log(rst);
        const resultData = rst.data;
        if (rst.status == 200) {
          if (resultData.error) {
            console.warn("Connection Error Happened on updating your account");
            this.resultErrMsg = resultData.message;
            this.resultErr = true;
            this.resultSuccessMsg = "";
            this.resultSuccess = false;
            this.signUpBtn = true;
          } else {
            this.signUpBtn = false;
            console.log("Good Job, you have updated your account..");
            //if everything is okay
            this.resultErrMsg = "";
            this.resultErr = false;
            this.resultSuccess = true;
            this.resultSuccessMsg = resultData.message;
            localStorage.setItem(
              "current-user-data",
              JSON.stringify(resultData.current_user_data[0])
            );
            setTimeout(() => {
              this.$router.push({
                name: "Account",
              });
            }, 2000);
          }
        }
      } else {
        this.signUpBtn = true;
        this.validateUserEmailInput(this.user.uEmail);
        this.validateUserNameInput(this.user.uName);
        this.validateUserPhoneInput(this.user.uPhone);
        this.validateUserAddressInput(this.user.uAddress);
        this.resultSuccessMsg = "";
        this.resultSuccess = false;

        this.resultErrMsg = "Must enter all information";
        this.resultErr = true;
      }
    },
  },
};
</script>

<style scoped>
.form-control:disabled,
.form-control[readonly] {
  background-color: #ffffff;
}

.noResize {
  resize: none;
}
</style>
