<template>
    <ul id="nav">
      <router-link tag="li" :to="{ name: 'Home' }" exact>
        <a><faIcons :icon="['fas', 'home']" /> Home</a>
      </router-link>
      <router-link tag="li" :to="{ name: 'Account' }">
        <a><faIcons :icon="['fas', 'id-card-alt']" /> Account</a>
      </router-link>
      <a href="#" @click="logMeOut()"
        ><faIcons :icon="['fas', 'user-lock']" /> Logout</a
      >
    </ul>
  </template>
  
  <script>
  export default {
    name: "CoManagerPanelHeader",
    methods: {
      logMeOut() {
        localStorage.clear();
        this.$router.push({ name: "Login" });
      },
    },
  };
  </script>
  
  <style lang="scss" scoped>
  $fontColor: darkcyan;
  $navColor: #525a5a;
  ul {
    display: flex;
    justify-content: center;
    list-style: none;
    margin-bottom: 50px;
    a {
      text-decoration: none;
      font-weight: bold;
      color: $fontColor;
      padding: 10px;
    }
  }
  a.router-link-exact-active a,
  ul a a:hover {
    color: $navColor;
    box-shadow: 2px 2px 4px 0px $navColor;
  }
  </style>
  