<template>
  <div class="container">
    <h1 class="text-center">Login Now</h1>
    <div>
      <!-- User Login Form -->
      <form
        v-if="is_DB_Connected"
        method="post"
        action="#"
        enctype="multipart/form-data"
      >
        <div class="row g-3 align-items-center mb-3">
          <div class="col-auto d-block mx-auto">
            <div
              class="form-floating"
              :class="{ 'form-data-error': userEmailErr }"
            >
              <input
                type="email"
                id="userEmailInput"
                placeholder="User Email"
                class="form-control w300"
                v-model.trim="userEmail"
              />
              <label for="userEmailInput">
                <faIcons :icon="['fas', 'envelope']" /> User Email
              </label>
              <span class="error-feedback" v-if="userEmailErr">
                {{ userEmailMsg }}
              </span>
            </div>
          </div>
        </div>
        <div class="row g-3 align-items-center mb-3">
          <div class="col-auto d-block mx-auto">
            <div
              class="form-floating"
              :class="{ 'form-data-error': userPasswordErr }"
            >
              <input
                type="password"
                id="userPasswordInput"
                placeholder="User Password"
                class="form-control w300"
                v-model.trim="userPassword"
              />
              <label for="userPasswordInput">
                <faIcons :icon="['fas', 'key']" /> User Password
              </label>
              <span class="error-feedback" v-if="userPasswordErr">
                {{ userPasswordMsg }}
              </span>
            </div>
          </div>
        </div>
        <div class="row d-grid gap-2 w300 col-auto d-block mx-auto mb-3">
          <button
            class="btn btn-outline-success"
            @click.prevent="userLogin()"
            v-if="loginBtn"
          >
            <faIcons :icon="['fas', 'unlock-alt']" /> Login
          </button>
        </div>
        <div class="row d-grid gap-2 w300 col-auto d-block mx-auto mb-3">
          <div class="alert alert-success" role="alert" v-if="resultSuccess">
            {{ resultSuccessMsg }}
          </div>
          <div class="alert alert-danger" role="alert" v-if="resultErr">
            {{ resultErrMsg }}
          </div>
        </div>
        <div class="row d-grid gap-2 w300 col-auto d-block mx-auto mb-3">
          <!-- <hr class="custom-hr-150" /> -->
          <router-link
            class="btn btn-outline-link blueInc"
            :to="{
              name: 'ForgetPassword',
            }"
          >
            Reset your password!
          </router-link>
          <router-link
            class="btn btn-outline-secondary"
            :to="{ name: 'SignUp' }"
          >
            <faIcons :icon="['fas', 'user-plus']" /> Create new account
          </router-link>
          <router-link
            class="btn btn-outline-secondary"
            :to="{ name: 'ViewAllRestaurants' }"
          >
            <faIcons :icon="['fas', 'store']" /> View Restaurant(s) Menu
          </router-link>
          <router-link
            class="btn btn-outline-secondary"
            :to="{ name: 'ContactUs' }"
          >
            <faIcons :icon="['fas', 'envelope-open-text']" /> Contact Us
          </router-link>
        </div>
      </form>
      <div v-else>
        <div role="alert" class="alert alert-danger fade show">
          Server's Connection is down
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import { mapState, mapMutations, mapGetters } from "vuex";
export default {
  name: "LoginPage",
  data() {
    return {
      userEmail: "",
      userEmailErr: false,
      userEmailMsg: "",
      isuserEmailValidated: false,
      userPassword: "",
      userPasswordErr: false,
      userPasswordMsg: "",
      isuserPasswordValidated: false,
      resultSuccess: false,
      resultSuccessMsg: "",
      resultErr: false,
      resultErrMsg: "",
      image: "",
      is_DB_Connected: true,
      db_connection_msg: "",
      // apiMainUrl: "http://localhost/php/login-system-php-vue-2021/myData.php",
      apiMainUrl: this.$store.getters.MainUrl, //call global variable
      loginBtn: true,
    };
  },
  mounted() {
    // this.dbConnection();
    // //if user is already logged in, can't visit this page
    // let user = localStorage.getItem("current-user-data");
    // if (user) {
    //   this.$router.push({ name: "Home" });
    // }
    console.log("page is mounted..");
  },
  beforeUnmount() {
    console.log("page is before unmounted..");
  },
  beforeCreate() {
    console.log("page is before created..");
  },
  created() {
    console.log("page is created..");
    // this.dbConnection();
    //if user is already logged in, can't visit this page
    let user = localStorage.getItem("current-user-data");
    if (user) {
      this.$router.push({ name: "Home" });
    } else {
      console.log("No user is logged in yet!");
    }
  },
  computed: {
    ...mapState([
      "__UserId",
      "__UserEmail",
      "__UserRole",
      "__UserName",
      "__MainUrl",
    ]),
    ...mapGetters(["MainUrl"]),
  },
  methods: {
    ...mapMutations(["setUserInfo"]),
    async dbConnection() {
      try {
        let res = await axios.post(this.apiMainUrl);
        const resData = res.data;
        this.is_DB_Connected = resData.is_db_connected;
        this.db_connection_msg = resData.connection_msg;
        console.log("connected...");
      } catch (error) {
        console.log(
          "Error happened while checking connection on to the backend"
        );
        // console.log(error.response.data.error);
        console.log(error);
        console.log("API Connection Error... Reconnecting..");
        //retry connecting again
        // this.dbConnection();
        //or page reload
        // this.$router.push({ name: "Home" });
        setTimeout(() => {
          this.dbConnection();
        }, "2000");
      }
    },
    validateUserEmail(e) {
      let val = e.target.value;
      this.validateUserEmailInput(val);
    },
    validateUserEmailInput(val) {
      //   let emailIs = /^\w+([-]?\w+)*@\w+([-]?\w+)*(\w{2,3})+$/.test(val);
      // let emailIs = /^\w+([-]?\w+)*@\w+([-]?\w+)*(\.\w{2,3})+$/.test(val);
      let emailIs = /\w+([-+.']\w+)*@\w+([-.]\w+)*\.\w+([-.]\w+)*/.test(val);
      if (val == "") {
        this.userEmailErr = true;
        this.isuserEmailValidated = false;
        this.userEmailMsg = "Must Enter User Email";
      } else if (!emailIs && val != "") {
        this.userEmailErr = true;
        this.isuserEmailValidated = false;
        this.userEmailMsg = "Must Enter Valid Email";
      } else {
        this.userEmailErr = false;
        this.isuserEmailValidated = true;
        this.userEmailMsg = "";
      }
    },
    validateUserPassword(e) {
      let val = e.target.value;
      this.validateUserPasswordInput(val);
    },
    validateUserPasswordInput(val) {
      // let emailIs = /^\w+([-]?\w+)*@\w+([-]?\w+)*(\w{2,3})+$/.test(val);
      // let passIs = /^(?=.*[0-9])(?=.*[!@#$%^&*])[a-zA-Z0-9!@#$%^&*]{7,15}$/;
      if (val == "") {
        this.userPasswordErr = true;
        this.isuserPasswordValidated = false;
        this.userPasswordMsg = "Must Enter User Password";
      } else {
        this.userPasswordErr = false;
        this.isuserPasswordValidated = true;
        this.userPasswordMsg = "";
      }
    },
    async userLogin() {
      this.dbConnection();
      this.resultErr = false;
      this.resultErrMsg = "";
      this.resultSuccess = true;
      this.resultSuccessMsg = "Wait while fetching your data...";
      this.validateUserEmailInput(this.userEmail);
      this.validateUserPasswordInput(this.userPassword);
      if (this.isuserEmailValidated && this.isuserPasswordValidated) {
        this.loginBtn = false;
        //show Result
        let fd = new FormData();
        fd.append("email", this.userEmail);
        fd.append("pass", this.userPassword);
        let rst = await axios.post(this.apiMainUrl + `?action=login`, fd);
        console.log(rst);
        const resultData = rst.data;
        if (rst.status == 200) {
          if (resultData.error) {
            console.warn("Error Happened on Back-End");
            this.resultErrMsg = resultData.message;
            this.resultErr = true;
            this.resultSuccessMsg = "";
            this.resultSuccess = false;
            this.loginBtn = true;
          } else {
            this.loginBtn = false;
            console.log("Validated Successfully");
            //if everything is okay
            this.resultErrMsg = "";
            this.resultErr = false;
            this.resultSuccessMsg = resultData.message;
            this.resultSuccess = true;
            //set user info globally in vuex
            // let currentUserIdIs = resultData.current_user_id;
            // let currentUserEmailIs = resultData.current_user_email;
            // let currentUserRoleIs = resultData.current_user_role;
            // let currentUserNameIs = resultData.current_user_name;
            // console.log("Current User Id is " + currentUserIdIs);
            //set user role as following:
            //very important since everything will be counted on my application based on roles
            //every role will show something different
            //keep this as low as possible
            // if (currentUserRoleIs == "sprAdmin") {
            //   //Only IT Such as Me
            //   currentUserRoleIs = "su739154";
            // } else if (currentUserRoleIs == "admin") {
            //   // Owner of the website
            //   currentUserRoleIs = "ad425174";
            // } else if (currentUserRoleIs == "employee") {
            //   // Employee of the website
            //   currentUserRoleIs = "em187256";
            // } else if (currentUserRoleIs == "customer") {
            //   // Customers of the website
            //   currentUserRoleIs = "cu415856";
            // }
            // this.setUserInfo({
            //   id: currentUserIdIs,
            //   name: currentUserNameIs,
            //   role: currentUserRoleIs,
            //   email: currentUserEmailIs,
            // });
            // localStorage.setItem(
            //   "current-user-info",
            //   JSON.stringify({
            //     id: currentUserIdIs,
            //     name: currentUserNameIs,
            //     role: currentUserRoleIs,
            //     email: currentUserEmailIs,
            //   })
            // );
            //show current user log in information
            localStorage.setItem(
              "current-user-data",
              JSON.stringify(resultData.current_user_data[0])
            );
            //show all related restaurants
            localStorage.setItem(
              "current_restaurants_data",
              JSON.stringify(resultData.current_restaurants_data)
            );
            localStorage.setItem(
              "current_restaurants_settings",
              JSON.stringify(resultData.current_restaurants_settings)
            );
            //show all current user categories
            localStorage.setItem(
              "current_user_menu_categories_data",
              JSON.stringify(resultData.current_user_menu_categories_data)
            );
            //show all current user menu modifiers
            localStorage.setItem(
              "current_user_menu_modifiers_data",
              JSON.stringify(resultData.current_user_menu_modifiers_data)
            );
            //show all current user menu modifiers Items
            localStorage.setItem(
              "current_user_menu_modifiers_items_data",
              JSON.stringify(resultData.current_user_menu_modifiers_items_data)
            );
            localStorage.setItem(
              "current_user_menu_items_data",
              JSON.stringify(resultData.current_user_menu_items_data)
            );
            localStorage.setItem(
              "current_restaurants_online_hours",
              JSON.stringify(resultData.current_restaurants_online_hours)
            );
            localStorage.setItem(
              "custom_restaurant_receipts",
              JSON.stringify(resultData.custom_restaurant_receipts)
            );
            localStorage.setItem(
              "rest_stripe_payment",
              JSON.stringify(resultData.rest_stripe_payment)
            );
            localStorage.setItem(
              "users_data",
              JSON.stringify(resultData.users_data)
            );
            localStorage.setItem(
              "users_messages",
              JSON.stringify(resultData.users_messages)
            );
            localStorage.setItem(
              "restaurant_bank_details",
              JSON.stringify(resultData.restaurant_bank_details)
            );
            localStorage.setItem(
              "created_payment_records",
              JSON.stringify(resultData.created_payment_records)
            );
            setTimeout(() => {
              this.$router.push({
                name: "Home",
              });
            }, 2000);
          }
        }
      } else {
        this.loginBtn = true;
        this.validateUserEmailInput(this.userEmail);
        this.validateUserPasswordInput(this.userPassword);
        this.resultSuccessMsg = "";
        this.resultSuccess = false;

        this.resultErrMsg = "Must Enter User Credentials";
        this.resultErr = true;
      }
    },
    //get current user info
    // current_user_info() {
    //   // let rst = await axios.post(this.apiMainUrl + `?action=userInfo`);
    //   console.log("user id is " + this.__UserId);
    // },
  },
};
</script>

<style>
.w300 {
  width: 300px !important;
}

.w150 {
  width: 150px !important;
}

.h125 {
  height: 125px !important;
}

.form-data-error {
  color: red;
}

.blueInc {
  color: #0d6efd !important;
}

.form-data-error input,
.form-data-error textarea {
  border-color: red;
}

.error-feedback {
  padding-left: 3px;
  font-size: 0.9rem;
}

.customFileField {
  padding-left: 24px !important;
  padding-top: 38px !important;
  padding-bottom: 10px !important;
  height: 75px !important;
}

.custom-hr-150 {
  box-sizing: content-box;
  height: 0;
  overflow: visible;
  width: 155px !important;
  margin: auto !important;
}
</style>
