<template>
  <div>
    <AdminPanelHeader v-if="uRole == 'admn'" />
    <ManagerPanelHeader v-if="uRole == 'mngr'" />
    <CustomerPanelHeader v-if="uRole == 'cstmr'" />
    <EmployeePanelHeader v-if="uRole == 'empl'" />
    <CoManagerPanelHeader v-if="uRole == 'cmngr'" />
  </div>
</template>

<script>
import AdminPanelHeader from "@/components/Header/AdminPanelHeader.vue";
import ManagerPanelHeader from "@/components/Header/ManagerPanelHeader.vue";
import CustomerPanelHeader from "@/components/Header/CustomerPanelHeader.vue";
import EmployeePanelHeader from "@/components/Header/EmployeePanelHeader.vue";
import CoManagerPanelHeader from "@/components/Header/CoManagerHeader.vue";

export default {
  name: "Navbar",
  components: {
    AdminPanelHeader,
    ManagerPanelHeader,
    CustomerPanelHeader,
    EmployeePanelHeader,
    CoManagerPanelHeader,
  },
  data() {
    return {
      isUserSignUpOrLoggedIn: false,
      uName: "",
      uEmail: "",
      uRole: "",
      uId: "",
    };
  },
  mounted() {
    let user = localStorage.getItem("current-user-data");
    if (user) {
      this.isUserSignUpOrLoggedIn = true;
      this.uName = JSON.parse(user).name;
      this.uEmail = JSON.parse(user).email;
      this.uRole = JSON.parse(user).role;
      this.uId = JSON.parse(user).id;
    } else {
      this.$router.push({ name: "Login" });
    }
  },
  methods: {
    logMeOut() {
      localStorage.clear();
      this.$router.push({ name: "Login" });
    },
  },
};
</script>
