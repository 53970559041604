<template>
  <div class="sign-up-page">
    <SignUpPage />
  </div>
</template>

<script>
import SignUpPage from "@/components/SignUp/SignUpPage.vue";
export default {
  name: "SignUp",
  components: {
    SignUpPage,
  },
};
</script>
