import { createRouter, createWebHistory } from "vue-router";
import Home from "../views/Home.vue";
import SignUp from "../views/SignUp.vue";
import Login from "../views/Login.vue";
import AccountOnHold from "../views/AccountOnHold.vue";
import ForgetPassword from "../views/ForgetPassword.vue";
import UpdatePassword from "../views/UpdatePassword.vue";
import Account from "../views/Account.vue";
import AccountUpdate from "../views/AccountUpdate.vue";
import UserPasswordUpdate from "../views/UserPasswordUpdate.vue";
import MyRestaurants from "../views/MyRestaurants.vue";
import AddNewRestaurant from "@/components/MyRestaurant/AddNewRestaurant.vue";
import UpdateCurrentRestaurant from "@/components/MyRestaurant/UpdateCurrentRestaurant.vue";
import AccessUsersToOnlineOrders from "@/components/MyRestaurant/AccessUsersToOnlineOrders.vue";
import CustomRestaurantReceipt from "@/components/MyRestaurant/CustomRestaurantReceipt.vue";
import RemoveUsersFromMyRestaurant from "@/components/MyRestaurant/RemoveUsersFromMyRestaurant.vue";
import AddNewUserToMyRestaurant from "@/components/MyRestaurant/AddNewUserToMyRestaurant.vue";
import ConfigureStripePayment from "@/components/MyRestaurant/ConfigureStripePayment.vue";
import DeactivateRestaurant from "@/components/MyRestaurant/DeactivateRestaurant.vue";
import ActivateRestaurant from "@/components/MyRestaurant/ActivateRestaurant.vue";
import CurrentRestaurantMenu from "@/components/MyRestaurant/Menu/CurrentRestaurantMenu.vue";
import CurrentRestaurantSettings from "@/components/MyRestaurant/Settings/CurrentRestaurantSettings.vue";
import CurrentRestaurantBankDetails from "@/components/MyRestaurant/Settings/CurrentRestaurantBankDetails.vue";
import AddNewCategory from "@/components/MyRestaurant/Menu/Categories/AddNewCategory.vue";
import UpdateMenuCategory from "@/components/MyRestaurant/Menu/Categories/UpdateMenuCategory.vue";
import RemoveMenuCategory from "@/components/MyRestaurant/Menu/Categories/RemoveMenuCategory.vue";
import AddMenuItem from "@/components/MyRestaurant/Menu/Categories/Items/AddMenuItem.vue";
import UpdateMenuItem from "@/components/MyRestaurant/Menu/Categories/Items/UpdateMenuItem.vue";
import RemoveMenuItem from "@/components/MyRestaurant/Menu/Categories/Items/RemoveMenuItem.vue";
import AssignMenuItemsModifier from "@/components/MyRestaurant/Menu/Categories/Items/AssignMenuItemsModifier.vue";
import AddNewMenuModifier from "@/components/MyRestaurant/Menu/Modifiers/AddNewMenuModifier.vue";
import UpdateMenuModifier from "@/components/MyRestaurant/Menu/Modifiers/UpdateMenuModifier.vue";
import RemoveMenuModifier from "@/components/MyRestaurant/Menu/Modifiers/RemoveMenuModifier.vue";
import AddMenuModifierItem from "@/components/MyRestaurant/Menu/Modifiers/Items/AddMenuModifierItem.vue";
import UpdateMenuModifierItem from "@/components/MyRestaurant/Menu/Modifiers/Items/UpdateMenuModifierItem.vue";
import RemoveMenuModifierItem from "@/components/MyRestaurant/Menu/Modifiers/Items/RemoveMenuModifierItem.vue";
import ViewOrders from "@/components/MyRestaurant/Orders/ViewOrders.vue";
import ViewOrdersOrTimeInAndOut from "@/components/MyRestaurant/Orders/ViewOrdersOrTimeInAndOut.vue";
import TimeInOut from "@/components/MyRestaurant/Orders/TimeInOut.vue";
import viewEachOrderDetails from "@/components/MyRestaurant/Orders/viewEachOrderDetails.vue";
import ViewOrdersReports from "@/components/MyRestaurant/Orders/ViewOrdersReports.vue";
import ViewUsersTimesheets from "@/components/MyRestaurant/Orders/ViewUsersTimesheets.vue";
import ViewKitchenOrders from "@/components/MyRestaurant/Orders/ViewKitchenOrders.vue";
import ViewEachCustomer from "@/components/MyRestaurant/Customers/ViewEachCustomer.vue";
import MyPaymentRecord from "@/components/MyRestaurant/PaymentRecord/MyPaymentRecord.vue";
import CreatePaymentRecord from "@/components/MyRestaurant/PaymentRecord/CreatePaymentRecord.vue";
import UpdatePaymentRecord from "@/components/MyRestaurant/PaymentRecord/UpdatePaymentRecord.vue";
import DeletePaymentRecord from "@/components/MyRestaurant/PaymentRecord/DeletePaymentRecord.vue";
import ManagerPaymentRecord from "@/components/MyRestaurant/PaymentRecord/ManagerPaymentRecord.vue";
import CreateCheck from "@/components/MyRestaurant/PaymentRecord/CreateCheck.vue";
import MyRestaurantChecks from "@/components/MyRestaurant/PaymentRecord/MyRestaurantChecks.vue";
import UpdateCurrentCheck from "@/components/MyRestaurant/PaymentRecord/UpdateCurrentCheck.vue";
import DeleteCurrentCheck from "@/components/MyRestaurant/PaymentRecord/DeleteCurrentCheck.vue";
import ViewAndPrintEachCheck from "@/components/MyRestaurant/PaymentRecord/ViewAndPrintEachCheck.vue";
import ReactivateCurrentCheck from "@/components/MyRestaurant/PaymentRecord/ReactivateCurrentCheck.vue";
import PosLogin from "@/components/POS/V1/PosLogin.vue";
import ViewAllRestaurants from "@/components/OnlineOrders/ViewAllRestaurants.vue";
import EachRestaurantMenu from "@/components/OnlineOrders/EachRestaurantMenu.vue";
import OrderConfirmationPage from "@/components/OnlineOrders/OrderConfirmationPage.vue";
import ViewAllUsers from "@/components/Users/ViewAllUsers.vue";
import ContactUs from "@/components/ContactUs/ContactUs.vue";
import ContactUsMessages from "@/components/ContactUs/ContactUsMessages.vue";
import ErrorPage from "@/components/ErrorPage/ErrorPage.vue";

const routes = [
  {
    path: "/",
    name: "Home",
    component: Home,
    meta: {
      title: `Home`,
      metaTags: [
        {
          name: "description",
          content: "Home Page of my website.",
        },
        {
          property: "og:description",
          content: "The home page of my webiste app to view.",
        },
      ],
    },
  },
  {
    path: "/new-user/sign-up",
    name: "SignUp",
    component: SignUp,
    meta: {
      title: "Sign Up",
      metaTags: [
        {
          name: "description",
          content: "Sign Up Now Description.",
        },
        {
          property: "og:description",
          content: "The home page of my webiste app.",
        },
      ],
    },
  },
  {
    path: "/user/log-in",
    name: "Login",
    component: Login,
    meta: {
      title: `Login`,
      metaTags: [
        {
          name: "description",
          content: "Login in at My Website.",
        },
        {
          property: "og:description",
          content: "The home page of my webiste app to login.",
        },
      ],
    },
  },
  {
    path: "/users/account-on-hold/:uName",
    name: "AccountOnHold",
    component: AccountOnHold,
    meta: {
      title: `Thanks For Signing Up`,
      metaTags: [
        {
          name: "description",
          content: "Thanks For Signing Up with us.",
        },
        {
          property: "og:description",
          content: "Thanks For Signing Up with us.",
        },
      ],
    },
  },
  {
    path: "/password/reset/request",
    name: "ForgetPassword",
    component: ForgetPassword,
    meta: {
      title: `Did you forget your password?`,
      metaTags: [
        {
          name: "description",
          content: "Forget your password? Enter your email to reset it!",
        },
        {
          property: "og:description",
          content: "Forget your password? Enter your email to reset it!",
        },
      ],
    },
  },
  {
    //remember, this has to be matched with the same link sent by email on resetting user password on myData.php
    path: "/users/password/update/:userId/:rqstId",
    name: "UpdatePassword",
    component: UpdatePassword,
    meta: {
      title: `Update Your Passowrd Now`,
      metaTags: [
        {
          name: "description",
          content: "You can update your password now!",
        },
        {
          property: "og:description",
          content: "You can update your password now!",
        },
      ],
    },
  },
  {
    path: "/user/account/view",
    name: "Account",
    component: Account,
    meta: {
      title: `Account`,
      metaTags: [
        {
          name: "description",
          content: "View your account Information.",
        },
        {
          property: "og:description",
          content: "View your account Information.",
        },
      ],
    },
  },
  {
    path: "/user/account/update",
    name: "AccountUpdate",
    component: AccountUpdate,
    meta: {
      title: `Account Update`,
      metaTags: [
        {
          name: "description",
          content: "Update your account Information.",
        },
        {
          property: "og:description",
          content: "Update your account Information.",
        },
      ],
    },
  },
  {
    path: "/user/password/update",
    name: "UserPasswordUpdate",
    component: UserPasswordUpdate,
    meta: {
      title: `Update User Password`,
      metaTags: [
        {
          name: "description",
          content: "Update your account Password.",
        },
        {
          property: "og:description",
          content: "Update your account Password.",
        },
      ],
    },
  },
  {
    path: "/view/my-restaurants",
    name: "MyRestaurants",
    component: MyRestaurants,
    meta: {
      title: `View My Restaurants`,
      metaTags: [
        {
          name: "description",
          content: "View My Restaurants",
        },
        {
          property: "og:description",
          content: "View My Restaurants",
        },
      ],
    },
  },
  {
    path: "/add/new-restaurant",
    name: "AddNewRestaurant",
    component: AddNewRestaurant,
    meta: {
      title: `Add New Restaurant`,
      metaTags: [
        {
          name: "description",
          content: "Add New Restaurant",
        },
        {
          property: "og:description",
          content: "Add New Restaurant",
        },
      ],
    },
  },
  {
    path: "/update/restaurant/:id/:restId",
    name: "UpdateCurrentRestaurant",
    component: UpdateCurrentRestaurant,
    meta: {
      title: `Update Current Restaurant`,
      metaTags: [
        {
          name: "description",
          content: "Update Current Restaurant",
        },
        {
          property: "og:description",
          content: "Update Current Restaurant",
        },
      ],
    },
  },
  {
    path: "/restaurant/access/users/:id/:restId",
    name: "AccessUsersToOnlineOrders",
    component: AccessUsersToOnlineOrders,
    meta: {
      title: `Access Users to Online Orders`,
      metaTags: [
        {
          name: "description",
          content: "Access Users to Online Orders",
        },
        {
          property: "og:description",
          content: "Access Users to Online Orders",
        },
      ],
    },
  },
  {
    path: "/restaurant/custom/reciept/:id/:restId",
    name: "CustomRestaurantReceipt",
    component: CustomRestaurantReceipt,
    meta: {
      title: `Custom Restaurant Reciept`,
      metaTags: [
        {
          name: "description",
          content: "Custom Restaurant Reciept",
        },
        {
          property: "og:description",
          content: "Custom Restaurant Reciept",
        },
      ],
    },
  },
  {
    path: "/restaurant/configuration/stripe/payment/:id/:restId",
    name: "ConfigureStripePayment",
    component: ConfigureStripePayment,
    meta: {
      title: `Configure Stripe Payment`,
      metaTags: [
        {
          name: "description",
          content: "Configure Stripe Payment",
        },
        {
          property: "og:description",
          content: "Configure Stripe Payment",
        },
      ],
    },
  },
  {
    path: "/restaurant/add/user/:id/:restId",
    name: "AddNewUserToMyRestaurant",
    component: AddNewUserToMyRestaurant,
    meta: {
      title: `Add New User to My Restaurant`,
      metaTags: [
        {
          name: "description",
          content: "Add New User to My Restaurant",
        },
        {
          property: "og:description",
          content: "Add New User to My Restaurant",
        },
      ],
    },
  },
  {
    path: "/restaurant/remove/user/:id/:restId/:userAccessId",
    name: "RemoveUsersFromMyRestaurant",
    component: RemoveUsersFromMyRestaurant,
    meta: {
      title: `Remove User to My Restaurant`,
      metaTags: [
        {
          name: "description",
          content: "Remove User to My Restaurant",
        },
        {
          property: "og:description",
          content: "Remove User to My Restaurant",
        },
      ],
    },
  },
  {
    path: "/activate/restaurant/:id/:restId",
    name: "ActivateRestaurant",
    component: ActivateRestaurant,
    meta: {
      title: `Activate Current Restaurant`,
      metaTags: [
        {
          name: "description",
          content: "Activate Current Restaurant",
        },
        {
          property: "og:description",
          content: "Activate Current Restaurant",
        },
      ],
    },
  },
  {
    path: "/deactivate/restaurant/:id/:restId",
    name: "DeactivateRestaurant",
    component: DeactivateRestaurant,
    meta: {
      title: `Deactivate Current Restaurant`,
      metaTags: [
        {
          name: "description",
          content: "Deactivate Current Restaurant",
        },
        {
          property: "og:description",
          content: "Deactivate Current Restaurant",
        },
      ],
    },
  },
  {
    path: "/restaurant/view/menu/:id/:restId",
    name: "CurrentRestaurantMenu",
    component: CurrentRestaurantMenu,
    meta: {
      title: `View Current Restaurant Menu`,
      metaTags: [
        {
          name: "description",
          content: "View Current Restaurant Menu",
        },
        {
          property: "og:description",
          content: "View Current Restaurant Menu",
        },
      ],
    },
  },
  {
    path: "/restaurant/settings/:id/:restId",
    name: "CurrentRestaurantSettings",
    component: CurrentRestaurantSettings,
    meta: {
      title: `View Current Restaurant Settings`,
      metaTags: [
        {
          name: "description",
          content: "View Current Restaurant Settings",
        },
        {
          property: "og:description",
          content: "View Current Restaurant Settings",
        },
      ],
    },
  },
  {
    path: "/restaurant/bank/settings/:id/:restId",
    name: "CurrentRestaurantBankDetails",
    component: CurrentRestaurantBankDetails,
    meta: {
      title: `Setup Deposit Details`,
      metaTags: [
        {
          name: "description",
          content: "Setup Deposit Details",
        },
        {
          property: "og:description",
          content: "Setup Deposit Details",
        },
      ],
    },
  },
  {
    path: "/restaurant/create/payment/record/:id/:restId",
    name: "CreatePaymentRecord",
    component: CreatePaymentRecord,
    meta: {
      title: `Create Payment Record`,
      metaTags: [
        {
          name: "description",
          content: "Create Payment Record",
        },
        {
          property: "og:description",
          content: "Create Payment Record",
        },
      ],
    },
  },
  {
    path: "/restaurant/view/my/payment/records/:id/:restId",
    name: "ManagerPaymentRecord",
    component: ManagerPaymentRecord,
    meta: {
      title: `View Payment Records`,
      metaTags: [
        {
          name: "description",
          content: "View Payment Records",
        },
        {
          property: "og:description",
          content: "View Payment Records",
        },
      ],
    },
  },
  {
    path: "/restaurant/add/new/check/:id/:restId",
    name: "CreateCheck",
    component: CreateCheck,
    meta: {
      title: `Create new check`,
      metaTags: [
        {
          name: "description",
          content: "Create new check",
        },
        {
          property: "og:description",
          content: "Create new check",
        },
      ],
    },
  },
  {
    path: "/restaurant/view/my/checks/:id/:restId",
    name: "MyRestaurantChecks",
    component: MyRestaurantChecks,
    meta: {
      title: `Create and View Restaurant Checks`,
      metaTags: [
        {
          name: "description",
          content: "Create and View Restaurant Checks",
        },
        {
          property: "og:description",
          content: "Create and View Restaurant Checks",
        },
      ],
    },
  },
  {
    path: "/restaurant/update/check/:id/:restId/:checkId",
    name: "UpdateCurrentCheck",
    component: UpdateCurrentCheck,
    meta: {
      title: `Update Current Check`,
      metaTags: [
        {
          name: "description",
          content: "Update Current Check",
        },
        {
          property: "og:description",
          content: "Update Current Check",
        },
      ],
    },
  },
  {
    path: "/restaurant/delete/check/:id/:restId/:checkId",
    name: "DeleteCurrentCheck",
    component: DeleteCurrentCheck,
    meta: {
      title: `Delete Current Check`,
      metaTags: [
        {
          name: "description",
          content: "Delete Current Check",
        },
        {
          property: "og:description",
          content: "Delete Current Check",
        },
      ],
    },
  },
  {
    path: "/restaurant/current/check/:id/:restId/:checkId",
    name: "ViewAndPrintEachCheck",
    component: ViewAndPrintEachCheck,
    meta: {
      title: `View Current Check`,
      metaTags: [
        {
          name: "description",
          content: "View Current Check",
        },
        {
          property: "og:description",
          content: "View Current Check",
        },
      ],
    },
  },
  {
    path: "/restaurant/re/activate/check/:id/:restId/:checkId",
    name: "ReactivateCurrentCheck",
    component: ReactivateCurrentCheck,
    meta: {
      title: `Reactivate Current Check`,
      metaTags: [
        {
          name: "description",
          content: "Reactivate Current Check",
        },
        {
          property: "og:description",
          content: "Reactivate Current Check",
        },
      ],
    },
  },
  {
    path: "/restaurant/update/payment/record/:id/:restId",
    name: "UpdatePaymentRecord",
    component: UpdatePaymentRecord,
    meta: {
      title: `Update Payment Record`,
      metaTags: [
        {
          name: "description",
          content: "Update Payment Record",
        },
        {
          property: "og:description",
          content: "Update Payment Record",
        },
      ],
    },
  },
  {
    path: "/restaurant/delete/payment/record/:id/:restId",
    name: "DeletePaymentRecord",
    component: DeletePaymentRecord,
    meta: {
      title: `Delete Payment Record`,
      metaTags: [
        {
          name: "description",
          content: "Delete Payment Record",
        },
        {
          property: "og:description",
          content: "Delete Payment Record",
        },
      ],
    },
  },
  {
    path: "/restaurant/payment/records/:id/:restId",
    name: "MyPaymentRecord",
    component: MyPaymentRecord,
    meta: {
      title: `Restaurant Payment Record`,
      metaTags: [
        {
          name: "description",
          content: "Restaurant Payment Record",
        },
        {
          property: "og:description",
          content: "Restaurant Payment Record",
        },
      ],
    },
  },
  {
    path: "/restaurant/add/new/category/:id/:restId",
    name: "AddNewCategory",
    component: AddNewCategory,
    meta: {
      title: `Add New Restaurant Category`,
      metaTags: [
        {
          name: "description",
          content: "Add New Restaurant Category",
        },
        {
          property: "og:description",
          content: "Add New Restaurant Category",
        },
      ],
    },
  },
  {
    path: "/restaurant/update/category/:id/:restId/:catId",
    name: "UpdateMenuCategory",
    component: UpdateMenuCategory,
    meta: {
      title: `Update Menu Category`,
      metaTags: [
        {
          name: "description",
          content: "Update Menu Category",
        },
        {
          property: "og:description",
          content: "Update Menu Category",
        },
      ],
    },
  },
  {
    path: "/restaurant/remove/category/:id/:restId/:catId",
    name: "RemoveMenuCategory",
    component: RemoveMenuCategory,
    meta: {
      title: `Remove Menu Category`,
      metaTags: [
        {
          name: "description",
          content: "Remove Menu Category",
        },
        {
          property: "og:description",
          content: "Remove Menu Category",
        },
      ],
    },
  },
  {
    path: "/restaurant/add/modifier/:id/:restId",
    name: "AddNewMenuModifier",
    component: AddNewMenuModifier,
    meta: {
      title: `Add Menu Modifier`,
      metaTags: [
        {
          name: "description",
          content: "Add Menu Modifier",
        },
        {
          property: "og:description",
          content: "Add Menu Modifier",
        },
      ],
    },
  },
  {
    path: "/restaurant/update/modifier/:id/:restId/:modifierId",
    name: "UpdateMenuModifier",
    component: UpdateMenuModifier,
    meta: {
      title: `Update Menu Modifier`,
      metaTags: [
        {
          name: "description",
          content: "Update Menu Modifier",
        },
        {
          property: "og:description",
          content: "Update Menu Modifier",
        },
      ],
    },
  },
  {
    path: "/restaurant/remove/modifier/:id/:restId/:modifierId",
    name: "RemoveMenuModifier",
    component: RemoveMenuModifier,
    meta: {
      title: `Remove Menu Modifier`,
      metaTags: [
        {
          name: "description",
          content: "Remove Menu Modifier",
        },
        {
          property: "og:description",
          content: "Remove Menu Modifier",
        },
      ],
    },
  },
  {
    path: "/restaurant/add/modifier/item/:id/:restId/:modifierId",
    name: "AddMenuModifierItem",
    component: AddMenuModifierItem,
    meta: {
      title: `Add Menu Modifier Item`,
      metaTags: [
        {
          name: "description",
          content: "Add Menu Modifier Item",
        },
        {
          property: "og:description",
          content: "Add Menu Modifier Item",
        },
      ],
    },
  },
  {
    path: "/restaurant/update/modifier/item/:id/:restId/:modifierId/:modifierItemId",
    name: "UpdateMenuModifierItem",
    component: UpdateMenuModifierItem,
    meta: {
      title: `Update Menu Modifier Item`,
      metaTags: [
        {
          name: "description",
          content: "Update Menu Modifier Item",
        },
        {
          property: "og:description",
          content: "Update Menu Modifier Item",
        },
      ],
    },
  },
  {
    path: "/restaurant/remove/modifier/item/:id/:restId/:modifierId/:modifierItemId",
    name: "RemoveMenuModifierItem",
    component: RemoveMenuModifierItem,
    meta: {
      title: `Remove Menu Modifier Item`,
      metaTags: [
        {
          name: "description",
          content: "Remove Menu Modifier Item",
        },
        {
          property: "og:description",
          content: "Remove Menu Modifier Item",
        },
      ],
    },
  },
  {
    path: "/restaurant/add/menu/item/:id/:restId/:menuCategoryId",
    name: "AddMenuItem",
    component: AddMenuItem,
    meta: {
      title: `Add Menu Item`,
      metaTags: [
        {
          name: "description",
          content: "Add Menu Item",
        },
        {
          property: "og:description",
          content: "Add Menu Item",
        },
      ],
    },
  },
  {
    path: "/restaurant/update/menu/item/:id/:restId/:catId/:menuItemId",
    name: "UpdateMenuItem",
    component: UpdateMenuItem,
    meta: {
      title: `Update Menu Item`,
      metaTags: [
        {
          name: "description",
          content: "Update Menu Item",
        },
        {
          property: "og:description",
          content: "Update Menu Item",
        },
      ],
    },
  },
  {
    path: "/restaurant/menu/assign/modifiers/to/items/:id/:restId/:catId/:menuItemId",
    name: "AssignMenuItemsModifier",
    component: AssignMenuItemsModifier,
    meta: {
      title: `Assign Modifiers to current Menu Item`,
      metaTags: [
        {
          name: "description",
          content: "Assign Modifiers to current Menu Item",
        },
        {
          property: "og:description",
          content: "Assign Modifiers to current Menu Item",
        },
      ],
    },
  },
  {
    path: "/restaurant/remove/menu/item/:id/:restId/:catId/:menuItemId",
    name: "RemoveMenuItem",
    component: RemoveMenuItem,
    meta: {
      title: `Remove Menu Item`,
      metaTags: [
        {
          name: "description",
          content: "Remove Menu Item",
        },
        {
          property: "og:description",
          content: "Remove Menu Item",
        },
      ],
    },
  },
  {
    path: "/online/order",
    name: "ViewAllRestaurants",
    component: ViewAllRestaurants,
    meta: {
      title: `Order Online Restaurants`,
      metaTags: [
        {
          name: "description",
          content: "Order Online Restaurants",
        },
        {
          property: "og:description",
          content: "Order Online Restaurants",
        },
      ],
    },
  },
  {
    path: "/online/order/:restId",
    name: "EachRestaurantMenu",
    component: EachRestaurantMenu,
    meta: {
      title: `Order Online Menu`,
      metaTags: [
        {
          name: "description",
          content: "Order Online Menu",
        },
        {
          property: "og:description",
          content: "Order Online Menu",
        },
      ],
    },
  },
  {
    path: "/online/order/confirmation/:restId/:orderNo",
    name: "OrderConfirmationPage",
    component: OrderConfirmationPage,
    meta: {
      title: `View Online Order Confirmation Page`,
      metaTags: [
        {
          name: "description",
          content: "View Online Order Confirmation Page",
        },
        {
          property: "og:description",
          content: "View Online Order Confirmation Page",
        },
      ],
    },
  },
  {
    path: "/order/details/:restId/:orderNo",
    name: "viewEachOrderDetails",
    component: viewEachOrderDetails,
    props: true,
    meta: {
      title: `View Order Details`,
      metaTags: [
        {
          name: "description",
          content: "View Order Details",
        },
        {
          property: "og:description",
          content: "View Order Details",
        },
      ],
    },
  },
  {
    path: "/order/kitchen/:restId",
    name: "ViewKitchenOrders",
    component: ViewKitchenOrders,
    meta: {
      title: `View Kitchen Order`,
      metaTags: [
        {
          name: "description",
          content: "View Kitchen Order",
        },
        {
          property: "og:description",
          content: "View Kitchen Order",
        },
      ],
    },
  },
  {
    path: "/order/tabs/:restId",
    name: "ViewOrders",
    component: ViewOrders,
    meta: {
      title: `View Coming Order`,
      metaTags: [
        {
          name: "description",
          content: "View Coming Order",
        },
        {
          property: "og:description",
          content: "View Coming Order",
        },
      ],
    },
  },
  {
    path: "/time/in/out/users/:restId",
    name: "TimeInOut",
    component: TimeInOut,
    meta: {
      title: `Time In and Out`,
      metaTags: [
        {
          name: "description",
          content: "Time In and Out",
        },
        {
          property: "og:description",
          content: "Time In and Out",
        },
      ],
    },
  },
  {
    path: "/orders/time/in/out/users/:restId",
    name: "ViewOrdersOrTimeInAndOut",
    component: ViewOrdersOrTimeInAndOut,
    meta: {
      title: `Orders and Time In/Out`,
      metaTags: [
        {
          name: "description",
          content: "Orders and Time In/Out",
        },
        {
          property: "og:description",
          content: "Orders and Time In/Out",
        },
      ],
    },
  },
  {
    path: "/orders/reports/:restId",
    name: "ViewOrdersReports",
    component: ViewOrdersReports,
    meta: {
      title: `View current restaurant order's reports`,
      metaTags: [
        {
          name: "description",
          content: "View current restaurant order's reports",
        },
        {
          property: "og:description",
          content: "View current restaurant order's reports",
        },
      ],
    },
  },
  {
    path: "/view/current/users",
    name: "ViewAllUsers",
    component: ViewAllUsers,
    meta: {
      title: `View Current Users`,
      metaTags: [
        {
          name: "description",
          content: "View Current Users",
        },
        {
          property: "og:description",
          content: "View Current Users",
        },
      ],
    },
  },
  {
    path: "/front/dashboard/pos/v1/:restId/:id",
    name: "PosLogin",
    component: PosLogin,
    meta: {
      title: `POS Screen`,
      metaTags: [
        {
          name: "description",
          content: "POS Screen",
        },
        {
          property: "og:description",
          content: "POS Screen",
        },
      ],
    },
  },
  {
    path: "/users/timesheet/:restId",
    name: "ViewUsersTimesheets",
    component: ViewUsersTimesheets,
    meta: {
      title: `View users timesheet`,
      metaTags: [
        {
          name: "description",
          content: "View users timesheet",
        },
        {
          property: "og:description",
          content: "View users timesheet",
        },
      ],
    },
  },
  {
    path: "/customers/view/:restId/:custId",
    name: "ViewEachCustomer",
    component: ViewEachCustomer,
    meta: {
      title: `View Customer Data`,
      metaTags: [
        {
          name: "description",
          content: "View Customer Data",
        },
        {
          property: "og:description",
          content: "View Customer Data",
        },
      ],
    },
  },
  {
    path: "/contact",
    name: "ContactUs",
    component: ContactUs,
    meta: {
      title: `Contact Us`,
      metaTags: [
        {
          name: "description",
          content: "Contact Us",
        },
        {
          property: "og:description",
          content: "Contact Us",
        },
      ],
    },
  },
  {
    path: "/users/contact/messages",
    name: "ContactUsMessages",
    component: ContactUsMessages,
    meta: {
      title: `Contact Us Messages`,
      metaTags: [
        {
          name: "description",
          content: "Contact Us Messages",
        },
        {
          property: "og:description",
          content: "Contact Us Messages",
        },
      ],
    },
  },
  {
    path: "/:catchAll(.*)",
    name: "ErrorPage",
    component: ErrorPage,
  },
];

// history: createWebHistory(process.env.BASE_URL),
const router = createRouter({
  // history: createMemoryHistory(),
  mode: "history",
  base: process.env.BASE_URL,
  history: createWebHistory(),
  routes,
});
router.beforeEach((to, from, next) => {
  /*
  if (
    to.params.pageTitle !== undefined &&
    process.env.VUE_APP_TITLE !== undefined
  ) {
    document.title = `${to.params.pageTitle} | ${process.env.VUE_APP_TITLE}`;
  } else if (
    to.params.pageTitle !== undefined &&
    process.env.VUE_APP_TITLE == undefined
  ) {
    document.title = `${to.params.pageTitle}`;
  } else {
    if (to.name == null) {
      document.title = `No Page with this name`;
    } else if(to.meta.title !== undefined ) {
      document.title = `${to.meta.title } | ${process.env.VUE_APP_TITLE}`;
    } else{
      document.title = `${process.env.VUE_APP_TITLE}`;
    }
  }
  */
  if (to.name == null) {
    document.title = `This Page is not found | ${process.env.VUE_APP_TITLE}`;
  } else if (
    to.meta.title !== undefined &&
    process.env.VUE_APP_TITLE !== undefined
  ) {
    document.title = `${to.meta.title} | ${process.env.VUE_APP_TITLE}`;
  } else {
    document.title = `${process.env.VUE_APP_TITLE}`;
  }
  next();
});
export default router;
