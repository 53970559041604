<template>
  <div class="container-fluid" v-if="doesRestDataExists">
    <div v-if="!canUserAccessThis">
      <div class="d-flex aligns-items-center justify-content-center">
        <div class="m-5">
          <div
            class="form-floating"
            :class="{ 'form-data-error': currentUserAccessCodeIsErr }"
          >
            <input
              autofocus
              type="number"
              min="0"
              id="userPhoneInput"
              placeholder="currentUserAccessCodeIsErr"
              @keydown="
                if (
                  $event.key === '.' ||
                  $event.key === '+' ||
                  $event.key === '-' ||
                  $event.key === 'e'
                ) {
                  $event.preventDefault();
                }
              "
              @input="validateUserAccessCode($event)"
              @change="validateUserAccessCode($event)"
              class="form-control w300"
              v-model.trim="currentUserAccessCodeIs"
            />
            <label for="userPhoneInput">
              <faIcons :icon="['fas', 'key']" /> Access Code
            </label>
            <span class="error-feedback" v-if="currentUserAccessCodeIsErr">{{
              currentUserAccessCodeIsMsg
            }}</span>
          </div>
        </div>
      </div>
      <div class="d-flex aligns-items-center justify-content-center m-3">
        <button
          class="btn btn-outline-success"
          @click.prevent="canUserAccessCurrentPage()"
          v-if="this.btnEnabled"
        >
          <faIcons :icon="['fas', 'eye']" /> View Orders</button
        >&nbsp;
        <button class="btn btn-outline-primary" type="button" @click="goBack()">
          <faIcons :icon="['fas', 'store']" />
          Home
        </button>
      </div>
    </div>
    <div
      v-if="canUserAccessThis"
      class="row justify-content-around main-order-select-date-custom"
    >
      <audio
        id="audio"
        src="https://order.hooksfishnchips.com/sound.wav"
        autoplay="false"
      ></audio>
      <div>
        <nav class="navbar navbar-expand d-block d-md-none">
          <div class="justify-content-center container-fluid">
            <div class="navbar-nav">
              <a
                class="nav-link report-button"
                href="javascript:void(0)"
                @click="showReports()"
                v-if="
                  currentLoggedInUserData[0].user_role == 'admn' ||
                  currentLoggedInUserData[0].user_role == 'mngr' ||
                  currentLoggedInUserData[0].user_role == 'cmngr'
                "
              >
                <faIcons
                  class="d-print-none pointerHand"
                  :icon="['fas', 'file-invoice-dollar']"
                />
              </a>
              <a
                class="nav-link report-button"
                href="javascript:void(0)"
                @click="showTimeInAndOut()"
                v-if="
                  currentLoggedInUserData[0].user_role == 'admn' ||
                  currentLoggedInUserData[0].user_role == 'mngr' ||
                  currentLoggedInUserData[0].user_role == 'cmngr'
                "
              >
                <faIcons
                  class="d-print-none pointerHand"
                  :icon="['fas', 'user-clock']"
                />
              </a>
              <a
                href="javascript:void(0)"
                class="nav-link fb greenColor"
                @click="currentActiveOrders()"
                >Active</a
              >
              <a
                href="javascript:void(0)"
                class="nav-link fb"
                @click="currentReadyOrders()"
                >Ready</a
              >
              <a
                href="javascript:void(0)"
                class="nav-link fb"
                @click="currentCompletedOrders()"
                >All</a
              >
              <a
                href="javascript:void(0)"
                class="nav-link fb redColor"
                @click="clearLogin()"
                >Exit</a
              >
            </div>
          </div>
        </nav>
        <nav
          class="navbar navbar-expand-sm navbar-light bg-light d-none d-md-block"
        >
          <div class="container-fluid">
            <span class="navbar-brand d-none d-sm-block">
              <faIcons :icon="['fas', 'user']" />
              {{
                capitalizeFisrtLetterOfEachWord(
                  currentLoggedInUserData[0].user_name
                    .split(" ")
                    .slice(0, 1)
                    .join(" ")
                )
              }}
            </span>
            <button
              class="navbar-toggler"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#mynavbar"
            >
              <span class="navbar-toggler-icon"></span>
            </button>
            <div class="collapse navbar-collapse" id="mynavbar">
              <ul class="navbar-nav me-auto">
                <li class="nav-item">
                  <a
                    class="nav-link report-button"
                    href="javascript:void(0)"
                    @click="showReports()"
                    v-if="
                      currentLoggedInUserData[0].user_role == 'admn' ||
                      currentLoggedInUserData[0].user_role == 'mngr' ||
                      currentLoggedInUserData[0].user_role == 'cmngr'
                    "
                    >Report(s)</a
                  >
                </li>
                <li class="nav-item">
                  <a
                    class="nav-link report-button"
                    href="javascript:void(0)"
                    @click="showTimeInAndOut()"
                    v-if="
                      currentLoggedInUserData[0].user_role == 'admn' ||
                      currentLoggedInUserData[0].user_role == 'mngr' ||
                      currentLoggedInUserData[0].user_role == 'cmngr'
                    "
                    >In & Out</a
                  >
                </li>
                <li class="nav-item d-block">
                  <a
                    href="javascript:void(0)"
                    class="nav-link fb greenColor"
                    @click="currentActiveOrders()"
                    >Active</a
                  >
                </li>
                <li class="nav-item d-block">
                  <a
                    href="javascript:void(0)"
                    class="nav-link fb"
                    @click="currentReadyOrders()"
                    >Ready</a
                  >
                </li>
                <li class="nav-item d-block">
                  <a
                    href="javascript:void(0)"
                    class="nav-link fb"
                    @click="currentCompletedOrders()"
                    >Completed</a
                  >
                </li>
                <li class="nav-item">
                  <a
                    href="javascript:void(0)"
                    class="btn btns-danger"
                    @click="clearLogin()"
                    >Exit</a
                  >
                </li>
              </ul>
              <form class="d-flex d-none d-sm-block">
                <span class="nav-link fb font-size-1-25-em">{{
                  timestamp
                }}</span>
              </form>
            </div>
          </div>
        </nav>
      </div>

      <!-- 
        <div class="btn-group mb-4" role="group" aria-label="Basic example">
          <button type="button" class="btn btns-success biggerFont" @click="currentActiveOrders()">
            Active
          </button>
          <button type="button" class="btn btns-primary biggerFont" @click="currentReadyOrders()">
            Ready
          </button>
          <button type="button" class="btn btns-orange biggerFont" @click="currentCompletedOrders()">
            Completed
          </button>
          <button type="button" class="btn btns-danger biggerFont d-none d-sm-block" @click="currentCancelledOrders()">
            Cancelled
          </button> 
        </div>
      -->
      <h4 class="text-center">{{ messageTitle }}</h4>
      <!-- <div class="addressStyle">
          <p>{{ pickUpAddress }}</p>
          <p>{{ pickUpPhoneNumber }}</p>
        </div>-->
      <div
        v-for="(o, index) in currentOrderInDetails"
        :key="index"
        class="col-12 col-sm-12 col-lg-4 col-xl-4"
      >
        <div :class="showInProgress == true ? 'in-progress' : ''">
          <div>
            <div :id="o.order_id" class="d-none d-print-block">
              <PrintEachOrderReciept
                :restIdIs="restId"
                :orderNoIs="o.order_id"
              />
            </div>
            <div
              class="each-order-item each-category-list"
              :id="o.order_id + index"
            >
              <div class="custom-bg">
                <div class="clearfix"></div>
                <div
                  class="d-print-none btn-group custom-header-left"
                  role="group"
                >
                  <!-- <button v-if="
                    o.is_order_picked_up == 0 && o.is_order_confirmed == 0
                  " class="d-print-none btn btns-success custom-button greenColor">
                    Active
                  </button>
                  <button v-if="
                    o.is_order_picked_up == 0 && o.is_order_confirmed == 1
                  " class="d-print-none btn btns-primary custom-button blueColor">
                    Ready
                  </button>
                  -->
                  <button
                    class="d-print-none btn btns-success custom-button blueColor"
                  >
                    <!-- <span class="text-muted mb-0 fb" v-if="
                      o.is_order_custom_date_and_time_or_right_away == 'now'
                    ">
                      Pick Up
                    </span>
                    <span class="text-muted mb-0 fb redColor" v-if="
                      o.is_order_custom_date_and_time_or_right_away ==
                      'custom'
                    ">
                      Scheduled
                    </span> -->
                    <span
                      class="text-muted mb-0 fb greenColor"
                      v-if="
                        o.is_order_custom_date_and_time_or_right_away == 'now'
                      "
                    >
                      <faIcons
                        class="d-print-none pointerHand"
                        :icon="['fas', 'user-clock']"
                      />
                      {{
                        removeSecondsFromTime(
                          displayCustomDateTime("", o.custom_time)
                        )
                      }}
                    </span>
                    <span
                      class="text-muted mb-0 fb redColor"
                      v-if="
                        o.is_order_custom_date_and_time_or_right_away ==
                        'custom'
                      "
                    >
                      <faIcons
                        class="d-print-none pointerHand"
                        :icon="['fas', 'user-clock']"
                      />
                      {{
                        removeSecondsFromTime(
                          displayCustomDateTime("", o.custom_time)
                        )
                      }}
                    </span>
                  </button>
                  <button
                    class="btn btn-secondary"
                    @click="printSection2(o.order_id)"
                  >
                    <faIcons
                      class="d-print-none float-end pointerHand"
                      :icon="['fas', 'print']"
                    />
                  </button>
                </div>
                <button
                  class="d-print-none btn btn-info custom-header-right"
                  :class="
                    new Date().toTimeString() > o.custom_time &&
                    o.is_order_picked_up == 0
                      ? 'borderRed'
                      : ''
                  "
                >
                  <span
                    :class="
                      new Date().toTimeString() > o.custom_time &&
                      o.is_order_picked_up == 0
                        ? 'blink'
                        : ''
                    "
                    >#{{ o.id }}</span
                  >
                </button>
                <div class="clearfix"></div>
                <div class="m-3" v-if="o.is_order_cancelled == 0">
                  <div
                    class="d-print-none text-center"
                    v-if="
                      new Date(todaysDateIs).toDateString() <=
                      new Date(o.custom_date).toDateString()
                    "
                  >
                    <div
                      class="d-print-none btn-group"
                      role="group"
                      v-if="
                        o.is_order_picked_up == 0 && o.is_order_confirmed == 0
                      "
                    >
                      <button
                        class="d-print-none btn btns-primary"
                        type="button"
                        @click="
                          orderReadyOrPickedUp(
                            o.order_id + index,
                            o.order_id,
                            'ready'
                          )
                        "
                      >
                        Ready
                      </button>
                      <button
                        class="d-print-none btn btns-orange"
                        type="button"
                        @click="
                          orderReadyOrPickedUp(
                            o.order_id + index,
                            o.order_id,
                            'picked'
                          )
                        "
                      >
                        Completed
                      </button>
                    </div>
                    <div
                      class="d-print-none btn-group d-flex"
                      role="group"
                      v-if="
                        o.is_order_picked_up == 0 && o.is_order_confirmed == 1
                      "
                    >
                      <button
                        class="d-print-none btn btns-orange"
                        type="button"
                        @click="
                          orderReadyOrPickedUp(
                            o.order_id + index,
                            o.order_id,
                            'picked'
                          )
                        "
                      >
                        Completed
                      </button>
                      <button
                        class="d-print-none btn btns-success"
                        type="button"
                        @click="
                          orderReadyOrPickedUp(
                            o.order_id + index,
                            o.order_id,
                            'inProgress'
                          )
                        "
                      >
                        Not Ready
                      </button>
                    </div>
                    <div
                      class="d-print-none btn-group d-flex"
                      role="group"
                      v-if="
                        o.is_order_picked_up == 1 && o.is_order_confirmed == 1
                      "
                    >
                      <button
                        class="d-print-none btn btns-primary"
                        type="button"
                        @click="
                          orderReadyOrPickedUp(
                            o.order_id + index,
                            o.order_id,
                            'ready'
                          )
                        "
                      >
                        Ready
                      </button>
                      <button
                        class="d-print-none btn btns-success"
                        type="button"
                        @click="
                          orderReadyOrPickedUp(
                            o.order_id + index,
                            o.order_id,
                            'inProgress'
                          )
                        "
                      >
                        Active
                      </button>
                    </div>
                  </div>
                  <div class="d-print-none" v-else>
                    <p class="text-muted fb text-center">
                      Order Date: {{ new Date(o.custom_date).toDateString() }}
                    </p>
                  </div>
                </div>
                <div class="m-3" v-else>
                  <div class="d-print-none">
                    <p class="redColor fb text-center d-block">
                      Cancelled by
                      <span :id="'returnedBy-' + o.order_id">{{
                        getUserNameForEachOrder(
                          o.refunded_by,
                          "returnedBy-" + o.order_id
                        )
                      }}</span>
                    </p>
                  </div>
                </div>
                <div class="clearfix"></div>
                <div class="m-2 d-print-none fsSmall">
                  <span class="float-start text-muted">
                    <faIcons
                      class="pointerHand"
                      :icon="['fas', 'user-circle']"
                    />
                    {{ capitalizeFisrtLetterOfEachWord(o.cust_name) }}
                  </span>
                  <span
                    v-if="o.cust_phone.length > 0"
                    class="float-end text-muted"
                  >
                    <faIcons class="pointerHand" :icon="['fas', 'phone-alt']" />
                    {{ formatPhoneNumber(o.cust_phone) }}
                  </span>
                </div>
                <div class="d-flex align-items-center m-3 float-end">
                  <div class="">
                    <!-- <p class="text-muted mb-0 fb" v-if="
                      o.is_order_custom_date_and_time_or_right_away == 'now'
                    ">
                      Pick Up
                    </p>
                    <p class="text-muted mb-0 fb redColor" v-if="
                      o.is_order_custom_date_and_time_or_right_away ==
                      'custom'
                    ">
                      Scheduled
                    </p>
                    <p class="text-muted mb-0 fb greenColor" v-if="
                      o.is_order_custom_date_and_time_or_right_away == 'now'
                    ">
                      <faIcons class="d-print-none pointerHand" :icon="['fas', 'user-clock']" />
                      {{
  removeSecondsFromTime(
    displayCustomDateTime("", o.custom_time)
)
                      }}
                    </p>
                    <p class="text-muted mb-0 fb greenColor" v-if="
                      o.is_order_custom_date_and_time_or_right_away ==
                      'custom'
                    ">
                      <faIcons class="d-print-none pointerHand" :icon="['fas', 'user-clock']" />
                      {{
  removeSecondsFromTime(
    displayCustomDateTime("", o.custom_time)
)
                      }}
                    </p> -->
                  </div>
                </div>
                <div class="clearfix"></div>
                <hr class="bg-info" />
                <div class="clearfix"></div>
                <div
                  v-for="eachOrder in JSON.parse(o.customer_order)"
                  :key="eachOrder"
                >
                  <p class="fb">
                    {{ capitalizeFisrtLetterOfEachWord(eachOrder.name) }}
                    <span class="float-end">{{
                      number_format(eachOrder.price)
                    }}</span>
                  </p>
                  <div class="custom-order" v-if="eachOrder.isCustom">
                    <div v-for="option in eachOrder.options" :key="option">
                      <p class="custom-name">
                        {{ capitalizeFisrtLetterOfEachWord(option.name) }}
                        <span
                          class="custom-price float-end"
                          v-if="option.price > 0"
                          >+{{ number_format(option.price) }}</span
                        >
                        <span class="custom-price float-end" v-else
                          >&nbsp;&nbsp;</span
                        >
                      </p>
                      <div class="clearfix"></div>
                    </div>
                    <div class="clearfix"></div>
                    <p
                      class="custom-name float-end total-end"
                      v-if="eachOrder.total_item_cost > eachOrder.price"
                    >
                      Total {{ number_format(eachOrder.total_item_cost) }}
                    </p>
                    <div class="clearfix"></div>
                  </div>
                </div>
                <div class="clearfix"></div>
                <hr class="bg-info" />
                <!-- <p
                  class="d-print-none text-bold alert alert-danger"
                  v-if="o.order_instructions != ''"
                >
                  Special Instructions
                  <br />
                  {{ capitalizeFisrtLetterOfEachWord(o.order_instructions) }}
                </p> -->
                <div class="clearfix"></div>
                <div
                  v-if="
                    currentLoggedInUserData[0].user_role == 'admn' ||
                    currentLoggedInUserData[0].user_role == 'mngr' ||
                    currentLoggedInUserData[0].user_role == 'cmngr'
                  "
                >
                  <p class="fb mb5">
                    Sales
                    <span class="float-end">{{
                      number_format(o.order_total)
                    }}</span>
                  </p>
                  <p class="fb mb5">
                    Taxes ({{ o.taxes_percentage }}%)
                    <span class="float-end">{{
                      number_format(o.order_taxes_calculated)
                    }}</span>
                  </p>
                  <p class="fb mb5" v-if="o.has_tips == 1">
                    Tips
                    <span class="float-end">{{
                      number_format(parseFloat(o.total_tips_added).toFixed(2))
                    }}</span>
                  </p>
                  <p class="fb mb5 redColor" v-if="o.has_refund == 1">
                    Cancelled Amount
                    <span class="float-end">
                      {{
                        number_format(
                          (
                            parseFloat(o.order_subtotal) +
                            parseFloat(o.total_tips_added)
                          ).toFixed(2)
                        )
                      }}
                    </span>
                  </p>
                  <p class="fb mb5">
                    Subtotal
                    <span class="float-end">
                      <span id="final-price" v-if="o.has_refund == 0">
                        {{
                          number_format(
                            (
                              parseFloat(o.order_subtotal) +
                              parseFloat(o.total_tips_added)
                            ).toFixed(2)
                          )
                        }}
                      </span>
                      <span id="final-price" v-if="o.has_refund == 1">
                        {{
                          number_format(
                            (
                              parseFloat(o.order_subtotal) +
                              parseFloat(o.total_tips_added) -
                              parseFloat(o.amount_refunded)
                            ).toFixed(2)
                          )
                        }}
                      </span>
                    </span>
                  </p>
                </div>
                <!-- Show Refund or Partial Refund -->
                <div v-if="currentLoggedInUserData[0].user_role == 'admn'">
                  <div v-if="o.is_order_cancelled == 0">
                    <hr class="bg-info" />
                    <div class="d-print-none btn-group d-flex" role="group">
                      <button
                        class="d-print-none btn btns-danger"
                        type="button"
                        data-bs-toggle="modal"
                        :data-bs-target="'#cancelOrderModal' + o.order_id"
                      >
                        Cancel Order
                        {{
                          number_format(
                            (
                              parseFloat(o.order_subtotal) +
                              parseFloat(o.total_tips_added)
                            ).toFixed(2)
                          )
                        }}
                      </button>
                      <!-- <button class="d-print-none btn btns-danger" type="button" data-bs-toggle="modal"
                          :data-bs-target="'#cancelPartialOrderModal' + o.order_id">
                          Partial Refund
                        </button> -->
                    </div>
                    <div class="mycustommodal">
                      <div
                        class="modal fade"
                        tabindex="-1"
                        :id="'cancelOrderModal' + o.order_id"
                        :aria-labelledby="'cancelOrderModal' + o.order_id"
                        aria-hidden="true"
                      >
                        <div class="modal-dialog modal-dialog-scrollable">
                          <div class="modal-content">
                            <div class="modal-header">
                              <h5 class="modal-title text-danger">
                                Cancel Order
                              </h5>
                              <button
                                type="button"
                                class="btn-close"
                                data-bs-dismiss="modal"
                                aria-label="Close"
                              ></button>
                            </div>
                            <div class="modal-body itemDataDetails">
                              <h5 class="text-center">
                                Cancel Order
                                <span id="final-price">
                                  {{
                                    number_format(
                                      (
                                        parseFloat(o.order_subtotal) +
                                        parseFloat(o.total_tips_added)
                                      ).toFixed(2)
                                    )
                                  }}
                                </span>
                              </h5>
                              <p class="text-center">
                                You are about to cancel this order
                              </p>
                              <div class="row g-3 align-items-center mb-3">
                                <div class="col-auto d-block mx-auto">
                                  <div class="form-floating">
                                    <select
                                      id="reasonToCancelThisOrder"
                                      placeholder="User Role"
                                      class="form-select w300"
                                      v-model.trim="reasonToCancelOrder"
                                      aria-label="reasonToCancelThisOrder"
                                    >
                                      <option
                                        value="Customer no longer wants this order"
                                      >
                                        Customer no longer needs order
                                      </option>
                                      <option value="Customer got wrong order">
                                        Customer got wrong order
                                      </option>
                                      <option value="Order is out of stock">
                                        Order is out of stock
                                      </option>
                                      <option value="Customer never showed up">
                                        Customer never showed up
                                      </option>
                                      <option value="Other">Other</option>
                                    </select>
                                    <label for="reasonToCancelThisOrder">
                                      <faIcons :icon="['fas', 'user-tag']" />
                                      Reason to cancel this order
                                    </label>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div class="modal-footer d-flex">
                              <button
                                class="btn btn-primary"
                                data-bs-dismiss="modal"
                                aria-label="Close"
                              >
                                Never mind
                              </button>
                              <button
                                class="btn btn-danger"
                                :id="'btn-' + o.order_id"
                                @click="
                                  deactivateCurrentOrder(
                                    o.order_id,
                                    o.rest_id,
                                    'showResult-' + o.order_id,
                                    'btn-' + o.order_id
                                  )
                                "
                              >
                                Cancel Order
                                {{
                                  number_format(
                                    (
                                      parseFloat(o.order_subtotal) +
                                      parseFloat(o.total_tips_added)
                                    ).toFixed(2)
                                  )
                                }}
                              </button>
                            </div>
                            <div :id="'showResult-' + o.order_id"></div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div v-else>
                    <hr class="bg-info" />
                    <div class="d-print-none d-flex align-items-center m-3">
                      <faIcons
                        class="d-print-none pointerHand"
                        :icon="['fas', 'credit-card']"
                        style="width: 30px; height: 30px"
                      />
                      <div class="ms-3">
                        <p class="text-muted mb-0">
                          Cancelled on {{ displayCustomDate(o.cancelled_at) }}
                        </p>
                        <p class="text-muted mb-0">
                          {{ o.cancellation_reason }}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <!-- If Manager -->
                <div
                  v-if="
                    (currentLoggedInUserData[0].user_role == 'mngr' ||
                      currentLoggedInUserData[0].user_role == 'cmngr') &&
                    new Date(todaysDateIs).toDateString() <=
                      new Date(o.custom_date).toDateString()
                  "
                >
                  <div v-if="o.is_order_cancelled == 0">
                    <hr class="bg-info" />
                    <div class="d-print-none btn-group d-flex" role="group">
                      <button
                        class="d-print-none btn btns-danger"
                        type="button"
                        data-bs-toggle="modal"
                        :data-bs-target="'#cancelOrderModal' + o.order_id"
                      >
                        Void
                        {{
                          number_format(
                            (
                              parseFloat(o.order_subtotal) +
                              parseFloat(o.total_tips_added)
                            ).toFixed(2)
                          )
                        }}
                      </button>
                    </div>
                    <div class="mycustommodal">
                      <div
                        class="modal fade"
                        tabindex="-1"
                        :id="'cancelOrderModal' + o.order_id"
                        :aria-labelledby="'cancelOrderModal' + o.order_id"
                        aria-hidden="true"
                      >
                        <div class="modal-dialog modal-dialog-scrollable">
                          <div class="modal-content">
                            <div class="modal-header">
                              <h5 class="modal-title text-danger">
                                Cancel Order
                              </h5>
                              <button
                                type="button"
                                class="btn-close"
                                data-bs-dismiss="modal"
                                aria-label="Close"
                              ></button>
                            </div>
                            <div class="modal-body itemDataDetails">
                              <h5 class="text-center">
                                Cancel Order
                                <span id="final-price">
                                  {{
                                    (
                                      parseFloat(o.order_subtotal) +
                                      parseFloat(o.total_tips_added)
                                    ).toFixed(2)
                                  }}
                                </span>
                              </h5>
                              <p class="text-center">
                                You are about to cancel this order
                              </p>
                              <div class="row g-3 align-items-center mb-3">
                                <div class="col-auto d-block mx-auto">
                                  <div class="form-floating">
                                    <select
                                      id="reasonToCancelThisOrder"
                                      placeholder="User Role"
                                      class="form-select w300"
                                      v-model.trim="reasonToCancelOrder"
                                      aria-label="reasonToCancelThisOrder"
                                    >
                                      <option
                                        value="Customer no longer wants this order"
                                      >
                                        Customer no longer needs order
                                      </option>
                                      <option value="Customer got wrong order">
                                        Customer got wrong order
                                      </option>
                                      <option value="Order is out of stock">
                                        Order is out of stock
                                      </option>
                                      <option value="Customer never showed up">
                                        Customer never showed up
                                      </option>
                                      <option value="Other">Other</option>
                                    </select>
                                    <label for="reasonToCancelThisOrder">
                                      <faIcons :icon="['fas', 'user-tag']" />
                                      Reason to cancel this order
                                    </label>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div class="modal-footer d-flex">
                              <button
                                class="btn btn-primary"
                                data-bs-dismiss="modal"
                                aria-label="Close"
                              >
                                Never mind
                              </button>
                              <button
                                class="btn btn-danger"
                                :id="'btn-' + o.order_id"
                                @click="
                                  deactivateCurrentOrder(
                                    o.order_id,
                                    o.rest_id,
                                    'showResult-' + o.order_id,
                                    'btn-' + o.order_id
                                  )
                                "
                              >
                                Cancel Order
                                {{
                                  number_format(
                                    (
                                      parseFloat(o.order_subtotal) +
                                      parseFloat(o.total_tips_added)
                                    ).toFixed(2)
                                  )
                                }}
                              </button>
                            </div>
                            <div :id="'showResult-' + o.order_id"></div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div v-else>
                    <hr class="bg-info" />
                    <div class="d-print-none d-flex align-items-center m-3">
                      <faIcons
                        class="d-print-none pointerHand"
                        :icon="['fas', 'credit-card']"
                        style="width: 30px; height: 30px"
                      />
                      <div class="ms-3">
                        <p class="text-muted mb-0">
                          Cancelled on {{ displayCustomDate(o.cancelled_at) }}
                        </p>
                        <p class="text-muted mb-0">
                          {{ o.cancellation_reason }}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div>
        <div v-if="showErrorForTimeInOut" class="box">
          <div class="text-center redColor alert alert-danger bet_time_error">
            Internal Error Happened, refresh the page!
          </div>
        </div>
        <!-- <div v-if="currentLoggedInUserData[0].user_role != 'admn'">
          <div
            v-if="isUserLoggedInOrOut.length > 0"
            @click="
              isUserLoggedInOrOut[0].is_logged_out == 0
                ? timeOutUser(restId, UserAccessCodeIs)
                : timeInUser(restId, UserAccessCodeIs)
            "
          >
            <a
              v-if="isUserLoggedInOrOut[0].is_logged_out == 0"
              href="javascript:void(0)"
              class="btn btns-danger fb bet_time"
              ><faIcons :icon="['fas', 'hourglass-end']" /> Time Out</a
            >
            <a
              v-if="isUserLoggedInOrOut[0].is_logged_out == 1"
              href="javascript:void(0)"
              class="btn btns-success fb bet_time"
              ><faIcons :icon="['fas', 'hourglass-start']" /> Time In</a
            >
          </div>
          <div @click="timeInUser(restId, UserAccessCodeIs)" v-else>
            <a href="javascript:void(0)" class="btn btns-success fb bet_time"
              ><faIcons :icon="['fas', 'hourglass-start']" /> Time In!</a
            >
          </div>
        </div> -->
        <!-- <div
          @click="timeAllUsersOut(restId, UserAccessCodeIs)"
          v-if="
            currentLoggedInUserData[0].user_role == 'admn' ||
            currentLoggedInUserData[0].user_role == 'mngr' ||
            currentLoggedInUserData[0].user_role == 'cmngr' 
          "
        >
          <a href="javascript:void(0)" class="btn btns-danger fb bet_time_left"
            ><faIcons :icon="['fas', 'hourglass-end']" /> Punch All Out</a
          >
        </div> -->
      </div>
    </div>
  </div>
  <div class="container-fluid" v-else>
    <div class="d-flex aligns-items-center justify-content-center">
      <div class="m-5 alert alert-danger">
        Make sure you have the right online order page.
      </div>
    </div>
  </div>
</template>
<script>
import PrintEachOrderReciept from "./PrintEachOrderReciept.vue";
import axios from "axios";
export default {
  components: {
    PrintEachOrderReciept,
  },
  name: "ViewOrders",
  data() {
    return {
      messageTitle: "Active Order(s)",
      showErrorForTimeInOut: false,
      reasonToCancelOrder: "Customer no longer wants this order",
      currentLoggedInUserData: "",
      currentUserAccessCodeIs: "",
      currentUserAccessCodeIsErr: false,
      isUserAccessCodeValidated: false,
      btnEnabled: false,
      currentUserAccessCodeIsMsg: "",
      canUserAccessThis: false,
      showPickedUp: false,
      showInProgress: true,
      showReadyOrders: false,
      showAllOrders: false,
      restId: this.$route.params.restId,
      currentOrder: [],
      newOrderLength: 0,
      prevOrderLength: 0,
      pickUpAddress: "",
      pickUpPhoneNumber: "",
      isCompletedClicked: false,
      isActiveClicked: false,
      isReadyClicked: false,
      isCancelledClicked: false,
      businessName: "",
      custName: "",
      orderData: "",
      custom_order_date_or_now: "",
      custEmail: "",
      showOrderData: true,
      showCustomerData: false,
      tips: 0,
      OrdersAPI: this.$store.getters.OrdersAPI,
      orderSequenceNumber: 0,
      custom_date_time: "",
      currentRealOrder: [],
      currentOrderInDetails: [],
      getNewOrders: null,
      doesRestDataExists: true,
      UserAccessCodeIs: "",
      todaysDateIs: new Date(
        new Date().getTime() - new Date().getTimezoneOffset() * 60000
      )
        .toISOString()
        .split("T")[0],
      viewOrderStartingDate: new Date(
        new Date().getTime() - new Date().getTimezoneOffset() * 60000
      )
        .toISOString()
        .split("T")[0],
      viewOrderEndingDate: new Date(
        new Date().getTime() - new Date().getTimezoneOffset() * 60000
      )
        .toISOString()
        .split("T")[0],
      timestamp: "",
      isUserLoggedInOrOut: [],
      currentRestDetailsArr: [],
      // wakeLock: "",
    };
  },
  mounted() {
    // console.table(currentLoggedInUserAccessData);
    // this.viewOrders(this.restId);
    this.doesThisRestExist();
    console.log("Current Date is " + this.getCurrentDate());
    console.log("Current Time is " + this.getCurrentTime());
    console.log("Full date format " + this.fullDateFormated());
    console.log(
      "Add 15 minutes to current time " +
        this.addHoursToDateAndReturnTimeOnly(new Date(), 0.25)
    );
    console.log(
      "Current Time in 24 Hours Format " + this.getCurrentTime24HoursFormat()
    );
    let currentRestDetails = localStorage.getItem("current_rest_details");
    if (currentRestDetails === null) {
      console.log("no rest data existing...");
    } else {
      console.log("current rest data");
      console.table(currentRestDetails);
    }
    if (localStorage.getItem("current_user_access_id") != null) {
      this.UserAccessCodeIs = JSON.parse(
        localStorage.getItem("current_user_access_id")
      ).id;
      this.isUserLoggedInOrOut = JSON.parse(
        localStorage.getItem("time_in_and_out")
      );
    }
    let currentLoggedInUserAccessData = localStorage.getItem(
      "current_logged_user_access_data"
    );
    if (currentLoggedInUserAccessData === null) {
      this.canUserAccessThis = false;
    } else {
      this.currentLoggedInUserData = JSON.parse(currentLoggedInUserAccessData);
      console.table(currentLoggedInUserAccessData);
      this.canUserAccessThis = true;
    }
    // this.acquireWakeLock();
  },
  beforeUnmount() {
    clearInterval(this.getNewOrders);
    // if (this.canUserAccessThis) {
    // }
  },
  created() {
    this.viewNewOrders();
    setInterval(this.getNow, 1000);
  },
  methods: {
    clearLogin() {
      this.currentUserAccessCodeIs = "";
      //clear localstorage
      localStorage.removeItem("current_logged_user_access_data");
      localStorage.clear();
      // const wakeLock = this.acquireWakeLock();
      // wakeLock.release(); //
    },
    number_format(amount) {
      const price = amount;
      // Format the price above to USD, INR, EUR using their locales.
      let dollarUS = Intl.NumberFormat("en-US", {
        style: "currency",
        currency: "USD",
      });
      return dollarUS.format(price);
    },
    removeSecondsFromTime(timeIs) {
      //assuming time in 12:34:00 AM/PM format
      return timeIs.replace(/(\d{1,2}:\d{2}):\d{2}/, "$1");
    },
    showReports() {
      this.$router.push({
        name: "ViewOrdersReports",
        params: { restId: this.restId },
      });
    },
    showTimeInAndOut() {
      this.$router.push({
        name: "TimeInOut",
        params: { restId: this.restId },
      });
    },
    goBack() {
      this.$router.push({
        name: "ViewOrdersOrTimeInAndOut",
        params: { restId: this.restId },
      });
    },
    async getUserNameForEachOrder(userId, appendTo) {
      let fd = new FormData();
      fd.append("userId", userId);
      let rst = await axios.post(
        this.OrdersAPI + `?action=currentUserName`,
        fd
      );
      if (rst.status == 200) {
        const resultData = rst.data;
        if (!resultData.error) {
          // console.log("current user name is " + resultData.user_name_is);
          let name = resultData.user_name_is;
          // console.log("order is cancelled by "+name);
          document.getElementById(appendTo).innerText =
            this.capitalizeFisrtLetterOfEachWord(name);
        }
      }
    },
    getCurrentDateAndTime() {
      let current = new Date();
      let cDate =
        current.getFullYear() +
        "-" +
        (current.getMonth() + 1) +
        "-" +
        current.getDate();
      let cTime =
        current.getHours() +
        ":" +
        current.getMinutes() +
        ":" +
        current.getSeconds();
      let dateTime = cDate + " " + cTime;
      return dateTime;
    },
    async timeInUser(restId, accessId) {
      let fd = new FormData();
      fd.append("restId", restId);
      fd.append("accessId", accessId);
      fd.append("timeIn", this.getCurrentDateAndTime());
      let rst = await axios.post(this.OrdersAPI + `?action=userTimeIn`, fd);
      if (rst.status == 200) {
        const resultData = rst.data;
        if (!resultData.error) {
          // console.log("current user name is " + resultData.user_name_is);
          localStorage.setItem(
            "time_in_and_out",
            JSON.stringify(resultData.time_in_and_out)
          );
          this.isUserLoggedInOrOut = JSON.parse(
            JSON.stringify(resultData.time_in_and_out)
          );
          this.showErrorForTimeInOut = false;
          console.log("logged in successfully");
        } else {
          this.showErrorForTimeInOut = true;
          console.log("error time in");
        }
      }
    },
    async timeOutUser(restId, accessId) {
      let fd = new FormData();
      fd.append("restId", restId);
      fd.append("accessId", accessId);
      fd.append("id", this.isUserLoggedInOrOut[0].id);
      fd.append("timeOut", this.getCurrentDateAndTime());
      let rst = await axios.post(this.OrdersAPI + `?action=userTimeOut`, fd);
      if (rst.status == 200) {
        const resultData = rst.data;
        if (!resultData.error) {
          // localStorage.setItem(
          //   "time_in_and_out",
          //   JSON.stringify(resultData.time_in_and_out)
          // );
          // this.isUserLoggedInOrOut = JSON.parse(
          //   JSON.stringify(resultData.time_in_and_out)
          // );
          console.log("logged out successfully");
          this.showErrorForTimeInOut = false;
          this.clearLogin();
        } else {
          this.showErrorForTimeInOut = true;
          console.log("error timing out");
        }
      }
    },
    playSound() {
      let sound = document.getElementById("audio");
      sound.play();
      // setTimeout(sound.play(), 4000);
      // sound.stop();
    },
    async timeAllUsersOut(restId, accessId) {
      let fd = new FormData();
      fd.append("restId", restId);
      fd.append("accessId", accessId);
      fd.append("timeOut", this.getCurrentDateAndTime());
      let rst = await axios.post(
        this.OrdersAPI + `?action=timeAllUsersOut`,
        fd
      );
      if (rst.status == 200) {
        const resultData = rst.data;
        if (!resultData.error) {
          console.log("logged out successfully");
          this.showErrorForTimeInOut = false;
          this.clearLogin();
        } else {
          console.log("error timing out");
          this.showErrorForTimeInOut = true;
        }
      }
    },
    async canUserAccessCurrentPage() {
      this.btnEnabled = false;
      console.log(this.restId);
      console.log(this.currentUserAccessCodeIs);
      let currentUserIdArr = { id: this.currentUserAccessCodeIs };
      localStorage.setItem(
        "current_user_access_id",
        JSON.stringify(currentUserIdArr)
      );
      this.UserAccessCodeIs = this.currentUserAccessCodeIs;
      // console.log(`find all orders for current restaurant ${restId}`);
      if (this.isUserAccessCodeValidated) {
        let fd = new FormData();
        fd.append("restId", this.restId);
        fd.append("accessId", this.currentUserAccessCodeIs);
        fd.append("todaysDateIs", this.todaysDateIs);
        let rst = await axios.post(
          this.OrdersAPI + `?action=canAccessThisPage`,
          fd
        );
        console.log(rst);
        const resultData = rst.data;
        if (rst.status == 200) {
          console.table(resultData);
          if (resultData.error) {
            console.log("this user has an error");
            this.currentUserAccessCodeIsErr = true;
            this.isUserAccessCodeValidated = false;
            this.btnEnabled = false;
            this.currentUserAccessCodeIsMsg = resultData.message;
          } else {
            this.btnEnabled = false;
            if (resultData.is_order_updated) {
              console.log("user name is " + resultData.userNameIs);
            }
            //now set a localstorage to save logged in user data
            localStorage.removeItem("current_logged_user_access_data");
            let loggedInUserId = [];
            loggedInUserId.push({
              user_name: resultData.userNameIs,
              user_id: resultData.userIdIs,
              user_role: resultData.userRoleIs,
            });
            localStorage.setItem(
              "current_logged_user_access_data",
              JSON.stringify(loggedInUserId)
            );
            localStorage.setItem(
              "time_in_and_out",
              JSON.stringify(resultData.setTimeInOut)
            );
            this.isUserLoggedInOrOut = JSON.parse(
              JSON.stringify(resultData.setTimeInOut)
            );
            // this.acquireWakeLock();
          }
        }
      } else {
        this.btnEnabled = false;
        this.currentUserAccessCodeIsErr = true;
        this.isUserAccessCodeValidated = false;
        this.currentUserAccessCodeIsMsg = "Refresh the page";
      }
    },
    currentCompletedOrders() {
      this.messageTitle = "Completed Order(s)";
      let currentOrderDetails = localStorage.getItem("current_orders");
      console.log("View Current Active Orders");
      this.isCancelledClicked = false;
      this.isCompletedClicked = true;
      this.isActiveClicked = false;
      this.isReadyClicked = false;
      let currentOrders = JSON.parse(currentOrderDetails).filter(
        (each) =>
          each.is_order_cancelled == 0 &&
          each.is_order_confirmed == 1 &&
          each.is_order_picked_up == 1
      );
      this.currentOrderInDetails = currentOrders
        .sort(function (a, b) {
          return (
            new Date(b.custom_date + " " + b.custom_time) -
            new Date(a.custom_date + " " + a.custom_time)
          );
        })
        .reverse();
    },
    currentReadyOrders() {
      this.messageTitle = "Ready Order(s)";
      let currentOrderDetails = localStorage.getItem("current_orders");
      console.log("View Current Active Orders");
      this.isCancelledClicked = false;
      this.isCompletedClicked = false;
      this.isActiveClicked = false;
      this.isReadyClicked = true;
      let currentOrders = JSON.parse(currentOrderDetails).filter(
        (each) =>
          each.is_order_cancelled == 0 &&
          each.is_order_confirmed == 1 &&
          each.is_order_picked_up == 0
      );
      this.currentOrderInDetails = currentOrders
        .sort(function (a, b) {
          return (
            new Date(b.custom_date + " " + b.custom_time) -
            new Date(a.custom_date + " " + a.custom_time)
          );
        })
        .reverse();
    },
    currentActiveOrders() {
      this.messageTitle = "Active Order(s)";
      let currentOrderDetails = localStorage.getItem("current_orders");
      console.log("View Current Active Orders");
      this.isCancelledClicked = false;
      this.isCompletedClicked = false;
      this.isActiveClicked = true;
      this.isReadyClicked = false;
      let currentOrders = JSON.parse(currentOrderDetails).filter(
        (each) =>
          each.is_order_cancelled == 0 &&
          each.is_order_confirmed == 0 &&
          each.is_order_picked_up == 0
      );
      //sort by time
      this.currentOrderInDetails = currentOrders
        .sort(function (a, b) {
          return (
            new Date(b.custom_date + " " + b.custom_time) -
            new Date(a.custom_date + " " + a.custom_time)
          );
        })
        .reverse();
    },
    currentCancelledOrders() {
      this.messageTitle = "Cancelled Order(s)";
      let currentOrderDetails = localStorage.getItem("current_orders");
      console.log("View Current Active Orders");
      this.isCancelledClicked = true;
      this.isCompletedClicked = false;
      this.isActiveClicked = false;
      this.isReadyClicked = false;
      let currentOrders = JSON.parse(currentOrderDetails).filter(
        (each) => each.is_order_cancelled == 1
      );
      //sort by time
      this.currentOrderInDetails = currentOrders
        .sort(function (a, b) {
          return (
            new Date(b.custom_date + " " + b.custom_time) -
            new Date(a.custom_date + " " + a.custom_time)
          );
        })
        .reverse();
    },
    async doesThisRestExist() {
      let fd = new FormData();
      fd.append("restId", this.restId);
      let rst = await axios.post(
        this.OrdersAPI + `?action=isRestaurantExist`,
        fd
      );
      const resultData = rst.data;
      if (rst.status == 200) {
        console.table(resultData);
        localStorage.setItem(
          "current_rest_data_existing",
          JSON.stringify(resultData.current_rest_data_existing)
        );
        //check if therre is return data, means restaurant is avail and active
        let currentRes = localStorage.getItem("current_rest_data_existing");
        if (JSON.parse(currentRes).length > 0 || this.pickUpAddress != "") {
          this.doesRestDataExists = true;
          console.log("this restaurant does exists");
        } else {
          console.log("this restaurant does NOT exists");
          this.doesRestDataExists = false;
        }
      }
    },

    async refundOrder(
      orderNo,
      chargeId,
      userId,
      amount,
      restId,
      showResultId,
      currentBtn,
      last4Digits
    ) {
      document.getElementById(currentBtn).setAttribute("disabled", "");
      let fd = new FormData();
      fd.append("orderNo", orderNo);
      fd.append("chargeId", chargeId);
      fd.append("restId", restId);
      fd.append("amount", amount);
      fd.append("userId", userId);
      fd.append("last4", last4Digits);
      fd.append("cancelationReason", this.reasonToCancelOrder);
      let rst = await axios.post(this.OrdersAPI + `?action=refundOrder`, fd);
      const resultData = rst.data;
      if (rst.status == 200) {
        console.log(resultData);
        if (resultData.error) {
          document.getElementById(currentBtn).removeAttribute("disabled");
          document.getElementById(showResultId).innerHTML =
            "<div class='m-5 alert alert-warning'>" +
            resultData.message +
            "</div>";
        } else {
          document.getElementById(currentBtn).setAttribute("disabled", "");
          document.getElementById(showResultId).innerHTML =
            "<div class='m-5 alert alert-success'>" +
            resultData.message +
            "</div>";
          // let ele = document.getElementsByTagName("body");
          document.body.classList.remove("modal-open");
          document.getElementById("cancelOrderModal" + orderNo).remove();
          document.body.removeAttribute("style");
          const boxes = document.querySelectorAll(".modal-backdrop");
          boxes.forEach((box) => {
            box.remove();
          });
        }
      } else {
        document.getElementById(currentBtn).removeAttribute("disabled");
        console.log(resultData);
        document.getElementById(showResultId).innerHTML =
          "<div class='m-5 alert alert-warning'>" +
          resultData.message +
          "</div>";
      }
    },
    async deactivateCurrentOrder(orderNo, restId, showResultId, currentBtn) {
      document.getElementById(currentBtn).setAttribute("disabled", "");
      let fd = new FormData();
      fd.append("orderNo", orderNo);
      fd.append("restId", restId);
      fd.append("cancelationReason", this.reasonToCancelOrder);
      let rst = await axios.post(
        this.OrdersAPI + `?action=deactivateCurrentOrder`,
        fd
      );
      const resultData = rst.data;
      if (rst.status == 200) {
        console.log(resultData);
        if (resultData.error) {
          document.getElementById(currentBtn).removeAttribute("disabled");
          document.getElementById(showResultId).innerHTML =
            "<div class='m-5 alert alert-warning'>" +
            resultData.message +
            "</div>";
        } else {
          document.getElementById(currentBtn).setAttribute("disabled", "");
          document.getElementById(showResultId).innerHTML =
            "<div class='m-5 alert alert-success'>" +
            resultData.message +
            "</div>";
          // let ele = document.getElementsByTagName("body");
          document.body.classList.remove("modal-open");
          document.getElementById("cancelOrderModal" + orderNo).remove();
          document.body.removeAttribute("style");
          const boxes = document.querySelectorAll(".modal-backdrop");
          boxes.forEach((box) => {
            box.remove();
          });
        }
      } else {
        document.getElementById(currentBtn).removeAttribute("disabled");
        console.log(resultData);
        document.getElementById(showResultId).innerHTML =
          "<div class='m-5 alert alert-warning'>" +
          resultData.message +
          "</div>";
      }
    },
    displayCustomDate(dateIs) {
      const currentDate = new Date(dateIs).toLocaleDateString();
      const currentTime = new Date(dateIs).toLocaleTimeString();
      console.log(currentDate);
      console.log(currentTime);
      // const [year, month, day] = currentDate.split("-");
      // const result = [month, day, year].join("/");
      let n = currentDate + " at " + this.timeFormat12Hours(currentTime);
      return n;
    },
    validateUserAccessCode(e) {
      let val = e.target.value;
      this.validateUserAccessCodeInput(val);
    },
    validateUserAccessCodeInput(val) {
      // let userPhoneRegex = /^\d{10}$/;
      console.log("User Code: " + val);
      if (val == "") {
        this.currentUserAccessCodeIsErr = true;
        this.isUserAccessCodeValidated = false;
        this.currentUserAccessCodeIsMsg = "Must Enter User Code ..";
        this.btnEnabled = false;
      } else if (/^\d+$/.test(val) == false) {
        //only numbers from 0-9 no digits, + - * / .
        this.btnEnabled = false;
        this.currentUserAccessCodeIsErr = true;
        this.isUserAccessCodeValidated = false;
        this.currentUserAccessCodeIsMsg = "Numbers Only";
      } else {
        this.btnEnabled = true;
        this.currentUserAccessCodeIsErr = false;
        this.isUserAccessCodeValidated = true;
        this.currentUserAccessCodeIsMsg = "";
      }
    },
    getNow() {
      const today = new Date();
      let hour = today.getHours();
      let amOrPm = hour < 12 ? " AM" : " PM";
      if (hour == 0) {
        hour = 12;
      }
      if (hour > 12) {
        hour = hour - 12;
      }
      if (hour < 10) {
        hour = "0" + hour;
      }
      let minute = today.getMinutes();
      if (minute < 10) {
        minute = "0" + minute;
      }
      // add a zero in front of numbers<10
      // minute = this.checkTime(minute);
      let sec = today.getSeconds();
      if (sec < 10) {
        sec = "0" + sec;
      }
      let time = hour + ":" + minute + ":" + sec + amOrPm;
      this.timestamp = time;
    },
    checkTime(i) {
      if (i < 10) {
        i = "0" + i;
      }
      return i;
    },
    UpdateTimer() {
      this.Timer.innerHTML = this.TotalMins + ":" + this.secs;
    },
    viewNewOrders() {
      //first find if user is not logged in
      this.getNewOrders = setInterval(() => {
        let currentLoggedInUserAccessData = localStorage.getItem(
          "current_logged_user_access_data"
        );
        if (currentLoggedInUserAccessData === null) {
          console.log("no log in");
          this.canUserAccessThis = false;
          // this.currentUserAccessCodeIs = "";
          // this.
        } else {
          console.log("searching for new orders...");
          console.log("user is logged in");
          this.canUserAccessThis = true;
          this.currentLoggedInUserData = JSON.parse(
            currentLoggedInUserAccessData
          );
          if (
            this.isCompletedClicked ||
            this.isReadyClicked ||
            this.isCancelledClicked
          ) {
            console.log(
              "Don't show new orders till user click on active orders"
            );
          } else {
            console.log("Show Current Orders");
            // let current_orders = JSON.parse(localStorage.getItem("current_orders"));
            // this.prevOrderLength = current_orders.length;
            this.prevOrderLength = this.currentOrder.length;
            console.log("Intitated # of order is: ", this.prevOrderLength);
            this.viewOrders(this.restId);
          }
        }
      }, 3000); //run this every 15 seconds
    },
    formatPhoneNumber(number) {
      //remove . + -  from any number
      let cleaned = ("" + number).replace(/\D/g, "");
      let match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
      if (match) {
        return "(" + match[1] + ") " + match[2] + "-" + match[3];
      }
      return null;
    },
    timeFormat12Hours(time) {
      // Check correct time format and split into components
      time = time
        .toString()
        .match(/^([01]\d|2[0-3])(:)([0-5]\d)(:[0-5]\d)?$/) || [time];

      if (time.length > 1) {
        // If time format correct
        time = time.slice(1); // Remove full string match value
        time[5] = +time[0] < 12 ? " AM" : " PM"; // Set AM/PM
        time[0] = +time[0] % 12 || 12; // Adjust hours
      }
      return time.join(""); // return adjusted time or original string
    },
    getCurrentDate() {
      // let currentDate = new Date().toJSON().slice(0, 10); // 10/26/2022 or use below
      let currentDate = new Date().toLocaleDateString();
      return currentDate;
    },
    getCurrentTime() {
      let currentTime = new Date().toLocaleTimeString();
      return currentTime;
    },
    addMinutesToCurrentTime(addMins) {
      let currentTime = new Date();
      return currentTime.setMinutes(currentTime.getMinutes() + addMins);
    },
    addHoursToDateAndReturnTimeOnly(objDate, intHours) {
      var numberOfMlSeconds = objDate.getTime();
      var addMlSeconds = intHours * 60 * 60 * 1000;
      var newDateObj = new Date(numberOfMlSeconds + addMlSeconds); //here it return full date with time added
      return newDateObj.toLocaleTimeString("en-US", {
        hour12: false,
      }); //here only returns time added to current time
    },
    fullDateFormated() {
      return new Date().toLocaleString("en-US", {
        hour12: false,
        year: "numeric",
        month: "2-digit",
        day: "2-digit",
        hour: "2-digit",
        minute: "2-digit",
        second: "2-digit",
      });
    },
    getCurrentTime24HoursFormat() {
      let currentTime = new Date().toLocaleTimeString("en-US", {
        hour12: false,
      });
      return currentTime;
    },
    displayCustomDateTime(dateIs, timeIs) {
      if (dateIs != "") {
        const [year, month, day] = dateIs.split("-");
        const result = [month, day, year].join("/");
        let n = result + " at " + this.timeFormat12Hours(timeIs);
        return n;
      } else {
        return this.timeFormat12Hours(timeIs);
      }
    },
    async viewOrders(restId) {
      console.log(`find all orders for current restaurant ${restId}`);
      let fd = new FormData();
      fd.append("restId", restId);
      fd.append("startDate", this.viewOrderStartingDate);
      fd.append("endDate", this.viewOrderEndingDate);
      let rst = await axios.post(this.OrdersAPI + `?action=viewOrders`, fd);
      console.log(rst);
      const resultData = rst.data;
      if (rst.status == 200) {
        console.table(resultData);
        if (resultData.error) {
          console.log("this order was not found");
        } else {
          console.log("this order was found");
          localStorage.setItem(
            "current_orders",
            JSON.stringify(resultData.current_orders)
          );
          localStorage.setItem(
            "current_rest_details",
            JSON.stringify(resultData.current_rest_details)
          );
          // show current order details
          let currentRestaurants = JSON.parse(
            localStorage.getItem("current_rest_details")
          );
          if (currentRestaurants.length >= 1) {
            // this.newOrderLength = currentRestaurants.length;
            // this.playSound();

            // sound.stop();
            console.table(currentRestaurants);
            this.pickUpAddress = currentRestaurants[0].rest_address;
            this.pickUpPhoneNumber = currentRestaurants[0].rest_phone;
            this.businessName = currentRestaurants[0].rest_name;
          }
          let current_orders = localStorage.getItem("current_orders");
          this.currentOrder = JSON.parse(current_orders);
          console.table(current_orders);

          let currentOrderDetails = localStorage.getItem("current_orders");
          console.table("View Current Orders: ", currentOrderDetails);
          //this.currentOrderInDetails = JSON.parse(currentOrderDetails);
          let currentActiveOrders = JSON.parse(currentOrderDetails).filter(
            (each) =>
              each.is_order_confirmed == 0 && each.is_order_cancelled == 0
          );
          //sort by time
          this.currentOrderInDetails = currentActiveOrders
            .sort(function (a, b) {
              return (
                new Date(b.custom_date + " " + b.custom_time) -
                new Date(a.custom_date + " " + a.custom_time)
              );
            })
            .reverse();
          this.newOrderLength = this.currentOrderInDetails.length;
          console.log("New loaded # of order is: ", this.newOrderLength);
          if (this.newOrderLength > this.prevOrderLength) {
            setTimeout(() => {
              this.playSound();
            }, 1000);
            let sound = document.getElementById("audio");
            sound.pause();
          }
        }
      }
    },
    //should get current logged in id to check if he owns current restaurant
    hideOrShowCurrentSection(id, status) {
      document.getElementById(id).style.display = status;
    },
    async orderReadyOrPickedUp(id, orderId, type) {
      this.hideOrShowCurrentSection(id, "none");
      let IdIs = id;
      let OrderIdIs = orderId;
      let TypeIs = type;
      //must update local storage while updating db
      //till db is updated, it will return new orders
      // hideOrShowCurrentSection(id, 'flex');
      // console.log(`find all orders for current restaurant ${restId}`);
      let fd = new FormData();
      fd.append("restId", this.restId);
      fd.append("orderId", orderId);
      fd.append("type", type);
      let rst = await axios.post(
        this.OrdersAPI + `?action=readyOrPickedUp`,
        fd
      );
      console.log(rst);
      const resultData = rst.data;
      if (rst.status == 200) {
        console.table(resultData);
        if (resultData.error) {
          console.log("this order has error");
          this.hideOrShowCurrentSection(id, "flex");
          //if failed, re run this function
          this.orderReadyOrPickedUp(IdIs, OrderIdIs, TypeIs);
        } else {
          if (resultData.is_order_updated) {
            console.log("this order status is updated");
            this.hideOrShowCurrentSection(id, "none");
          }
        }
      }
    },
    capitalizeFisrtLetterOfEachWord(text) {
      return text
        .toLowerCase()
        .split(" ")
        .map((s) => s.charAt(0).toUpperCase() + s.substring(1))
        .join(" ");
    },
    async acquireWakeLock() {
      try {
        const wakeLock = await navigator.wakeLock.request("screen");
        console.log("Wake lock acquired");
        return wakeLock;
      } catch (err) {
        console.error(err);
      }
    },
    printSection2(id) {
      var restorepage = document.body.innerHTML;
      var printcontent = document.getElementById(id).innerHTML;
      document.body.innerHTML = printcontent;
      window.print();
      document.body.innerHTML = restorepage;
      location.reload();
      // setTimeout(() => {
      //   // window.open("", "_self").close();
      //   // this.$router.push({ name: 'MyRestaurantChecks', params: { id: this.restNum, restId: this.restId } });
      // }, 2000);
    },
    printSection(id) {
      // Get HTML to print from element
      const prtHtml = document.getElementById(id).innerHTML;
      // Get all stylesheets HTML
      let stylesHtml = "";
      for (const node of [
        ...document.querySelectorAll('link[rel="stylesheet"], style'),
      ]) {
        stylesHtml += node.outerHTML;
      }
      // Open the print window
      const WinPrint = window.open(
        "",
        "",
        "left=0,top=0,width=800,height=900,toolbar=0,scrollbars=0,status=0"
      );

      WinPrint.document.write(`<!DOCTYPE html>
          <html>
          <head>
              ${stylesHtml}
          </head>
          <body>
              ${prtHtml}
          </body>
          </html>`);

      WinPrint.document.close();
      WinPrint.focus();
      WinPrint.print();
      WinPrint.close();
    },
  },
};
</script>

<style scoped>
/* @import "https://cdn.jsdelivr.net/npm/bootstrap-print-css/css/bootstrap-print.min.css"; */
@media print {
  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  p,
  div,
  body,
  hr,
  .redColor,
  button,
  .btn,
  .biggerFont,
  .alert-danger,
  .btns-danger {
    font-size: 10pt;
    color: black !important;
    font-weight: bold !important;
    font-family: monospace !important;
    font-variant-caps: normal !important;
    font-optical-sizing: auto !important;
    font-style: normal !important;
    font-variant: proportional-nums !important;
    background-color: white !important;
  }

  hr {
    height: 0;
  }

  .btns-danger,
  .btns-danger:hover,
  .btns-danger:active {
    color: black !important;
    background-color: white !important;
    border-color: black !important;
    font-weight: bold !important;
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    font-size: 15pt;
    font-family: monospace !important;
  }

  .alert-danger {
    background-color: none;
    border-radius: 5px;
    border: 1px solid #e2e2e2;
  }

  .each-order-item {
    border: none !important;
  }
}

.each-order-title {
  background-color: #f8f8f8 !important;
  padding: 8px;
  font-weight: bold;
  width: 100%;
  overflow: auto !important;
  color: #02027e !important;
}

.marginTop10 {
  margin-top: 14px !important;
}

.custom-bg {
  position: relative;
  padding: 2px 0 0 0;
}

.custom-header-left {
  position: absolute;
  top: -32px;
}

.custom-header-right {
  position: absolute;
  right: 0;
  top: -32px;
  font-weight: bold !important;
  background-color: white !important;
}

.addBorder {
  background-color: beige;
  border: 1px solid black;
  padding: 8px;
  font-weight: bold;
  width: 90%;
  margin: 18px auto;
  border-radius: 5px;
}

.addressStyle {
  font-style: italic;
  padding: 0;
  margin: 0;
  line-height: 0.5;
}

.form-data-error {
  color: red;
}

.form-data-error input,
.form-data-error textarea {
  border-color: red;
}

.itemDataDetails {
  padding: 10px;
  margin-bottom: 10px;
  border: 10px solid #eee;
}

.each-order-item {
  border: 1px solid #000203;
  padding: 6px;
  margin: 23px 0;
}

.itemDataDetails img {
  width: 320px;
  height: 215px;
  margin-bottom: 10px;
}

.bw {
  overflow-wrap: break-word;
}

.darkred {
  color: darkred;
}

.redColor {
  color: red !important;
}

.order-placed {
  padding: 10px;
}

.order-placed h2 {
  color: green;
}

.addressInfo {
  display: block;
  font-style: italic;
  font-weight: bold;
}

.card-header {
  background-color: #008c5d !important;
  font-weight: bold;
}

.mb5 {
  margin-bottom: 5px;
}

.text-bold {
  font-weight: bold;
}

.card-header a,
.card-header a:hover,
.card-header a:visited,
.card-header a:active,
.card-header a:focus {
  color: white;
  display: block;
  text-align: left;
  border: none;
}

.card-header .btn-check:focus + .btn,
.card-header .btn:focus {
  box-shadow: none !important;
}

.navbar {
  padding-top: 0px !important;
  padding-bottom: 5px !important;
}

.fb {
  font-weight: bold;
}

.custom-order {
  margin-bottom: 10px;
}

.btns-success,
.btns-success:hover,
.btns-success:active {
  color: #21b023;
  background-color: #fff;
  border-color: #21b023;
  font-weight: bold;
}

.biggerFont {
  font-size: 1.5em;
  padding: 0;
}

.btns-orange,
.btns-orange:hover,
.btns-orange:active {
  color: #ff5722 !important;
  background-color: #fff;
  border-color: #ff5722 !important;
  font-weight: bold;
}

.btns-primary,
.btns-primary:hover,
.btns-primary:active {
  color: blue;
  background-color: white;
  border-color: blue;
  font-weight: bold;
}

/* .fsSmall {
  font-size: 0.85em;
} */

.btns-danger,
.btns-danger:hover,
.btns-danger:active {
  color: red;
  background-color: white;
  border-color: red;
  font-weight: bold;
}

.color-356f71 {
  color: #356f71;
}

.custom-name {
  padding-left: 20px;
  margin-bottom: -5px;
  clear: both;
  margin-top: -14px;
}

.total-end {
  font-weight: bold;
  margin-top: 5px;
}

.slidecontainer {
  width: 100%;
}

.slider {
  -webkit-appearance: none;
  width: 100%;
  height: 15px;
  border-radius: 5px;
  background: #d3d3d3;
  outline: none;
  opacity: 0.7;
  -webkit-transition: 0.2s;
  transition: opacity 0.2s;
}

.slider:hover {
  opacity: 1;
}

.slider::-webkit-slider-thumb {
  -webkit-appearance: none;
  appearance: none;
  width: 25px;
  height: 25px;
  border-radius: 50%;
  background: #04aa6d;
  cursor: pointer;
}

.slider::-moz-range-thumb {
  width: 25px;
  height: 25px;
  border-radius: 50%;
  background: #04aa6d;
  cursor: pointer;
}

.pointerHand {
  cursor: pointer;
}

.greenColor {
  color: #00c904 !important;
}

.mainHeader {
  box-sizing: border-box;
  color: red;
  width: 100%;
  margin: 0;
}

.colorRed {
  color: red;
}

.custom-button {
  background-color: white;
}

.greenColor {
  color: #21b023 !important;
}

.yellowColor {
  color: #ffff00;
}

.blueColor {
  color: #0000ff;
}

.exit-button,
.report-button,
.search-button {
  font-weight: bold;
}

.exit-button {
  color: #d20000;
}

.search-button {
  color: #3668a3;
}

.report-button {
  color: #3668a3 !important;
}

.borderRed {
  border-color: red !important;
  background-color: red !important;
  color: white !important;
}

.box {
  background-color: white;
  border-top: 2px solid #21b023;
  height: 43px;
  position: fixed;
  bottom: 0;
  padding: 0;
  z-index: 1000;
}

.bet_time {
  z-index: 1001;
  position: absolute;
  bottom: 4px;
  right: 10px;
  padding: 3px;
}

.bet_time_left {
  z-index: 1001;
  position: absolute;
  bottom: 4px;
  left: 10px;
  padding: 3px;
}

.bet_time_error {
  position: fixed;
  bottom: 27px;
  width: 100%;
  padding: 5px;
}

.blink {
  border-color: red;
  animation: blink-animation 1s steps(5, start) infinite;
  -webkit-animation: blink-animation 1s steps(5, start) infinite;
}

@keyframes blink-animation {
  to {
    visibility: hidden;
  }
}

@-webkit-keyframes blink-animation {
  to {
    visibility: hidden;
  }
}

.font-size-1-25-em {
  font-size: 1.25em;
}

@media only screen and (max-width: 600px) {
  .biggerFont {
    font-size: 1em;
  }
}
</style>
