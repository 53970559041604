<template>
  <div class="container-fluid">
    <div id="currentRestMenu">
      <div v-if="this.isRestExists" class="add-new-restaurant-page">
        <div v-if="showPickDateAndTime">
          <div class="row g-3 align-items-center mb-3">
            <div
              class="col-auto d-block mx-auto col-12 col-sm-12 col-md-5 main-order-select-date-custom text-center"
            >
              <h1 class="f-bold titleBrown">
                {{ this.capitalizeFisrtLetterOfEachWord(userName) }}
              </h1>
              <p>
                <faIcons :icon="['fas', 'map-pin']" />
                {{ this.capitalizeFisrtLetterOfEachWord(userAddress) }}
              </p>
              <p class="telephone">
                <faIcons :icon="['fas', 'phone-alt']" />
                <a class="telephone" :href="'tel:' + userPhone">{{
                  " " + formatPhoneNumber(userPhone)
                }}</a>
              </p>
              <div class="clearfix"></div>
              <div v-if="canCustomerOrderNow">
                <h3 class="titleBrown">Pick up time</h3>
                <div class="form-check form-check-inline m-3">
                  <input
                    class="form-check-input"
                    type="radio"
                    name="inlineRadioOptions"
                    id="inlineRadio1"
                    value="now"
                    v-model="isCustom"
                    @click="
                      set15MinutesOrder();
                      showMenuBtnIfOrderNow();
                    "
                  />
                  <label
                    class="form-check-label"
                    for="inlineRadio1"
                    @click="
                      set15MinutesOrder();
                      showMenuBtnIfOrderNow();
                    "
                    >in 15 Minutes</label
                  >
                </div>
                <div class="form-check form-check-inline m-3">
                  <input
                    @click="selectDate()"
                    class="form-check-input"
                    type="radio"
                    name="inlineRadioOptions"
                    id="inlineRadio2"
                    value="custom"
                    v-model="isCustom"
                  />
                  <label
                    class="form-check-label"
                    @click="selectDate()"
                    for="inlineRadio2"
                    >Scheduled</label
                  >
                </div>
                <div class="clearfix"></div>
                <div class="input-group mb-3" v-if="isCustom == 'custom'">
                  <span
                    class="input-group-text"
                    :class="datePickerError ? 'error-data' : ''"
                    id="selectedDate"
                    >Select Date</span
                  >
                  <input
                    type="date"
                    class="form-control"
                    v-model="customDatePicker"
                    :min="minDate"
                    placeholder="selectedDate"
                    aria-label="selectedDate"
                    aria-describedby="selectedDate"
                    @change="pickingCustomDate()"
                    @input="pickingCustomDate()"
                    @keyup="pickingCustomDate()"
                  />
                </div>
                <div class="input-group mb-3" v-if="isCustom == 'custom'">
                  <span class="input-group-text" id="selectedTime"
                    >Select Time</span
                  >
                  <input
                    type="time"
                    class="form-control"
                    v-model="customTimePicker"
                    placeholder="selectedTime"
                    aria-label="selectedTime"
                    aria-describedby="selectedTime"
                    @change="pickingCustomDate()"
                    @keyup="pickingCustomDate()"
                    @input="pickingCustomDate()"
                  />
                </div>
                <div v-if="datePickerError" class="alert alert-danger">
                  {{ datePickerErrorMsg }}
                </div>
                <!-- <p class="text-muted m-3" v-if="isCustom == 'custom'">
                  if you're making a future order and need to cancel your order,
                  call us today. Otherwise it will be hard to refund you
                </p> -->
                <!-- <br /> -->
                <button
                  class="btn btn-block btn-primary"
                  :disabled="showMenuBtn"
                  @click="
                    showMenu = true;
                    showPickDateAndTime = false;
                  "
                >
                  <faIcons :icon="['fas', 'hamburger']" /> Get Started</button
                >&nbsp;
                <router-link tag="li" :to="{ name: 'ViewAllRestaurants' }">
                  <a class="btn btn-success">
                    <faIcons :icon="['fas', 'store-alt']" /> All Locations
                  </a>
                </router-link>
              </div>
              <div v-else>
                <div class="alert alert-warning">
                  <faIcons :icon="['fas', 'store-alt']" /> Business is currently
                  not accepting online orders at this time, but you may browse
                  thier online menu..
                </div>
                <button
                  class="btn btn-block btn-primary"
                  @click="
                    showMenu = true;
                    showPickDateAndTime = false;
                  "
                >
                  <faIcons :icon="['fas', 'hamburger']" /> Browse menu
                </button>
                &nbsp;
                <router-link tag="li" :to="{ name: 'ViewAllRestaurants' }">
                  <a class="btn btn-success">
                    <faIcons :icon="['fas', 'store-alt']" /> All Locations
                  </a>
                </router-link>
              </div>
            </div>
          </div>
        </div>
        <div v-if="showMenu">
          <div
            v-if="this.$store.state.__IsOrderPlaced"
            class="customer-added-order text-center"
          >
            <!-- <p class="d-flex m-2">
              {{
                this.capitalizeFisrtLetterOfEachWord(
                  this.$store.state.__AddedMealName
                )
              }}
            </p> -->
            <!-- is added to your cart! -->
            <p class="text-center biggerCheck m-3">
              <faIcons :icon="['fas', 'check-double']" />
            </p>
          </div>
          <div v-else>
            <h1 class="f-bold titleBrown">
              {{ this.capitalizeFisrtLetterOfEachWord(userName) }}
            </h1>
            <p>
              <faIcons :icon="['fas', 'map-pin']" />
              {{ this.capitalizeFisrtLetterOfEachWord(userAddress) }}
            </p>
            <p>
              <faIcons :icon="['fas', 'phone-alt']" />
              <a class="telephone" :href="'tel:' + userPhone">{{
                " " + formatPhoneNumber(userPhone)
              }}</a>
            </p>
            <router-link tag="li" :to="{ name: 'ViewAllRestaurants' }">
              <a class="btn btn-success">
                <faIcons :icon="['fas', 'store-alt']" /> All Location(s)
              </a> </router-link
            >&nbsp;
            <a
              class="btn btn-primary"
              v-if="menuCategories.length > 0"
              id="changePickUpTime"
              @click="
                showMenu = false;
                showPickDateAndTime = true;
              "
            >
              <faIcons :icon="['fas', 'clock']" /> Pickup time
            </a>
            <div class="clearfix"></div>
            <br />
            <!--View Menu Here -->
            <div class="restaurant-menu">
              <div v-if="menuCategories.length > 0">
                <!-- <p class="cart-total">Total: ${{ cartTotal == 0 ? '0.00': this.$store.state.__CartTotal }}</p> -->
                <!-- <button class="btn btn-block btn-primary" id="changePickUpTime" @click="
    showMenu = false;
  showPickDateAndTime = true;
                ">
                  <faIcons :icon="['fas', 'clock']" /> Pickup time
                </button> -->
                <div class="clearfix"></div>
                <div class="float-right mb-3 stickyPosition">
                  <button
                    class="btn btn-default cart-total"
                    v-if="canCustomerOrderNow"
                  >
                    <faIcons :icon="['fas', 'hand-holding-usd']" />
                    {{ number_format(this.$store.state.__CartTotal) }}
                  </button>
                  <button
                    class="btn btn-success float-end"
                    v-if="canCustomerOrderNow"
                    data-bs-toggle="modal"
                    data-bs-target="#customerOrderModal"
                    type="button"
                    :disabled="!this.$store.state.__CartTotal > 0"
                  >
                    <faIcons :icon="['fas', 'shopping-cart']" /> View & Pay
                  </button>

                  <div class="clearfix"></div>
                  <div class="scrollmenu">
                    <faIcons
                      class="whiteColor"
                      :icon="['fas', 'angle-double-right']"
                    />
                    <a
                      v-for="(i, iii) in menuCategories"
                      :key="iii + i.id"
                      class="nav-link"
                      :href="'#section-' + i.id"
                      >{{ this.capitalizeFisrtLetterOfEachWord(i.cat_name) }}</a
                    >
                    <faIcons
                      class="whiteColor"
                      :icon="['fas', 'angle-double-left']"
                    />
                  </div>
                  <div class="clearfix"></div>
                </div>
                <div></div>
                <CustomerOrderModal />
                <div class="panel-group" id="accordion">
                  <!-- <p class="fb text-center">Click on category to view menu items</p> -->

                  <div
                    v-for="(i, iii) in menuCategories"
                    :key="iii + i.id"
                    class="menu-categories"
                  >
                    <div class="clearfix"></div>
                    <h4
                      class="f-bold mt-3 catName text-center"
                      :id="'section-' + i.id"
                    >
                      {{ this.capitalizeFisrtLetterOfEachWord(i.cat_name) }}
                    </h4>
                    <!-- class="row justify-content-around panel-collapse collapse" :class="iii == 0 ? 'show' : ''"> -->
                    <div class="row justify-content-around">
                      <div
                        v-for="n in currentMenuItem(i.cat_id)"
                        :key="n"
                        class="col-12 col-sm-6 col-md-3 each-category-list"
                      >
                        <div v-if="n.item_image.length > 0" class="h300">
                          <!-- :src="getImageUrl(n.item_image)" -->
                          <img
                            :src="
                              'https://order.hooksfishnchips.com/restaurants/' +
                              n.item_image
                            "
                            class="img-thumbnail d-block mx-auto img-fluid w200"
                            :title="
                              this.capitalizeFisrtLetterOfEachWord(
                                n.item_name
                              ) +
                              ' | ' +
                              this.capitalizeFisrtLetterOfEachWord(userName) +
                              ' | ' +
                              this.capitalizeFisrtLetterOfEachWord(userAddress)
                            "
                            :alt="
                              this.capitalizeFisrtLetterOfEachWord(
                                n.item_name
                              ) +
                              ' | ' +
                              this.capitalizeFisrtLetterOfEachWord(userName) +
                              ' | ' +
                              this.capitalizeFisrtLetterOfEachWord(userAddress)
                            "
                          />
                        </div>
                        <div class="h300" v-else>
                          <img
                            src="https://order.hooksfishnchips.com/restaurants/No-image.png"
                            class="img-thumbnail d-block mx-auto img-fluid w200"
                            :title="
                              this.capitalizeFisrtLetterOfEachWord(userName) +
                              ' | ' +
                              this.capitalizeFisrtLetterOfEachWord(userAddress)
                            "
                            :alt="
                              this.capitalizeFisrtLetterOfEachWord(userName) +
                              ' | ' +
                              this.capitalizeFisrtLetterOfEachWord(userAddress)
                            "
                          />
                        </div>
                        <div class="each-menu-title-top p3">
                          <h5 class="c-brown">
                            {{
                              this.capitalizeFisrtLetterOfEachWord(n.item_name)
                            }}<span
                              class="float-end"
                              v-if="n.item_price != '0.00'"
                            >
                              {{ number_format(n.item_price) }}</span
                            >
                          </h5>
                          <!-- <span class="text-muted fs90" v-if="n.item_description.length > 1">{{
      n.item_description.slice(0, 90).toUpperCase()
  }}</span>
                          <span v-if="n.item_description.length > 90" class="text-muted fs90">...</span> -->
                        </div>
                        <!-- <h5 class="p3 f-bold c-darkGreen text-center">
                          ${{ n.item_price }}
                        </h5> -->
                        <div class="clearfix"></div>
                        <div
                          class="p3 m-2 each-menu-buttons-bottom"
                          v-if="canCustomerOrderNow"
                        >
                          <!-- <hr /> -->
                          <button
                            v-if="n.item_price != '0.00' && n.item_price != '0'"
                            class="btn btn-success float-start"
                            :id="'quick-order-' + n.item_rand_id"
                            type="button"
                            @click="
                              addToMyCart(
                                this.capitalizeFisrtLetterOfEachWord(
                                  n.item_name
                                ),
                                n.item_price
                              )
                            "
                          >
                            <faIcons :icon="['fas', 'cart-plus']" /> Quick Add
                          </button>
                          <button
                            class="btn btn-primary float-end"
                            type="button"
                            data-bs-toggle="modal"
                            v-if="n.modifiers_ids.length > 0"
                            :data-bs-target="'#showMenuItem' + n.item_rand_id"
                          >
                            <faIcons :icon="['fas', 'cart-plus']" /> Adds On
                          </button>
                          <EachItemModifications
                            :itemData="n.item_rand_id"
                            :restId="restId"
                          />
                          <div class="clearfix"></div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <br />
            </div>
            <div class="clearfix"></div>
          </div>
        </div>
      </div>
      <div v-else class="m-3">
        <!-- Show error if no restaurant matches this Criteria -->
        <div
          class="alert alert-danger"
          role="alert"
          v-if="!this.isRestExists && !this.isNotAcceptingOnlineOrders"
        >
          This is not a real page
        </div>
        <div
          class="alert alert-warning"
          role="alert"
          v-if="this.isNotAcceptingOnlineOrders"
        >
          <!-- This restaurant is not accepting online orders right now. -->
          <h2>Website Under Maintenance</h2>
          <p>
            Temporarily down for maintenance. We'll be back soon. Please call us
            to place your order.
          </p>
          <a
            class="btn btn-success"
            href="https://hooksfishnchips.com/index.php?menu"
            >View Hooks Menu</a
          >
        </div>
        <router-link tag="li" :to="{ name: 'ViewAllRestaurants' }">
          <a class="btn btn-success">Go back to my restaurant(s)</a>
        </router-link>
      </div>
    </div>
    <div id="processing" class="text-center d-none">
      <img
        src="https://order.hooksfishnchips.com/processing.gif"
        class="img-responsive"
      />
    </div>
  </div>
</template>

<script>
import axios from "axios";
import { mapState, mapGetters } from "vuex";
import EachItemModifications from "@/components/OnlineOrders/EachItemModifications.vue";
import CustomerOrderModal from "@/components/OnlineOrders/CustomerOrderModal.vue";
export default {
  name: "EachRestaurantMenu",
  components: {
    EachItemModifications,
    CustomerOrderModal,
  },
  data() {
    return {
      isNotAcceptingOnlineOrders: false,
      customDatePicker: new Date(
        new Date().getTime() - new Date().getTimezoneOffset() * 60000
      )
        .toISOString()
        .split("T")[0],
      customTimePicker: "",
      isOrderPlaced: this.$store.state.__IsOrderPlaced,
      addedMeal: this.$store.state.__AddedMealName,
      cartTotal: 0,
      showPickDateAndTime: true,
      showMenu: false,
      isCustom: "",
      showMenuBtn: true,
      //current Menu Categroies
      menuCategories: [],
      menuModifiers: [],
      currentPaymentStripe: [],
      //Restaurant Info from URL
      restId: this.$route.params.restId,
      //address
      userAddress: "",
      userAddressErr: false,
      userAddressMsg: "",
      isuserAddressValidated: false,
      //each modifier Items
      eachModifierContent: [],
      //phone
      userPhone: "",
      userPhoneErr: false,
      userPhoneMsg: "",
      isuserPhoneValidated: false,
      //name
      userName: "",
      userNameErr: false,
      userNameMsg: "",
      isuserNameValidated: false,

      resultSuccess: false,
      resultSuccessMsg: "",
      resultErr: false,
      resultErrMsg: "",
      image: "",
      is_DB_Connected: "",
      db_connection_msg: "",
      // apiMainUrl: "http://localhost/php/login-system-php-vue-2021/myData.php",
      apiMainUrl: this.$store.getters.MainUrl, //call global variable
      signUpBtn: true,
      isRestExists: true,
      currentMenuModifiersItems: "",
      currentOrder: [],
      minDate: new Date(
        new Date().getTime() - new Date().getTimezoneOffset() * 60000
      )
        .toISOString()
        .split("T")[0],
      minTime: "",
      maxTime: "",
      datePickerError: false,
      datePickerErrorMsg: "",
      canCustomerOrderNow: true,
      orderStartingTime: "",
      orderEndingTime: "",
      checkIfCustomerCanOrder: null,
      // preventNav: true,
      // isEditing: true,
    };
  },
  mounted() {
    this.viewAllRestaurants();
    // localStorage.setItem(
    //   "current_customer_order",
    //   []
    // );
    let currentRestaurants = localStorage.getItem(
      "current_restaurants_data_public"
    );
    let currentMenuCategories = localStorage.getItem(
      "current_user_menu_categories_data_public"
    );
    let currentMenuModifiers = localStorage.getItem(
      "current_user_menu_modifiers_data_public"
    );
    let restSettings = localStorage.getItem(
      "current_restaurants_settings_public"
    );
    let restPaymentSettings = localStorage.getItem(
      "current_restaurants_payment_data"
    );
    let currentRestPaymentSettings = JSON.parse(restPaymentSettings).filter(
      (restInfo) => restInfo.rest_id == this.restId
    );
    this.currentPaymentStripe = currentRestPaymentSettings;
    //only get current restaurant that accepts online order
    let currentRestaurantSettings = JSON.parse(restSettings).filter(
      (restInfo) =>
        restInfo.rest_id == this.restId && restInfo.accept_online_orders == 1
    );
    let currentRestaurant = JSON.parse(currentRestaurants).filter(
      (restInfo) => restInfo.rest_id == this.restId
    );
    this.menuCategories = JSON.parse(currentMenuCategories).filter(
      (restInfo) => restInfo.rest_id == this.restId
    );
    this.menuModifiers = JSON.parse(currentMenuModifiers).filter(
      (restInfo) => restInfo.rest_id == this.restId
    );
    this.currentMenuModifiersItems = JSON.parse(
      localStorage.getItem("current_user_menu_modifiers_items_data_public")
    );

    // currentMenuCategoriesList = this.menuCategories;
    if (currentRestaurants != null) {
      //set rest info
      if (currentRestaurant.length > 0) {
        console.table(currentRestaurant);
        console.table(this.menuCategories);
        console.table(this.menuModifiers);
        this.isRestExists = true;
        this.userPhone = currentRestaurant[0].rest_phone;
        this.userName = this.capitalizeFisrtLetterOfEachWord(
          currentRestaurant[0].rest_name
        );
        this.userAddress = this.capitalizeFisrtLetterOfEachWord(
          currentRestaurant[0].rest_address
        );
        let taxes = "";
        if (currentRestaurantSettings.length > 0) {
          taxes = currentRestaurantSettings[0].taxes;
          this.isNotAcceptingOnlineOrders = false;
          let custOrder = {
            restId: this.restId,
            order: [],
            cust_name: "",
            cust_phone: "",
            cust_email: "",
            receipt_url: "",
            cust_notes: "",
            custom_order_date_or_now: "",
            custom_date: "",
            custom_time: "",
            tips_added: 0,
            order_type: "pickup",
            total: parseFloat(0),
            taxes_percentage: parseFloat(taxes),
            taxes: parseFloat(0),
            sub_total: parseFloat(0),
          };
          localStorage.setItem("current_customer_order", [
            JSON.stringify(custOrder),
          ]);

          this.canCustomerMakeAnOnlineOrder();
        } else {
          //disallow orders
          this.isRestExists = false;
          this.isNotAcceptingOnlineOrders = true;
        }
      } else {
        this.isRestExists = false;
      }
    }
  },
  computed: {
    ...mapState(["__OnlineUrl"]),
    ...mapGetters(["OnlineUrl", "CartTotal"]),
  },
  beforeUnmount() {
    clearInterval(this.checkIfCustomerCanOrder);
  },
  created() {
    this.canCustomerOrderOnlineAutoRun();
  },
  //run auto function every 10 seconds
  // https://renatello.com/vue-js-polling-using-setinterval/
  methods: {
    formatPhoneNumber(number) {
      //remove . + -  from any number
      let cleaned = ("" + number).replace(/\D/g, "");
      let match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
      if (match) {
        return "(" + match[1] + ") " + match[2] + "-" + match[3];
      }
      return null;
    },
    timeFormat12Hours(time) {
      // Check correct time format and split into components
      time = time
        .toString()
        .match(/^([01]\d|2[0-3])(:)([0-5]\d)(:[0-5]\d)?$/) || [time];

      if (time.length > 1) {
        // If time format correct
        time = time.slice(1); // Remove full string match value
        time[5] = +time[0] < 12 ? " AM" : " PM"; // Set AM/PM
        time[0] = +time[0] % 12 || 12; // Adjust hours
      }
      return time.join(""); // return adjusted time or original string
    },
    canCustomerOrderOnlineAutoRun() {
      this.checkIfCustomerCanOrder = setInterval(() => {
        this.canCustomerMakeAnOnlineOrder();
      }, 100000); //run this every 2 minutes
    },
    canCustomerMakeAnOnlineOrder() {
      this.viewAllRestaurants(); //this is good to get all new menu updates every 2 minutes
      // const weekday = [
      //     "Sunday",
      //     "Monday",
      //     "Tuesday",
      //     "Wednesday",
      //     "Thursday",
      //     "Friday",
      //     "Saturday",
      //   ];
      const getDayFromArr = [
        "is_sun",
        "is_mon",
        "is_tue",
        "is_wed",
        "is_thu",
        "is_fri",
        "is_sat",
      ];
      const startingTimeArr = [
        "sun_start",
        "mon_start",
        "tue_start",
        "wed_start",
        "thu_start",
        "fri_start",
        "sat_start",
      ];
      const endingTimeArr = [
        "sun_end",
        "mon_end",
        "tue_end",
        "wed_end",
        "thu_end",
        "fri_end",
        "sat_end",
      ];
      //Extract day from the selected date, then check min/max order time
      const currentSelectedDate = new Date();
      // let currentSelectedDate = new Date(
      //     new Date().getTime() - new Date().getTimezoneOffset() * 60000
      //   )
      //     .toISOString()
      //     .split("T")[0];
      console.log("today's date is " + currentSelectedDate);
      var time =
        (currentSelectedDate.getHours() < 10 ? "0" : "") +
        currentSelectedDate.getHours() +
        ":" +
        (currentSelectedDate.getMinutes() < 10 ? "0" : "") +
        currentSelectedDate.getMinutes() +
        ":" +
        (currentSelectedDate.getSeconds() < 10 ? "0" : "") +
        currentSelectedDate.getSeconds();
      let dayToSearchFor = getDayFromArr[currentSelectedDate.getDay()];
      let startingTimeIs = startingTimeArr[currentSelectedDate.getDay()];
      let endingTimeIs = endingTimeArr[currentSelectedDate.getDay()];
      console.log("Today's date number is : " + currentSelectedDate.getDay());
      console.log("Today's date is : " + dayToSearchFor);
      console.log("Today's date starting at : " + startingTimeIs);
      console.log("Current time is : " + time);
      console.log("Today's date ending at : " + endingTimeIs);
      let currentRestHours = localStorage.getItem(
        "current_restaurants_online_hours_public"
      );
      let currentRestHoursData = JSON.parse(currentRestHours).filter(
        (restInfo) => restInfo.rest_id == this.restId
      );
      let currentRestPayment = localStorage.getItem(
        "current_restaurants_payment_data"
      );
      let currentRestPaymentData = JSON.parse(currentRestPayment).filter(
        (restInfo) => restInfo.rest_id == this.restId
      );
      console.table(currentRestHoursData);
      console.table(currentRestPaymentData);
      console.log("length of my payment data " + currentRestPaymentData.length);
      let isRestOpenOnSelectedDay = currentRestHoursData[0][dayToSearchFor];
      let startingTime = currentRestHoursData[0][startingTimeIs];
      let endingTime = currentRestHoursData[0][endingTimeIs];
      console.log("Restaurant Starting Time is " + startingTime);
      console.log("Restaurant Ending Time is " + endingTime);
      if (isRestOpenOnSelectedDay == 1) {
        if (time >= startingTime && time <= endingTime) {
          console.log("customer can order now");
          if (currentRestPaymentData.length > 0) {
            if (currentRestPaymentData[0].publish_key != "") {
              console.log(
                "secret key is " + currentRestPaymentData[0].stripe_secret_key
              );
              console.log(
                "business is open today, and you can start order now."
              );
              // const todayDay = new Date();
              let todayDay = new Date(
                new Date().getTime() - new Date().getTimezoneOffset() * 60000
              )
                .toISOString()
                .split("T")[0];
              // let pickedDate = new Date(this.customDatePicker);
              if (todayDay == this.customDatePicker) {
                console.log("Customer like to order a late order for today 1");
                console.log("Customer like to order a late order for today 2");
              } else {
                console.log("Customer like to have a future order 1");
                console.log("Customer like to have a future order 2");
              }
              console.log("current date is " + todayDay);
              this.canCustomerOrderNow = true;
              this.orderStartingTime = startingTime;
              this.orderEndingTime = endingTime;
            } else {
              console.log(
                "business is open today, but no publish key is setup yet."
              );
              this.canCustomerOrderNow = false;
              this.orderStartingTime = startingTime;
              this.orderEndingTime = endingTime;
            }
          } else {
            console.log(
              "business is open today, but payment is not setup yet."
            );
            this.canCustomerOrderNow = false;
            this.orderStartingTime = startingTime;
            this.orderEndingTime = endingTime;
          }
        } else {
          console.log("business is open today, but not at this current time");
          this.canCustomerOrderNow = false;
          this.orderStartingTime = startingTime;
          this.orderEndingTime = endingTime;
        }
      } else {
        console.log("business is not open today");
        this.canCustomerOrderNow = false;
        this.orderStartingTime = startingTime;
        this.orderEndingTime = endingTime;
      }
    },
    selectDate() {
      this.showMenuBtn = true;
    },
    showMenuBtnIfOrderNow() {
      this.showMenuBtn = false;
    },
    set15MinutesOrder() {
      var currentOrderArr = JSON.parse(
        localStorage.getItem("current_customer_order")
      );
      let currentDateIs = new Date(
        new Date().getTime() - new Date().getTimezoneOffset() * 60000
      )
        .toISOString()
        .split("T")[0];
      // const [year, month, day] = currentDateIs.split("-");
      // const result = [month, day, year].join("/");
      currentOrderArr["custom_order_date_or_now"] = "now";
      currentOrderArr["custom_date"] = currentDateIs; //set custom_date to this date
      // const currentSelectedDate = new Date();
      // currentSelectedDate.setMinutes(currentSelectedDate.getMinutes() + 15);
      //add 15 minutes and only get time like hh:mm not hh:mm:ss substring(0,5)
      // currentOrderArr["custom_time"] = this.addHoursToDateAndReturnTimeOnly(new Date(), 0.25).substring(0,5);
      currentOrderArr["custom_time"] = this.addMinutes(15).substring(0, 5);
      localStorage.setItem("current_customer_order", [
        JSON.stringify(currentOrderArr),
      ]);
    },
    // addMinutes(minutes) {
    //   const date = new Date();
    //   date.setMinutes(date.getMinutes() + minutes);
    //   return new Date(date).toLocaleTimeString("en-US", {
    //     hour12: true
    //   });
    // },
    addMinutes(minutes) {
      var d = new Date();
      d.setHours(d.getHours());
      d.setMinutes(d.getMinutes() + minutes);
      return d.toTimeString();
    },
    addHoursToDateAndReturnTimeOnly(objDate, intHours) {
      var numberOfMlSeconds = objDate.getTime();
      var addMlSeconds = intHours * 60 * 60 * 1000;
      var newDateObj = new Date(numberOfMlSeconds + addMlSeconds); //here it return full date with time added
      return newDateObj.toLocaleTimeString("en-US", {
        hour12: false,
      }); //here only returns time added to current time
    },
    pickingCustomDate() {
      var currentOrderArr = JSON.parse(
        localStorage.getItem("current_customer_order")
      );
      currentOrderArr["custom_date"] = this.customDatePicker;
      currentOrderArr["custom_time"] = this.customTimePicker;
      if (this.customDatePicker == "" || this.customTimePicker == "") {
        // if any is empty
        this.datePickerError = true;
        this.datePickerErrorMsg = "Must pick both date & time";
        this.showMenuBtn = true;
      } else if (this.customDatePicker != "" && this.customTimePicker != "") {
        //if both not empty
        const weekday = [
          "Monday",
          "Tuesday",
          "Wednesday",
          "Thursday",
          "Friday",
          "Saturday",
          "Sunday",
        ];
        const getDayFromArr = [
          "is_mon",
          "is_tue",
          "is_wed",
          "is_thu",
          "is_fri",
          "is_sat",
          "is_sun",
        ];
        const startingTimeArr = [
          "mon_start",
          "tue_start",
          "wed_start",
          "thu_start",
          "fri_start",
          "sat_start",
          "sun_start",
        ];
        const endingTimeArr = [
          "mon_end",
          "tue_end",
          "wed_end",
          "thu_end",
          "fri_end",
          "sat_end",
          "sun_end",
        ];
        //Extract day from the selected date, then check min/max order time
        let currentSelectedDate = new Date(this.customDatePicker);
        console.log("current selected date is " + currentSelectedDate);
        let dayToSearchFor = getDayFromArr[currentSelectedDate.getDay()];
        console.log(
          "current dayToSearchFor date is " + currentSelectedDate.getDay()
        );
        let startingTimeIs = startingTimeArr[currentSelectedDate.getDay()];
        let endingTimeIs = endingTimeArr[currentSelectedDate.getDay()];
        console.log(
          "picked up date is : " + weekday[currentSelectedDate.getDay()]
        );
        console.log(
          "Look for current opening and closing time in this day : " +
            dayToSearchFor
        );
        let currentRestHours = localStorage.getItem(
          "current_restaurants_online_hours_public"
        );
        let currentRestHoursData = JSON.parse(currentRestHours).filter(
          (restInfo) => restInfo.rest_id == this.restId
        );
        console.table(currentRestHoursData);
        let isRestOpenOnSelectedDay = currentRestHoursData[0][dayToSearchFor];
        let startingTime = currentRestHoursData[0][startingTimeIs];
        let endingTime = currentRestHoursData[0][endingTimeIs];
        this.minTime = startingTime;
        this.maxTime = endingTime;
        let currentDateIs = new Date(
          new Date().getTime() - new Date().getTimezoneOffset() * 60000
        )
          .toISOString()
          .split("T")[0];
        if (this.customDatePicker < currentDateIs) {
          this.datePickerError = true;
          this.datePickerErrorMsg = "You can't select past dates.";
          this.showMenuBtn = true;
        } else {
          if (isRestOpenOnSelectedDay == 0) {
            //business is closed on this day
            //show error and tell customer to select other day
            this.datePickerError = true;
            this.datePickerErrorMsg =
              "Restaurant doesn't accept order on this day";
            this.showMenuBtn = true;
          } else if (isRestOpenOnSelectedDay == 1) {
            //business is open on this day
            //now check if time selected is in between open hours
            //but first, check if customer is ordering today and time is greater than current time
            //which means customer doesn't order something for early today and current time is more selected time
            if (
              this.customTimePicker <= endingTime &&
              this.customTimePicker >= startingTime
            ) {
              //Ex: customer picked today's date and selected a time that is less than current time
              // const todayDay = new Date();
              // const pickedDate = new Date(this.customDatePicker);
              if (currentDateIs == this.customDatePicker) {
                console.log("Customer like to order a late order for today");
                //here make sure customer order time is greater than current time
                const currentSelectedDate = new Date();
                currentSelectedDate.setMinutes(
                  currentSelectedDate.getMinutes() + 30
                );
                //at least add 15 minutes for custom time if customer just in case if customer
                // likes to have his order as custom and selected any time from current time -> 15 minutes from now
                //so he has to order after that
                //ex: customer select custom time, and time now is 5:00 pm, customer at least select time > 5:16 pm
                var time =
                  (currentSelectedDate.getHours() < 10 ? "0" : "") +
                  currentSelectedDate.getHours() +
                  ":" +
                  (currentSelectedDate.getMinutes() < 10 ? "0" : "") +
                  currentSelectedDate.getMinutes() +
                  ":" +
                  (currentSelectedDate.getSeconds() < 10 ? "0" : "") +
                  currentSelectedDate.getSeconds();
                if (this.customTimePicker > time) {
                  console.log(
                    "current time picked is " + this.customTimePicker
                  );
                  console.log("current local time is " + time);
                  console.log("current date is " + currentDateIs);
                  console.log(
                    "Your are picking an online order for later today"
                  );
                  this.datePickerError = false;
                  this.datePickerErrorMsg = "";
                  this.showMenuBtn = false;
                  currentOrderArr["custom_date"] = this.customDatePicker;
                  currentOrderArr["custom_time"] = this.customTimePicker;
                } else {
                  //also check if custom time is less than ending time for order: like last time to pick up
                  this.datePickerError = true;
                  this.datePickerErrorMsg =
                    "Scheduled order has to be at least 30 minutes from now";
                  this.showMenuBtn = true;
                }
              } else {
                console.log("Customer like to have a future order");
                console.log(`You can start picking your order now`);
                this.datePickerError = false;
                this.datePickerErrorMsg = "";
                this.showMenuBtn = false;
                currentOrderArr["custom_date"] = this.customDatePicker;
                currentOrderArr["custom_time"] = this.customTimePicker;
              }
              // console.log("current date is " + currentDateIs);
              // console.log(`Your order is good to be placed`);
              // this.datePickerError = false;
              // this.datePickerErrorMsg = "";
              // this.showMenuBtn = false;
              // currentOrderArr["custom_date"] = this.customDatePicker;
              // currentOrderArr["custom_time"] = this.customTimePicker;
            } else {
              console.log(
                `Restaurant is open on this day from ${startingTime} to ${endingTime}`
              );
              this.datePickerError = true;
              this.datePickerErrorMsg = `Restaurant is open on this day from ${this.timeFormat12Hours(
                startingTime
              )} to ${this.timeFormat12Hours(endingTime)}`;
              this.showMenuBtn = true;
            }
          }
        }
      } else {
        //
        this.showMenuBtn = false;
        this.datePickerError = false;
        this.datePickerErrorMsg = "";
      }
      currentOrderArr["custom_order_date_or_now"] = "custom";
      localStorage.setItem("current_customer_order", [
        JSON.stringify(currentOrderArr),
      ]);
    },
    addToMyCart(name, price) {
      let cartTotal = parseFloat(
        Number(this.$store.state.__CartTotal) + Number(price)
      ).toFixed(2);
      var currentOrderArr = JSON.parse(
        localStorage.getItem("current_customer_order")
      );
      // localStorage.setItem(
      //   "current_customer_order",
      //   JSON.stringify(currentOrderArr)
      // );
      this.addedMeal = name;
      this.$store.state.__AddedMealName = name;
      this.$store.state.__IsOrderPlaced = true;
      setTimeout(() => {
        this.$store.state.__IsOrderPlaced = false;
        if (currentOrderArr["restId"] == this.restId) {
          console.log("hi");
          currentOrderArr["total"] = parseFloat(cartTotal).toFixed(2);
          currentOrderArr["taxes"] = parseFloat(
            (parseFloat(currentOrderArr["total"]) *
              parseFloat(currentOrderArr["taxes_percentage"])) /
              100
          ).toFixed(2);
          currentOrderArr["sub_total"] = parseFloat(
            parseFloat(currentOrderArr["total"]) +
              parseFloat(currentOrderArr["taxes"])
          ).toFixed(2);
          let k = {
            name: name,
            price: parseFloat(price),
            isCustom: false,
            options: [],
            custom_price: 0,
            total_item_cost: parseFloat(price),
          };
          currentOrderArr["order"].push(k);
          console.log(currentOrderArr["order"]);
          // currentOrderArr['order'].push(k);
          // currentOrderArr['total'] = parseFloat(currentOrderArr['total']);
          // currentOrderArr['taxes'] = parseFloat(currentOrderArr['taxes']);
          // currentOrderArr['sub_total'] = parseFloat(currentOrderArr['sub_total']);
          localStorage.setItem(
            "current_customer_order",
            JSON.stringify(currentOrderArr)
          );
        }
      }, 200); //hold for half a second
      console.log(`type of price is ${typeof Number(price)}`);
      console.log(
        `You are adding this item to your menu ${name} at cost $${price}`
      );
      //NOW SET THE TOTAL HERE
      this.$store.state.__CartTotal = cartTotal;
    },
    async viewAllRestaurants() {
      let res = await axios.post(this.OnlineUrl + `?action=getAllRestaurants`);
      const resultData = res.data;
      console.log(resultData);
      localStorage.setItem(
        "current_restaurants_data_public",
        JSON.stringify(resultData.current_restaurants_data_public)
      );
      localStorage.setItem(
        "current_restaurants_settings_public",
        JSON.stringify(resultData.current_restaurants_settings_public)
      );
      //show all current user categories
      localStorage.setItem(
        "current_user_menu_categories_data_public",
        JSON.stringify(resultData.current_user_menu_categories_data_public)
      );
      //show all current user menu modifiers
      localStorage.setItem(
        "current_user_menu_modifiers_data_public",
        JSON.stringify(resultData.current_user_menu_modifiers_data_public)
      );
      //show all current user menu modifiers Items
      localStorage.setItem(
        "current_user_menu_modifiers_items_data_public",
        JSON.stringify(resultData.current_user_menu_modifiers_items_data_public)
      );
      localStorage.setItem(
        "current_user_menu_items_data_public",
        JSON.stringify(resultData.current_user_menu_items_data_public)
      );
      localStorage.setItem(
        "current_restaurants_online_hours_public",
        JSON.stringify(resultData.current_restaurants_online_hours_public)
      );
      let currentRestPaymentSettings = localStorage.setItem(
        "current_restaurants_payment_data",
        JSON.stringify(resultData.current_restaurants_payment_data)
      );
      //set current_order_details to empty
      localStorage.setItem("current_order_details", JSON.stringify([]));
      this.currentPaymentStripe = currentRestPaymentSettings;
      let currentRestaurants = localStorage.getItem(
        "current_restaurants_data_public"
      );
      this.currentRestaurantsArr = JSON.parse(currentRestaurants);
    },
    capitalizeFisrtLetterOfEachWord(text) {
      return text
        .toLowerCase()
        .split(" ")
        .map((s) => s.charAt(0).toUpperCase() + s.substring(1))
        .join(" ");
    },
    number_format(amount) {
      const price = amount;
      // Format the price above to USD, INR, EUR using their locales.
      let dollarUS = Intl.NumberFormat("en-US", {
        style: "currency",
        currency: "USD",
      });
      return dollarUS.format(price);
    },
    getImageUrl(imgName) {
      let img = require.context("@/../restaurants/");
      console.log(img);
      return img("./" + imgName);
    },
    currentModifierItem(modifierIdIs) {
      let currentModifiersItemsData = JSON.parse(
        localStorage.getItem("current_user_menu_modifiers_items_data_public")
      );
      let newData = currentModifiersItemsData.filter(
        (restInfo) => restInfo.group_rand_id == modifierIdIs
      );
      return newData;
    },
    currentMenuItem(catIdIs) {
      let currentMenuItemsData = JSON.parse(
        localStorage.getItem("current_user_menu_items_data_public")
      );
      let newData = currentMenuItemsData.filter(
        (restInfo) => restInfo.cat_id == catIdIs
      );
      return newData;
    },
  },
};
</script>
<style lang="scss" scoped>
$navColor: #3f51b6;

nav a {
  text-decoration: none;
  color: $navColor;
  font-weight: bold;
}

// h3 {
//   border-bottom: 3px solid #000;
//   line-height: 0em;
//   margin: 10px 0 20px;
// }

// h3 span {
//   background: #fff;
//   padding: 0 10px;
// }
.p3 {
  padding: 5px;
}

.fs90 {
  font-size: 0.85em;
}

.c-brown {
  color: #bf301a;
  padding: 5px;
  background-color: #ffffff9c;
  font-weight: bold;
}

.textBetweenTwoLines {
  font-family: sans-serif;
  color: #228b22;
  text-align: center;
  font-size: 30px;
  max-width: 600px;
  position: relative;
}

div.scrollmenu {
  margin-top: 3px;
  background-color: #333;
  overflow: auto;
  white-space: nowrap;
  scroll-behavior: smooth;
}

div.scrollmenu a {
  display: inline-block;
  color: white;
  text-align: center;
  padding: 4px 4px 4px 2px;
  text-decoration: none;
}

div.scrollmenu a:hover {
  background-color: #777;
}

div.scrollmenu a:after {
  content: " |";
}

div.scrollmenu a:not(:last-child) {
  content: "";
}

.textBetweenTwoLines:before {
  content: "";
  display: block;
  width: 130px;
  height: 5px;
  background: #191970;
  left: 0;
  top: 50%;
  position: absolute;
}

.whiteColor {
  color: #fff;
}

.textBetweenTwoLines:after {
  content: "";
  display: block;
  width: 130px;
  height: 5px;
  background: #191970;
  right: 0;
  top: 50%;
  position: absolute;
}

.c-darkGreen {
  color: #77a41b;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

.each-category-list {
  position: relative;
  padding: 0;
  border-radius: 0;
  box-shadow: 2px 2px 4px 0px #525a5a;
  margin: 8px;
  border-radius: 5px;
  margin-bottom: 20px;
}

.catName {
  color: #ad0505;
  // background-color: #03a56f12;
  // padding: 10px;
  // width: 100%;
  // margin: 0 5%;
  // cursor: pointer;
}

.titleBrown {
  color: #ad0505;
}

.main-order-select-date-custom {
  padding: 12px;
  border-radius: 0;
  box-shadow: 2px 2px 4px 0px #cccccc;
  margin-top: 8px;
  border-radius: 5px;
  margin-bottom: 20px;
}

.biggerCheck {
  font-size: 3em;
  color: green;
}

.h300 {
  height: 300px;
}

.each-category-list img {
  border-radius: 0.25rem 0.25rem 0 0 !important;
}

.price-tag {
  text-align: center;
  padding: 10px;
  font-weight: bold;
}

.title-tag {
  text-align: left;
  padding: 10px;
  font-weight: bold;
}

.addressDisplay {
  white-space: pre-wrap;
}

.each-menu-title-top {
  position: absolute;
  top: 8px;
  // background-color: #ffffffbd;
  display: block;
  width: 100%;
}

.each-menu-buttons-bottom {
  position: absolute;
  bottom: 1px;
  background-color: #ffffff5e;
  display: block;
  width: 96%;
}

.menu-category-title {
  background-color: #ffffff;
  padding: 10px;
  color: black;
  font-weight: bold;
  box-shadow: 2px 2px 7px #4c95af;
  margin-bottom: 9px;
}

.menu-category-title h4 {
  color: darkblue;
  font-weight: bold;
}

.mb-10 {
  margin-bottom: 10px;
}

.w200 {
  height: 100%;
  width: 100%;
  object-fit: cover;
}

.f-bold {
  font-weight: bold;
}

.telephone {
  text-decoration: none;
  color: #3f51b6;
  font-weight: bold;
}

#accordion {
  position: relative;
}

.customer-added-order {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background: white;
  padding: 0;
  margin: 0;
  font-weight: 700;
  font-size: 1.2em;
}

.error-data {
  border-color: red;
  color: red;
}

.stickyPosition {
  position: sticky;
  z-index: 1000;
  top: 0;
  background-color: white;
  padding: 5px;
}

@media screen and (max-width: 600px) {
  .main-order-select-date-custom {
    padding: 2px;
    border-radius: 0;
    box-shadow: none;
    margin-top: 8px;
    border-radius: 5px;
    margin-bottom: 20px;
  }
}
</style>
