<template>
  <div id="showCustomerNameAndInstructions">
    <div id="showCurrentNameAndNumber">
      <div class="row g-3 align-items-center mb-3">
        <div class="col-auto d-block mx-auto">
          <!-- <p class="text-center titleBrown"></p> -->
          <div
            class="form-floating"
            :class="{ 'form-data-error': userNameErr }"
          >
            <input
              type="text"
              id="userNameInput"
              placeholder="Your Name"
              class="form-control w300"
              @keyup="validateCustomerOrderName($event)"
              @change="validateCustomerOrderName($event)"
              v-model.trim="userName"
            />
            <label for="userNameInput">
              <faIcons :icon="['fas', 'user']" /> Customer Name
            </label>
            <span class="error-feedback" v-if="userNameErr">
              {{ userNameMsg }}
            </span>
          </div>
        </div>
      </div>

      <div class="row g-3 align-items-center mb-3">
        <div class="col-auto d-block mx-auto">
          <div
            class="form-floating"
            :class="{ 'form-data-error': userPhoneErr }"
          >
            <input
              type="number"
              min="0"
              id="userPhoneInput"
              placeholder="Call Back Number"
              @keydown="
                if (
                  $event.key === '.' ||
                  $event.key === '+' ||
                  $event.key === 'e' ||
                  $event.key === '-'
                ) {
                  $event.preventDefault();
                }
              "
              @keyup="validateCustomerOrderPhone($event)"
              @change="validateCustomerOrderPhone($event)"
              class="form-control w300"
              v-model.trim="userPhone"
            />
            <label for="userPhoneInput">
              <faIcons :icon="['fas', 'phone-alt']" /> Call Back Number
            </label>
            <span class="error-feedback" v-if="userPhoneErr">
              {{ userPhoneMsg }}
            </span>
          </div>
        </div>
      </div>

      <!-- <div class="row g-3 align-items-center mb-3">
        <div class="col-auto d-block mx-auto">
          <div
            class="form-floating"
            :class="{ 'form-data-error': userEmailErr }"
          >
            <input
              type="email"
              id="userEmailInput"
              placeholder="Your Email"
              class="form-control w300"
              @keyup="validateCustomerOrderEmail($event)"
              @change="validateCustomerOrderEmail($event)"
              v-model.trim="userEmail"
            />
            <label for="userEmailInput">
              <faIcons :icon="['fas', 'envelope']" /> Customer Email
            </label>
            <p class="error-feedback" v-if="userEmailErr">
              {{ userEmailMsg }}
            </p>
          </div>
        </div>
      </div>

      <div class="row g-3 align-items-center mb-3">
        <div class="col-auto d-block mx-auto">
          <div
            class="form-floating"
            :class="{ 'form-data-error': userCardNumberErr }"
          >
            <input
              type="number"
              min="0"
              id="userCardNumberInput"
              placeholder="Card Number"
              @keydown="
                if (
                  $event.key === '.' ||
                  $event.key === '+' ||
                  $event.key === 'e' ||
                  $event.key === '-'
                ) {
                  $event.preventDefault();
                }
              "
              @keyup="validateCustomerOrderCardNumber($event)"
              class="form-control w300"
              v-model.trim="userCardNumber"
            />
            <label for="userCardNumberInput">
              <faIcons :icon="['fas', 'credit-card']" /> Card Number
            </label>
            <span class="error-feedback" v-if="userCardNumberErr">
              {{ userCardNumberMsg }}
            </span>
          </div>
        </div>
      </div> -->
      <!-- <p class="text-center">Expiry Date & Security Code</p> -->
      <!-- <div class="row g-3 align-items-center mb-3">
        <div class="col-auto d-block mx-auto">
          <div
            class="form-floating"
            :class="{ 'form-data-error': userExpirationDateErr }"
          >
            <input
              type="month"
              :min="currentDateIs"
              id="userExpirationDateInput"
              placeholder="Expiration Month & Year"
              @change="validateUserExpirationDate($event)"
              @input="validateUserExpirationDate($event)"
              class="form-control w300"
              v-model.trim="userExpirationDate"
            />
            <label for="userExpirationDateInput">
              <faIcons :icon="['fas', 'credit-card']" /> Expiration Month & Year
            </label>
            <span class="error-feedback" v-if="userExpirationDateErr">
              {{ userExpirationDateMsg }}
            </span>
          </div>
        </div>
      </div> -->
      <!-- <div class="row g-3 align-items-center mb-3">
        <div
          class="col-auto d-block mx-auto"
          :class="{
            'form-data-error': userExpMonthErr,
            'form-data-error': userExpYearErr,
          }"
        >
          <div class="form-floating wAuto">
            <select
              id="userMonthCardInput"
              placeholder="Expiry Month"
              class="form-select wAuto"
              @change="validateUserMonthCard($event)"
              v-model.trim="userExpMonth"
              aria-label="userMonthCardInput"
            >
              <option value="" disabled>Select</option>
              <option value="1">01 Jan.</option>
              <option value="2">02 Feb.</option>
              <option value="3">03 Mar.</option>
              <option value="4">04 Apr.</option>
              <option value="5">05 May.</option>
              <option value="6">06 Jun.</option>
              <option value="7">07 Jul.</option>
              <option value="8">08 Aug.</option>
              <option value="9">09 Sep.</option>
              <option value="10">10 Oct.</option>
              <option value="11">11 Nov.</option>
              <option value="12">12 Dec.</option>
            </select>
            <label for="userMonthCardInput">
              <faIcons :icon="['fas', 'address-book']" /> Expiry Month
            </label>
          </div>
          <div
            class="form-floating wAuto"
            :class="{ 'form-data-error': userExpYearErr }"
          >
            <select
              id="userYearCardInput"
              placeholder="Expiry Year"
              class="form-select wAuto"
              @change="validateUserYearCard($event)"
              v-model.trim="userExpYear"
              aria-label="userYearCardInput"
            >
              <option value="" disabled>select</option>
              <option
                v-for="i in 20"
                :key="currentYear + i"
                :value="currentYear + i"
              >
                {{ currentYear + i }}
              </option>
            </select>
            <label for="userYearCardInput">
              <faIcons :icon="['fas', 'calendar']" /> Expiry Year
            </label>
          </div>

          <p
            class="error-feedback"
            v-if="userExpMonthErr"
            :class="{ 'form-data-error': userExpMonthErr }"
          >
            {{ userExpMonthMsg }}
          </p>
          <p
            class="error-feedback"
            v-if="userExpYearErr"
            :class="{ 'form-data-error': userExpYearErr }"
          >
            {{ userExpYearMsg }}
          </p>
        </div>
      </div> -->
      <!-- <div class="row g-3 align-items-center mb-3">
        <div class="col-auto d-block mx-auto">
          <div
            class="form-floating w300"
            :class="{ 'form-data-error': userCVVErr }"
          >
            <input
              type="number"
              min="0"
              id="userCVVCardInput"
              placeholder="CVV"
              @keydown="
                if (
                  $event.key === '.' ||
                  $event.key === '+' ||
                  $event.key === 'e' ||
                  $event.key === '-'
                ) {
                  $event.preventDefault();
                }
              "
              @keyup="validateCVV($event)"
              @change="validateCVV($event)"
              class="form-control"
              v-model.trim="userCVV"
            />
            <label for="userCVVCardInput">
              <faIcons :icon="['fas', 'lock']" /> Security Code (CVV)
            </label>
            <span class="error-feedback" v-if="userCVVErr">
              {{ userCVVMsg }}
            </span>
          </div>
        </div>
      </div> -->
      <!-- <div class="row g-3 align-items-center mb-3">
      <div class="col-auto d-block mx-auto">
        <div
          class="form-floating"
          :class="{ 'form-data-error': userAddressErr }"
        >
          <textarea
            id="userAddressInput"
            placeholder="Order Instructions (optional)"
            @input="validateCustomerOrderAddress($event)"
            class="form-control w300 h100"
            v-model.trim="userAddress"
          />
          <label for="userAddressInput">
            <faIcons :icon="['fas', 'address-book']" /> Order Instructions
            (optional)
          </label>
          <span class="error-feedback" v-if="userAddressErr">
            {{ userAddressMsg }}
          </span>
        </div>
      </div>
    </div> -->

      <div class="d-grid" v-if="userName.length > 0">
        <div class="col-auto d-block mx-auto">
          <button
            class="btn btn-success btn-block w300"
            id="payOnlineOrderBtn"
            v-if="
              // isuserAddressValidated == true &&
              isuserPhoneValidated == true &&
              // isuserEmailValidated == true &&
              //  isuserCardNumberValidated == true &&
              // isuserExpirationDateValidated == true &&
              // isuserCVVValidated == true &&
              isuserNameValidated == true
            "
            :disabled="!this.$store.state.__CartTotal > 0 || !signUpBtn"
            type="button"
            @click="processPayment()"
          >
            <faIcons :icon="['fas', 'credit-card']" /> Place pickup order now
          </button>
        </div>
        <div class="clearfix"></div>
        <div class="row d-grid gap-2 w300 col-auto d-block mx-auto m-2">
          <div class="alert alert-success" role="alert">
            You can conveniently settle your payment on arrival using various
            methods, including credit cards (Visa, Mastercard, American Express,
            Discover), Phone Tabs, debit cards, and cash. Thank you for your
            understanding and cooperation.
          </div>
          <div class="alert alert-success" role="alert" v-if="resultSuccess">
            {{ resultSuccessMsg }}
          </div>
          <div class="alert alert-danger" role="alert" v-if="resultErr">
            {{ resultErrMsg }}
          </div>
        </div>
        <!-- <div class="d-grid gap-3" id="showOrderPaymentError"></div>
        <div id="token_response"></div> -->
        <div class="clearfix"></div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
// import { mapState, mapMutations, mapGetters } from "vuex";
export default {
  name: "CustomerAndOrderNotesDetails",
  data() {
    return {
      apiMainUrl: this.$store.getters.MainUrl, //call global variable
      signUpBtn: true,
      resultSuccess: false,
      resultSuccessMsg: "",
      resultErr: false,
      resultErrMsg: "",
      // orderProcessing: false,
      currentDateIs: new Date().getFullYear() + "-" + new Date().getMonth(),
      currentYear: new Date().getFullYear() - 1,
      addYearsToCurrentYear: new Date().getFullYear() + 15, //add 15 years to current years
      expiredMonth: "",
      expiredYear: "",
      //address
      userAddress: "",
      userAddressErr: false,
      userAddressMsg: "",
      isuserAddressValidated: true,
      //email
      userEmail: "",
      userEmailErr: false,
      userEmailMsg: "",
      isuserEmailValidated: false, //required
      //card number
      userCardNumber: "",
      userCardNumberErr: false,
      userCardNumberMsg: "",
      isuserCardNumberValidated: false, //required
      //card expiration date
      userExpirationDate: "",
      userExpirationDateErr: false,
      userExpirationDateMsg: "",
      isuserExpirationDateValidated: false, //required
      //exp year
      userExpYear: "",
      userExpYearErr: false,
      userExpYearMsg: "",
      isuserExpYearValidated: false, //required
      //exp month
      userExpMonth: "",
      userExpMonthErr: false,
      userExpMonthMsg: "",
      isuserExpMonthValidated: false, //required
      //CVV
      userCVV: "",
      userCVVErr: false,
      userCVVMsg: "",
      isuserCVVValidated: false, //required
      //phone - optional
      userPhone: "",
      userPhoneErr: false,
      userPhoneMsg: "",
      isuserPhoneValidated: false, //optional
      //name
      userName: "",
      userNameErr: false,
      userNameMsg: "",
      isuserNameValidated: false, //required
      restId: this.$route.params.restId,
    };
  },
  mounted() {
    // show current order details
    let currentRestaurants = localStorage.getItem("current_customer_order");
    this.currentOrder = JSON.parse(currentRestaurants);
    let currentOrderData = localStorage.getItem("current_customer_order");
    let currentRestaurantsData = localStorage.getItem(
      "current_restaurants_data_public"
    );
    let currentOrder = JSON.parse(currentOrderData);
    let currentRestaurant = JSON.parse(currentRestaurantsData).filter(
      (restInfo) => restInfo.rest_id == currentOrder.restId
    );
    console.table(currentRestaurant);
    console.log("hello");
    this.pickUpAddress = currentRestaurant[0].rest_address;
    this.pickUpPhoneNumber = currentRestaurant[0].rest_phone;
    // let script001 = document.createElement('link');
    // script001.setAttribute('rel', 'stylesheet');
    // script001.setAttribute('href', 'https://cdnjs.cloudflare.com/ajax/libs/twitter-bootstrap/4.1.3/css/bootstrap.min.css');
    // document.head.appendChild(script001);
    // let script01 = document.createElement("script");
    // script01.setAttribute("src", "https://code.jquery.com/jquery-3.3.1.min.js");
    // document.head.appendChild(script01);
    // let script0 = document.createElement('script');
    // script0.setAttribute('src', 'https://cdnjs.cloudflare.com/ajax/libs/popper.js/1.14.3/umd/popper.min.js');
    // document.head.appendChild(script0);
    // let script2 = document.createElement('script');
    // script2.setAttribute('src', 'https://stackpath.bootstrapcdn.com/bootstrap/4.1.3/js/bootstrap.min.js');
    // document.head.appendChild(script2);
    // let script1 = document.createElement("script");
    // script1.setAttribute("src", "https://checkout.stripe.com/checkout.js");
    // document.head.appendChild(script1);
    // let payFunction = document.createElement("script");
    // payFunction.setAttribute(
    //   "src",
    //   "https://order.hooksfishnchips.com/checkout.js"
    // );
    // document.head.appendChild(payFunction);
  },
  methods: {
    validateCustomerOrderName(e) {
      let val = e.target.value;
      this.validateCustomerOrderNameInput(val);
    },
    validateCustomerOrderNameInput(val) {
      let currentOrderData = localStorage.getItem("current_customer_order");
      let currentOrder = JSON.parse(currentOrderData);
      if (val == "") {
        currentOrder["cust_name"] = val;
        this.userNameErr = true;
        this.isuserNameValidated = false;
        this.userNameMsg = "Must enter your name ..";
      } else {
        currentOrder["cust_name"] = val;
        this.userNameErr = false;
        this.isuserNameValidated = true;
        this.userNameMsg = "";
      }
      localStorage.setItem(
        "current_customer_order",
        JSON.stringify(currentOrder)
      );
    },
    validateCustomerOrderPhone(e) {
      let val = e.target.value;
      this.validateCustomerOrderPhoneInput(val);
    },
    validateCustomerOrderPhoneInput(val) {
      // let userPhoneRegex = /^\d{10}$/;
      let currentOrderData = localStorage.getItem("current_customer_order");
      let currentOrder = JSON.parse(currentOrderData);
      console.log("Customer Phone is: " + val);
      // if (val.length > 0) {
      //   if (val !== "") {
      //     if (
      //       (val !== "" && val.length < 9) ||
      //       (val !== "" && val.length > 11)
      //     ) {
      //       currentOrder["cust_phone"] = "";
      //       this.userPhoneErr = true;
      //       this.isuserPhoneValidated = false;
      //       this.userPhoneMsg = "Must Only Enter 9-11 Digits ..";
      //     } else {
      //       currentOrder["cust_phone"] = val;
      //       this.userPhoneErr = false;
      //       this.isuserPhoneValidated = true;
      //       this.userPhoneMsg = "";
      //     }
      //   } else {
      //     currentOrder["cust_phone"] = val;
      //     this.userPhoneErr = false;
      //     this.isuserPhoneValidated = true;
      //     this.userPhoneMsg = "";
      //   }
      // } else {
      //   currentOrder["cust_phone"] = "";
      //   this.userPhoneErr = false;
      //   this.isuserPhoneValidated = true;
      //   this.userPhoneMsg = "";
      // }
      if (val == "" || val.length == 0) {
        currentOrder["cust_phone"] = val;
        this.userPhoneErr = true;
        this.isuserPhoneValidated = false;
        this.userPhoneMsg = "Must Enter A Call Back Number.";
        console.log("Is Customer Number Empty: Yes");
      } else if (val !== "" && (val.length > 11 || val.length < 10)) {
        console.log("Customer Number Not Valid 9-11 Digits");
        currentOrder["cust_phone"] = val;
        this.userPhoneErr = true;
        this.isuserPhoneValidated = false;
        this.userPhoneMsg = "Call Back Number is 9-11 Digits.";
      } else {
        console.log("Customer Number is Valid");
        currentOrder["cust_phone"] = val;
        this.userPhoneErr = false;
        this.isuserPhoneValidated = true;
        this.userPhoneMsg = "";
      }
      localStorage.setItem(
        "current_customer_order",
        JSON.stringify(currentOrder)
      );
    },
    validateCustomerOrderAddress(e) {
      let val = e.target.value;
      this.validateCustomerOrderAddressInput(val);
    },
    validateCustomerOrderAddressInput(val) {
      let currentOrderData = localStorage.getItem("current_customer_order");
      let currentOrder = JSON.parse(currentOrderData);
      console.log("Order instructions Is: " + val);
      if (val.length > 0) {
        currentOrder["cust_notes"] = val;
        this.userAddressErr = false;
        this.isuserAddressValidated = true;
        this.userAddressMsg = "";
      } else {
        currentOrder["cust_notes"] = "";
      }
      localStorage.setItem(
        "current_customer_order",
        JSON.stringify(currentOrder)
      );
    },
    validateCustomerOrderEmail(e) {
      let val = e.target.value;
      this.validateCustomerOrderEmailInput(val);
    },
    validateCustomerOrderEmailInput(val) {
      let currentOrderData = localStorage.getItem("current_customer_order");
      let currentOrder = JSON.parse(currentOrderData);
      let emailIs = /\w+([-+.']\w+)*@\w+([-.]\w+)*\.\w+([-.]\w+)*/.test(val);
      console.log("Customer email: " + val);
      if (val == "") {
        // currentOrder["cust_email"] = val;
        // this.userEmailErr = false;
        // this.isuserEmailValidated = true;
        // this.userEmailMsg = "";
        currentOrder["cust_email"] = "";
        this.userEmailErr = true;
        this.isuserEmailValidated = false;
        this.userEmailMsg = "Enter your email.";
      } else if (!emailIs && val != "") {
        currentOrder["cust_email"] = "";
        this.userEmailErr = true;
        this.isuserEmailValidated = false;
        this.userEmailMsg = "Enter valid email.";
      } else {
        currentOrder["cust_email"] = val;
        this.userEmailErr = false;
        this.isuserEmailValidated = true;
        this.userEmailMsg = "";
      }
      localStorage.setItem(
        "current_customer_order",
        JSON.stringify(currentOrder)
      );
    },
    validateCustomerOrderCardNumber(e) {
      let val = e.target.value;
      this.validateCustomerOrderCardNumberInput(val);
    },
    // https://www.prowaretech.com/articles/current/javascript/validate-credit-card-number#!
    // returns true or false
    validateCreditCardNumber(cardNumber) {
      cardNumber = cardNumber.split(" ").join("");
      if (
        parseInt(cardNumber) <= 0 ||
        !/\d{15,16}(~\W[a-zA-Z])*$/.test(cardNumber) ||
        cardNumber.length > 16
      ) {
        return false;
      }
      var carray = new Array();
      for (var i = 0; i < cardNumber.length; i++) {
        carray[carray.length] = cardNumber.charCodeAt(i) - 48;
      }
      carray.reverse();
      var sum = 0;
      for (i = 0; i < carray.length; i++) {
        var tmp = carray[i];
        if (i % 2 != 0) {
          tmp *= 2;
          if (tmp > 9) {
            tmp -= 9;
          }
        }
        sum += tmp;
      }
      return sum % 10 == 0;
    },
    validateCustomerOrderCardNumberInput(val) {
      // let currentOrderData = localStorage.getItem("current_customer_order");
      // let currentOrder = JSON.parse(currentOrderData);
      // let cardNumIs =
      //   /^5[1-5][0-9]{14}$|^2(?:2(?:2[1-9]|[3-9][0-9])|[3-6][0-9][0-9]|7(?:[01][0-9]|20))[0-9]{12}$/;
      console.log("card num: " + val);
      if (val == "") {
        // currentOrder["cust_email"] = val;
        // this.userEmailErr = false;
        // this.isuserEmailValidated = true;
        // this.userEmailMsg = "";
        // currentOrder["card_num"] = "";
        this.userCardNumberErr = true;
        this.isuserCardNumberValidated = false;
        this.userCardNumberMsg = "Enter card number";
      } else if (!this.validateCreditCardNumber(val) && val != "") {
        //if false
        // currentOrder["cust_email"] = "";
        this.userCardNumberErr = true;
        this.isuserCardNumberValidated = false;
        this.userCardNumberMsg = "Enter valid card number";
      } else {
        // currentOrder["cust_email"] = val;
        this.userCardNumberErr = false;
        this.isuserCardNumberValidated = true;
        this.userCardNumberMsg = "";
      }
      // localStorage.setItem(
      //   "current_customer_order",
      //   JSON.stringify(currentOrder)
      // );
    },
    validateUserMonthCard(e) {
      let val = e.target.value;
      this.validateUserMonthCardInput(val);
    },
    validateUserMonthCardInput(val) {
      console.log("Expiration Month selected is: " + val);
      if (val == "") {
        this.userExpMonthErr = true;
        this.isuserExpMonthValidated = false;
        this.userExpMonthMsg = "Select Expiration Month";
      } else if (val != "" && this.validateExpirationDates() == false) {
        this.userExpMonthErr = true;
        this.isuserExpMonthValidated = false;
        this.userExpMonthMsg = "Card month/year is expired.";
      } else {
        this.userExpMonthErr = false;
        this.isuserExpMonthValidated = true;
        this.userExpMonthMsg = "";
      }
    },
    validateExpirationDates() {
      //check if cvv is same year as selected, if so make sure
      //selected month is >= current month
      //ex: today 02/2023 -> user can't select 01/2023
      let currentYear = new Date().getFullYear();
      let currentMonth = new Date().getMonth();
      console.log("Current month => ", currentMonth + 1);
      console.log("Current year => ", currentYear);
      console.log("This expired month => ", this.userExpMonth);
      console.log("This expired year => ", this.userExpYear);
      if (this.userExpYear == currentYear) {
        if (
          this.userExpMonth < currentMonth + 1 &&
          this.userExpYear == currentYear
        ) {
          // this.userExpMonth = "";
          // this.userExpYear = "";
          return false;
        } else {
          return true;
        }
      } else if (this.userExpYear > currentYear) {
        return true;
      } else {
        return false;
      }
    },
    validateCVV(e) {
      let val = e.target.value;
      this.validateCVVInput(val);
    },
    validateCVVInput(val) {
      console.log("Security code is: " + val);
      if (val == "") {
        this.userCVVErr = true;
        this.isuserCVVValidated = false;
        this.userCVVMsg = "Enter Security Code (CVV)";
      } else {
        this.userCVVErr = false;
        this.isuserCVVValidated = true;
        this.userCVVMsg = "";
      }
    },
    validateUserYearCard(e) {
      let val = e.target.value;
      this.validateUserYearCardInput(val);
    },
    validateUserYearCardInput(val) {
      console.log("Expiration Year selected is: " + val);
      if (val == "") {
        this.userExpYearErr = true;
        this.isuserExpYearValidated = false;
        this.userExpYearMsg = "Select Expiration Year";
      } else if (val != "" && this.validateExpirationDates() == false) {
        this.userExpYearErr = true;
        this.isuserExpYearValidated = false;
        this.userExpYearMsg = "1. Card month/year is expired.";
      } else {
        this.userExpYearErr = false;
        this.isuserExpYearValidated = true;
        this.userExpYearMsg = "";
      }
    },
    validateUserExpirationDate(e) {
      let val = e.target.value;
      this.validateUserExpirationDateInput(val);
    },
    validateUserExpirationDateInput(val) {
      console.log("Expiration Month/Year selected is: " + val);
      let newVal = new Date(val);
      console.log("Selected Expiration Date =>", newVal);
      let currentYear = new Date().getFullYear();
      let currentMonth = new Date().getMonth() + 1;
      let month = val.slice(-2);
      let year = val.slice(0, 4);
      console.log("current year =>", currentYear);
      console.log("current month =>", currentMonth);
      console.log("selected month =>", month);
      console.log("selected year =>", year);
      if (val == "") {
        console.log("0. fix current expiration");
        this.userExpirationDateErr = true;
        this.isuserExpirationDateValidated = false;
        this.userExpirationDateMsg = "Enter Expiration Month/Year";
      } else if (val != "" && year < currentYear) {
        console.log("1. fix current expiration");
        // let pickedMonth = val;
        this.userExpirationDateErr = true;
        this.isuserExpirationDateValidated = false;
        this.userExpirationDateMsg = "Enter Valid Expiration Month/Year";
      } else if (val != "" && currentYear == year && month < currentMonth) {
        console.log("1. fix current expiration");
        // let pickedMonth = val;
        this.userExpirationDateErr = true;
        this.isuserExpirationDateValidated = false;
        this.userExpirationDateMsg = "Enter Valid Expiration Month/Year";
      } else {
        this.userExpirationDateErr = false;
        this.isuserExpirationDateValidated = true;
        this.userExpirationDateMsg = "";
      }
    },
    addHoursToDateAndReturnTimeOnly(objDate, intHours) {
      let numberOfMlSeconds = objDate.getTime();
      let addMlSeconds = intHours * 60 * 60 * 1000;
      let newDateObj = new Date(numberOfMlSeconds + addMlSeconds); //here it return full date with time added
      return newDateObj.toLocaleTimeString("en-US", {
        hour12: false,
      }); //here only returns time added to current time
    },
    async processPayment() {
      this.resultErr = false;
      this.resultErrMsg = "";
      this.resultSuccess = true;
      this.resultSuccessMsg = "Processing your payment...";
      console.log("you are clicked to pay...");
      if (
        // this.isuserAddressValidated == true &&
        this.isuserPhoneValidated == true &&
        // this.isuserEmailValidated == true &&
        // this.isuserCardNumberValidated == true &&
        // this.isuserExpirationDateValidated == true &&
        // this.isuserCVVValidated == true &&
        this.isuserNameValidated == true
      ) {
        this.signUpBtn = false;
        //show processing staus
        let processingEle = document.getElementById("processing");
        let mainPage = document.getElementById("currentRestMenu");
        processingEle.classList.remove("d-none");
        mainPage.classList.add("d-none");
        //get data
        let currentOrderData = localStorage.getItem("current_customer_order");
        let currentRestaurantsData = localStorage.getItem(
          "current_restaurants_data_public"
        );
        let currentOrder = JSON.parse(currentOrderData);
        let restPaymentSettings = localStorage.getItem(
          "current_restaurants_payment_data"
        );
        let currentRestPaymentSettings = JSON.parse(restPaymentSettings).filter(
          (restInfo) => restInfo.rest_id == currentOrder.restId
        );
        let custName = currentOrder.cust_name;
        // let custEmail = currentOrder.cust_email;
        let custNotes = currentOrder.cust_notes;
        let custPhone = currentOrder.cust_phone;
        let isCustomOrNow = currentOrder.custom_order_date_or_now;
        let customDate = currentOrder.custom_date;
        let customTime = "";
        if (isCustomOrNow == "now") {
          console.log("customer likes his order to be ready in 15 minutes");
          //if now,
          //so we can add 15 minutes from current time 60/4 = 25 addHoursToDateAndReturnTimeOnly(new Date(), addHours)
          customTime = this.addHoursToDateAndReturnTimeOnly(
            new Date(),
            0.25
          ).substring(0, 5);
          //check if current time is 24
          if (customTime.startsWith("24")) {
            customTime = customTime.replace(/24/g, "00");
            console.log("current custom time is ", customTime);
          } else {
            customTime = this.addHoursToDateAndReturnTimeOnly(
              new Date(),
              0.25
            ).substring(0, 5);
          }
        } else {
          customTime = currentOrder.custom_time;
        }
        let customerOrder = currentOrder.order;
        let currentRestId = currentOrder.restId;
        let sub_total = currentOrder.sub_total;
        let taxes = currentOrder.taxes;
        let taxes_percentage = currentOrder.taxes_percentage;
        let tips_added = currentOrder.tips_added;
        let total = currentOrder.total;

        console.log("Current Order before paid is");
        console.table(currentOrder);
        console.log("Current Order after paid is");
        var amount = (
          parseFloat(currentOrder.sub_total) +
          parseFloat(currentOrder.tips_added)
        ).toFixed(2);
        // var amount = (
        //   (parseFloat(currentOrder.sub_total) +
        //     parseFloat(currentOrder.tips_added)) *
        //   100
        // ).toFixed(0);
        console.log("hello");
        console.table(currentOrder);
        console.table(currentRestaurantsData);
        let currentRestaurant = JSON.parse(currentRestaurantsData).filter(
          (restInfo) => restInfo.rest_id == currentOrder.restId
        );
        console.table(currentRestaurant);
        console.log("hello");
        let restName = currentRestaurant[0].rest_name;
        // let restAddress = currentRestaurant[0].rest_address;
        console.log("restaurant name is " + currentRestaurant[0].rest_name);
        console.log(
          "restaurant address is " + currentRestaurant[0].rest_address
        );
        let description =
          restName +
          " | " +
          currentRestaurant[0].rest_address +
          " | " +
          currentOrder.restId;
        let fd = new FormData();
        fd.append("custName", custName);
        fd.append("custNotes", custNotes);
        fd.append("custPhone", custPhone);
        fd.append("email", this.userEmail);
        fd.append("isCustomOrNow", isCustomOrNow);
        fd.append(
          "orderPlacedOn",
          new Date(
            new Date().getTime() - new Date().getTimezoneOffset() * 60000
          )
            .toISOString()
            .split("T")[0] +
            " " +
            new Date().toTimeString().substring(0, 8)
        );
        fd.append("customDate", customDate);
        fd.append("customTime", customTime);
        fd.append("customerOrder", JSON.stringify(customerOrder));
        fd.append("currentRestId", currentRestId);
        fd.append("sub_total", sub_total);
        fd.append("taxes", taxes);
        fd.append("taxes_percentage", taxes_percentage);
        fd.append("tips_added", tips_added);
        fd.append("total", total);
        // fd.append("amount", parseInt(amount));
        fd.append("amount", amount);
        fd.append("description", description);
        fd.append("key", currentRestPaymentSettings[0].stripe_secret_key);
        //send card details
        let month = this.userExpirationDate.slice(-2);
        let year = this.userExpirationDate.slice(0, 4);
        fd.append("cardNo", this.userCardNumber);
        fd.append("cvv", this.userCVV);
        fd.append("expirationMonth", month);
        fd.append("expirationYear", year);
        // let rst = await axios.post(this.apiMainUrl + `?action=addNewUser`, fd);
        let rst = await axios.post(
          // "https://order.hooksfishnchips.com/pay.php",
          "https://hooksmn.com/placeOrder.php",
          //"http://localhost/php/POS-SYSTEM-BACKUP-STRIPE/pay2.php",
          fd
        );
        const resultData = rst.data;
        console.table(resultData);
        if (rst.status == 200) {
          if (resultData.error) {
            console.warn(
              "Connection Error Happened on proccessing current payment"
            );
            this.resultErrMsg = resultData.errorMsg;
            this.resultErr = true;
            this.resultSuccessMsg = "";
            this.resultSuccess = false;
            this.signUpBtn = true;
            //remove processing loading icon and let customer resubmit payment
            let processingEle = document.getElementById("processing");
            let mainPage = document.getElementById("currentRestMenu");
            processingEle.classList.add("d-none");
            mainPage.classList.remove("d-none");
          } else {
            //if success submitted
            localStorage.setItem(
              "current_customer_order",
              JSON.stringify(currentOrder)
            );
            this.signUpBtn = false;
            console.log("Good Job, customer successfully paid this order..");
            //if everything is okay
            this.resultErrMsg = "";
            this.resultErr = false;
            this.resultSuccess = true;
            //if success -> redirect to receipt page
            window.location.href =
              "/online/order/confirmation/" +
              this.restId +
              "/" +
              resultData.orderId;
            // setTimeout(() => {
            //   this.$router.push({
            //     name: "OrderConfirmationPage",
            //     params: {
            //       restId: this.restId,
            //       currentOrderId: resultData.orderId,
            //     },
            //   });
            //   console.log("User Name: " + this.userName);
            // }, 1000);
          }
        } else {
          console.log("Status is not successed...");
        }
        console.log("checking on payment status..");
      } else {
        this.signUpBtn = true;
        let processingEle = document.getElementById("processing");
        let mainPage = document.getElementById("currentRestMenu");
        processingEle.classList.add("d-none");
        mainPage.classList.remove("d-none");
        this.resultSuccessMsg = "";
        this.resultSuccess = false;
        this.resultErrMsg = "Must enter all required information";
        this.resultErr = true;
      }
    },
  },
};
</script>

<style>
.titleBrown {
  color: #ad0505;
}
.wAuto {
  width: auto !important;
  max-width: 150px;
  min-width: 150px;
  display: inline-block !important;
}
</style>
