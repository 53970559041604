<template>
  <div class="container">
    <h1 class="text-center">Create an Account</h1>
    <div class="modal fade" id="termsAndCondition">
      <TermsAndConditions />
    </div>
    <div>
      <div v-if="!is_DB_Connected">
        <div role="alert" class="alert alert-danger fade show">
          Server's Connection is down
        </div>
      </div>
      <!-- Add New Car Form -->
      <div v-else>
        <form method="post" action="#" enctype="multipart/form-data">
          <div class="row g-3 align-items-center mb-3">
            <div class="col-auto d-block mx-auto">
              <div class="form-floating" :class="{ 'form-data-error': userEmailErr }">
                <input type="email" id="userEmailInput" placeholder="User Email" @keyup="validateUserEmail($event)"
                  @change="validateUserEmail($event)" class="form-control w300" v-model.trim="userEmail" />
                <label for="userEmailInput">
                  <faIcons :icon="['fas', 'envelope']" /> User Email
                </label>
                <span class="error-feedback" v-if="userEmailErr">
                  {{ userEmailMsg }}
                </span>
              </div>
            </div>
          </div>

          <div class="row g-3 align-items-center mb-3">
            <div class="col-auto d-block mx-auto">
              <div class="form-floating" :class="{ 'form-data-error': userNameErr }">
                <input type="text" id="userNameInput" placeholder="First & Last Name" class="form-control w300"
                  @keyup="validateUserName($event)" @change="validateUserName($event)" v-model.trim="userName" />
                <label for="userNameInput">
                  <faIcons :icon="['fas', 'user-circle']" /> First & Last Name
                </label>
                <span class="error-feedback" v-if="userNameErr">
                  {{ userNameMsg }}
                </span>
              </div>
            </div>
          </div>

          <div class="row g-3 align-items-center mb-3">
            <div class="col-auto d-block mx-auto">
              <div class="form-floating" :class="{ 'form-data-error': userPhoneErr }">
                <input type="number" min="0" id="userPhoneInput" placeholder="User Phone"
                  @keyup="validateUserPhone($event)" @change="validateUserPhone($event)" @keydown="
                    if (
  $event.key === '.' ||
  $event.key === '+' ||
  $event.key === '-'
) {
  $event.preventDefault();
}
                  " class="form-control w300" v-model.trim="userPhone" />
                <label for="userPhoneInput">
                  <faIcons :icon="['fas', 'phone']" /> User Phone
                </label>
                <span class="error-feedback" v-if="userPhoneErr">
                  {{ userPhoneMsg }}
                </span>
              </div>
            </div>
          </div>
          <div class="row g-3 align-items-center mb-3">
            <div class="col-auto d-block mx-auto">
              <div class="form-floating" :class="{ 'form-data-error': userRoleErr }">
                <select id="userRoleInput" placeholder="User Role" class="form-select w300"
                  @change="validateUserRole($event)" v-model.trim="userRole" aria-label="userRoleInput">
                  <option value="" disabled>Select From Below List</option>
                  <option value="manager">Business Owner</option>
                  <option value="comanager">Manager</option>
                  <option value="employee">Employee</option>
                  <option value="customer">Customer</option>
                </select>
                <label for="userRoleInput">
                  <faIcons :icon="['fas', 'user-tag']" /> Sign Up as
                </label>
                <span class="error-feedback" v-if="userRoleErr">
                  {{ userRoleMsg }}
                </span>
              </div>
            </div>
          </div>
          <div class="row g-3 align-items-center mb-3">
            <div class="col-auto d-block mx-auto">
              <div class="form-floating" :class="{ 'form-data-error': userAddressErr }">
                <textarea id="userAddressInput" placeholder="User Full Address" @input="validateUserAddress($event)"
                  class="form-control w300 h100" v-model.trim="userAddress" />
                <label for="userAddressInput">
                  <faIcons :icon="['fas', 'address-book']" /> User Address
                </label>
                <span class="error-feedback" v-if="userAddressErr">
                  {{ userAddressMsg }}
                </span>
              </div>
            </div>
          </div>
          <div class="row g-3 align-items-center mb-3">
            <div class="col-auto d-block mx-auto">
              <div class="form-floating w300" :class="{ 'form-data-error': userPasswordErr }">
                <input type="password" id="userPasswordInput" placeholder="User Password"
                  @keyup="validateUserPassword($event)" @change="validateUserPassword($event)" class="form-control w300"
                  v-model.trim="userPassword" autocomplete />
                <label for="userPasswordInput">
                  <faIcons :icon="['fas', 'key']" /> User Password
                </label>
                <span class="error-feedback" v-if="userPasswordErr">
                  {{ userPasswordMsg }}
                </span>
              </div>
            </div>
          </div>

          <div class="row d-grid gap-2 w300 col-auto d-block mx-auto mb-3">
            <button class="btn btn-outline-link blueInc" type="button" data-bs-toggle="modal"
              data-bs-target="#termsAndCondition">
              <faIcons :icon="['fas', 'gavel']" /> Agree to terms & conditions
            </button>
          </div>
          <div class="row d-grid gap-2 w300 col-auto d-block mx-auto mb-3">
            <button class="btn btn-outline-success" @click.prevent="userSignUp()" v-if="signUpBtn">
              <faIcons :icon="['fas', 'id-card']" /> Sign Up Now
            </button>
          </div>
          <div class="row d-grid gap-2 w300 col-auto d-block mx-auto mb-3">
            <div class="alert alert-success" role="alert" v-if="resultSuccess">
              {{ resultSuccessMsg }}
            </div>
            <div class="alert alert-danger" role="alert" v-if="resultErr">
              {{ resultErrMsg }}
            </div>
          </div>
          <div class="row d-grid gap-2 w300 col-auto d-block mx-auto mb-3">
            <router-link class="btn btn-outline-secondary" :to="{ name: 'Login' }">
              <faIcons :icon="['fas', 'user-tie']" /> Have an Account, Login Now
            </router-link>
          </div>
          <div class="row d-grid gap-2 w300 col-auto d-block mx-auto mb-3">
            <router-link class="btn btn-outline-secondary" :to="{ name: 'ContactUs' }">
              <faIcons :icon="['fas', 'envelope-open-text']" /> Contact Us
            </router-link>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import TermsAndConditions from "@/components/TermsAndConditions/TermsAndConditions.vue";
import { mapState, mapMutations, mapGetters } from "vuex";
export default {
  name: "SignUpPage",
  components: {
    TermsAndConditions,
  },
  data() {
    return {
      //email
      userEmail: "",
      userEmailErr: false,
      userEmailMsg: "",
      isuserEmailValidated: false,
      //address
      userAddress: "",
      userAddressErr: false,
      userAddressMsg: "",
      isuserAddressValidated: false,
      //phone
      userPhone: "",
      userPhoneErr: false,
      userPhoneMsg: "",
      isuserPhoneValidated: false,
      //role
      userRole: "",
      userRoleErr: false,
      userRoleMsg: "",
      isuserRoleValidated: false,
      //name
      userName: "",
      userNameErr: false,
      userNameMsg: "",
      isuserNameValidated: false,
      //password
      userPassword: "",
      userPasswordErr: false,
      userPasswordMsg: "",
      isuserPasswordValidated: false,

      resultSuccess: false,
      resultSuccessMsg: "",
      resultErr: false,
      resultErrMsg: "",
      image: "",
      is_DB_Connected: true,
      db_connection_msg: "",
      // apiMainUrl: "http://localhost/php/login-system-php-vue-2021/myData.php",
      apiMainUrl: this.$store.getters.MainUrl, //call global variable
      signUpBtn: true,
    };
  },
  mounted() {
    this.dbConnection();
    //if user is already logged in, can't visit this page
    let user = localStorage.getItem("current-user-data");
    if (user) {
      this.$router.push({ name: "Home" });
    }
  },
  computed: {
    ...mapState([
      "__UserId",
      "__UserEmail",
      "__UserRole",
      "__UserName",
      "__MainUrl",
    ]),
    ...mapGetters(["MainUrl"]),
  },
  methods: {
    ...mapMutations(["setUserInfo"]),
    async dbConnection() {
      let res = await axios.post(this.apiMainUrl);
      const resData = res.data;
      this.is_DB_Connected = resData.is_db_connected;
      this.db_connection_msg = resData.connection_msg;
    },
    validateUserEmail(e) {
      let val = e.target.value;
      this.validateUserEmailInput(val);
    },
    validateUserEmailInput(val) {
      // let emailIs = /^\w+([-]?\w+)*@\w+([-]?\w+)*(\w{2,3})+$/.test(val);
      // let emailIs = /^\w+([-]?\w+)*@\w+([-]?\w+)*(\.\w{2,3})+$/.test(val);
      let emailIs = /\w+([-+.']\w+)*@\w+([-.]\w+)*\.\w+([-.]\w+)*/.test(val);
      if (val == "") {
        this.userEmailErr = true;
        this.isuserEmailValidated = false;
        this.userEmailMsg = "Must Enter User Email ..";
      } else if (!emailIs && val != "") {
        this.userEmailErr = true;
        this.isuserEmailValidated = false;
        this.userEmailMsg = "Must Enter Valid Email ..";
      } else {
        this.userEmailErr = false;
        this.isuserEmailValidated = true;
        this.userEmailMsg = "";
      }
    },
    validateUserPassword(e) {
      let val = e.target.value;
      this.validateUserPasswordInput(val);
    },
    validateUserPasswordInput(val) {
      // let emailIs = /^\w+([-]?\w+)*@\w+([-]?\w+)*(\w{2,3})+$/.test(val);
      // let passIs = /^(?=.*[0-9])(?=.*[!@#$%^&*])[a-zA-Z0-9!@#$%^&*]{7,15}$/;
      let passIs = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[!@#$%^&*]).{8,}$/;
      if (val == "") {
        this.userPasswordErr = true;
        this.isuserPasswordValidated = false;
        this.userPasswordMsg = "Must Enter User Password ..";
      } else if (val !== "" && !passIs.test(val)) {
        this.userPasswordErr = true;
        this.isuserPasswordValidated = false;
        this.userPasswordMsg =
          "Compose at least 8 Chars of lowercase Letters, upperrcase Letters, Numbers and !@#$%^&*";
      } else {
        this.userPasswordErr = false;
        this.isuserPasswordValidated = true;
        this.userPasswordMsg = "";
      }
    },
    //validate User Name
    validateUserName(e) {
      let val = e.target.value;
      this.validateUserNameInput(val);
    },
    validateUserNameInput(val) {
      // let userNameRegex = /^[a-zA-Z ]+$/; //allow spaces
      // let userNameRegex = /[^A-Za-z ]/g; //allow spaces, international letters
      // console.log("Is User Name Validated? " + userNameRegex.test(val));
      if (val == "") {
        this.userNameErr = true;
        this.isuserNameValidated = false;
        this.userNameMsg = "Must Enter User Name ..";
      } else {
        this.userNameErr = false;
        this.isuserNameValidated = true;
        this.userNameMsg = "";
      }
    },
    //validate User Name
    validateUserPhone(e) {
      let val = e.target.value;
      this.validateUserPhoneInput(val);
    },
    validateUserPhoneInput(val) {
      // let userPhoneRegex = /^\d{10}$/;
      console.log("User Phone: " + val);
      if (val == "") {
        this.userPhoneErr = true;
        this.isuserPhoneValidated = false;
        this.userPhoneMsg = "Must Enter User Phone ..";
      } else if (val !== "" && (val.length > 11 || val.length < 8)) {
        this.userPhoneErr = true;
        this.isuserPhoneValidated = false;
        this.userPhoneMsg = "Must Only Enter 9-11 Digits ..";
      } else {
        this.userPhoneErr = false;
        this.isuserPhoneValidated = true;
        this.userPhoneMsg = "";
      }
    },
    //validate User Role
    validateUserRole(e) {
      let val = e.target.value;
      this.validateUserRoleInput(val);
    },
    validateUserRoleInput(val) {
      const roles = ["manager", "employee", "customer", "comanager"];
      console.log("Is User Role Validated? " + roles.includes(val));
      if (val == "") {
        this.userRoleErr = true;
        this.isuserRoleValidated = false;
        this.userRoleMsg = "Must Select Your Role ..";
      } else if (roles.includes(val) == false) {
        this.userRoleErr = true;
        this.isuserRoleValidated = false;
        this.userRoleMsg = "Must Enter Valid User Role ..";
      } else {
        this.userRoleErr = false;
        this.isuserRoleValidated = true;
        this.userRoleMsg = "";
      }
    },
    //validate User Address
    validateUserAddress(e) {
      let val = e.target.value;
      this.validateUserAddressInput(val);
    },
    validateUserAddressInput(val) {
      // let userAddressRegex = /^[a-zA-Z0-9 \n]+$/;
      // console.log("Is User Address Validated? " + userAddressRegex.test(val));
      console.log("User Address Is: " + val);
      if (val == "") {
        this.userAddressErr = true;
        this.isuserAddressValidated = false;
        this.userAddressMsg = "Must Enter Your Address ..";
      } else {
        this.userAddressErr = false;
        this.isuserAddressValidated = true;
        this.userAddressMsg = "";
      }
    },
    async userSignUp() {
      this.resultErr = false;
      this.resultErrMsg = "";
      this.resultSuccess = true;
      this.resultSuccessMsg =
        "Please wait while we're creating an account for you...";
      this.validateUserEmailInput(this.userEmail);
      this.validateUserNameInput(this.userName);
      this.validateUserPasswordInput(this.userPassword);
      this.validateUserPhoneInput(this.userPhone);
      this.validateUserRoleInput(this.userRole);
      this.validateUserAddressInput(this.userAddress);
      if (
        this.isuserEmailValidated &&
        this.isuserNameValidated &&
        this.isuserPasswordValidated &&
        this.isuserPhoneValidated &&
        this.isuserRoleValidated &&
        this.isuserAddressValidated
      ) {
        this.signUpBtn = false;
        //show Result
        let fd = new FormData();
        fd.append("email", this.userEmail);
        fd.append("name", this.userName);
        fd.append("pass", this.userPassword);
        fd.append("phone", this.userPhone);
        fd.append("role", this.userRole);
        fd.append("address", this.userAddress);
        let rst = await axios.post(this.apiMainUrl + `?action=addNewUser`, fd);
        console.log(rst);
        const resultData = rst.data;
        if (rst.status == 200) {
          if (resultData.error) {
            console.warn("Connection Error Happened on Adding New User");
            this.resultErrMsg = resultData.message;
            this.resultErr = true;
            this.resultSuccessMsg = "";
            this.resultSuccess = false;
            this.signUpBtn = true;
          } else {
            this.signUpBtn = false;
            console.log("Good Job, user has been added successfully..");
            //if everything is okay
            this.resultErrMsg = "";
            this.resultErr = false;
            this.resultSuccess = true;
            //redirect only if customer
            if (this.userRole == "customer") {
              this.resultSuccessMsg = resultData.message;
              localStorage.setItem(
                "current-user-data",
                JSON.stringify(resultData.current_user_data[0])
              );
              setTimeout(() => {
                this.$router.push({
                  name: "Home",
                });
              }, 3000);
            } else {
              //if employee or manager: must be confirmed by admin
              this.resultSuccessMsg =
                "Thanks for signing up. You will receive an account confirmation email to access our system";
              setTimeout(() => {
                this.$router.push({
                  name: "AccountOnHold",
                  params: { uName: this.userName },
                });

                console.log("User Name: " + this.userName);
              }, 3000);
            }
          }
        }
      } else {
        this.signUpBtn = true;
        this.validateUserEmailInput(this.userEmail);
        this.validateUserPasswordInput(this.userPassword);
        this.validateUserNameInput(this.userName);
        this.validateUserPhoneInput(this.userPhone);
        this.validateUserRoleInput(this.userRole);
        this.validateUserAddressInput(this.userAddress);
        this.resultSuccessMsg = "";
        this.resultSuccess = false;

        this.resultErrMsg = "Must enter all required information";
        this.resultErr = true;
      }
    },
  },
};
</script>

<style>
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

.w300 {
  width: 300px !important;
}

.w150 {
  width: 150px !important;
}

.h100 {
  height: 120px !important;
  resize: none;
}

.form-data-error {
  color: red;
}

.form-data-error input,
.form-data-error textarea {
  border-color: red;
}

.error-feedback {
  padding-left: 3px;
  font-size: 0.9rem;
}

.customFileField {
  padding-left: 24px !important;
  padding-top: 38px !important;
  padding-bottom: 10px !important;
  height: 75px !important;
}

.custom-hr-150 {
  box-sizing: content-box;
  height: 0;
  overflow: visible;
  width: 155px !important;
  margin: auto !important;
}
</style>
