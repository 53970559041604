<template>
  <div>
    <AdminDashboard v-if="uRole == 'admn'" />
    <ManagerDashboard v-if="uRole == 'mngr'" />
    <CustomerDashboard v-if="uRole == 'cstmr'" />
    <CoManagerDashboard v-if="uRole == 'cmngr'" />
    <EmployeeDashboard v-if="uRole == 'empl'" />
  </div>
</template>

<script>
import AdminDashboard from "@/components/Dashboard/AdminDashboard.vue";
import ManagerDashboard from "@/components/Dashboard/ManagerDashboard.vue";
import CustomerDashboard from "@/components/Dashboard/CustomerDashboard.vue";
import CoManagerDashboard from "@/components/Dashboard/CoManagerDashboard.vue";
import EmployeeDashboard from "@/components/Dashboard/EmployeeDashboard.vue";

export default {
  name: "DashboardPanel",
  components: {
    AdminDashboard,
    ManagerDashboard,
    CustomerDashboard,
    EmployeeDashboard,
    CoManagerDashboard,
  },
  data() {
    return {
      isUserSignUpOrLoggedIn: false,
      uName: "",
      uEmail: "",
      uRole: "",
      uId: "",
    };
  },
  mounted() {
    let user = localStorage.getItem("current-user-data");
    if (user) {
      this.isUserSignUpOrLoggedIn = true;
      this.uName = JSON.parse(user).name;
      this.uEmail = JSON.parse(user).email;
      this.uRole = JSON.parse(user).role;
      this.uId = JSON.parse(user).id;
    } else {
      this.$router.push({ name: "Login" });
    }
  },
  methods: {
    logMeOut() {
      localStorage.clear();
      this.$router.push({ name: "Login" });
    },
  },
};
</script>

<style lang="scss" scoped></style>
