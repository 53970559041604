<template>
  <div>
    <!-- 
    <div class="row justify-content-around">
      <div class="col-12 col-sm-3 each-category-list">
        <h3>Managers</h3>
        <ul class="list-group list-group-flush">
          <li class="list-group-item">
            <div class="ms-2 me-auto">
              <div class="fw-bold">
                <span class="linkElement">All</span
                ><span class="float-end">15,500</span>
              </div>
            </div>
          </li>
          <li class="list-group-item">
            <div class="ms-2 me-auto">
              <div class="fw-bold">
                <span class="linkElement">Active</span
                ><span class="float-end">15,500</span>
              </div>
            </div>
          </li>
          <li class="list-group-item">
            <div class="ms-2 me-auto">
              <div class="fw-bold">
                <span class="linkElement">Inactive</span
                ><span class="float-end">15,500</span>
              </div>
            </div>
          </li>
        </ul>
      </div>
      <div class="col-12 col-sm-3 each-category-list">
        <h3>Employees</h3>
        <ul class="list-group list-group-flush">
          <li class="list-group-item">
            <div class="ms-2 me-auto">
              <div class="fw-bold">
                <span class="linkElement">All</span
                ><span class="float-end">15,500</span>
              </div>
            </div>
          </li>
          <li class="list-group-item">
            <div class="ms-2 me-auto">
              <div class="fw-bold">
                <span class="linkElement">Active</span
                ><span class="float-end">15,500</span>
              </div>
            </div>
          </li>
          <li class="list-group-item">
            <div class="ms-2 me-auto">
              <div class="fw-bold">
                <span class="linkElement">Inactive</span
                ><span class="float-end">15,500</span>
              </div>
            </div>
          </li>
        </ul>
      </div>
      <div class="col-12 col-sm-3 each-category-list">
        <h3>Customers</h3>
        <ul class="list-group list-group-flush">
          <li class="list-group-item">
            <div class="ms-2 me-auto">
              <div class="fw-bold">
                <span class="linkElement">All</span
                ><span class="float-end">15,500</span>
              </div>
            </div>
          </li>
          <li class="list-group-item">
            <div class="ms-2 me-auto">
              <div class="fw-bold">
                <span class="linkElement">Active</span
                ><span class="float-end">15,500</span>
              </div>
            </div>
          </li>
          <li class="list-group-item">
            <div class="ms-2 me-auto">
              <div class="fw-bold">
                <span class="linkElement">Inactive</span
                ><span class="float-end">15,500</span>
              </div>
            </div>
          </li>
        </ul>
      </div>
    </div>
    <br />
  -->
    <div class="row justify-content-around">
      <!-- <div class="col-12 col-sm-3 each-category-list">
      <h3>Sales</h3>
      <ul class="list-group list-group-flush">
        <li class="list-group-item">
          <div class="ms-2 me-auto">
            <div class="fw-bold">
              <span class="linkElement">Today</span
              ><span class="float-end">15,500</span>
            </div>
          </div>
        </li>
        <li class="list-group-item">
          <div class="ms-2 me-auto">
            <div class="fw-bold">
              <span class="linkElement">This Month</span
              ><span class="float-end">15,500</span>
            </div>
          </div>
        </li>
        <li class="list-group-item">
          <div class="ms-2 me-auto">
            <div class="fw-bold">
              <span class="linkElement">This Year</span
              ><span class="float-end">15,500</span>
            </div>
          </div>
        </li>
        <li class="list-group-item">
          <div class="ms-2 me-auto">
            <div class="fw-bold">
              <span class="linkElement">Past Years</span
              ><span class="float-end">15,500</span>
            </div>
          </div>
        </li>
      </ul>
    </div>
    <div class="col-12 col-sm-3 each-category-list">
      <h3>Miscellaneous</h3>
      <ul class="list-group list-group-flush">
        <li class="list-group-item">
          <div class="ms-2 me-auto">
            <div class="fw-bold linkElement">Timesheets</div>
          </div>
        </li>
        <li class="list-group-item">
          <div class="ms-2 me-auto">
            <div class="fw-bold linkElement">Checks</div>
          </div>
        </li>
        <li class="list-group-item">
          <div class="ms-2 me-auto">
            <div class="fw-bold linkElement">Inventories</div>
          </div>
        </li>
        <li class="list-group-item">
          <div class="ms-2 me-auto">
            <div class="fw-bold linkElement">Expenses</div>
          </div>
        </li>
        <li class="list-group-item">
          <div class="ms-2 me-auto">
            <div class="fw-bold linkElement">Vendors</div>
          </div>
        </li>
      </ul>
    </div> -->
      <div class="col-12 col-sm-3 each-category-list">
        <h3>Restaurants</h3>
        <ul class="list-group list-group-flush">
          <li class="list-group-item">
            <div class="ms-2 me-auto">
              <div class="fw-bold linkElement">
                <router-link tag="li" :to="{ name: 'MyRestaurants' }">
                  <a><faIcons :icon="['fas', 'home']" /> My Restaurant(s)</a>
                </router-link>
              </div>
            </div>
          </li>
          <li class="list-group-item">
            <div class="ms-2 me-auto">
              <div class="fw-bold linkElement">
                <router-link tag="li" :to="{ name: 'ViewAllRestaurants' }">
                  <a
                    ><faIcons :icon="['fas', 'hamburger']" /> Online Order
                    Menu</a
                  >
                </router-link>
              </div>
            </div>
          </li>
          <li class="list-group-item">
            <div class="ms-2 me-auto">
              <div class="fw-bold linkElement">
                <router-link tag="li" :to="{ name: 'ViewAllUsers' }">
                  <a><faIcons :icon="['fas', 'users']" /> Users</a>
                </router-link>
              </div>
            </div>
          </li>
          <li class="list-group-item">
            <div class="ms-2 me-auto">
              <div class="fw-bold linkElement">
                <router-link tag="li" :to="{ name: 'ContactUsMessages' }">
                  <a
                    ><faIcons :icon="['fas', 'comments']" /> Incoming
                    Messages</a
                  >
                </router-link>
              </div>
            </div>
          </li>
          <li class="list-group-item">
            <div class="ms-2 me-auto">
              <div class="fw-bold linkElement">
                  <button class="btn btn-outline-success" @click.prevent="optimizeRestaurantImages()"
                    ><faIcons :icon="['fas', 'images']" /> Optimize Uploaded Images (3 Mins)</button
                  >
              </div>
            </div>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
export default {
  name: "ManagerDashboard",
  methods: {
    async optimizeRestaurantImages() {
      // console.log(`find all orders for current restaurant ${restId}`);
      let rst = await axios.post('https://order.hooksfishnchips.com/restaurants/optimizeImages.php');
      console.log(rst);
      const resultData = rst.data;
      if (rst.status == 200) {
        alert("Start optimizing all restaurant images..");
        console.table(resultData);
        if (resultData.error) {
          alert("Not all images optimized");
        } else {
          alert("All Images are ptimized..");
        }
      }
    },
  },
};
</script>

<style lang="scss" scoped>
$blueColor: #3f51b6;
a {
  text-decoration: none;
  color: $blueColor;
}
h3 {
  color: darkcyan;
}
.linkElement {
  color: $blueColor;
  cursor: pointer;
}
.each-category-list {
  padding: 15px;
  border-radius: 5px;
  box-shadow: 2px 2px 4px 0px #525a5a;
}
</style>
