<template>
  <div class="container">
    <Navbar />
    <nav style="--bs-breadcrumb-divider: '>'" aria-label="breadcrumb">
      <ol class="breadcrumb">
        <li class="breadcrumb-item">
          <router-link tag="li" :to="{ name: 'Home' }">
            <a>Home</a>
          </router-link>
        </li>
        <li class="breadcrumb-item">
          <router-link tag="li" :to="{ name: 'MyRestaurants' }">
            <a>{{ capitalizeFisrtLetterOfEachWord(this.userName) }}</a>
          </router-link>
        </li>
        <li class="breadcrumb-item active" aria-current="page">
          View Access Users
        </li>
      </ol>
    </nav>
    <div class="my-restaurant-page">
      <div class="float-start m-2">
        <router-link :to="{
  name: 'AddNewUserToMyRestaurant',
  params: { restId: restId, id: restNum },
}">
          <button class="btn btn-outline-success">
            <faIcons :icon="['fas', 'users']" /> Add New User
          </button>
        </router-link>
      </div>
      <div class="clearfix"></div>
      <div class="row main-restaurant-table">
        <!-- View all resturants here -->
        <div v-if="currentRestaurantsArr.length > 0" class="row justify-content-around">
          <h3>Employees and Managers Access</h3>
          <p>{{ userAddress }}</p>
          <p class="fb text-center">
            Your access code is {{ user.uAccessCode }}
          </p>
          <p>Add managers/employees to your restaurant to:</p>
          <ol class="list-group list-group-numbered">
            <li class="list-group-item">You don't need to add yourself.</li>
            <li class="list-group-item">
              Access code is on user's account page
            </li>
            <li class="list-group-item">Punch In/Out</li>
            <li class="list-group-item">View restaurant orders</li>
            <li class="list-group-item">
              Employees will not be able to cancel/refund orders..!
            </li>
            <li class="list-group-item">
              You and assigned Managers will view reports and cancel/refund
              orders
            </li>
            <li class="list-group-item">
              No one will have any access to edit/update menu or restaurant data
              but you.
            </li>
          </ol>
          <div class="m-2" v-if="currentUsersAre.length < 1">
            <div class="alert alert-warning">
              You don't have any employee/managers assigned to access your
              orders
            </div>
          </div>
          <div v-else class="m-2">
            <table class="table table-hover">
              <thead>
                <tr>
                  <th>Name</th>
                  <th>Code</th>
                  <th>Role</th>
                  <th>Added On</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="each in currentUsersAre" :key="each">
                  <td :id="'name-' + each.access_id">
                    {{ getUserName(each.access_id) }}
                  </td>
                  <td>{{ each.access_id }}</td>
                  <td v-if="each.role == 'empl'">Employee</td>
                  <td v-else-if="each.role == 'mngr'">Manager</td>
                  <td v-else-if="each.role == 'admn'">IT</td>
                  <td>{{ new Date(each.added_on).toLocaleDateString() }}</td>
                  <td>
                    <router-link :to="{
  name: 'RemoveUsersFromMyRestaurant',
  params: {
    restId: restId,
    id: restNum,
    userAccessId: each.access_id,
  },
}">
                      <button class="btn btn-outline-danger">
                        <faIcons :icon="['fas', 'trash']" /> Remove
                      </button>
                    </router-link>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <div v-else class="d-flex justify-content-evenly m-2">
          <div class="alert alert-warning m-2" role="alert">
            <faIcons :icon="['fas', 'store']" /> You currently don't have any restaurants.
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import Navbar from "@/components/Header/Navbar.vue";
export default {
  name: "AccessUsersToOnlineOrders",
  components: {
    Navbar,
  },
  data() {
    return {
      currentUsersAre: [],
      user: {
        uId: "",
        uUserId: "",
        uName: "",
        uAccessCode: "",
        uEmail: "",
        uPassword: "",
        uMemberSince: "",
        uAddress: "",
        uPhone: "",
        uRole: "",
        uRoleIs: "",
      },
      apiMainUrl: this.$store.getters.MainUrl,
      restId: this.$route.params.restId,
      restNum: this.$route.params.id,
      currentRestaurantsArr: [],
      userPhone: "",
      userName: "",
      userAddress: "",
      allowedUsers: [],
      currentEmployeeName: "",
    };
  },
  mounted() {
    let user = localStorage.getItem("current-user-data");
    let currentRestaurants = localStorage.getItem("current_restaurants_data");
    let currentRestaurant = JSON.parse(currentRestaurants).filter(
      (restInfo) =>
        restInfo.id == this.restNum && restInfo.rest_id == this.restId
    );
    // console.log(currentRestaurants);
    this.currentRestaurantsArr = JSON.parse(currentRestaurants);
    if (user) {
      this.userPhone = currentRestaurant[0].rest_phone;
      this.userName = currentRestaurant[0].rest_name;
      this.userAddress = currentRestaurant[0].rest_address;
      this.user.uId = JSON.parse(user).id;
      this.user.uUserId = JSON.parse(user).user_id;
      this.user.uAccessCode = JSON.parse(user).access_id;
      this.user.uName = JSON.parse(user).name;
      this.user.uEmail = JSON.parse(user).email;
      this.user.uPassword = JSON.parse(user).password;
      this.user.uMemberSince = JSON.parse(user).member_since;
      this.user.uAddress = JSON.parse(user).full_address;
      this.user.uPhone = JSON.parse(user).phone_number;
      this.user.uRole = JSON.parse(user).role;
      this.getUsersData(this.restId);
      //if role isnt manager or admin, redirect
      if (
        JSON.parse(user).role !== "mngr" &&
        JSON.parse(user).role !== "admn"
      ) {
        this.$router.push({ name: "Login" });
      }
    } else {
      this.$router.push({ name: "Login" });
    }
  },
  methods: {
    async getUsersData(restId) {
      let fd = new FormData();
      fd.append("restId", restId);
      fd.append("userId", this.user.uUserId);
      let rst = await axios.post(
        this.apiMainUrl + `?action=getAllAccessedUsers`,
        fd
      );
      if (rst.status == 200) {
        const resultData = rst.data;
        if (!resultData.error) {
          this.currentUsersAre = resultData.users;
        }
      }
    },
    async getUserName(userId) {
      let fd = new FormData();
      fd.append("userId", userId);
      let rst = await axios.post(this.apiMainUrl + `?action=getUserName`, fd);
      if (rst.status == 200) {
        const resultData = rst.data;
        if (!resultData.error) {
          document.getElementById("name-" + userId).innerText =
            this.capitalizeFisrtLetterOfEachWord(resultData.name);
        }
      }
    },
    capitalizeFisrtLetterOfEachWord(text) {
      return text
        .toLowerCase()
        .split(" ")
        .map((s) => s.charAt(0).toUpperCase() + s.substring(1))
        .join(" ");
    },
    addNewRestaurant() {
      this.$router.push({ name: "AddNewRestaurant" });
    },
    formatPhoneNumber(number) {
      //remove . + -  from any number
      let cleaned = ("" + number).replace(/\D/g, "");
      let match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
      if (match) {
        return "(" + match[1] + ") " + match[2] + "-" + match[3];
      }
      return null;
    },
  },
};
</script>
<style lang="scss" scoped>
$navColor: #3f51b6;

a {
  text-decoration: none;
  color: $navColor;
  font-weight: bold;
}

.each-category-list {
  padding: 15px;
  border-radius: 5px;
  box-shadow: 2px 2px 4px 0px #525a5a;
  margin: 8px;
}

.addressDisplay {
  white-space: pre-wrap;
}

.lightColor {
  margin-top: -1rem;
  margin-bottom: 1rem;
  font-size: 0.875em;
  color: #6c757d;
}
</style>
