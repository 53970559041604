<template>
  <div class="container">
    <!-- Reset User Password -->
    <div v-if="is_DB_Connected">
      <form v-if="canUserAccessThisPage" method="post" action="#" enctype="multipart/form-data">
        <div class="row g-3 align-items-center m-3">
          <div class="col-auto d-block mx-auto">
            <h3>Change Your Password</h3>
          </div>
        </div>
        <div class="row g-3 align-items-center mb-3">
          <div class="col-auto d-block mx-auto">
            <div class="form-floating w300" :class="{ 'form-data-error': userPasswordErr }">
              <input autofocus type="password" id="userPasswordInput" placeholder="Enter New Password"
                class="form-control w300" autocomplete @keyup="validateUserPassword($event)"
                @change="validateUserPassword($event)" v-model.trim="userPassword" />
              <label for="userPasswordInput">
                <faIcons :icon="['fas', 'key']" /> Enter New Password
              </label>
              <span class="error-feedback w300" v-if="userPasswordErr">
                {{ userPasswordMsg }}
              </span>
            </div>
          </div>
        </div>
        <div class="row d-grid gap-2 w300 col-auto d-block mx-auto m-1">
          <button class="btn btn-outline-primary" @click.prevent="resetUserPassword()" v-if="resetUserPasswordBtn">
            <faIcons :icon="['fas', 'key']" /> Update Password Now
          </button>
        </div>
        <div class="row d-grid gap-2 w300 col-auto d-block mx-auto m-2">
          <div class="alert alert-success" role="alert" v-if="resultSuccess" v-html="resultSuccessMsg"></div>
          <div class="alert alert-danger" role="alert" v-if="resultErr" v-html="resultErrMsg"></div>
        </div>
      </form>
      <div class="row d-grid gap-2 w300 col-auto d-block mx-auto m-1">
        <!-- <hr class="custom-hr-150" /> -->
        <router-link class="btn btn-outline-secondary" :to="{ name: 'Login' }">
          <faIcons :icon="['fas', 'home']" /> Go to Main Page
        </router-link>
      </div>
    </div>
    <div v-else>
      <div role="alert" class="alert alert-danger fade show">
        Server's Connection is down
      </div>
    </div>
    <div role="alert" class="alert alert-danger fade show" v-if="!canUserAccessThisPage">
      {{ pageMessage }}
    </div>
  </div>
</template>

<script>
import axios from "axios";
import { mapState, mapMutations, mapGetters } from "vuex";
export default {
  name: "LoginPage",
  data() {
    return {
      canUserAccessThisPage: true,
      userRequestIdIs: "",
      userIdIs: "",
      userPassword: "",
      userPasswordErr: false,
      userPasswordMsg: "",
      isuserPasswordValidated: false,
      resultSuccess: false,
      resultSuccessMsg: "",
      resultErr: false,
      resultErrMsg: "",
      pageMessage: "",
      image: "",
      is_DB_Connected: true,
      db_connection_msg: "",
      // apiMainUrl: "http://localhost/php/login-system-php-vue-2021/myData.php",
      apiMainUrl: this.$store.getters.MainUrl, //call global variable
      resetUserPasswordBtn: true,
    };
  },
  mounted() {
    this.dbConnection();
    //if user is already logged in, can't visit this page
    let user = localStorage.getItem("current-user-data");
    if (user) {
      this.$router.push({ name: "Home" });
    }
    /*
      now check if password user id and request id already exists,
      if so also validate reset ending time has not passed
    */
    let userRequestId = this.$route.params.rqstId,
      userId = this.$route.params.userId;
    this.canUserChangePassword(userRequestId, userId);
    this.userRequestIdIs = userRequestId;
    this.userIdIs = userId;
  },
  computed: {
    ...mapState([
      "__UserId",
      "__userPassword",
      "__UserRole",
      "__UserName",
      "__MainUrl",
    ]),
    ...mapGetters(["MainUrl"]),
  },
  methods: {
    ...mapMutations(["setUserInfo"]),
    async dbConnection() {
      let res = await axios.post(this.apiMainUrl);
      const resData = res.data;
      this.is_DB_Connected = resData.is_db_connected;
      this.db_connection_msg = resData.connection_msg;
    },
    async canUserChangePassword(rqstId, usrId) {
      let today = new Date();
      function addZero(i) {
        if (i < 10) {
          i = "0" + i;
        }
        return i;
      }
      let time_is =
        today.getFullYear() +
        "-" +
        (today.getMonth() + 1) +
        "-" +
        today.getDate() +
        " " +
        addZero(today.getHours()) +
        ":" +
        addZero(today.getMinutes()) +
        ":" +
        addZero(today.getSeconds());
      let fd = new FormData();
      fd.append("timeIs", time_is);
      fd.append("rqstId", rqstId);
      fd.append("usrId", usrId);
      let res = await axios.post(
        this.apiMainUrl + `?action=canUserChangePassword`,
        fd
      );
      const resData = res.data;
      if (res.status == 200) {
        if (resData.error) {
          this.pageMessage = resData.message;
          this.canUserAccessThisPage = false;
        } else {
          this.pageMessage = "";
          this.canUserAccessThisPage = true;
        }
      }
    },
    validateUserPassword(e) {
      let val = e.target.value;
      this.validateUserPasswordInput(val);
    },
    validateUserPasswordInput(val) {
      console.log("validate user pass: " + val);
      let passIs = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[!@#$%^&*]).{8,}$/;
      if (val == "") {
        this.userPasswordErr = true;
        this.isuserPasswordValidated = false;
        this.userPasswordMsg = "Must Enter User Password ..";
      } else if (val !== "" && !passIs.test(val)) {
        this.userPasswordErr = true;
        this.isuserPasswordValidated = false;
        this.userPasswordMsg =
          "Compose at least 8 Chars of lowercase Letters, upperrcase Letters, Numbers and !@#$%^&*";
      } else {
        this.userPasswordErr = false;
        this.isuserPasswordValidated = true;
        this.userPasswordMsg = "";
        this.resultErrMsg = "";
        this.resultErr = false;
      }
    },
    addZero(i) {
      if (i < 10) {
        i = "0" + i;
      }
      return i;
    },
    async resetUserPassword() {
      this.resultErr = false;
      this.resultErrMsg = "";
      this.resultSuccess = true;
      this.resultSuccessMsg =
        "Please wait while we're updating your password...";
      this.validateUserPasswordInput(this.userPassword);
      if (this.isuserPasswordValidated) {
        this.resetUserPasswordBtn = false;
        //show Result
        let fd = new FormData();
        fd.append("usrId", this.userIdIs);
        fd.append("rqstId", this.userRequestIdIs);
        fd.append("pass", this.userPassword);
        let rst = await axios.post(
          this.apiMainUrl + `?action=changeUserPassword`,
          fd
        );
        console.log(rst);
        const resultData = rst.data;
        if (rst.status == 200) {
          if (resultData.error) {
            this.resultErrMsg = resultData.message;
            this.resultErr = true;
            this.resultSuccessMsg = "";
            this.resultSuccess = false;
            this.resetUserPasswordBtn = true;
          } else {
            this.resetUserPasswordBtn = false;
            //if everything is okay
            this.resultErrMsg = "";
            this.resultErr = false;
            this.resultSuccess = true;
            this.resultSuccessMsg = resultData.message;
            localStorage.setItem(
              "current-user-data",
              JSON.stringify(resultData.current_user_data[0])
            );
            setTimeout(() => {
              this.$router.push({
                name: "Home",
              });
            }, 2000);
          }
        }
      } else {
        this.resetUserPasswordBtn = true;
        this.validateUserPasswordInput(this.userPassword);
        this.resultSuccessMsg = "";
        this.resultSuccess = false;

        this.resultErrMsg = "Must Enter New Password";
        this.resultErr = true;
      }
    },
  },
};
</script>

<style>
.w300 {
  width: 300px !important;
}

.w150 {
  width: 150px !important;
}

.h125 {
  height: 125px !important;
}

.form-data-error {
  color: red;
}

.blueInc {
  color: #0d6efd !important;
}

.form-data-error input,
.form-data-error textarea {
  border-color: red;
}

.error-feedback {
  padding-left: 3px;
  font-size: 0.9rem;
}

.customFileField {
  padding-left: 24px !important;
  padding-top: 38px !important;
  padding-bottom: 10px !important;
  height: 75px !important;
}

.custom-hr-150 {
  box-sizing: content-box;
  height: 0;
  overflow: visible;
  width: 155px !important;
  margin: auto !important;
}
</style>
