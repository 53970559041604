<template>
    <div class="container">
        <Navbar />
        <nav style="--bs-breadcrumb-divider: '>'" aria-label="breadcrumb">
            <ol class="breadcrumb">
                <li class="breadcrumb-item">
                    <router-link tag="li" :to="{ name: 'MyRestaurants' }">
                        <a>My Restaurant(s)</a>
                    </router-link>
                </li>
                <li v-if="this.isRestExists" class="breadcrumb-item active" aria-current="page">
                    {{ this.businessName }}
                </li>
                <li v-if="this.isRestExists" class="breadcrumb-item active" aria-current="page">
                    Bank Settings
                </li>
            </ol>
        </nav>
        <div v-if="this.isRestExists" class="add-new-restaurant-page">
            <h3>
                {{ capitalizeFisrtLetterOfEachWord(businessName) }}
            </h3>
            <p class="text-muted">
                <faIcons :icon="['fas', 'map-pin']" /> {{ businessAddress }}
            </p>
            <div class="row g-3 align-items-center mb-3">
                <div class="col-auto d-block mx-auto">
                    <ul class="list-group list-group-numbered">
                        <li class="list-group-item">Recieve your online order payment.</li>
                        <li class="list-group-item">Create checks to Employees, Vendors, etc.</li>
                    </ul>
                </div>
            </div>
            <div class="row g-3 align-items-center mb-3">
                <div class="col-auto d-block mx-auto">
                    <h4 class="text-center">Update Bank Details</h4>
                    <div class="form-floating" :class="{ 'form-data-error': bankNameErr }">
                        <input type="text" id="bankNameInput" placeholder="Bank Name" @keyup="validateBankName($event)"
                            @change="validateBankName($event)" class="form-control w300" v-model.trim="bankName" />
                        <label for="bankNameInput">
                            <faIcons :icon="['fas', 'university']" /> Bank Name
                        </label>
                        <span class="error-feedback" v-if="bankNameErr">
                            {{ bankNameMsg }}
                        </span>
                    </div>
                </div>
            </div>

            <div class="row g-3 align-items-center mb-3">
                <div class="col-auto d-block mx-auto">
                    <div class="form-floating" :class="{ 'form-data-error': bankNumberErr }">
                        <input type="text" id="bankNumberInput"
                            oninput="this.value = this.value.replace(/[^0-9]/g, '').replace(/(\..*)\./g, '$1');"
                            placeholder="Bank Number" class="form-control w300" @keyup="validateBankNumber($event)"
                            @change="validateBankNumber($event)" v-model.trim="bankNumber" />
                        <label for="bankNumberInput">
                            <faIcons :icon="['fas', 'money-check-alt']" /> Bank Number
                        </label>
                        <span class="error-feedback" v-if="bankNumberErr">
                            {{ bankNumberMsg }}
                        </span>
                    </div>
                </div>
            </div>

            <div class="row g-3 align-items-center mb-3">
                <div class="col-auto d-block mx-auto">
                    <div class="form-floating" :class="{ 'form-data-error': bankRoutingNumberErr }">
                        <input type="text" id="bankRoutingNumberInput"
                            oninput="this.value = this.value.replace(/[^0-9]/g, '').replace(/(\..*)\./g, '$1');"
                            placeholder="Routing Number" @keyup="validateBankRoutingNumber($event)"
                            @change="validateBankRoutingNumber($event)" class="form-control w300"
                            v-model.trim="bankRoutingNumber" />
                        <label for="bankRoutingNumberInput">
                            <faIcons :icon="['fas', 'money-check']" /> Routing Number
                        </label>
                        <span class="error-feedback" v-if="bankRoutingNumberErr">
                            {{ bankRoutingNumberMsg }}
                        </span>
                    </div>
                </div>
            </div>
            <div class="row g-3 align-items-center mb-3">
                <div class="col-auto d-block mx-auto">
                    <div class="form-floating" :class="{ 'form-data-error': bankEINErr }">
                        <input type="text"
                            oninput="this.value = this.value.replace(/[^0-9-]/g, '').replace(/(\..*)\./g, '$1');"
                            id="bankEINInput" placeholder="Employer's Identification Number"
                            @keyup="validatebankEIN($event)" @change="validatebankEIN($event)"
                            class="noResize form-control w300" v-model.trim="bankEIN" />
                        <label for="bankEINInput">
                            <faIcons :icon="['fas', 'key']" /> Employer's Identification
                            Number
                        </label>
                        <p class="text-muted smallerSize">Ex: EIN Format 12-123456</p>
                        <span class="error-feedback" v-if="bankEINErr">
                            {{ bankEINMsg }}
                        </span>
                    </div>
                </div>
            </div>
            <div class="row d-grid gap-2 w300 col-auto d-block mx-auto m-2">
                <button class="btn btn-outline-success" @click.prevent="updateBankDetails()" v-if="signUpBtn">
                    <faIcons :icon="['fas', 'money-check-alt']" /> Update Bank Account
                </button>
            </div>
            <div class="row d-grid gap-2 w300 col-auto d-block mx-auto m-2">
                <div class="alert alert-success" role="alert" v-if="resultSuccess">
                    {{ resultSuccessMsg }}
                </div>
                <div class="alert alert-danger" role="alert" v-if="resultErr">
                    {{ resultErrMsg }}
                </div>
            </div>
            <div class="row d-grid gap-2 w300 col-auto d-block mx-auto m-2">
                <button class="btn btn-outline-primary" @click.prevent="this.$router.go(-1)">
                    <faIcons :icon="['fas', 'undo']" /> Go Back
                </button>
            </div>
        </div>
        <div v-else>
            <!-- Show error if no restaurant matches this Criteria -->
            <div class="alert alert-dark" role="alert">
                <p>
                    <faIcons :icon="['fas', 'store']" /> No restaurant found. Make sure you click in the right
                    restaurant name.
                </p>
            </div>
            <router-link tag="li" :to="{ name: 'MyRestaurants' }">
                <a class="btn btn-success">
                    <faIcons :icon="['fas', 'store']" /> My restaurant(s)
                </a>
            </router-link>
        </div>
    </div>
</template>

<script>
import axios from "axios";
import { mapState, mapMutations, mapGetters } from "vuex";
import Navbar from "@/components/Header/Navbar.vue";
export default {
    name: "CurrentRestaurantBankDetails",
    components: {
        Navbar,
    },
    data() {
        return {
            user: {
                uId: "",
                userId: "",
                uName: "",
                uEmail: "",
                uPassword: "",
                uMemberSince: "",
                uAddress: "",
                uPhone: "",
                uRole: "",
                uRoleIs: "",
            },
            restAcceptingOrderStatus: "",
            //cat
            catPrice: "",
            catPriceErr: false,
            catPriceMsg: "",
            iscatPriceValidated: false,
            //is my restaurant accepting online orders
            isMenuActive: "",
            isMenuActiveErr: false,
            isMenuActiveSuccess: false,
            isMenuActiveMsg: "Restaurant is now accepting online orders",
            isMenuActiveValidated: false,
            //Restaurant Info from URL
            restId: this.$route.params.restId,
            restNum: this.$route.params.id,
            //phone
            bankRoutingNumber: "",
            bankRoutingNumberErr: false,
            bankRoutingNumberMsg: "",
            isbankRoutingNumberValidated: false,
            //name
            bankNumber: "",
            bankNumberErr: false,
            bankNumberMsg: "",
            isbankNumberValidated: false,
            //email
            bankName: "",
            bankNameErr: false,
            bankNameMsg: "",
            isbankNameValidated: false,
            //address
            bankEIN: "",
            bankEINErr: false,
            bankEINMsg: "",
            isbankEINValidated: false,
            //show result
            resultSuccess: false,
            resultSuccessMsg: "",
            resultErr: false,
            resultErrMsg: "",
            //show onlnie hours result
            onlineHoursResultSuccess: false,
            onlineHoursResultSuccessMsg: "",
            onlineHoursResultErr: false,
            onlineHoursResultErrMsg: "",
            image: "",
            is_DB_Connected: "",
            db_connection_msg: "",
            // apiMainUrl: "http://localhost/php/login-system-php-vue-2021/myData.php",
            apiMainUrl: this.$store.getters.MainUrl, //call global variable
            signUpBtn: true,
            acceptOrdersBtn: true,
            onlineHoursBtn: true,
            isRestExists: true,
            businessPhone: "",
            businessName: "",
            businessAddress: "",
        };
    },
    mounted() {
        let user = localStorage.getItem("current-user-data");
        let currentRestaurants = localStorage.getItem("current_restaurants_data");
        let restaurant_bank_details = localStorage.getItem(
            "restaurant_bank_details"
        );
        let currentRestaurant = JSON.parse(currentRestaurants).filter(
            (restInfo) =>
                restInfo.id == this.restNum && restInfo.rest_id == this.restId
        );
        let currentRestaurantBankDetails = JSON.parse(
            restaurant_bank_details
        ).filter((restInfo) => restInfo.rest_id == this.restId);
        console.table(JSON.parse(restaurant_bank_details));
        console.table(currentRestaurantBankDetails);
        console.log("Length of rest found: ", currentRestaurantBankDetails.length);
        if (currentRestaurantBankDetails.length > 0) {
            // console.table(currentRestaurantBankDetails.routing_number);
            //set rest info
            this.businessPhone = currentRestaurant[0].rest_phone;
            this.businessName = currentRestaurant[0].rest_name;
            this.businessAddress = currentRestaurant[0].rest_address;
            this.isRestExists = true;
            this.bankRoutingNumber = currentRestaurantBankDetails[0].routing_number;
            this.bankName = this.capitalizeFisrtLetterOfEachWord(
                currentRestaurantBankDetails[0].bank_name
            );
            this.bankEIN = currentRestaurantBankDetails[0].ein_number;
            this.bankNumber = currentRestaurantBankDetails[0].bank_number;
            //get users info
            this.user.uId = JSON.parse(user).id;
            this.user.userId = JSON.parse(user).user_id;
            this.user.uName = JSON.parse(user).name;
            this.user.uEmail = JSON.parse(user).email;
            this.user.uPassword = JSON.parse(user).password;
            this.user.uMemberSince = JSON.parse(user).member_since;
            this.user.uAddress = JSON.parse(user).full_address;
            this.user.uPhone = JSON.parse(user).phone_number;
            this.user.uRole = JSON.parse(user).role;
            //if role isn't manager or admin, redirect
            if (currentRestaurant[0].active == 0) {
                //if restaurant isn't active
                this.$router.push({ name: "MyRestaurants" });
            } else if (
                JSON.parse(user).role !== "mngr" &&
                JSON.parse(user).role !== "admn"
            ) {
                this.$router.push({ name: "Login" });
            }
        } else {
            this.$router.push({ name: "MyRestaurants" });
        }
    },
    computed: {
        ...mapState([
            "__RestaurantId",
            "__RestaurantEmail",
            "__RestaurantRole",
            "__RestaurantName",
            "__MainUrl",
        ]),
        ...mapGetters(["MainUrl"]),
    },
    methods: {
        ...mapMutations(["setRestaurantInfo"]),
        capitalizeFisrtLetterOfEachWord(text) {
            return text
                .toLowerCase()
                .split(" ")
                .map((s) => s.charAt(0).toUpperCase() + s.substring(1))
                .join(" ");
        },
        formatPhoneNumber(number) {
            //remove . + -  from any number
            let cleaned = ("" + number).replace(/\D/g, "");
            let match = "";
            if (number.length == 10) {
                match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
            } else if (number.length == 9) {
                match = cleaned.match(/^(\d{3})(\d{3})(\d{3})$/);
            } else if (number.length == 11) {
                match = cleaned.match(/^(\d{3})(\d{4})(\d{4})$/);
            } else if (number.length == 8) {
                match = cleaned.match(/^(\d{2})(\d{3})(\d{3})$/);
            }
            if (match) {
                return "(" + match[1] + ") " + match[2] + "-" + match[3];
            }
            return null;
        },
        validateBankNumber(e) {
            let val = e.target.value;
            this.validateBankNumberInput(val);
        },
        validateBankNumberInput(val) {
            if (val == "") {
                this.bankNumberErr = true;
                this.bankNumberValidated = false;
                this.bankNumberMsg = "Enter bank number!";
            } else if (val !== "" && val.length < 8) {
                this.bankNumberErr = true;
                this.isbankNumberValidated = false;
                this.bankNumberMsg = "Must be at least 8 Digits";
            } else {
                this.bankNumberErr = false;
                this.isbankNumberValidated = true;
                this.bankNumberMsg = "";
            }
        },
        validateBankRoutingNumber(e) {
            let val = e.target.value;
            this.validateBankRoutingNumberInput(val);
        },
        validateBankRoutingNumberInput(val) {
            if (val == "") {
                this.bankRoutingNumberErr = true;
                this.bankRoutingNumberValidated = false;
                this.bankRoutingNumberMsg = "Enter bank routing number!";
            } else if (val !== "" && val.length < 8) {
                this.bankRoutingNumberErr = true;
                this.isbankRoutingNumberValidated = false;
                this.bankRoutingNumberMsg = "Must be at least 8 Digits";
            } else {
                this.bankRoutingNumberErr = false;
                this.isbankRoutingNumberValidated = true;
                this.bankRoutingNumberMsg = "";
            }
        },
        validateBankName(e) {
            let val = e.target.value;
            this.validateBankNameInput(val);
        },
        validateBankNameInput(val) {
            if (val == "") {
                this.bankNameErr = true;
                this.bankNameValidated = false;
                this.bankNameMsg = "Enter bank name!";
            } else if (val !== "" && val.length < 2) {
                this.bankNameErr = true;
                this.isbankNameValidated = false;
                this.bankNameMsg = "Enter real bank name";
            } else {
                this.bankNameErr = false;
                this.isbankNameValidated = true;
                this.bankNameMsg = "";
            }
        },
        validatebankEIN(e) {
            let val = e.target.value;
            this.validatebankEINInput(val);
        },
        validatebankEINInput(val) {
            if (val == "") {
                this.bankEINErr = true;
                this.bankEINValidated = false;
                this.bankEINMsg = "Enter EIN as nn-nnnnnnn!";
            } else if (val !== "" && val.length < 7) {
                this.bankEINErr = true;
                this.isbankEINValidated = false;
                this.bankEINMsg = "Enter real EIN as nn-nnnnnnn";
            } else if (val !== "" && /[,-]/.test(val) == false) {
                this.bankEINErr = true;
                this.isbankEINValidated = false;
                this.bankEINMsg = "EIN must contain - as in nn-nnnnnnn";
            } else {
                this.bankEINErr = false;
                this.isbankEINValidated = true;
                this.bankEINMsg = "";
            }
        },
        async updateBankDetails() {
            this.resultErrMsg = "";
            this.resultErr = false;
            this.resultSuccess = true;
            this.resultSuccessMsg = "Wait while we're updating your bank details...";
            this.validateBankNumberInput(this.bankNumber);
            this.validateBankRoutingNumberInput(this.bankRoutingNumber);
            this.validateBankNameInput(this.bankName);
            this.validatebankEINInput(this.bankEIN);
            if (
                this.isbankEINValidated &&
                this.isbankNameValidated &&
                this.isbankNumberValidated &&
                this.isbankRoutingNumberValidated
            ) {
                this.signUpBtn = false;
                //show Result
                let fd = new FormData();
                fd.append("bankNumber", this.bankNumber);
                fd.append("bankRoutingNumber", this.bankRoutingNumber);
                fd.append("bankName", this.bankName);
                fd.append("bankEIN", this.bankEIN);
                fd.append("userId", this.user.userId);
                fd.append("restId", this.restId);
                let rst = await axios.post(
                    this.apiMainUrl + `?action=updateBankDetails`,
                    fd
                );
                console.log(rst);
                const resultData = rst.data;
                if (rst.status == 200) {
                    if (resultData.error) {
                        console.warn("Connection Error Happened on Updating Bank Details");
                        this.resultErrMsg = resultData.message;
                        this.resultErr = true;
                        this.resultSuccessMsg = "";
                        this.resultSuccess = false;
                        this.signUpBtn = true;
                    } else {
                        this.signUpBtn = false;
                        console.log("Good Job, you have updated your bank detailes..");
                        //if everything is okay
                        this.resultErrMsg = "";
                        this.resultErr = false;
                        this.resultSuccess = true;
                        this.resultSuccessMsg = resultData.message;
                        //set restaurant to local host: current_restaurants_data
                        localStorage.setItem(
                            "restaurant_bank_details",
                            JSON.stringify(resultData.restaurant_bank_details)
                        );
                        //redirect only if customer
                        setTimeout(() => {
                            //   this.$router.push({ name: "MyRestaurants" });
                            console.log("Bank Name is updated: " + this.bankName);
                        }, 2000);
                    }
                }
            } else {
                this.signUpBtn = true;
                this.validateBankNumberInput(this.bankNumber);
                this.validateBankRoutingNumberInput(this.bankRoutingNumber);
                this.validateBankNameInput(this.bankName);
                this.validatebankEINInput(this.bankEIN);
                this.resultSuccessMsg = "";
                this.resultSuccess = false;

                this.resultErrMsg = "Must fill in all information";
                this.resultErr = true;
            }
        },
    },
};
</script>
<style lang="scss" scoped>
$navColor: #3f51b6;

.form-data-success {
    color: darkgreen;
}

.smallerSize {
    font-size: 0.75em;
}

nav a {
    text-decoration: none;
    color: $navColor;
    font-weight: bold;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

/* Firefox */
input[type="number"] {
    -moz-appearance: textfield;
}

.w100 {
    width: 100px;
}

.bgBlue {
    background-color: #0d6efd;
    color: white;
    padding: 10px;
    border-radius: 7px;
}

.addBorder {
    border-bottom: 1px solid #ced4da;
}

.p4 {
    padding: 4px;
}
</style>
