<template>
  <div class="text-center account-hold-page">
    <br />
    <img
      src="@/assets/AccountOnHold.jpg"
      class="rounded mx-auto img-fluid d-block h400"
    />
    <br />
    <div class="centerItem">
      <div class="alert alert-success" role="alert">
        <p>We are reviewing your account {{ userName }}</p>
        <p>Contact us to activate your account!</p>
      </div>
      <br />
    </div>
    <div class="row d-grid gap-2 w300 col-auto d-block mx-auto mb-3">
      <router-link
        class="btn btn-outline-secondary"
        :to="{ name: 'ContactUs' }"
      >
        <faIcons :icon="['fas', 'envelope-open-text']" /> Contact Us
      </router-link>
      &nbsp;
      <router-link class="btn btn-outline-secondary" :to="{ name: 'Login' }">
        Main Page
      </router-link>
    </div>
  </div>
</template>

<script>
export default {
  name: "AccountOnHold",
  // props: ["uName"],
  data() {
    return {
      userName: this.$route.params.uName,
    };
  },
};
</script>

<style>
.centerItem {
  /* margin: auto;
  width: 50%;
  padding: 10px; */
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>
