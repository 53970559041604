<template>
  <div class="change-user-pass">
    <Navbar />
    <UserPasswordUpdatePage />
  </div>
</template>

<script>
import Navbar from "@/components/Header/Navbar.vue";
import UserPasswordUpdatePage from "@/components/Account/UserPasswordUpdatePage.vue";
export default {
  name: "UserPasswordUpdate",
  components: {
    UserPasswordUpdatePage,
    Navbar,
  },
};
</script>
