<template>
  <div class="container">
    <Navbar />
    <nav style="--bs-breadcrumb-divider: '>'" aria-label="breadcrumb">
      <ol class="breadcrumb">
        <li class="breadcrumb-item">
          <router-link tag="li" :to="{ name: 'Home' }">
            <a>Home</a>
          </router-link>
        </li>
        <li class="breadcrumb-item">
          <router-link tag="li" :to="{
            name: 'AccessUsersToOnlineOrders',
            params: { restId: restId, id: restNum },
          }">
            <a>{{ capitalizeFisrtLetterOfEachWord(userName) }}</a>
          </router-link>
        </li>
        <li class="breadcrumb-item active" aria-current="page">
          Configure Payment
        </li>
      </ol>
    </nav>
    <div class="add-new-restaurant-page">
      <!-- Add new restaurant -->
      <p>{{ userAddress }}</p>
      <form method="post" action="#" enctype="multipart/form-data">
        <div class="row g-3 align-items-center mb-3">
          <div class="col-auto d-block mx-auto">
            <h3>Configure Payment</h3>
          </div>
        </div>

        <div class="row g-3 align-items-center mb-3">
          <div class="col-auto d-block mx-auto">
            <div class="form-floating" :class="{ 'form-data-error': userNameErr }">
              <input type="text" id="userNameInput" placeholder="Secret Key" class="form-control w300 h100"
                @input="validateRestaurantPublishKeyFunction($event)"
                @change="validateRestaurantPublishKeyFunction($event)" v-model.trim="restSecretKey" />
              <label for="userNameInput">
                <faIcons :icon="['fas', 'globe']" /> Secret Key
              </label>
              <span class="error-feedback" v-if="userNameErr">
                <span>{{ userNameMsg }}</span>
              </span>
            </div>
          </div>
        </div>
        <div class="row g-3 align-items-center mb-3">
          <div class="col-auto d-block mx-auto">
            <div class="form-floating" :class="{ 'form-data-error': userAddressErr }">
              <input type="text" id="userAddressInput" placeholder="Publish Key"
                @keyup="validateRestaurantSecretKeyFunction($event)"
                @change="validateRestaurantSecretKeyFunction($event)" class="form-control w300 h100"
                v-model.trim="restPublishKey" />
              <label for="userAddressInput">
                <faIcons :icon="['fas', 'address-book']" /> Publish Key
              </label>
              <span class="error-feedback" v-if="userAddressErr">
                {{ userAddressMsg }}
              </span>
            </div>
          </div>
        </div>
        <div class="row d-grid gap-2 w300 col-auto d-block mx-auto mb-3">
          <button class="btn btn-outline-success" @click.prevent="configureStripePayment()" v-if="signUpBtn">
            <faIcons :icon="['fas', 'file-invoice-dollar']" /> Update Payment
          </button>
        </div>
        <div class="row d-grid gap-2 w300 col-auto d-block mx-auto mb-3">
          <div class="alert alert-success" role="alert" v-if="resultSuccess">
            {{ resultSuccessMsg }}
          </div>
          <div class="alert alert-danger" role="alert" v-if="resultErr">
            {{ resultErrMsg }}
          </div>
          <small>
            <faIcons :icon="['fas', 'clock']" /> {{ new Date(lastUpdate).toLocaleDateString() }}
          </small>
        </div>
        <div class="row d-grid gap-2 w300 col-auto d-block mx-auto mb-3">
          <router-link class="btn btn-outline-primary" :to="{ name: 'MyRestaurants' }">
            <faIcons :icon="['fas', 'undo']" /> View my restaurant(s)
          </router-link>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import { mapState, mapMutations, mapGetters } from "vuex";
import Navbar from "@/components/Header/Navbar.vue";
export default {
  name: "ConfigureStripePayment",
  components: {
    Navbar,
  },
  data() {
    return {
      user: {
        uId: "",
        userId: "",
        uName: "",
        uEmail: "",
        uPassword: "",
        uMemberSince: "",
        uAddress: "",
        uPhone: "",
        uRole: "",
        uRoleIs: "",
      },
      lastUpdate: "",
      userAccessCode: "",
      restId: this.$route.params.restId,
      restNum: this.$route.params.id,
      currentRestaurantsArr: [],
      //address
      userAddress: "",
      userAddressErr: false,
      userAddressMsg: "",
      isuserAddressValidated: false,
      //phone
      userPhone: "",
      userPhoneErr: false,
      userPhoneMsg: "",
      isuserPhoneValidated: false,
      //name
      userName: "",
      userNameErr: false,
      userNameMsg: "",
      isuserNameValidated: false,

      resultSuccess: false,
      resultSuccessMsg: "",
      resultErr: false,
      resultErrMsg: "",
      image: "",
      is_DB_Connected: "",
      db_connection_msg: "",
      // apiMainUrl: "http://localhost/php/login-system-php-vue-2021/myData.php",
      apiMainUrl: this.$store.getters.MainUrl, //call global variable
      signUpBtn: true,
      restPublishKey: "",
      restSecretKey: "",
    };
  },
  mounted() {
    let user = localStorage.getItem("current-user-data");
    if (user) {
      this.user.uId = JSON.parse(user).id;
      this.user.userId = JSON.parse(user).user_id;
      this.user.uName = JSON.parse(user).name;
      this.user.uEmail = JSON.parse(user).email;
      this.user.uPassword = JSON.parse(user).password;
      this.user.uMemberSince = JSON.parse(user).member_since;
      this.user.uAddress = JSON.parse(user).full_address;
      this.user.uPhone = JSON.parse(user).phone_number;
      this.user.uRole = JSON.parse(user).role;
      //if role isnt manager or admin, redirect
      if (JSON.parse(user).role !== "admn") {
        this.$router.push({ name: "Login" });
      } else {
        let currentRestaurants = localStorage.getItem(
          "current_restaurants_data"
        );
        let currentRestaurant = JSON.parse(currentRestaurants).filter(
          (restInfo) =>
            restInfo.id == this.restNum && restInfo.rest_id == this.restId
        );
        let currentRestaurantPaymentDatas = localStorage.getItem(
          "rest_stripe_payment"
        );
        let currentRestaurantPaymentData = JSON.parse(
          currentRestaurantPaymentDatas
        ).filter((restInfo) => restInfo.rest_id == this.restId);
        this.restPublishKey = currentRestaurantPaymentData[0].publish_key;
        this.restSecretKey = currentRestaurantPaymentData[0].stripe_secret_key;
        this.lastUpdate = currentRestaurantPaymentData[0].added_on;
        // console.log(currentRestaurants);
        this.currentRestaurantsArr = JSON.parse(currentRestaurants);
        this.userPhone = currentRestaurant[0].rest_phone;
        this.userName = currentRestaurant[0].rest_name;
        this.userAddress = currentRestaurant[0].rest_address;
      }
    } else {
      this.$router.push({ name: "Login" });
    }
  },
  computed: {
    ...mapState([
      "__RestaurantId",
      "__RestaurantEmail",
      "__RestaurantRole",
      "__RestaurantName",
      "__MainUrl",
    ]),
    ...mapGetters(["MainUrl"]),
  },
  methods: {
    ...mapMutations(["setRestaurantInfo"]),
    capitalizeFisrtLetterOfEachWord(text) {
      return text
        .toLowerCase()
        .split(" ")
        .map((s) => s.charAt(0).toUpperCase() + s.substring(1))
        .join(" ");
    },
    validateRestaurantPublishKeyFunction(e) {
      let val = e.target.value;
      this.validateRestaurantPublishKey(val);
    },
    validateRestaurantPublishKey(val) {
      if (val == "") {
        this.userNameErr = true;
        this.isuserNameValidated = false;
        this.userNameMsg = "Must enter secret key code";
      } else {
        this.userNameErr = false;
        this.isuserNameValidated = true;
        this.userNameMsg = "";
      }
    },
    validateRestaurantSecretKeyFunction(e) {
      let val = e.target.value;
      this.validateRestaurantSecretKey(val);
    },
    validateRestaurantSecretKey(val) {
      if (val != "") {
        this.userAddressErr = false;
        this.isuserAddressValidated = true;
        this.userAddressMsg = "";
      } else {
        this.userAddressErr = true;
        this.isuserAddressValidated = false;
        this.userAddressMsg = "Must enter public key code";
      }
    },
    async configureStripePayment() {
      this.resultErr = false;
      this.resultErrMsg = "";
      this.resultSuccess = true;
      this.resultSuccessMsg =
        "Please wait while we're configuring stripe data...";
      this.validateRestaurantSecretKey(this.restSecretKey);
      this.validateRestaurantPublishKey(this.restPublishKey);
      if (this.isuserNameValidated && this.isuserAddressValidated) {
        this.signUpBtn = false;
        //show Result
        let fd = new FormData();
        fd.append("userId", this.user.userId);
        fd.append("restId", this.restId);
        fd.append("publishKey", this.restPublishKey);
        fd.append("secretKey", this.restSecretKey);
        let rst = await axios.post(
          this.apiMainUrl + `?action=configureStripePayment`,
          fd
        );
        console.log(rst);
        const resultData = rst.data;
        if (rst.status == 200) {
          if (resultData.error) {
            console.warn("Error configuring current restaurant's payment");
            this.resultErrMsg = resultData.message;
            this.resultErr = true;
            this.resultSuccessMsg = "";
            this.resultSuccess = false;
            this.signUpBtn = true;
          } else {
            this.signUpBtn = false;
            console.log("You have updated current restaurant's payment");
            //if everything is okay
            this.resultErrMsg = "";
            this.resultErr = false;
            this.resultSuccess = true;
            //redirect only if customer
            this.resultSuccessMsg = resultData.message;
            localStorage.setItem(
              "rest_stripe_payment",
              JSON.stringify(resultData.rest_stripe_payment)
            );
            // setTimeout(() => {
            //   this.$router.push({
            //     name: "AccessUsersToOnlineOrders",
            //     params: {
            //       restId: this.restId,
            //       id: this.restNum,
            //     },
            //   });
            // }, 2000);
          }
        }
      } else {
        this.signUpBtn = true;
        this.validateRestaurantSecretKey(this.restSecretKey);
        this.validateRestaurantPublishKey(this.restPublishKey);
        this.resultSuccessMsg = "";
        this.resultSuccess = false;

        this.resultErrMsg = "Must enter all required information";
        this.resultErr = true;
      }
    },
  },
};
</script>
<style lang="scss" scoped>
$navColor: #3f51b6;

nav a {
  text-decoration: none;
  color: $navColor;
  font-weight: bold;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}
</style>
