<template>
  <div class="view-account-info">
    <div class="row g-3 align-items-center mb-3">
      <div class="col-auto d-block mx-auto text-center">
        <h3>Account Information</h3>
        <p class="fb">Access Code: {{ user.uAccessId }}</p>
        <p v-if="user.uRole == 'admn' || user.uRole == 'mngr'" class="text-muted">
          Use this code to view & manage your orders.<br />
          Share with manager to manage thier business.<br />
          Never share your access code.
        </p>
        <p v-else class="text-muted">Share with your manager to add you</p>
      </div>
    </div>
    <div class="row g-3 align-items-center mb-3">
      <div class="col-auto d-block mx-auto">
        <div class="form-floating">
          <input type="email" id="userEmailInput" placeholder="User Email" @keyup="validateUserEmail($event)"
            @change="validateUserEmail($event)" class="form-control w300" v-model.trim="user.uEmail" disabled />
          <label for="userEmailInput">
            <faIcons :icon="['fas', 'envelope']" /> User Email
          </label>
        </div>
      </div>
    </div>

    <div class="row g-3 align-items-center mb-3">
      <div class="col-auto d-block mx-auto">
        <div class="form-floating">
          <input type="text" id="userNameInput" placeholder="User Name" class="form-control w300"
            @keyup="validateUserName($event)" @change="validateUserName($event)" v-model.trim="user.uName" disabled />
          <label for="userNameInput">
            <faIcons :icon="['fas', 'user-circle']" /> User Name
          </label>
        </div>
      </div>
    </div>

    <div class="row g-3 align-items-center mb-3">
      <div class="col-auto d-block mx-auto">
        <div class="form-floating">
          <input type="number" min="0" id="userPhoneInput" placeholder="User Phone" @keyup="validateUserPhone($event)"
            @change="validateUserPhone($event)" class="form-control w300" v-model.trim="user.uPhone" disabled />
          <label for="userPhoneInput">
            <faIcons :icon="['fas', 'phone']" /> User Phone
          </label>
        </div>
      </div>
    </div>
    <div class="row g-3 align-items-center mb-3">
      <div class="col-auto d-block mx-auto">
        <div class="form-floating">
          <input type="text" id="userRoleInput" placeholder="Sign Up as" class="form-control w300"
            @change="validateUserRole($event)" aria-label="userRoleInput" v-model.trim="user.uRoleIs" disabled />
          <label for="userRoleInput">
            <faIcons :icon="['fas', 'user-tag']" /> Signed Up as
          </label>
        </div>
      </div>
    </div>
    <div class="row g-3 align-items-center mb-3">
      <div class="col-auto d-block mx-auto">
        <div class="form-floating">
          <textarea id="userAddressInput" placeholder="User Full Address" @input="validateUserAddress($event)"
            class="noResize form-control w300 h100" v-model.trim="user.uAddress" disabled />
          <label for="userAddressInput">
            <faIcons :icon="['fas', 'address-book']" /> User Address
          </label>
        </div>
      </div>
    </div>
    <div class="row d-grid gap-2 w300 col-auto d-block mx-auto mb-3">
      <button class="btn btn-outline-success" @click.prevent="updateAccountInfo()">
        <faIcons :icon="['fas', 'marker']" /> Update Account Information
      </button>
    </div>
    <div class="row d-grid gap-2 w300 col-auto d-block mx-auto mb-3">
      <button class="btn btn-outline-success" @click.prevent="updateUserPassword()">
        <faIcons :icon="['fas', 'key']" /> Change Password
      </button>
    </div>
  </div>
</template>

<script>
export default {
  name: "AccountPage",
  //   props: ["user"],
  data() {
    return {
      signUpBtn: true,
      user: {
        uId: "",
        uUserId: "",
        uAccessId: "",
        uName: "",
        uEmail: "",
        uPassword: "",
        uMemberSince: "",
        uAddress: "",
        uPhone: "",
        uRole: "",
        uRoleIs: "",
      },
    };
  },
  mounted() {
    let user = localStorage.getItem("current-user-data");
    if (user) {
      this.user.uId = JSON.parse(user).id;
      this.user.uUserId = JSON.parse(user).user_id;
      this.user.uAccessId = JSON.parse(user).access_id;
      this.user.uName = JSON.parse(user).name;
      this.user.uEmail = JSON.parse(user).email;
      this.user.uPassword = JSON.parse(user).password;
      this.user.uMemberSince = JSON.parse(user).member_since;
      this.user.uAddress = JSON.parse(user).full_address;
      this.user.uPhone = JSON.parse(user).phone_number;
      this.user.uRole = JSON.parse(user).role;
      if (JSON.parse(user).role == "mngr") {
        this.user.uRoleIs = "Owner";
      } else if (JSON.parse(user).role == "empl") {
        this.user.uRoleIs = "Employee";
      } else if (JSON.parse(user).role == "cstmr") {
        this.user.uRoleIs = "Customer";
      } else if (JSON.parse(user).role == "cmngr") {
        this.user.uRoleIs = "Manager";
      } else if (JSON.parse(user).role == "admn") {
        this.user.uRoleIs = "IT/Admin";
      }
    } else {
      this.$router.push({ name: "Login" });
    }
  },
  methods: {
    updateAccountInfo() {
      this.$router.push({ name: "AccountUpdate" });
    },
    updateUserPassword() {
      this.$router.push({ name: "UserPasswordUpdate" });
    },
  },
};
</script>

<style scoped>
.form-control:disabled,
.form-control[readonly] {
  background-color: #ffffff;
}

.noResize {
  resize: none;
}
</style>
