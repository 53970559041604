<template>
  <div class="container">
    <Navbar />
    <nav style="--bs-breadcrumb-divider: '>'" aria-label="breadcrumb">
      <ol class="breadcrumb">
        <li class="breadcrumb-item">
          <router-link tag="li" :to="{ name: 'MyRestaurants' }">
            <a>My Restaurant(s)</a>
          </router-link>
        </li>
        <li
          v-if="this.isRestExists"
          class="breadcrumb-item active"
          aria-current="page"
        >
          {{ this.businessName }}
        </li>
        <li
          v-if="this.isRestExists"
          class="breadcrumb-item active"
          aria-current="page"
        >
          Checks
        </li>
      </ol>
    </nav>
    <div v-if="this.isRestExists" class="add-new-restaurant-page">
      <button
        class="btn btn-outline-secondary m-2"
        v-if="currentRestaurantChecks.length > 0"
        @click="printSection('printPage')"
      >
        <faIcons :icon="['fas', 'print']" /> Report
      </button>
      <router-link
        tag="li"
        :to="{ name: 'CreateCheck', params: { id: restNum, restId: restId } }"
      >
        <button class="btn btn-outline-secondary m-2" v-if="isBankSetup">
          <faIcons :icon="['fas', 'money-check']" /> Create a check
        </button>
      </router-link>
      <div v-if="resultErr" class="alert alert-danger m-2">
        {{ resultErrMsg }}
      </div>
      <div class="row g-3 align-items-center">
        <div
          class="col-auto d-block mx-auto col-12 col-sm-12 main-order-select-date-custom"
        >
          <div class="form-check form-check-inline mt-3">
            <div class="input-group">
              <div class="form-floating">
                <select
                  placeholder="Select Checks Year"
                  class="form-select w300"
                  v-model.trim="currentYear"
                  aria-label="reasonToCancelThisOrder"
                >
                  <option value="2022" :selected="currentYear == 2022">
                    Year of 2022
                  </option>
                  <option value="2023" :selected="currentYear == 2023">
                    Year of 2023
                  </option>
                  <option value="2024" :selected="currentYear == 2024">
                    Year of 2024
                  </option>
                  <option value="2025" :selected="currentYear == 2025">
                    Year of 2025
                  </option>
                  <option value="2026" :selected="currentYear == 2026">
                    Year of 2026
                  </option>
                  <option value="2027" :selected="currentYear == 2027">
                    Year of 2027
                  </option>
                  <option value="2028" :selected="currentYear == 2028">
                    Year of 2028
                  </option>
                  <option value="2029" :selected="currentYear == 2029">
                    Year of 2029
                  </option>
                  <option value="2030" :selected="currentYear == 2030">
                    Year of 2030
                  </option>
                  <option value="2031" :selected="currentYear == 2031">
                    Year of 2031
                  </option>
                  <option value="2032" :selected="currentYear == 2032">
                    Year of 2032
                  </option>
                  <option value="2033" :selected="currentYear == 2033">
                    Year of 2033
                  </option>
                  <option value="2034" :selected="currentYear == 2034">
                    Year of 2034
                  </option>
                  <option value="2035" :selected="currentYear == 2035">
                    Year of 2035
                  </option>
                </select>
                <label for="reasonToCancelThisOrder">
                  <faIcons :icon="['fas', 'calendar']" />
                  Select Year
                </label>
              </div>
              <div class="form-floating w300 m-2">
                <button
                  class="btn btn-primary mb-2"
                  :class="showViewTimeHours ? '' : 'disabled'"
                  @click="getCurrentRestaurantChecks()"
                >
                  <faIcons
                    class="d-print-none pointerHand"
                    :icon="['fas', 'money-check']"
                  />
                  View Checks
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div id="printPage">
        <h3>
          {{ capitalizeFisrtLetterOfEachWord(businessName) }}
        </h3>
        <p class="text-muted">
          <faIcons :icon="['fas', 'map-pin']" />
          {{ capitalizeFisrtLetterOfEachWord(businessAddress) }}
        </p>
        <div v-if="!isBankSetup" class="alert alert-warning m-2">
          <p>In order to create checks, you must set up your bank details!</p>
          <router-link
            tag="li"
            :to="{
              name: 'CurrentRestaurantBankDetails',
              params: { id: restNum, restId: restId },
            }"
          >
            <button class="btn btn-primary" v-if="!isBankSetup">
              <faIcons :icon="['fas', 'money-check']" /> Setup Payment Details
            </button>
          </router-link>
        </div>
        <div v-if="searchingForData" class="alert alert-info m-2">
          <p>
            Please wait while connecting to your data to fetch your checks...
          </p>
        </div>
        <div v-else>
          <h4 class="text-center text-muted">
            Current {{ currentYear }} Checks ({{
              currentRestaurantChecks.length
            }})
          </h4>
          <div v-if="isBankSetup" class="table-responsive">
            <table class="table">
              <thead class="table-success">
                <tr>
                  <th class="header" scope="col">No.</th>
                  <th class="header" scope="col">Payee Name</th>
                  <th class="header" scope="col">Amount</th>
                  <th class="header" scope="col">Memo</th>
                  <th class="header" scope="col">On</th>
                  <th class="header d-print-none" scope="col">Actions</th>
                </tr>
              </thead>
              <tbody v-if="currentRestaurantChecks.length > 0">
                <tr
                  v-for="(each, ii) in currentRestaurantChecks"
                  :key="ii + '-' + each"
                >
                  <td>{{ each.check_num }}</td>
                  <td>{{ each.pay_to.toUpperCase() }}</td>
                  <td>{{ number_format(each.amount) }}</td>
                  <td>{{ capitalizeFisrtLetterOfEachWord(each.memo) }}</td>
                  <td>{{ formatDateFormulas(each.added_on) }}</td>
                  <td class="d-print-none displayFlex">
                    <span v-if="each.active == 1" class="displayFlex">
                      <router-link
                        tag="li"
                        :to="{
                          name: 'UpdateCurrentCheck',
                          params: {
                            id: restNum,
                            restId: restId,
                            checkId: each.check_id,
                          },
                        }"
                      >
                        <button
                          class="btn btn-outline-success"
                          title="Update Check"
                        >
                          <faIcons :icon="['fas', 'edit']" />
                        </button> </router-link
                      >&nbsp;
                      <router-link
                        tag="li"
                        :to="{
                          name: 'DeleteCurrentCheck',
                          params: {
                            id: restNum,
                            restId: restId,
                            checkId: each.check_id,
                          },
                        }"
                      >
                        <button
                          class="btn btn-outline-danger"
                          title="Delete Check"
                        >
                          <faIcons :icon="['fas', 'trash']" />
                        </button> </router-link
                      >&nbsp;
                      <router-link
                        tag="li"
                        target="_blank"
                        class="d-block d-xs-none"
                        :to="{
                          name: 'ViewAndPrintEachCheck',
                          params: {
                            id: restNum,
                            restId: restId,
                            checkId: each.check_id,
                          },
                        }"
                      >
                        <a
                          target="_blank"
                          class="btn btn-outline-primary"
                          title="View Check"
                        >
                          <faIcons :icon="['fas', 'print']" />
                        </a>
                      </router-link>
                    </span>
                    <span v-else class="displayFlex">
                      <router-link
                        tag="li"
                        :to="{
                          name: 'ReactivateCurrentCheck',
                          params: {
                            id: restNum,
                            restId: restId,
                            checkId: each.check_id,
                          },
                        }"
                      >
                        <button
                          class="btn btn-outline-warning"
                          title="Re Activate Check"
                        >
                          <faIcons :icon="['fas', 'retweet']" />
                        </button> </router-link
                      >&nbsp;
                    </span>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
    <div v-else>
      <!-- Show error if no restaurant matches this Criteria -->
      <div class="alert alert-dark" role="alert">
        <p>
          <faIcons :icon="['fas', 'store']" /> No restaurant found. Make sure
          you click in the right restaurant name.
        </p>
      </div>
      <router-link tag="li" :to="{ name: 'MyRestaurants' }">
        <a class="btn btn-success">
          <faIcons :icon="['fas', 'store']" /> My restaurant(s)
        </a>
      </router-link>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import { mapState, mapMutations, mapGetters } from "vuex";
import Navbar from "@/components/Header/Navbar.vue";
export default {
  name: "MyRestaurantChecks",
  components: {
    Navbar,
  },
  data() {
    return {
      user: {
        uId: "",
        userId: "",
        uName: "",
        uEmail: "",
        uPassword: "",
        uMemberSince: "",
        uAddress: "",
        uPhone: "",
        uRole: "",
        uRoleIs: "",
      },
      restAcceptingOrderStatus: "",
      searchingForData: true,
      //cat
      catPrice: "",
      catPriceErr: false,
      showViewTimeHours: false,
      setUpPaymentAccount: false,
      catPriceMsg: "",
      iscatPriceValidated: false,
      //is my restaurant accepting online orders
      isMenuActive: "",
      isMenuActiveErr: false,
      isMenuActiveSuccess: false,
      isMenuActiveMsg: "Restaurant is now accepting online orders",
      isMenuActiveValidated: false,
      //Restaurant Info from URL
      restId: this.$route.params.restId,
      restNum: this.$route.params.id,
      //phone
      end: new Date(
        new Date().getTime() - new Date().getTimezoneOffset() * 60000
      )
        .toISOString()
        .split("T")[0],
      endErr: false,
      endMsg: "",
      isendValidated: false,
      //name
      start: new Date(
        new Date().getTime() - new Date().getTimezoneOffset() * 60000
      )
        .toISOString()
        .split("T")[0],
      startErr: false,
      startMsg: "",
      isstartValidated: false,
      //email
      notes: "",
      notesErr: false,
      notesMsg: "",
      isnotesValidated: false,
      //address
      totalPayment: "",
      totalPaymentErr: false,
      totalPaymentMsg: "",
      istotalPaymentValidated: false,
      //show result
      resultSuccess: false,
      resultSuccessMsg: "",
      resultErr: false,
      resultErrMsg: "",
      //show onlnie hours result
      onlineHoursResultSuccess: false,
      onlineHoursResultSuccessMsg: "",
      onlineHoursResultErr: false,
      onlineHoursResultErrMsg: "",
      image: "",
      is_DB_Connected: "",
      db_connection_msg: "",
      // apiMainUrl: "http://localhost/php/login-system-php-vue-2021/myData.php",
      apiMainUrl: this.$store.getters.MainUrl, //call global variable
      signUpBtn: true,
      acceptOrdersBtn: true,
      onlineHoursBtn: true,
      isRestExists: true,
      isBankSetup: false,
      businessPhone: "",
      businessName: "",
      businessAddress: "",
      restaurant_added_by: "",
      currentRestaurantChecks: [],
      currentYear: new Date().getUTCFullYear(),
      bank_name_is: "",
      bank_number_is: "",
      bank_routing_number_is: "",
    };
  },
  mounted() {
    // const fontUrl = require('https://order.hooksfishnchips.com/fonts/micr-encoding.regular.ttf').default;
    // new FontFace('numbers', `url(${fontUrl})`);
    let user = localStorage.getItem("current-user-data");
    let currentRestaurants = localStorage.getItem("current_restaurants_data");
    let currentRestaurant = JSON.parse(currentRestaurants).filter(
      (restInfo) =>
        restInfo.id == this.restNum && restInfo.rest_id == this.restId
    );
    let restaurant_bank_details = localStorage.getItem(
      "restaurant_bank_details"
    );
    let currentRestaurantBankDetails = JSON.parse(
      restaurant_bank_details
    ).filter((restInfo) => restInfo.rest_id == this.restId);
    if (currentRestaurant.length > 0) {
      if (currentRestaurantBankDetails[0].bank_name.length > 0) {
        this.isBankSetup = true;
        this.bank_name_is = currentRestaurantBankDetails[0].bank_name;
        this.bank_number_is = currentRestaurantBankDetails[0].bank_number;
        this.bank_routing_number_is =
          currentRestaurantBankDetails[0].routing_number;
        // console.log("Is bank setup? length: ", currentRestaurantBankDetails.length);
        // console.table("Current bank name: ", currentRestaurantBankDetails[0].bank_name);
      } else {
        this.isBankSetup = false;
      }
      //set rest info
      this.businessPhone = currentRestaurant[0].rest_phone;
      this.businessName = currentRestaurant[0].rest_name;
      this.businessAddress = currentRestaurant[0].rest_address;
      this.restaurant_added_by = currentRestaurant[0].added_by;
      this.isRestExists = true;
      this.user.uId = JSON.parse(user).id;
      this.user.userId = JSON.parse(user).user_id;
      this.user.uName = JSON.parse(user).name;
      this.user.uEmail = JSON.parse(user).email;
      this.user.uPassword = JSON.parse(user).password;
      this.user.uMemberSince = JSON.parse(user).member_since;
      this.user.uAddress = JSON.parse(user).full_address;
      this.user.uPhone = JSON.parse(user).phone_number;
      this.user.uRole = JSON.parse(user).role;
      //if role isn't manager, redirect
      if (JSON.parse(user).role == "mngr" || JSON.parse(user).role == "admn") {
        this.getCurrentRestaurantChecks();
      } else {
        this.$router.push({ name: "MyRestaurants" });
      }
    } else {
      this.$router.push({ name: "MyRestaurants" });
    }
  },
  computed: {
    ...mapState([
      "__RestaurantId",
      "__RestaurantEmail",
      "__RestaurantRole",
      "__RestaurantName",
      "__MainUrl",
    ]),
    ...mapGetters(["MainUrl"]),
  },
  methods: {
    ...mapMutations(["setRestaurantInfo"]),
    capitalizeFisrtLetterOfEachWord(text) {
      return text
        .toLowerCase()
        .split(" ")
        .map((s) => s.charAt(0).toUpperCase() + s.substring(1))
        .join(" ");
    },
    printSection(id) {
      // Get HTML to print from element
      const prtHtml = document.getElementById(id).innerHTML;
      // Get all stylesheets HTML
      let stylesHtml = "";
      for (const node of [
        ...document.querySelectorAll('link[rel="stylesheet"], style'),
      ]) {
        stylesHtml += node.outerHTML;
      }
      // Open the print window
      const WinPrint = window.open(
        "",
        "",
        "left=0,top=0,width=800,height=900,toolbar=0,scrollbars=0,status=0"
      );

      WinPrint.document.write(`<!DOCTYPE html>
            <html>
            <head>
                ${stylesHtml}
            </head>
            <body>
                ${prtHtml}
            </body>
            </html>`);

      WinPrint.document.close();
      WinPrint.focus();
      WinPrint.print();
      WinPrint.close();
    },
    formatDateFormulas(dateIs) {
      const [year, month, day] = dateIs.split("-");
      const result = [month, day, year].join("/");
      let n = result;
      return n;
    },
    async getCurrentRestaurantChecks() {
      try {
        this.currentRestaurantChecks.length = 0; //reset checks to zero
        this.resultErr = false;
        this.showViewTimeHours = false;
        //this.currentRestaurantChecks
        let fd = new FormData();
        fd.append("userId", this.user.userId);
        fd.append("restId", this.restId);
        fd.append("year", this.currentYear);
        let rst = await axios.post(
          this.apiMainUrl + `?action=getCurrentRestaurantChecks`,
          fd
        );
        if (rst.status == 200) {
          console.log("connected...");
          this.searchingForData = false;
          const resultData = rst.data;
          if (!resultData.error) {
            this.currentRestaurantChecks = resultData.currentRestaurantChecks;
            this.resultErr = false;
            this.showViewTimeHours = true;
          } else {
            console.log("validate data sent to API...");
            //if error
            this.showViewTimeHours = false;
            this.resultErr = true;
            this.resultErrMsg = resultData.message;
          }
        }
      } catch (error) {
        this.searchingForData = true;
        console.log("API Connection Error... Reconnecting..");
        console.log(error);
        setTimeout(() => {
          this.getCurrentRestaurantChecks();
        }, "2000");
      }
    },
    formatPhoneNumber(number) {
      //remove . + -  from any number
      let cleaned = ("" + number).replace(/\D/g, "");
      let match = "";
      if (number.length == 10) {
        match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
      } else if (number.length == 9) {
        match = cleaned.match(/^(\d{3})(\d{3})(\d{3})$/);
      } else if (number.length == 11) {
        match = cleaned.match(/^(\d{3})(\d{4})(\d{4})$/);
      } else if (number.length == 8) {
        match = cleaned.match(/^(\d{2})(\d{3})(\d{3})$/);
      }
      if (match) {
        return "(" + match[1] + ") " + match[2] + "-" + match[3];
      }
      return null;
    },
    number_format(amount) {
      const price = amount;
      // Format the price above to USD, INR, EUR using their locales.
      let dollarUS = Intl.NumberFormat("en-US", {
        style: "currency",
        currency: "USD",
      });
      return dollarUS.format(price);
    },
    // convertNumsToWords(number) {
    //     const first = ['', 'one ', 'two ', 'three ', 'four ', 'five ', 'six ', 'seven ', 'eight ', 'nine ', 'ten ', 'eleven ', 'twelve ', 'thirteen ', 'fourteen ', 'fifteen ', 'sixteen ', 'seventeen ', 'eighteen ', 'nineteen '];
    //     const tens = ['', '', 'twenty', 'thirty', 'forty', 'fifty', 'sixty', 'seventy', 'eighty', 'ninety'];
    //     const mad = ['', 'thousand', 'million', 'billion', 'trillion'];
    //     let word = '';

    //     for (let i = 0; i < mad.length; i++) {
    //         let tempNumber = number % (100 * Math.pow(1000, i));
    //         if (Math.floor(tempNumber / Math.pow(1000, i)) !== 0) {
    //             if (Math.floor(tempNumber / Math.pow(1000, i)) < 20) {
    //                 word = first[Math.floor(tempNumber / Math.pow(1000, i))] + mad[i] + ' ' + word;
    //             } else {
    //                 word = tens[Math.floor(tempNumber / (10 * Math.pow(1000, i)))] + '-' + first[Math.floor(tempNumber / Math.pow(1000, i)) % 10] + mad[i] + ' ' + word;
    //             }
    //         }

    //         tempNumber = number % (Math.pow(1000, i + 1));
    //         if (Math.floor(tempNumber / (100 * Math.pow(1000, i))) !== 0) word = first[Math.floor(tempNumber / (100 * Math.pow(1000, i)))] + 'hunderd ' + word;
    //     }
    //     return word;
    // },
    // convertNumToWords(numIs) {
    //     var a = ['', 'one ', 'two ', 'three ', 'four ', 'five ', 'six ', 'seven ', 'eight ', 'nine ', 'ten ', 'eleven ', 'twelve ', 'thirteen ', 'fourteen ', 'fifteen ', 'sixteen ', 'seventeen ', 'eighteen ', 'nineteen '];
    //     var b = ['', '', 'twenty', 'thirty', 'forty', 'fifty', 'sixty', 'seventy', 'eighty', 'ninety'];

    //     const convertNumberToString = num => {
    //         if ((num = num.toString()).length > 9) return 'overflow';
    //         n = ('000000000' + num).substr(-9).match(/^(\d{2})(\d{2})(\d{2})(\d{1})(\d{2})$/);
    //         if (!n) return; var str = '';
    //         str += (n[1] != 0) ? (a[Number(n[1])] || b[n[1][0]] + ' ' + a[n[1][1]]) + 'crore ' : '';
    //         str += (n[2] != 0) ? (a[Number(n[2])] || b[n[2][0]] + ' ' + a[n[2][1]]) + 'lakh ' : '';
    //         str += (n[3] != 0) ? (a[Number(n[3])] || b[n[3][0]] + ' ' + a[n[3][1]]) + 'thousand ' : '';
    //         str += (n[4] != 0) ? (a[Number(n[4])] || b[n[4][0]] + ' ' + a[n[4][1]]) + 'hundred ' : '';
    //         str += (n[5] != 0) ? ((str != '') ? 'and ' : '') + (a[Number(n[5])] || b[n[5][0]] + ' ' + a[n[5][1]]) + 'only ' : '';
    //         return str;
    //     }
    //     return convertNumberToString(numIs);
    // },
    convertNumberToWords(numIs) {
      const numbers = {
        0: "Zero",
        1: "One",
        2: "Two",
        3: "Three",
        4: "Four",
        5: "Five",
        6: "Six",
        7: "Seven",
        8: "Eight",
        9: "Nine",
        10: "Ten",
        11: "Eleven",
        12: "Twelve",
        13: "Thirteen",
        14: "Fourteen",
        15: "Fifteen",
        16: "Sixteen",
        17: "Seventeen",
        18: "Eighteen",
        19: "Nineteen",
        20: "Twenty",
        30: "Thirty",
        40: "Forty",
        50: "Fifty",
        60: "Sixty",
        70: "Seventy",
        80: "Eighty",
        90: "Ninety",
      };
      const numberScales = [
        "",
        "",
        "Thousand",
        "Million",
        "Billion",
        "Trillion",
        "Quadrillion",
        "Quintillion",
        "Sextillion",
        "Septillion",
        "Octillion",
        "Nonillion",
        "Decillion",
        "Undecillion",
        "Duodecilion",
        "Tredecilion",
        "Quattuordecilion",
        "Quindecilion",
        "Sexdecillion",
        "Septendecilion",
        "Octodecilion",
        "Novemdecilion",
        "Vigintilion",
      ];

      const convertNumberToString = (amount) => {
        let result = "";
        let isString = typeof amount === "string" || amount instanceof String;

        if (isNaN(amount)) {
          return `'${amount}' is not a valid number.`;
        }
        if (!isNaN(amount) && !isString && amount > Number.MAX_SAFE_INTEGER) {
          return `'${amount}' is past the bounds of MAX_SAFE_INTEGER. Please pass in the number wrapped in quotes (ex: '954.34') for an accurate conversion.`;
        }

        const amountString = amount.toString();
        const splitAmountArray = amountString.split(".");
        const amountIntegerString = splitAmountArray[0];
        const amountDecimalString = splitAmountArray[1] || "00";
        if (amountDecimalString > 99) {
          return `'${amount}' contains an invalid decimal amount. Please re-enter a value of 0-99 cents.`;
        }

        const tripleStack = [];

        for (let i = amountIntegerString.length; i > 0; i -= 3) {
          const startIndex = i - 3 < 0 ? 0 : i - 3;
          const tripleString = amountIntegerString.slice(startIndex, i);
          const tripleNum = parseInt(tripleString, 10);

          tripleStack.push(tripleNum);
        }

        tripleStack.reverse();
        tripleStack.forEach(function (triple, index) {
          const scalePosition = tripleStack.length - index;

          if (triple < 20) {
            result += ` ${numbers[triple]}`;
          } else if (triple < 100) {
            const tensValue = Math.floor(triple / 10) * 10;
            const onesValue = triple - tensValue;
            if (onesValue === 0) {
              result += ` ${numbers[tensValue]}`;
            } else {
              result += ` ${numbers[tensValue]}-${numbers[onesValue]}`;
            }
          } else {
            const hundredsValue = Math.floor(triple / 100) * 100;
            const realTensValue = triple - hundredsValue;
            console.log(
              "triple " +
                triple +
                " hundreds: " +
                hundredsValue +
                " realTens: " +
                realTensValue
            );
            const tensValue = Math.floor(realTensValue / 10) * 10;
            const onesValue = realTensValue - tensValue;

            if (hundredsValue > 0) {
              result += ` ${numbers[hundredsValue / 100]} Hundred`;
            }
            if (realTensValue < 20) {
              result += ` ${numbers[realTensValue]}`;
            } else {
              if (onesValue === 0) {
                result += ` ${numbers[tensValue]}`;
              } else {
                result += ` ${numbers[tensValue]}-${numbers[onesValue]}`;
              }
            }
          }
          let scale = numberScales[scalePosition];
          if (scale === undefined) scale = "bajillion";
          result += ` ${scale}`;
        });

        if (result) {
          // result += ` Dollar(s) & ${amountDecimalString}/100 Cents Only.`;
          result += ` & ${amountDecimalString}/100 U.S. Dollars Only.`;
        }

        return result;
      };
      return convertNumberToString(numIs);
    },
  },
};
</script>
<style lang="scss" scoped>
$navColor: #3f51b6;

// @font-face {
//     font-family: "numbers";
//     // src: url("https://order.hooksfishnchips.com/fonts/micr-encoding.regular.ttf") format("font-truetype");
//     src: url('https://order.hooksfishnchips.com/fonts/micr-encoding.regular.ttf') format("truetype");
// }

.heightThirdPage {
  min-height: 320px;
}

.securityCode {
  font-family: numbers;
  font-size: 30px;
  font-weight: bold;
  display: initial;
}

/*
	https://support.idautomation.com/MICR-OCR-Fonts/How-to-enter-MICR-Special-Characters/_1893
	download font from : https://www.1001fonts.com/
	Use this font: to print checks font : 
	also make sure to download and save fonts/micr-encoding.regular.ttf
*/
//call check ttf file
// @import url('https://order.hooksfishnchips.com/micr-encoding.regular.ttf');
.form-data-success {
  color: darkgreen;
}

.mNeg20 {
  margin-top: -20px 0 20px 0;
}

.mNeg30 {
  margin-top: -40px 0 10px 0;
}

.mPos30 {
  margin-top: 40px 0 10px 0;
}

.smallerSize {
  font-size: 0.75em;
}

nav a {
  text-decoration: none;
  color: $navColor;
  font-weight: bold;
}

.pointerClick {
  cursor: pointer;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

.ml-3 {
  margin: 0 0 0 50px;
  border-bottom: 2px solid black;
}

.displayFlex {
  display: flex !important;
}

.checkPrintSection {
  min-width: 1320px;
  width: 1320px;
  overflow-x: auto;
}

.addBorderBottom {
  border-bottom: 2px solid black;
}

.w100 {
  width: 100px;
}

.bgBlue {
  background-color: #0d6efd;
  color: white;
  padding: 10px;
  border-radius: 7px;
}

.addBorder {
  border-bottom: 1px solid #ced4da;
}

.smallLineHeight {
  line-height: 1px;
}

.p4 {
  padding: 4px;
}

@media only print {
  html,
  body {
    margin: 0;
    padding: 0;
  }

  .currentPage {
    padding: 0 !important;
    margin: -10px 0 !important;
  }

  .mNeg20 {
    margin-top: -20px 0 20px 0 !important;
  }

  .mNeg30 {
    margin-top: -50px 0 0 0 !important;
  }

  .mPos30 {
    margin-top: 40px 0 10px 0 !important;
  }

  // .heightThirdPage {
  //     margin-top: 10px;
  //     height: 33.33vh !important;
  // }

  // @font-face {
  //     font-family: "numbers";
  //     src: url("https://order.hooksfishnchips.com/fonts/micr-encoding.regular.ttf") format("truetype");
  // }
  // @font-face {
  //     font-family: "numbers";
  //     src: local("numbers"),
  //         url("https://order.hooksfishnchips.com/fonts/micr-encoding.regular.ttf") format("truetype");
  // }

  .securityCode {
    font-family: numbers !important;
    font-size: 30px !important;
    font-weight: bold !important;
  }

  // .mNeg20 {
  //     margin-top: -50px !important;
  // }

  .heightThirdPage {
    min-height: 315px !important;
    margin: 10px 0 !important;
  }

  // .securityCode {
  //     display: inline-block !important;
  // }
}
</style>
