<template>
  <div class="container">
    <nav style="--bs-breadcrumb-divider: '>'" aria-label="breadcrumb">
      <ol class="breadcrumb">
        <li class="breadcrumb-item">
          <router-link tag="li" :to="{ name: 'Home' }">
            <a>Home</a>
          </router-link>
        </li>
        <li class="breadcrumb-item active" aria-current="page">
          View My Restaurants
        </li>
      </ol>
    </nav>
    <div class="my-restaurant-page">
      <div class="m-2 text-center" v-if="user.uRole == 'mngr'">
        <router-link :to="{ name: 'AddNewRestaurant' }">
          <button class="btn btn-outline-secondary">
            <faIcons :icon="['fas', 'store']" /> New restaurant
          </button>
        </router-link>
      </div>
      <div class="clearfix"></div>
      <div class="main-restaurant-table">
        <!-- View all resturants here -->
        <!-- <p v-if="currentRestaurantsArr.length > 0">View My Restaurant(s)</p> -->
        <div v-if="currentRestaurantsArr.length > 0" class="row justify-content-around">
          <div v-for="i in currentRestaurantsArr" :key="i" class="col-12 col-sm-12 col-md-5 each-category-list">
            <div>
              <h3>
                {{ capitalizeFisrtLetterOfEachWord(i.rest_name) }}
                <router-link class="mw-20 float-end text-danger" :to="{
  name: 'DeactivateRestaurant',
  params: { id: i.id, restId: i.rest_id },
}">
                  <faIcons :icon="['fas', 'trash']" />
                </router-link>
                <router-link class="mw-20 float-end text-success" :to="{
  name: 'UpdateCurrentRestaurant',
  params: { id: i.id, restId: i.rest_id },
}">
                  <faIcons :icon="['fas', 'edit']" />
                </router-link>
              </h3>
              <span :class="
  JSON.parse(currentRestaurantsSettings).filter(
    (restInfo) => restInfo.rest_id == i.rest_id
  )[0].accept_online_orders == 1
    ? 'accepting-orders'
    : 'not-accepting-orders'
">
                <faIcons :icon="['fas', 'cash-register']" /> {{
    JSON.parse(currentRestaurantsSettings).filter(
      (restInfo) => restInfo.rest_id == i.rest_id
    )[0].accept_online_orders == 1
      ? "Accepting orders"
      : "Not accepting orders"
}}
              </span>&nbsp;
              <span class="taxes">
                <faIcons :icon="['fas', 'file-invoice-dollar']" /> Taxes:
                {{
    JSON.parse(currentRestaurantsSettings).filter(
      (restInfo) => restInfo.rest_id == i.rest_id
    )[0].taxes
}}%
              </span>
              <hr />
              <p class="mt-2 lightColor">
                <faIcons :icon="['fas', 'map-marker-alt']" />
                {{ capitalizeFisrtLetterOfEachWord(i.rest_address) }}
              </p>
              <p class="lightColor">
                <faIcons :icon="['fas', 'phone-alt']" />
                {{ formatPhoneNumber(i.rest_phone) }}
              </p>
              <hr />
              <div class="mt-2 lightColor" v-if="user.uRole == 'admn'">
                <p>
                  <faIcons :icon="['fas', 'cash-register']" /> Configure
                  <span class="">
                    <router-link :to="{
  name: 'ConfigureStripePayment',
  params: { id: i.id, restId: i.rest_id },
}">
                      Stripe payment account
                    </router-link>
                  </span>
                </p>
              </div>
              <div class="lightColor" :class="user.uRole == 'mngr' ? 'mt-2' : ''">
                <p>
                  <faIcons :icon="['fas', 'dollar-sign']" /> Setup
                  <span class="">
                    <router-link :to="{
  name: 'CurrentRestaurantSettings',
  params: { id: i.id, restId: i.rest_id },
}">
                      Taxes & Business Availabilty
                    </router-link>
                  </span>
                </p>
              </div>
              <div class="lightColor">
                <p>
                  <faIcons :icon="['fas', 'user']" /> Allow users to
                  <span class="">
                    <router-link :to="{
  name: 'AccessUsersToOnlineOrders',
  params: { id: i.id, restId: i.rest_id },
}">
                      access online orders
                    </router-link>
                  </span>
                </p>
              </div>
              <div class="lightColor">
                <p>
                  <faIcons :icon="['fas', 'receipt']" /> Custom
                  restaurant
                  <span class="">
                    <router-link :to="{
  name: 'CustomRestaurantReceipt',
  params: { id: i.id, restId: i.rest_id },
}">
                      receipt
                    </router-link>
                  </span>
                </p>
              </div>
              <div class="lightColor">
                <p>
                  <faIcons :icon="['fas', 'file-invoice-dollar']" /> Setup
                  <span class="">
                    <router-link :to="{
  name: 'CurrentRestaurantBankDetails',
  params: { id: i.id, restId: i.rest_id },
}">
                      deposit details
                    </router-link>
                  </span>
                </p>
              </div>
              <div class="lightColor" v-if="user.uRole == 'admn'">
                <p>
                  <faIcons :icon="['fas', 'file-invoice']" />
                  <span class="">
                    <router-link :to="{
  name: 'CreatePaymentRecord',
  params: { id: i.id, restId: i.rest_id },
}">
                      Create a payment report
                    </router-link>
                  </span>
                </p>
              </div>
              <div class="lightColor" v-if="user.uRole == 'admn'">
                <p>
                  <faIcons :icon="['fas', 'donate']" />
                  <span class="">
                    <router-link :to="{
  name: 'MyPaymentRecord',
  params: { id: i.id, restId: i.rest_id },
}">
                      View payment report(s)
                    </router-link>
                  </span>
                </p>
              </div>
              <div class="lightColor" v-if="user.uRole == 'mngr'">
                <p>
                  <faIcons :icon="['fas', 'donate']" />
                  <span class="">
                    <router-link :to="{
  name: 'ManagerPaymentRecord',
  params: { id: i.id, restId: i.rest_id },
}">
                      View payment report(s)
                    </router-link>
                  </span>
                </p>
              </div>
              <div class="lightColor" v-if="user.uRole == 'mngr' || user.uRole == 'admn'">
                <p>
                  <faIcons :icon="['fas', 'money-check']" />
                  <span class="">
                    <router-link :to="{
  name: 'MyRestaurantChecks',
  params: { id: i.id, restId: i.rest_id },
}">
                      View and create checks
                    </router-link>
                  </span>
                </p>
              </div>
              <hr />
              <div class="d-flex justify-content-evenly m-2">
                <span class="text-start" v-if="i.active == 1">
                  <router-link :to="{
  name: 'CurrentRestaurantMenu',
  params: { id: i.id, restId: i.rest_id },
}">
                    <button class="btn btn-outline-secondary">
                      <faIcons :icon="['fas', 'hamburger']" /> Menu
                    </button>
                  </router-link>
                </span>
                <span class="text-start" v-if="i.active == 1">
                  <router-link :to="{
  name: 'ViewKitchenOrders',
  params: { restId: i.rest_id },
}">
                    <button class="btn btn-outline-secondary">
                      <faIcons :icon="['fas', 'fish']" /> Kitchen
                    </button>
                  </router-link>
                </span>
                <span class="text-end" v-if="i.active == 1">
                  <router-link :to="{
  name: 'ViewOrdersOrTimeInAndOut',
  params: { restId: i.rest_id },
}">
                    <button class="btn btn-outline-secondary">
                      <faIcons :icon="['fas', 'cash-register']" /> Cashier
                    </button>
                  </router-link>
                </span>
                <span class="text-end" v-if="i.active == 0">
                  <router-link :to="{
  name: 'ActivateRestaurant',
  params: { id: i.id, restId: i.rest_id },
}">
                    <button class="btn btn-outline-success">
                      <faIcons :icon="['fas', 'trash-restore']" /> Activate
                    </button>
                  </router-link>
                </span>
              </div>
              <div class="clearfix"></div>
              <hr />
              <div class="clearfix"></div>
              <div class="">
                <p class="mt-2 float-start lightColor">
                  Since {{ new Date(i.added_on).toLocaleDateString() }}
                </p>
                <p class="mt-2 float-end lightColor">
                  Last update {{ new Date(i.last_update).toLocaleDateString() }}
                </p>
              </div>
              <div class="clearfix"></div>
            </div>
          </div>
        </div>
        <div v-else class="d-flex justify-content-evenly">
          <div class="alert alert-warning m-2" role="alert">
            <faIcons :icon="['fas', 'store']" /> You currently don't have any restaurants.
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "ViewMyRestaurants",
  data() {
    return {
      user: {
        uId: "",
        uUserId: "",
        uName: "",
        uEmail: "",
        uPassword: "",
        uMemberSince: "",
        uAddress: "",
        uPhone: "",
        uRole: "",
        uRoleIs: "",
      },
      currentRestaurantsArr: [],
      currentRestaurantsSettings: [],
    };
  },
  mounted() {
    let user = localStorage.getItem("current-user-data");
    let currentRestaurants = localStorage.getItem("current_restaurants_data");
    // console.log(currentRestaurants);
    this.currentRestaurantsArr = JSON.parse(currentRestaurants);
    this.currentRestaurantsSettings = localStorage.getItem(
      "current_restaurants_settings"
    );
    // let currentRestaurantSettings = ;
    if (user != null) {
      this.user.uId = JSON.parse(user).id;
      this.user.uUserId = JSON.parse(user).user_id;
      this.user.uName = JSON.parse(user).name;
      this.user.uEmail = JSON.parse(user).email;
      this.user.uPassword = JSON.parse(user).password;
      this.user.uMemberSince = JSON.parse(user).member_since;
      this.user.uAddress = JSON.parse(user).full_address;
      this.user.uPhone = JSON.parse(user).phone_number;
      this.user.uRole = JSON.parse(user).role;
      //if role for Admin or Manager, redirect
      if (this.user.uRole !== "admn" && this.user.uRole !== "mngr") {
        console.log("Login is not a manager/admin user");
        this.$router.push({ name: "Login" });
      }
    } else {
      console.log("Login is not authorized user");
      this.$router.push({ name: "Login" });
    }
  },
  methods: {
    capitalizeFisrtLetterOfEachWord(text) {
      return text
        .toLowerCase()
        .split(" ")
        .map((s) => s.charAt(0).toUpperCase() + s.substring(1))
        .join(" ");
    },
    addNewRestaurant() {
      this.$router.push({ name: "AddNewRestaurant" });
    },
    formatPhoneNumber(number) {
      //remove . + -  from any number
      let cleaned = ("" + number).replace(/\D/g, "");
      let match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
      if (match) {
        return "(" + match[1] + ") " + match[2] + "-" + match[3];
      }
      return null;
    },
  },
};
</script>
<style lang="scss" scoped>
$navColor: #3f51b6;

a {
  text-decoration: none;
  color: $navColor;
  font-weight: bold;
}

.each-category-list {
  padding: 15px;
  border-radius: 5px;
  box-shadow: 2px 2px 4px 0px #525a5a;
  margin: 8px;
}

.addressDisplay {
  white-space: pre-wrap;
}

.lightColor {
  margin-top: -1rem;
  margin-bottom: 1rem;
  font-size: 0.875em;
  color: #6c757d;
}

h3 {
  font-size: 1em;
  font-weight: bold;
}

.mw-20 {
  min-width: 20px;
}

.not-accepting-orders {
  background-color: red;
  width: auto;
  display: inline-block;
  margin: 0;
  padding: 1px 8px;
  color: white;
  border-radius: 3px;
}

.accepting-orders {
  background-color: green;
  width: auto;
  display: inline-block;
  margin: 0;
  padding: 1px 8px;
  color: white;
  border-radius: 3px;
}

.taxes {
  background-color: #ab960c;
  width: auto;
  display: inline-block;
  margin: 0;
  padding: 1px 8px;
  color: white;
  border-radius: 3px;
}
</style>
