<template>
  <div class="change-user-password-page">
    <UpdatePasswordPage />
  </div>
</template>

<script>
import UpdatePasswordPage from "@/components/Password/UpdatePasswordPage.vue";
export default {
  name: "ForgetPassword",
  components: {
    UpdatePasswordPage,
  },
};
</script>
