<template>
  <div class="container" v-if="showOrderData">
    <div v-if="doesUrlLookGood" class="">
      <div
        class="col-auto d-block mx-auto col-12 col-md-5 main-order-select-date-custom"
      >
        <button
          class="float-start btn btn-primary report-button m-3"
          type="button"
          @click="goBackToOrdersPage()"
        >
          <faIcons :icon="['fas', 'store']" /> Orger Again
        </button>
        <button
          class="float-end btn btn btn-secondary report-button m-3"
          type="button"
          @click="exportToPDF()"
        >
          <faIcons :icon="['fas', 'file-pdf']" /> Reciept
        </button>
      </div>
      <div class="clearfix"></div>
      <div
        id="detailedReciepts"
        class="col-auto d-block mx-auto col-12 col-md-5 main-order-select-date-custom"
      >
        <h3 class="text-bold text-center">
          {{ capitalizeFisrtLetterOfEachWord(businessName) }}
        </h3>
        <div class="text-center">
          <p>{{ capitalizeFisrtLetterOfEachWord(pickUpAddress) }}</p>
          <p>{{ formatPhoneNumber(pickUpPhoneNumber) }}</p>
        </div>
        <hr class="bg-info" />
        <h3 class="text-center" v-if="currentOrderInDetails.has_refund == 1">
          Order is Cancelled
        </h3>
        <h3 class="text-center" v-else>Pickup order reciept (Unpaid)</h3>
        <div class="clearfix"></div>
        <p>Amount due for this order to be paid on pickup</p>
        <div class="clearfix"></div>
        <p class="float-start text-bold">
          {{
            new Date(
              this.currentOrderInDetails.order_placed_on
            ).toLocaleDateString()
          }}
          {{
            removeSecondsFromTime(
              new Date(
                this.currentOrderInDetails.order_placed_on
              ).toLocaleTimeString()
            )
          }}
        </p>
        <p class="float-end text-bold">#94-{{ orderSequenceNumber }}</p>
        <div class="clearfix"></div>
        <p>
          Pick up:
          <span class="text-bold">{{ orderData }}</span>
        </p>
        <hr class="bg-info" />
        <div
          class="btn-group d-flex m-3"
          role="group"
          v-if="currentOrderInDetails.has_refund == 1"
        >
          <button class="btn btns-danger biggerFont" type="button">
            Order was cancelled ${{
              (
                parseFloat(currentOrderInDetails.order_subtotal) +
                parseFloat(currentOrderInDetails.total_tips_added)
              ).toFixed(2)
            }}
          </button>
        </div>
        <p
          v-if="currentOrderInDetails.has_refund == 1"
          class="text-center redColor"
        >
          Order was cancelled
        </p>
        <hr class="bg-info" v-if="currentOrderInDetails.has_refund == 1" />
        <div
          v-for="(o, index) in currentRealOrder"
          :key="index"
          class="each-item-ordered"
        >
          <p class="fb">
            {{ capitalizeFisrtLetterOfEachWord(o.name)
            }}<span class="float-end"
              >${{ parseFloat(o.price).toFixed(2) }}</span
            >
          </p>
          <div class="custom-order" v-if="o.isCustom">
            <div v-for="option in o.options" :key="option">
              <p class="custom-name">
                {{ capitalizeFisrtLetterOfEachWord(option.name) }}
                <span class="custom-price float-end" v-if="option.price > 0"
                  >+${{ option.price }}</span
                >
                <span class="custom-price float-end" v-else>
                  &nbsp;&nbsp;
                </span>
              </p>
              <div class="clearfix"></div>
            </div>
            <div class="clearfix"></div>
            <p
              class="custom-name float-end total-end"
              v-if="o.total_item_cost > o.price"
            >
              Total ${{ o.total_item_cost }}
            </p>
            <div class="clearfix"></div>
            <br />
          </div>
          <div class="clearfix"></div>
          <hr class="bg-info d-print-none" />
        </div>
        <div class="clearfix"></div>
        <!-- <p
          class="text-bold alert alert-danger"
          v-if="currentOrderInDetails.order_instructions != ''"
        >
          Special Instruction <br />{{
            currentOrderInDetails.order_instructions
          }}
        </p> -->
        <div class="clearfix"></div>
        <div>
          <p class="fb mb5">
            Sales
            <span class="float-end"
              >${{ currentOrderInDetails.order_total }}</span
            >
          </p>
          <!-- <p>Service <span class="float-end">${{ 1.49 }}</span></p> -->
          <p class="fb mb5">
            Taxes ({{ currentOrderInDetails.taxes_percentage }}%)<span
              class="float-end"
              >${{ currentOrderInDetails.order_taxes_calculated }}</span
            >
          </p>
          <!-- <p class="fb mb5" v-if="tips > 0">
            Tips<span class="float-end"
              >${{ parseFloat(tips).toFixed(2) }}</span
            >
          </p> -->
          <p class="fb mb5">
            Subtotal
            <span class="float-end"
              >$<span id="final-price">{{
                (
                  parseFloat(currentOrderInDetails.order_subtotal) +
                  parseFloat(tips)
                ).toFixed(2)
              }}</span></span
            >
          </p>
          <hr class="bg-info d-print-none" />
          <p
            class="fb mb5 text-center"
            v-if="currentOrderInDetails.has_refund == 1"
          >
            Sorry for the Inconvenience,
            {{ capitalizeFisrtLetterOfEachWord(custName) }}!
          </p>
          <p class="fb mb5 text-center" v-else>
            Thank you for your order,
            {{ capitalizeFisrtLetterOfEachWord(custName) }}!
          </p>
          <!-- Buttom of each Receipt -->
          <p class="fb text-center">{{ websiteBottomText }}</p>
          <p class="fb text-center">{{ websiteUrl }}</p>
        </div>
      </div>
    </div>
    <div class="container-fluid" v-else>
      <div class="d-flex aligns-items-center justify-content-center">
        <div class="m-5 alert alert-danger">
          Make sure you have the right online order page.
        </div>
      </div>
    </div>
  </div>
  <div class="container-fluid" v-else>
    <div class="d-flex aligns-items-center justify-content-center">
      <div>
        <div class="m-5 alert alert-danger justify-content-center">
          This order has is no longer available!
        </div>
      </div>
    </div>
    <div class="d-flex aligns-items-center justify-content-center">
      <button
        class="btn btn-success justify-content-center m-3"
        type="button"
        @click="goBackToOrdersPage()"
      >
        <faIcons :icon="['fas', 'hamburger']" />
        {{ capitalizeFisrtLetterOfEachWord(businessName) }} Menu
      </button>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import * as html2pdf from "html2pdf.js";
// import * as html2canvas from "html2canvas";
export default {
  name: "OrderConfirmationPage",
  data() {
    return {
      websiteBottomText: "",
      websiteUrl: "",
      isOrderPlaced: this.$store.state.__IsOrderPlaced,
      addedMeal: this.$store.state.__AddedMealName,
      restId: this.$route.params.restId,
      currentOrderId: this.$route.params.orderNo,
      currentOrder: [],
      pickUpAddress: "",
      pickUpPhoneNumber: "",
      businessName: "",
      custName: "",
      orderData: "",
      custom_order_date_or_now: "",
      custEmail: "",
      showOrderData: true,
      showCustomerData: false,
      tips: 0,
      customerUrl: this.$store.getters.CustomerUrl,
      orderSequenceNumber: 0,
      custom_date_time: "",
      currentRealOrder: [],
      currentOrderInDetails: [],
      doesUrlLookGood: true,
    };
  },
  mounted() {
    this.getCurrentOrderDetails(this.restId, this.currentOrderId);
  },
  methods: {
    timeFormat12Hours(time) {
      // Check correct time format and split into components
      time = time
        .toString()
        .match(/^([01]\d|2[0-3])(:)([0-5]\d)(:[0-5]\d)?$/) || [time];

      if (time.length > 1) {
        // If time format correct
        time = time.slice(1); // Remove full string match value
        time[5] = +time[0] < 12 ? " AM" : " PM"; // Set AM/PM
        time[0] = +time[0] % 12 || 12; // Adjust hours
      }
      return time.join(""); // return adjusted time or original string
    },
    exportToPDF() {
      this.showAddressPrint = true;
      this.showCustomer = false;
      this.showOrderNO = false;
      this.showOrderTime = false;
      setTimeout(() => {
        //https://pspdfkit.com/blog/2022/how-to-generate-a-pdf-with-vuejs/
        //https://www.youtube.com/watch?v=V0gMajxp990
        //good explanation for https://www.youtube.com/watch?v=zmJV6VN2h2E
        var element = document.getElementById("detailedReciepts");
        var opt = {
          margin: 10, //margin is better when printing
          filename: this.businessName + " Online Order Reciept" + ".pdf",
          image: { type: "jpeg", quality: 0.98 },
          html2canvas: {
            scale: 2,
            logging: true,
            dpi: 600,
            letterRendering: true,
          },
          // pagebreak: { mode: ['avoid-all', 'css', 'legacy'] },
          // pagebreak: { before: '.beforeClass', after: ['#after1', '#after2'], avoid: 'img' },
          // pagebreak: { mode: "avoid-all", before: "#page2el" },
          jsPDF: { unit: "mm", format: "a4", orientation: "portrait" },
        };
        html2pdf().set(opt).from(element).save();
        // html2pdf(document.getElementById("detailedReciepts"), {
        //     margin: 1,
        //     filename: "i-was-html.pdf",
        // });
        // this.showCustomer = true;
        // this.showOrderNO = true;
        this.showCustomer = true;
        this.showOrderNO = true;
        this.showOrderTime = true;
      }, 2000);
    },
    displayCustomDateTime(dateIs, timeIs) {
      const [year, month, day] = dateIs.split("-");
      const result = [month, day, year].join("/");
      let n = result + " at " + this.timeFormat12Hours(timeIs);
      return n;
    },
    displayCustomDate(dateIs) {
      const [year, month, day] = dateIs.split("-");
      const result = [month, day, year].join("/");
      return result;
    },
    capitalizeFisrtLetterOfEachWord(text) {
      return text
        .toLowerCase()
        .split(" ")
        .map((s) => s.charAt(0).toUpperCase() + s.substring(1))
        .join(" ");
    },
    formatPhoneNumber(number) {
      //remove . + -  from any number
      let cleaned = ("" + number).replace(/\D/g, "");
      let match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
      if (match) {
        return "(" + match[1] + ") " + match[2] + "-" + match[3];
      }
      return null;
    },
    goBackToOrdersPage() {
      this.$router.push({
        name: "EachRestaurantMenu",
        params: { restId: this.restId },
      });
    },
    goBackToMainOrder() {
      this.$router.push({
        name: "ViewAllRestaurants",
      });
    },
    removeSecondsFromTime(timeIs) {
      //assuming time in 12:34:00 AM/PM format
      return timeIs.replace(/(\d{1,2}:\d{2}):\d{2}/, "$1");
    },
    async getCurrentOrderDetails(restId, orderNum) {
      console.log(
        `find current order ${orderNum} for current restaurant ${restId}`
      );
      let fd = new FormData();
      fd.append("restId", restId);
      fd.append("orderNum", orderNum);
      let rst = await axios.post(
        this.customerUrl + `?action=viewCurrentOrderDetailsToCustomer`,
        fd
      );
      console.log(rst);
      const resultData = rst.data;
      if (rst.status == 200) {
        console.table(resultData);
        if (resultData.error) {
          console.log("this order was not found");
        } else {
          console.log("this order was found");
          localStorage.setItem(
            "current_order_details",
            JSON.stringify(resultData.current_order_details)
          );
          localStorage.setItem(
            "current_restaurants_business_data",
            JSON.stringify(resultData.current_restaurants_business_data)
          );
          localStorage.setItem(
            "bottom_receipt",
            JSON.stringify(resultData.bottom_receipt)
          );
          let currentRestaurantsReceipt = JSON.parse(
            localStorage.getItem("bottom_receipt")
          );
          this.websiteBottomText = currentRestaurantsReceipt[0].bottom_text;
          this.websiteUrl = currentRestaurantsReceipt[0].url;
          // show current order details
          // let currentRestaurants = localStorage.getItem(
          //   "current_customer_order"
          // );
          // this.currentOrder = JSON.parse(currentRestaurants);
          //   let currentOrderData = localStorage.getItem("current_customer_order");
          //   let currentOrder = JSON.parse(currentOrderData);
          let currentRestaurantsData = JSON.parse(
            localStorage.getItem("current_restaurants_business_data")
          );
          if (
            resultData.current_order_details.length < 1 ||
            resultData.current_restaurants_business_data.length < 1
          ) {
            console.log("current Order Details does not exist");
            this.doesUrlLookGood = false;
          } else {
            this.doesUrlLookGood = true;
            console.log("current Order Details does exist");
            console.log("hello");
            this.pickUpAddress = currentRestaurantsData[0].rest_address;
            this.pickUpPhoneNumber = currentRestaurantsData[0].rest_phone;
            this.businessName = currentRestaurantsData[0].rest_name;
            // this.tips = currentOrder.tips_added;

            let currentOrderDetails = localStorage.getItem(
              "current_order_details"
            );
            this.currentOrderInDetails = JSON.parse(currentOrderDetails)[0];
            this.orderSequenceNumber = JSON.parse(currentOrderDetails)[0].id;
            let customDate = JSON.parse(currentOrderDetails)[0].custom_date;
            const [year, month, day] = customDate.split("-");
            const result = [month, day, year].join("/");
            let n = result;
            // let currentDateIs = new Date(
            //   new Date().getTime() - new Date().getTimezoneOffset() * 60000
            // )
            //   .toISOString()
            //   .split("T")[0];
            console.log("Custom Date is " + customDate);
            console.log("Custom Date is " + n);
            console.log("Custom Date Function is " + new Date(customDate));
            console.log("Today's Date is " + new Date().toLocaleDateString());
            console.log(
              "Today's Date is " +
                new Date().toLocaleString("en-US", {
                  year: "numeric",
                  month: "2-digit",
                  day: "2-digit",
                })
            );
            let currentDayIs = new Date().toLocaleDateString("en-US", {
              year: "numeric",
              month: "2-digit",
              day: "2-digit",
            });
            // let newCustomDate = new Date(customDate).toLocaleDateString("en-US", {
            //   year: "numeric",
            //   month: "2-digit",
            //   day: "2-digit",
            // });
            if (currentDayIs <= n) {
              this.showOrderData = true;
              console.log("Order reciept can be accessed");
              this.custName = JSON.parse(currentOrderDetails)[0].cust_name;
              this.tips = parseFloat(
                this.currentOrderInDetails.total_tips_added
              ).toFixed(2);
              if (
                this.currentOrderInDetails
                  .is_order_custom_date_and_time_or_right_away == "custom"
              ) {
                this.custom_date_time = this.displayCustomDateTime(
                  this.currentOrderInDetails.custom_date,
                  this.currentOrderInDetails.custom_time
                );
                // new Date(this.currentOrderInDetails.custom_date).toDateString() +
                // " at " +
                // this.timeFormat12Hours(this.currentOrderInDetails.custom_time);
              }
              this.orderData =
                this.currentOrderInDetails
                  .is_order_custom_date_and_time_or_right_away == "now"
                  ? "in 15 minutes"
                  : this.displayCustomDate(
                      this.currentOrderInDetails.custom_date
                    ) +
                    " " +
                    this.timeFormat12Hours(
                      this.removeSecondsFromTime(
                        this.currentOrderInDetails.custom_time
                      )
                    );
              console.log("total tips paid is " + this.tips);
              console.table(JSON.parse(currentOrderDetails));
              let currentCustomerOrderIs =
                JSON.parse(currentOrderDetails)[0].customer_order;
              console.table(currentCustomerOrderIs);
              let currentOrderNewData = [];
              currentOrderNewData.push(currentCustomerOrderIs);
              let newDataOrderItemDetails = JSON.parse(currentOrderNewData); //this will hold order details
              console.table(newDataOrderItemDetails);
              this.currentRealOrder = newDataOrderItemDetails;
              // console.log("Order Name " + newDataOrderItemDetails[1].name);
            } else {
              this.showOrderData = false;
              console.log("you can't view this order");
            }
          }
        }
      }
    },
  },
};
</script>

<style>
.addressStyle {
  font-style: italic;
  padding: 0;
  margin: 0;
  line-height: 0.5;
}

.itemDataDetails {
  padding: 10px;
  margin-bottom: 10px;
  border: 10px solid #eee;
}

.itemDataDetails img {
  width: 320px;
  height: 215px;
  margin-bottom: 10px;
}

.bw {
  overflow-wrap: break-word;
}

.darkred {
  color: darkred;
}

.order-placed {
  padding: 10px;
}

.order-placed h2 {
  color: green;
}

.addressInfo {
  display: block;
  font-style: italic;
  font-weight: bold;
}

.card-header {
  background-color: #008c5d !important;
  font-weight: bold;
}

.mb5 {
  margin-bottom: 5px;
}

.text-bold {
  font-weight: bold;
}

.card-header a,
.card-header a:hover,
.card-header a:visited,
.card-header a:active,
.card-header a:focus {
  color: white;
  display: block;
  text-align: left;
  border: none;
}

.card-header .btn-check:focus + .btn,
.card-header .btn:focus {
  box-shadow: none !important;
}

.fb {
  font-weight: bold;
}

.custom-order {
  margin-bottom: 10px;
}

.custom-name {
  padding-left: 20px;
  margin-bottom: -5px;
  clear: both;
  margin-top: -14px;
}

.total-end {
  font-weight: bold;
  margin-top: 5px;
}

.slidecontainer {
  width: 100%;
}

.slider {
  -webkit-appearance: none;
  width: 100%;
  height: 15px;
  border-radius: 5px;
  background: #d3d3d3;
  outline: none;
  opacity: 0.7;
  -webkit-transition: 0.2s;
  transition: opacity 0.2s;
}

.slider:hover {
  opacity: 1;
}

.slider::-webkit-slider-thumb {
  -webkit-appearance: none;
  appearance: none;
  width: 25px;
  height: 25px;
  border-radius: 50%;
  background: #04aa6d;
  cursor: pointer;
}

.slider::-moz-range-thumb {
  width: 25px;
  height: 25px;
  border-radius: 50%;
  background: #04aa6d;
  cursor: pointer;
}

.btns-danger,
.btns-danger:hover,
.btns-danger:active {
  color: red !important;
  background-color: white !important;
  border-color: red !important;
  font-weight: bold !important;
}

.redColor {
  color: red;
}
</style>
