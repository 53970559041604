<template>
  <div class="login-page">
    <LoginPage />
  </div>
</template>

<script>
import LoginPage from "@/components/Login/LoginPage.vue";
export default {
  name: "Login",
  components: {
    LoginPage,
  },
};
</script>
