<template>
  <div>
    <br />
    <div class="text-center">
      <router-link :to="{ name: 'Login' }">
        <button class="btn btn-outline-secondary my-3">Visit Home Page</button>
        <br />
        <img
          src="@/assets/pageNotFound.jpg"
          class="rounded mx-auto d-block h400 img-fluid"
        />
      </router-link>
    </div>
  </div>
</template>

<script>
export default {
  name: "ErrorPage",
};
</script>

<style>
.h400 {
  height: 400px;
}
</style>
