<template>
  <div class="container">
    <Navbar />
    <nav style="--bs-breadcrumb-divider: '>'" aria-label="breadcrumb">
      <ol class="breadcrumb">
        <li class="breadcrumb-item">
          <router-link tag="li" :to="{ name: 'MyRestaurants' }">
            <a>My Restaurant(s)</a>
          </router-link>
        </li>
        <li v-if="this.isRestExists" class="breadcrumb-item active" aria-current="page">
          <router-link tag="li" :to="{
  name: 'CurrentRestaurantMenu',
  params: { id: restNum, restId: restId },
}">
            {{ this.userName }}
          </router-link>
        </li>
        <li v-if="this.isRestExists" class="breadcrumb-item active" aria-current="page">
          {{ this.categoryNameIs }} Category
        </li>
        <li v-if="this.isRestExists" class="breadcrumb-item active" aria-current="page">
          {{ this.catName }}
        </li>
      </ol>
    </nav>
    <div v-if="this.isRestExists" class="add-new-restaurant-page">
      <p>
        {{ userName }}
        <faIcons :icon="['fas', 'map-pin']" />
        {{ userAddress }}
      </p>
      <!-- Add new category -->
      <form method="post" action="#" enctype="multipart/form-data">
        <div class="row d-grid gap-2 w300 col-auto d-block mx-auto mb-2">
          <h3 class="title-header">{{ this.categoryNameIs }} Category</h3>
          <h3 class="title-header">Item: {{ this.catName }}</h3>
        </div>
        <div class="row g-3 align-items-center mb-3">
          <div class="col-auto d-block mx-auto">
            <div class="form-floating" v-for="c in allModifiersSelected" :key="c">
              <div class="form-check align-items-start">
                <input class="form-check-input" type="checkbox" value="" :id="c.id" v-model="c.selected" />
                <label class="form-check-label" :for="c.id">
                  {{ c.name.toUpperCase() }}
                </label>
              </div>
            </div>
          </div>
        </div>
        <div class="row d-grid gap-2 w300 col-auto d-block mx-auto mb-2">
          <button class="btn btn-outline-success" @click.prevent="AssignModifiersToCurrentItem()" v-if="signUpBtn">
            <faIcons :icon="['fas', 'sitemap']" /> Update Modifiers for current
            item
          </button>
        </div>
        <div class="row d-grid gap-2 w300 col-auto d-block mx-auto mb-3">
          <div class="alert alert-success" role="alert" v-if="resultSuccess">
            {{ resultSuccessMsg }}
          </div>
          <div class="alert alert-danger" role="alert" v-if="resultErr">
            {{ resultErrMsg }}
          </div>
        </div>
        <div class="row d-grid gap-2 w300 col-auto d-block mx-auto mb-3">
          <router-link class="btn btn-outline-primary" :to="{
  name: 'CurrentRestaurantMenu',
  params: { id: restNum, restId: restId },
}">
            <faIcons :icon="['fas', 'undo']" /> Never mind, go back
          </router-link>
        </div>
      </form>
    </div>
    <div v-else>
      <!-- Show error if no restaurant matches this Criteria -->
      <div class="alert alert-dark" role="alert">
        <p>
          No restaurant found. Make sure you click in the right restaurant name.
        </p>
      </div>
      <router-link tag="li" :to="{ name: 'MyRestaurants' }">
        <a class="btn btn-success">Go back to my restaurant(s)</a>
      </router-link>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import { mapState, mapMutations, mapGetters } from "vuex";
import Navbar from "@/components/Header/Navbar.vue";
export default {
  name: "AssignMenuItemsModifier",
  components: {
    Navbar,
  },
  data() {
    return {
      user: {
        uId: "",
        userId: "",
        uName: "",
        uEmail: "",
        uPassword: "",
        uMemberSince: "",
        uAddress: "",
        uPhone: "",
        uRole: "",
        uRoleIs: "",
      },
      thisItemModifiers: [],
      selectedModifiers: [],
      // currentItemModifiersAre: [],
      uploadItemImageNow: false,
      addMenuItemBtnIs: false,
      categoryName: "",
      //cat
      catName: "",
      catNameErr: false,
      catNameMsg: "",
      iscatNameValidated: false,
      //description
      catDescription: "",
      catDescriptionErr: false,
      catDescriptionMsg: "",
      iscatDescriptionValidated: false,
      //price
      catPrice: "",
      catPriceErr: false,
      catPriceMsg: "",
      iscatPriceValidated: false,
      //Restaurant Info from URL
      restId: this.$route.params.restId,
      restNum: this.$route.params.id,
      menuItemId: this.$route.params.menuItemId,
      menuCategoryId: this.$route.params.catId,
      //image
      itemImage: "",
      itemImageErr: false,
      itemImageMsg: "",
      isItemImageValidated: false,
      image: "",
      //address
      userAddress: "",
      userAddressErr: false,
      userAddressMsg: "",
      isuserAddressValidated: false,
      //phone
      userPhone: "",
      userPhoneErr: false,
      userPhoneMsg: "",
      isuserPhoneValidated: false,
      currentItemImage: "",
      //current item modifiers ids are
      currentItemModifierIds: [],
      //name
      userName: "",
      userNameErr: false,
      userNameMsg: "",
      isuserNameValidated: false,
      categoryNameIs: "",
      resultSuccess: false,
      resultSuccessMsg: "",
      resultErr: false,
      resultErrMsg: "",
      // image: "",
      is_DB_Connected: "",
      db_connection_msg: "",
      // apiMainUrl: "http://localhost/php/login-system-php-vue-2021/myData.php",
      apiMainUrl: this.$store.getters.MainUrl, //call global variable
      signUpBtn: true,
      isRestExists: false,
      allModifiersSelected: [],
    };
  },
  mounted() {
    let user = localStorage.getItem("current-user-data");
    let currentRestaurants = localStorage.getItem("current_restaurants_data");
    let currentModifiers = localStorage.getItem(
      "current_user_menu_modifiers_data"
    );
    let currentModifiersArr = JSON.parse(currentModifiers).filter(
      (restInfo) => restInfo.rest_id == this.restId
    );
    let currentCategories = localStorage.getItem(
      "current_user_menu_categories_data"
    );
    let currentCategoriesName = JSON.parse(currentCategories).filter(
      (restInfo) =>
        restInfo.cat_id == this.menuCategoryId &&
        restInfo.rest_id == this.restId
    );
    let currentRestaurant = JSON.parse(currentRestaurants).filter(
      (restInfo) =>
        restInfo.id == this.restNum && restInfo.rest_id == this.restId
    );
    let currentMenuItem = localStorage.getItem("current_user_menu_items_data");
    let currentMenuItemInfo = JSON.parse(currentMenuItem).filter(
      (restInfo) =>
        restInfo.item_rand_id == this.menuItemId &&
        restInfo.rest_id == this.restId
    );
    if (user != null && currentRestaurants != null) {
      console.table(currentRestaurant);
      //set rest info
      if (
        currentRestaurant.length > 0 &&
        currentMenuItemInfo.length > 0 &&
        currentCategoriesName.length > 0
      ) {
        console.log(currentMenuItemInfo);
        console.table(currentModifiersArr);
        this.currentModifiers = currentModifiersArr;
        this.categoryNameIs = currentCategoriesName[0].cat_name;
        this.catName = currentMenuItemInfo[0].item_name;
        this.catPrice = currentMenuItemInfo[0].item_price;
        this.catDescription = currentMenuItemInfo[0].item_description;
        this.currentItemImage = currentMenuItemInfo[0].item_image;
        this.currentItemModifierIds = [];
        if (currentMenuItemInfo[0].modifiers_ids.length < 1) {
          this.currentItemModifierIds = []; //this holds all modifiers related to this item
        } else {
          this.currentItemModifierIds = currentMenuItemInfo[0].modifiers_ids; //this holds all modifiers related to this item
        }
        console.log(
          "Current Menu Item Name is: " + currentMenuItemInfo[0].item_name
        );
        // const currentItemModifierIdsData = [];
        //create an array for all selected items
        const currentSelectedModifiers = [];
        for (let g = 0; g < currentModifiersArr.length; g++) {
          //get previous selected modifiers
          if (
            this.currentItemModifierIds.includes(
              currentModifiersArr[g].group_name_rand_id
            )
          ) {
            const x = {
              name: currentModifiersArr[g].group_name,
              id: currentModifiersArr[g].group_name_rand_id,
              selected: true,
            };
            currentSelectedModifiers.push(x);
          } else {
            const y = {
              name: currentModifiersArr[g].group_name,
              id: currentModifiersArr[g].group_name_rand_id,
              selected: false,
            };
            currentSelectedModifiers.push(y);
          }
        }
        this.allModifiersSelected = currentSelectedModifiers;
        console.log(currentRestaurant);
        this.isRestExists = true;
        this.userPhone = currentRestaurant[0].rest_phone;
        this.userName = currentRestaurant[0].rest_name;
        this.userAddress = currentRestaurant[0].rest_address;
        //get users info
        this.user.uId = JSON.parse(user).id;
        this.user.userId = JSON.parse(user).user_id;
        this.user.uName = JSON.parse(user).name;
        this.user.uEmail = JSON.parse(user).email;
        this.user.uPassword = JSON.parse(user).password;
        this.user.uMemberSince = JSON.parse(user).member_since;
        this.user.uAddress = JSON.parse(user).full_address;
        this.user.uPhone = JSON.parse(user).phone_number;
        this.user.uRole = JSON.parse(user).role;
        //if role isn't manager or admin, redirect
        if (currentRestaurant[0].active == 0) {
          //if restaurant isn't active
          this.$router.push({ name: "MyRestaurants" });
        } else if (
          JSON.parse(user).role !== "mngr" &&
          JSON.parse(user).role !== "admn"
        ) {
          this.$router.push({ name: "Login" });
        }
      }
    } else {
      this.$router.push({ name: "Login" });
    }
  },
  computed: {
    ...mapState([
      "__RestaurantId",
      "__RestaurantEmail",
      "__RestaurantRole",
      "__RestaurantName",
      "__MainUrl",
    ]),
    ...mapGetters(["MainUrl"]),
    selectedNames() {
      return this.allModifiersSelected.filter((each) => each.selected);
    },
  },
  methods: {
    ...mapMutations(["setRestaurantInfo"]),
    // addOrRemoveItem(modifierId) {
    //   const c = [];
    //   if (currentItemModifiersAre.includes(modifierId)) {
    //     return true;
    //   } else {
    //     return false;
    //   }
    // },
    getImageUrl(imgName) {
      let img = require.context("@/../restaurants/");
      console.log(img);
      return img("./" + imgName);
    },
    uploadItemImageBtn() {
      if (!this.addMenuItemBtnIs) {
        this.uploadItemImageNow = true;
      } else {
        this.uploadItemImageNow = false;
      }
    },
    //validate Restaurant Name
    validateCategoryName(e) {
      let val = e.target.value;
      this.validateCategoryNameInput(val);
    },
    validateItemDescription(e) {
      let val = e.target.value;
      this.validateItemDescriptionInput(val);
    },
    validateCategoryPrice(e) {
      let val = e.target.value;
      this.validateCategoryPriceInput(val);
    },
    validateItemDescriptionInput(val) {
      console.log("Item Description Is: " + val);
      if (val == "") {
        this.catDescriptionErr = true;
        this.iscatDescriptionValidated = false;
        this.catDescriptionMsg = "Must Enter Item Description ..";
      } else {
        this.catDescriptionErr = false;
        this.iscatDescriptionValidated = true;
        this.catDescriptionMsg = "";
      }
    },
    validateCategoryNameInput(val) {
      if (val == "") {
        this.catNameErr = true;
        this.iscatNameValidated = false;
        this.catNameMsg = "Must Enter Item Name ..";
      } else {
        this.catNameErr = false;
        this.iscatNameValidated = true;
        this.catNameMsg = "";
      }
    },
    validateCategoryPriceInput(val) {
      // let priceValidation = '^\d+(?:[.,]\d+)*$';
      // let priceValidationRegex  = /^\d+(?:\.\d{0,2})$/;
      let priceValidationRegex = /^[0-9]\d*(((,\d{3}){1})?(\.\d{0,2})?)$/;
      let priceValidation = priceValidationRegex.test(val);
      console.log("Is Price Value Correct: " + priceValidation);
      if (val == "") {
        //if empty
        this.catPriceErr = true;
        this.iscatPriceValidated = false;
        this.catPriceMsg = "Must Enter Item Price ..";
      } else if (!priceValidation) {
        // not any of these forms 65.00, 1.00
        this.catPriceErr = true;
        this.iscatPriceValidated = false;
        this.catPriceMsg = "Price Format 15.40, 0.50 or 1.00 ..";
      } else {
        this.catPriceErr = false;
        this.iscatPriceValidated = true;
        this.catPriceMsg = "";
      }
    },
    validateFileExtension(id) {
      let fileInput = document.getElementById(id);
      let filePath = fileInput.value;
      //Allowing file type
      let allowExtensions = /(\.jpg|\.png|\.jpeg|\.gif)$/i;
      if (!allowExtensions.exec(filePath)) {
        return false;
      } else {
        return true;
      }
    },
    createImage(file) {
      new Image();
      let reader = new FileReader();
      reader.onload = (e) => {
        this.image = e.target.result;
      };
      reader.readAsDataURL(file);
    },
    removeImage() {
      this.image = "";
      setTimeout(() => {
        this.validateItemImage();
      }, 500);
    },
    validateItemImage() {
      if (this.$refs.itemImage.files[0]) {
        if (this.validateFileExtension("menuItemImage") == true) {
          this.itemImageErr = false;
          this.isItemImageValidated = true;
          this.itemImageMsg = "";
          this.createImage(this.$refs.itemImage.files[0]);
          this.itemImage = this.$refs.itemImage.files[0];
        } else {
          this.itemImageErr = true;
          this.isItemImageValidated = false;
          this.itemImageMsg =
            "Menu item image has to be : PNG, JPEG, JPG, or GIF";
        }
      } else {
        this.itemImageErr = true;
        this.isItemImageValidated = false;
        this.itemImageMsg = "Must Select Menu Item Image";
      }
    },
    async AssignModifiersToCurrentItem() {
      this.resultErr = false;
      this.resultErrMsg = "";
      this.resultSuccess = true;
      this.resultSuccessMsg =
        "Please wait while we're assigning a modifier to current item...";
      this.signUpBtn = false;
      //show Result
      //only return selected ids
      const selectedIds = [];
      for (let k = 0; k < this.selectedNames.length; k++) {
        selectedIds.push(this.selectedNames[k].id);
      }
      let fd = new FormData();
      fd.append("userId", this.user.userId);
      // fd.append("selectedModifiers", this.selectedNames);
      fd.append("selectedIds", selectedIds); //send only modifiers ids
      fd.append("restId", this.restId);
      fd.append("menuItemId", this.menuItemId);
      let rst = await axios.post(
        this.apiMainUrl + `?action=AssignModifiersToMenuItems`,
        fd
      );
      console.log(rst);
      const resultData = rst.data;
      if (rst.status == 200) {
        if (resultData.error) {
          console.warn("Connection Error Happened on Adding New Menu Item");
          this.resultErrMsg = resultData.message;
          this.resultErr = true;
          this.resultSuccessMsg = "";
          this.resultSuccess = false;
          this.signUpBtn = true;
        } else {
          this.signUpBtn = false;
          console.log("New Menu Item has been added..");
          //if everything is okay
          this.resultErrMsg = "";
          this.resultErr = false;
          this.resultSuccess = true;
          // var newMenuItemInfo =
          //   resultData.myData[0];
          this.resultSuccessMsg = resultData.message;
          //redirect to current restaurant page
          var currentMenuItem = JSON.parse(
            localStorage.getItem("current_user_menu_items_data")
          );
          for (let i = 0; i < currentMenuItem.length; i++) {
            if (currentMenuItem[i].item_rand_id === this.menuItemId) {
              console.log(
                "Add this modifers to current menu item " + selectedIds
              );
              currentMenuItem[i].modifiers_ids = selectedIds; //convert to array
            }
          }
          localStorage.setItem(
            "current_user_menu_items_data",
            JSON.stringify(currentMenuItem)
          );
          setTimeout(() => {
            this.$router.push({
              name: "CurrentRestaurantMenu",
              params: { id: this.restNum, restId: this.restId },
            });
            console.log("Restaurant Name: " + this.userName);
          }, 2000);
        }
      }
    },
  },
};
</script>
<style lang="scss" scoped>
$navColor: #3f51b6;

nav a {
  text-decoration: none;
  color: $navColor;
  font-weight: bold;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.title-header {
  background-color: #46a7fd;
  padding: 10px;
  font-size: 1.25em;
  color: white;
}

.w175 {
  width: 175px !important;
}

.w200 {
  width: 200px;
  height: 200px;
  max-width: 200px;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}
</style>
