<template>
  <div class="container-fluid" v-if="doesRestDataExists">
    <div v-if="!canUserAccessThis">
      <div class="d-flex aligns-items-center justify-content-center">
        <div class="m-5">
          <div class="form-floating" :class="{ 'form-data-error': currentUserAccessCodeIsErr }">
            <input type="number" min="0" id="userPhoneInput" placeholder="currentUserAccessCodeIsErr" @keydown="
              if ($event.key === '.') {
              $event.preventDefault();
            }
            " @input="validateUserAccessCode($event)" @change="validateUserAccessCode($event)"
              class="form-control w300" v-model.trim="currentUserAccessCodeIs" autofocus />
            <label for="userPhoneInput">
              <faIcons :icon="['fas', 'key']" />Access Code
            </label>
            <span class="error-feedback" v-if="currentUserAccessCodeIsErr">{{
              currentUserAccessCodeIsMsg
            }}</span>
          </div>
        </div>
      </div>
      <div class="d-flex aligns-items-center justify-content-center m-3">
        <button class="btn btn-outline-success" @click.prevent="canUserAccessCurrentPage()" v-if="this.btnEnabled">
          <faIcons :icon="['fas', 'eye']" />View Page
        </button>
      </div>
    </div>
    <div v-if="canUserAccessThis" class="row justify-content-around main-order-select-date-custom">
      <h1 class="mainHeader text-bold text-center mb-2">
        <span class="color-356f71 float-end">
          {{ capitalizeFisrtLetterOfEachWord(businessName) }}
        </span>
        <span class="color-356f71 text-center">
          {{
            capitalizeFisrtLetterOfEachWord(
              currentLoggedInUserData[0].user_name
                .split(" ")
                .slice(0, 1)
                .join(" ")
                    )
          }}
          <button class="btn btns-danger" @click="clearLogin()">
            Exit Now
          </button>
        </span>
        <span class="float-start">{{ timestamp }}</span>
      </h1>
      <div v-for="(o, index) in currentOrderInDetails" :key="index" class="my-2 col-12 col-sm-6 col-lg-4 col-xl-3"
        :id="o.order_id">
        <div class v-if="
          o.is_order_picked_up == 0 && o.is_order_confirmed == 0
            ? showInProgress
            : '' || (o.is_order_picked_up == 0 && o.is_order_confirmed == 1)
              ? showReadyOrders
              : '' || (o.is_order_picked_up == 1 && o.is_order_confirmed == 1)
                ? showPickedUp
                : '' || showAllOrders
        ">
          <div class="each-order-item each-category-list">
            <div class="custom-bg">
              <button class="d-print-none btn btn-info custom-header-right" :class="
                new Date().toTimeString() > o.custom_time ? 'borderRed' : ''
              ">
                <span :class="
                  new Date().toTimeString() > o.custom_time &&
                    o.is_order_picked_up == 0
                    ? 'blink'
                    : ''
                ">Order #{{ o.id }}</span>
              </button>
              <button class="d-print-none btn btns-success custom-header-left redColor"
                v-if="o.is_order_custom_date_and_time_or_right_away == 'custom'">
                <faIcons :icon="['fas', 'clock']" />
                {{
  removeSecondsFromTime(
    displayCustomDateTime("", o.custom_time)
)
                }}
              </button>
              <button class="d-print-none btn btns-success custom-header-left greenColor"
                v-if="o.is_order_custom_date_and_time_or_right_away == 'now'">
                By
                {{
  removeSecondsFromTime(
    displayCustomDateTime("", o.custom_time)
)
                }}
              </button>
              <div class="d-none d-print-block">
                <h3 class="text-center">
                  {{ capitalizeFisrtLetterOfEachWord(businessName) }}
                </h3>
                <p class="text-center">{{ pickUpPhoneNumber }}</p>
                <p class="text-center">
                  {{ capitalizeFisrtLetterOfEachWord(pickUpAddress) }}
                </p>
              </div>
              <div class="clearfix"></div>
              <p class>
                <span class="d-none d-print-block">
                  Order Date:
                  {{ new Date(o.order_placed_on).toLocaleString() }}
                </span>
              </p>
              <div class>
                <span class="text-left d-none d-print-block fontSizeBigger">
                  {{ capitalizeFisrtLetterOfEachWord(o.cust_name) }}
                </span>
                <span class="text-bold float-end d-none d-print-block">Order #{{ o.id }}</span>
              </div>
              <div class="clearfix"></div>
              <div class="mb-3 mt-3 d-none d-print-block">
                <p class="text-center">Online Order</p>
                <p class="text-left">
                  Pickup Date
                  {{
                    o.is_order_custom_date_and_time_or_right_away == "now"
                      ? "in 15 Minutes"
                      : displayCustomDateTime(o.custom_date, o.custom_time)
                  }}
                </p>
              </div>
              <div class="clearfix"></div>
              <div v-for="eachOrder in JSON.parse(o.customer_order)" :key="eachOrder">
                <p class="fb fontSizeBigger mb-2">
                  {{ capitalizeFisrtLetterOfEachWord(eachOrder.name) }}
                </p>
                <div class="custom-kitchen-order" v-if="eachOrder.isCustom">
                  <div v-for="option in eachOrder.options" :key="option">
                    <p class="fb mb-2 custom-name">
                      {{ capitalizeFisrtLetterOfEachWord(option.name) }}
                    </p>
                  </div>
                </div>
                <!-- <hr class="bg-info" /> -->
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="container-fluid" v-else>
    <div class="d-flex aligns-items-center justify-content-center">
      <div class="m-5 alert alert-danger">
        Make sure you have the right online order page.
      </div>
    </div>
  </div>
</template>
<script>
import axios from "axios";
export default {
  name: "ViewKitchenOrders",
  data() {
    return {
      reasonToCancelOrder: "Customer no longer wants this order",
      currentLoggedInUserData: "",
      currentUserAccessCodeIs: "",
      currentUserAccessCodeIsErr: false,
      isUserAccessCodeValidated: false,
      btnEnabled: false,
      currentUserAccessCodeIsMsg: "",
      canUserAccessThis: false,
      showPickedUp: false,
      showInProgress: true,
      showReadyOrders: false,
      showAllOrders: false,
      restId: this.$route.params.restId,
      currentOrder: [],
      pickUpAddress: "",
      pickUpPhoneNumber: "",

      businessName: "",
      custName: "",
      orderData: "",
      custom_order_date_or_now: "",
      custEmail: "",
      showOrderData: true,
      showCustomerData: false,
      tips: 0,
      OrdersAPI: this.$store.getters.OrdersAPI,
      orderSequenceNumber: 0,
      custom_date_time: "",
      currentRealOrder: [],
      currentOrderInDetails: [],
      getNewOrders: null,
      wakeLock: "",
      doesRestDataExists: true,
      todaysDateIs: new Date(
        new Date().getTime() - new Date().getTimezoneOffset() * 60000
      )
        .toISOString()
        .split("T")[0],
      viewOrderStartingDate: new Date(
        new Date().getTime() - new Date().getTimezoneOffset() * 60000
      )
        .toISOString()
        .split("T")[0],
      viewOrderEndingDate: new Date(
        new Date().getTime() - new Date().getTimezoneOffset() * 60000
      )
        .toISOString()
        .split("T")[0],
      timestamp: "",
      flash: "",
    };
  },
  mounted() {
    // console.table(currentLoggedInUserAccessData);
    // this.viewOrders(this.restId);
    //allow input focus on load:
    // document.getElementById('userPhoneInput').focus();
    this.doesThisRestExist();
    console.log("Current Date is " + this.getCurrentDate());
    console.log("Current Time is " + this.getCurrentTime());
    console.log("Full date format " + this.fullDateFormated());
    console.log(
      "Add 15 minutes to current time " +
      this.addHoursToDateAndReturnTimeOnly(new Date(), 0.25)
    );
    console.log(
      "Current Time in 24 Hours Format " + this.getCurrentTime24HoursFormat()
    );
    // console.table(currentUserData);
    // console.table(currentUserData.length);
    let currentRestDetails = localStorage.getItem("current_rest_details");
    if (currentRestDetails === null) {
      console.log("no rest data existing...");
    } else {
      console.log("current rest data");
      console.table(currentRestDetails);
    }

    let currentLoggedInUserAccessData = localStorage.getItem(
      "current_logged_user_access_data"
    );
    if (currentLoggedInUserAccessData === null) {
      this.canUserAccessThis = false;
    } else {
      this.currentLoggedInUserData = JSON.parse(currentLoggedInUserAccessData);
      console.table(currentLoggedInUserAccessData);
      this.canUserAccessThis = true;
    }
    // this.acquireWakeLock();
  },
  beforeUnmount() {
    clearInterval(this.getNewOrders);
  },
  created() {
    this.viewNewOrders();
    setInterval(this.getNow, 1000);
  },
  methods: {
    clearLogin() {
      this.currentUserAccessCodeIs = "";
      //clear localstorage
      localStorage.removeItem("current_logged_user_access_data");
      localStorage.clear();
      // const wakeLock = this.acquireWakeLock();
      // wakeLock.release(); //
    },
    async getUserName(userId, appendTo) {
      let fd = new FormData();
      fd.append("userId", userId);
      let rst = await axios.post(
        this.OrdersAPI + `?action=currentUserName`,
        fd
      );
      if (rst.status == 200) {
        const resultData = rst.data;
        if (!resultData.error) {
          console.log("current user name is " + resultData.user_name_is);
          let name = resultData.user_name_is;
          document.getElementById(appendTo).innerText = name;
        }
      }
    },
    async canUserAccessCurrentPage() {
      this.btnEnabled = false;
      console.log(this.restId);
      console.log(this.currentUserAccessCodeIs);
      // console.log(`find all orders for current restaurant ${restId}`);
      if (this.isUserAccessCodeValidated) {
        let fd = new FormData();
        fd.append("restId", this.restId);
        fd.append("accessId", this.currentUserAccessCodeIs);
        fd.append("todaysDateIs", this.todaysDateIs);
        let rst = await axios.post(
          this.OrdersAPI + `?action=canAccessThisPage`,
          fd
        );
        console.log(rst);
        const resultData = rst.data;
        if (rst.status == 200) {
          console.table(resultData);
          if (resultData.error) {
            console.log("this user has an error");
            this.currentUserAccessCodeIsErr = true;
            this.isUserAccessCodeValidated = false;
            this.btnEnabled = false;
            this.currentUserAccessCodeIsMsg = resultData.message;
          } else {
            this.btnEnabled = false;
            if (resultData.is_order_updated) {
              console.log("user name is " + resultData.userNameIs);
            }
            //now set a localstorage to save logged in user data
            localStorage.removeItem("current_logged_user_access_data");
            let loggedInUserId = [];
            loggedInUserId.push({
              user_name: resultData.userNameIs,
              user_id: resultData.userIdIs,
              user_role: resultData.userRoleIs,
            });
            localStorage.setItem(
              "current_logged_user_access_data",
              JSON.stringify(loggedInUserId)
            );
            this.acquireWakeLock();
            // this.viewOrders(this.restId);
            // this.canUserAccessThis = true;
          }
        }
      } else {
        this.btnEnabled = false;
        this.currentUserAccessCodeIsErr = true;
        this.isUserAccessCodeValidated = false;
        this.currentUserAccessCodeIsMsg = "Refresh the page";
      }
    },
    async doesThisRestExist() {
      let fd = new FormData();
      fd.append("restId", this.restId);
      let rst = await axios.post(
        this.OrdersAPI + `?action=isRestaurantExist`,
        fd
      );
      const resultData = rst.data;
      if (rst.status == 200) {
        console.table(resultData);
        localStorage.setItem(
          "current_rest_data_existing",
          JSON.stringify(resultData.current_rest_data_existing)
        );
        //check if therre is return data, means restaurant is avail and active
        let currentRes = localStorage.getItem("current_rest_data_existing");
        if (JSON.parse(currentRes).length > 0) {
          this.doesRestDataExists = true;
          console.log("this restaurant does exists");
        } else {
          console.log("this restaurant does NOT exists");
          this.doesRestDataExists = false;
        }
      }
    },
    displayCustomDate(dateIs) {
      const currentDate = new Date(dateIs).toLocaleDateString();
      const currentTime = new Date(dateIs).toLocaleTimeString();
      console.log(currentDate);
      console.log(currentTime);
      // const [year, month, day] = currentDate.split("-");
      // const result = [month, day, year].join("/");
      let n = currentDate + " at " + this.timeFormat12Hours(currentTime);
      return n;
    },
    validateUserAccessCode(e) {
      let val = e.target.value;
      this.validateUserAccessCodeInput(val);
    },
    validateUserAccessCodeInput(val) {
      // let userPhoneRegex = /^\d{10}$/;
      console.log("User Code: " + val);
      if (val == "") {
        this.currentUserAccessCodeIsErr = true;
        this.isUserAccessCodeValidated = false;
        this.currentUserAccessCodeIsMsg = "Must Enter User Code ..";
        this.btnEnabled = false;
      } else if (/^\d+$/.test(val) == false) {
        //only numbers from 0-9 no digits, + - * / .
        this.btnEnabled = false;
        this.currentUserAccessCodeIsErr = true;
        this.isUserAccessCodeValidated = false;
        this.currentUserAccessCodeIsMsg = "Numbers Only";
      } else {
        this.btnEnabled = true;
        this.currentUserAccessCodeIsErr = false;
        this.isUserAccessCodeValidated = true;
        this.currentUserAccessCodeIsMsg = "";
      }
    },
    getNow() {
      const today = new Date();
      let hour = today.getHours();
      let amOrPm = hour < 12 ? " AM" : " PM";
      if (hour == 0) {
        hour = 12;
      }
      if (hour > 12) {
        hour = hour - 12;
      }
      if (hour < 10) {
        hour = "0" + hour;
      }
      let minute = today.getMinutes();
      if (minute < 10) {
        minute = "0" + minute;
      }
      // add a zero in front of numbers<10
      // minute = this.checkTime(minute);
      let sec = today.getSeconds();
      if (sec < 10) {
        sec = "0" + sec;
      }
      let time = hour + ":" + minute + ":" + sec + amOrPm;
      this.timestamp = time;
    },
    checkTime(i) {
      if (i < 10) {
        i = "0" + i;
      }
      return i;
    },
    UpdateTimer() {
      this.Timer.innerHTML = this.TotalMins + ":" + this.secs;
    },
    viewNewOrders() {
      //find if
      this.getNewOrders = setInterval(() => {
        let currentLoggedInUserAccessData = localStorage.getItem(
          "current_logged_user_access_data"
        );
        if (currentLoggedInUserAccessData === null) {
          console.log("no log in");
          this.canUserAccessThis = false;
        } else {
          console.log("searching for new orders...");
          console.log("user is logged in");
          this.canUserAccessThis = true;
          this.currentLoggedInUserData = JSON.parse(
            currentLoggedInUserAccessData
          );
          this.viewOrders(this.restId);
        }
      }, 3000); //run this every 2 minutes
    },
    timeFormat12Hours(time) {
      // Check correct time format and split into components
      time = time
        .toString()
        .match(/^([01]\d|2[0-3])(:)([0-5]\d)(:[0-5]\d)?$/) || [time];

      if (time.length > 1) {
        // If time format correct
        time = time.slice(1); // Remove full string match value
        time[5] = +time[0] < 12 ? " AM" : " PM"; // Set AM/PM
        time[0] = +time[0] % 12 || 12; // Adjust hours
      }
      return time.join(""); // return adjusted time or original string
    },
    AddMinutesToDate(date, minutes) {
      return new Date(date.getTime() + minutes * 60000);
    },
    DateFormat(date) {
      var days = date.getDate();
      var year = date.getFullYear();
      var month = date.getMonth() + 1;
      var hours = date.getHours();
      var minutes = date.getMinutes();
      minutes = minutes < 10 ? "0" + minutes : minutes;
      var strTime =
        days + "/" + month + "/" + year + "/ " + hours + ":" + minutes;
      return strTime;
    },
    getCurrentDate() {
      // let currentDate = new Date().toJSON().slice(0, 10); // 10/26/2022 or use below
      let currentDate = new Date().toLocaleDateString();
      return currentDate;
    },
    getCurrentTime() {
      let currentTime = new Date().toLocaleTimeString();
      return currentTime;
    },
    addMinutesToCurrentTime(addMins) {
      let currentTime = new Date();
      return currentTime.setMinutes(currentTime.getMinutes() + addMins);
    },
    addHoursToDateAndReturnTimeOnly(objDate, intHours) {
      var numberOfMlSeconds = objDate.getTime();
      var addMlSeconds = intHours * 60 * 60 * 1000;
      var newDateObj = new Date(numberOfMlSeconds + addMlSeconds); //here it return full date with time added
      return newDateObj.toLocaleTimeString("en-US", {
        hour12: false,
      }); //here only returns time added to current time
    },
    fullDateFormated() {
      return new Date().toLocaleString("en-US", {
        hour12: false,
        year: "numeric",
        month: "2-digit",
        day: "2-digit",
        hour: "2-digit",
        minute: "2-digit",
        second: "2-digit",
      });
    },
    getCurrentTime24HoursFormat() {
      let currentTime = new Date().toLocaleTimeString("en-US", {
        hour12: false,
      });
      return currentTime;
    },
    displayCustomDateTime(dateIs, timeIs) {
      if (dateIs != "") {
        const [year, month, day] = dateIs.split("-");
        const result = [month, day, year].join("/");
        let n = result + " at " + this.timeFormat12Hours(timeIs);
        return n;
      } else {
        return this.timeFormat12Hours(timeIs);
      }
    },
    async viewOrders(restId) {
      console.log(`find all orders for current restaurant ${restId}`);
      let fd = new FormData();
      fd.append("restId", restId);
      fd.append("startDate", this.viewOrderStartingDate);
      fd.append("endDate", this.viewOrderEndingDate);
      let rst = await axios.post(
        this.OrdersAPI + `?action=viewKitchenOrders`,
        fd
      );
      console.log(rst);
      const resultData = rst.data;
      if (rst.status == 200) {
        console.table(resultData);
        if (resultData.error) {
          console.log("this order was not found");
        } else {
          console.log("this order was found");
          localStorage.setItem(
            "current_kitchen_orders",
            JSON.stringify(resultData.current_kitchen_orders)
          );
          localStorage.setItem(
            "current_rest_details",
            JSON.stringify(resultData.current_rest_details)
          );
          // show current order details
          let currentRestaurants = JSON.parse(
            localStorage.getItem("current_rest_details")
          );
          if (currentRestaurants.length >= 1) {
            console.table(currentRestaurants);
            this.pickUpAddress = currentRestaurants[0].rest_address;
            this.pickUpPhoneNumber = currentRestaurants[0].rest_phone;
            this.businessName = currentRestaurants[0].rest_name;
          }
          let current_kitchen_orders = localStorage.getItem(
            "current_kitchen_orders"
          );
          let currentOrders2 = JSON.parse(current_kitchen_orders);
          console.table(current_kitchen_orders);

          let currentOrderDetails = localStorage.getItem(
            "current_kitchen_orders"
          );
          console.table(currentOrderDetails);
          let currentOrders = JSON.parse(currentOrderDetails);
          this.currentOrderInDetails = currentOrders
            .sort(function (a, b) {
              return (
                new Date(b.custom_date + " " + b.custom_time) -
                new Date(a.custom_date + " " + a.custom_time)
              );
            })
            .reverse();
          this.currentOrder = currentOrders2
            .sort(function (a, b) {
              return (
                new Date(b.custom_date + " " + b.custom_time) -
                new Date(a.custom_date + " " + a.custom_time)
              );
            })
            .reverse();
        }
      }
    },
    flashBorder(elmId, stopFlash) {
      var elm = document.getElementById(elmId);
      if (stopFlash == "true") {
        elm.style.border = "";
        console.log("flashing 0");
        clearInterval(this.flash);
      } else {
        console.log("flashing 1");
        var borderPattern = false;
        let setBorder;
        setBorder = function () {
          if (borderPattern) {
            console.log("flashing 2");
            borderPattern = false;
            elm.style.border = "solid red";
            elm.style.borderWidth = "3px";
          } else {
            console.log("flashing 3");
            borderPattern = true;
            elm.style.border = "solid blue";
            elm.style.borderWidth = "3px";
          }
        };
        this.flash = setInterval(setBorder, 100);
      }
    },
    //should get current logged in id to check if he owns current restaurant
    async orderReadyOrPickedUp(orderId, type) {
      // console.log(`find all orders for current restaurant ${restId}`);
      let fd = new FormData();
      fd.append("restId", this.restId);
      fd.append("orderId", orderId);
      fd.append("type", type);
      let rst = await axios.post(
        this.OrdersAPI + `?action=readyOrPickedUp`,
        fd
      );
      console.log(rst);
      const resultData = rst.data;
      if (rst.status == 200) {
        console.table(resultData);
        if (resultData.error) {
          console.log("this order has error");
        } else {
          if (resultData.is_order_updated) {
            console.log("this order status is updated");
          }
          // let result = resultData;
          // show current order details
        }
      }
    },
    removeSecondsFromTime(timeIs) {
      //assuming time in 12:34:00 AM/PM format
      return timeIs.replace(/(\d{1,2}:\d{2}):\d{2}/, "$1");
    },
    capitalizeFisrtLetterOfEachWord(text) {
      return text
        .toLowerCase()
        .split(" ")
        .map((s) => s.charAt(0).toUpperCase() + s.substring(1))
        .join(" ");
    },
    async acquireWakeLock() {
      try {
        const wakeLock = await navigator.wakeLock.request('screen');
        console.log('Wake lock acquired');
        return wakeLock;
      } catch (err) {
        console.error(err);
      }
    },
    printSection(id) {
      // Get HTML to print from element
      const prtHtml = document.getElementById(id).innerHTML;
      // Get all stylesheets HTML
      let stylesHtml = "";
      for (const node of [
        ...document.querySelectorAll('link[rel="stylesheet"], style'),
      ]) {
        stylesHtml += node.outerHTML;
      }
      // Open the print window
      const WinPrint = window.open(
        "",
        "",
        "left=0,top=0,width=800,height=900,toolbar=0,scrollbars=0,status=0"
      );

      WinPrint.document.write(`<!DOCTYPE html>
          <html>
          <head>
              ${stylesHtml}
          </head>
          <body>
              ${prtHtml}
          </body>
l>`);

      WinPrint.document.close();
      WinPrint.focus();
      WinPrint.print();
      WinPrint.close();
    },
  },
};
</script>

<style scoped>
/* @import "https://cdn.jsdelivr.net/npm/bootstrap-print-css/css/bootstrap-print.min.css"; */
@media print {

  p,
  div,
  body,
  hr,
  .alert-danger {
    font-size: 10pt;
    color: black;
    font-weight: bold;
  }

  .alert-danger {
    background-color: none;
    border-radius: 5px;
    border: 1px solid #e2e2e2;
  }

  .each-order-item {
    border: none !important;
  }
}

.blink {
  border-color: red;
  animation: blink-animation 1s steps(5, start) infinite;
  -webkit-animation: blink-animation 1s steps(5, start) infinite;
}

@keyframes blink-animation {
  to {
    visibility: hidden;
  }
}

@-webkit-keyframes blink-animation {
  to {
    visibility: hidden;
  }
}

.each-order-title {
  background-color: #f8f8f8 !important;
  padding: 8px;
  font-weight: bold;
  width: 100%;
  overflow: auto !important;
  color: #02027e !important;
}

.marginTop10 {
  margin-top: 14px !important;
}

.custom-bg {
  position: relative;
  padding: 2px 0 0 0;
}

.custom-header-left {
  position: absolute;
  top: -32px;
}

.custom-header-right {
  position: absolute;
  right: 0;
  top: -32px;
  font-weight: bold !important;
  background-color: white !important;
}

.addBorder {
  background-color: beige;
  border: 1px solid black;
  padding: 8px;
  font-weight: bold;
  width: 90%;
  margin: 18px auto;
  border-radius: 5px;
}

.addressStyle {
  font-style: italic;
  padding: 0;
  margin: 0;
  line-height: 0.5;
}

.form-data-error {
  color: red;
}

.form-data-error input,
.form-data-error textarea {
  border-color: red;
}

.itemDataDetails {
  padding: 10px;
  margin-bottom: 10px;
  border: 10px solid #eee;
}

.each-order-item {
  border: 1px solid #000203;
  padding: 6px;
  margin: 23px 0;
}

.itemDataDetails img {
  width: 320px;
  height: 215px;
  margin-bottom: 10px;
}

.bw {
  overflow-wrap: break-word;
}

.darkred {
  color: darkred;
}

.redColor {
  color: red !important;
}

.order-placed {
  padding: 10px;
}

.order-placed h2 {
  color: green;
}

.addressInfo {
  display: block;
  font-style: italic;
  font-weight: bold;
}

.card-header {
  background-color: #008c5d !important;
  font-weight: bold;
}

.mb5 {
  margin-bottom: 5px;
}

.text-bold {
  font-weight: bold;
}

.card-header a,
.card-header a:hover,
.card-header a:visited,
.card-header a:active,
.card-header a:focus {
  color: white;
  display: block;
  text-align: left;
  border: none;
}

.card-header .btn-check:focus+.btn,
.card-header .btn:focus {
  box-shadow: none !important;
}

.fb {
  font-weight: bold;
}

.custom-kitchen-order {
  margin-bottom: 10px !important;
}

.btns-success,
.btns-success:hover,
.btns-success:active {
  color: #21b023;
  background-color: #fff;
  border-color: #21b023;
  font-weight: bold;
}

.biggerFont {
  font-size: 1.5em;
  padding: 0;
}

.btns-orange,
.btns-orange:hover,
.btns-orange:active {
  color: #ff5722 !important;
  background-color: #fff;
  border-color: #ff5722 !important;
  font-weight: bold;
}

.btns-primary,
.btns-primary:hover,
.btns-primary:active {
  color: blue;
  background-color: white;
  border-color: blue;
  font-weight: bold;
}

.btns-danger,
.btns-danger:hover,
.btns-danger:active {
  color: red;
  background-color: white;
  border-color: red;
  font-weight: bold;
}

.color-356f71 {
  color: #356f71;
}

.custom-name {
  padding-left: 20px;
  margin-bottom: -5px;
  clear: both;
  margin-top: -14px;
}

.total-end {
  font-weight: bold;
  margin-top: 5px;
}

.slidecontainer {
  width: 100%;
}

.slider {
  -webkit-appearance: none;
  width: 100%;
  height: 15px;
  border-radius: 5px;
  background: #d3d3d3;
  outline: none;
  opacity: 0.7;
  -webkit-transition: 0.2s;
  transition: opacity 0.2s;
}

.slider:hover {
  opacity: 1;
}

.slider::-webkit-slider-thumb {
  -webkit-appearance: none;
  appearance: none;
  width: 25px;
  height: 25px;
  border-radius: 50%;
  background: #04aa6d;
  cursor: pointer;
}

.slider::-moz-range-thumb {
  width: 25px;
  height: 25px;
  border-radius: 50%;
  background: #04aa6d;
  cursor: pointer;
}

.pointerHand {
  cursor: pointer;
}

.greenColor {
  color: #00c904;
}

.mainHeader {
  box-sizing: border-box;
  color: red;
  width: 100%;
  margin: 0;
  border-bottom: 2px solid #356f71;
  padding-bottom: 10px;
}

.colorRed {
  color: red;
}

.custom-button {
  background-color: white;
}

.greenColor {
  color: #21b023;
}

.yellowColor {
  color: #ffff00;
}

.blueColor {
  color: #0000ff;
}

.fontSizeBigger {
  font-size: 1.75em;
}

.borderRed {
  border-color: red !important;
  background-color: red !important;
  color: white !important;
}
</style>
