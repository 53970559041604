<template>
  <div id="pos-login">
    <h1 class="text-center">Hooks Fish & Chips</h1>
    <div v-if="!userLoggedIn"></div>
    <div v-else>
      <p>Welcome User {{ userName }}</p>
    </div>
  </div>
  <!--
    While Loading screen, ... show loading screen text
    
    1. Validate if restaurant is existing -> current_restaurant_details
    2. Load Restaurant Menu matching rest_id 
        -> restaurant_menu_modifiers_details & restaurant_menu_categories_details & restaurant_users_data &
        restaurant_menu_items_modifiers_details
    3. Now Validate Login ID or Found in restaurant_users_data
    4. Show All Orders or else show error
    5. If step #4 is good and userLoggedIn = true, show POS Screen
    6. If user click logout, set userLoggedIn = false;
   -->
</template>

<script>
import axios from "axios";
import { mapState, mapMutations, mapGetters } from "vuex";
export default {
  name: "PosLogin",
  data() {
    return {
      //is user logged in
      userLoggedIn: false,
      userName: "Test Test",
      userID: "",
      userEmail: "",
      userRole: "",
      restId: this.$route.params.restId,
      restNum: this.$route.params.id,
      menuCategories: [],
      menuModifiers: [],
      currentMenuModifiersItems: [],
      currentRestaurantDetails: [],
      currentRestaurantReciept: [],
      POSAPI: this.$store.getters.POSAPI,
    };
  },
  mounted() {
    console.log("Mouting Data..");
    //set local storage
    //we need to fetch all data if it doesn't exist in our local storage
    // this.setAndGetStoredData();
    // let users = localStorage.getItem("restaurant_users_data")
    //   ? null
    //   : localStorage.getItem("restaurant_users_data");
    // let restaurantsDetails = localStorage.getItem("current_restaurant_details")
    //   ? null
    //   : localStorage.getItem("current_restaurant_details");
    // let menuCategories = localStorage.getItem(
    //   "restaurant_menu_categories_details"
    // )
    //   ? null
    //   : localStorage.getItem("restaurant_menu_categories_details");
    // let menuModifiers = localStorage.getItem(
    //   "restaurant_menu_modifiers_details"
    // )
    //   ? null
    //   : localStorage.getItem("restaurant_menu_modifiers_details");
    // let menuItemsModifiers = localStorage.getItem(
    //   "restaurant_menu_items_modifiers_details"
    // )
    //   ? null
    //   : localStorage.getItem("restaurant_menu_items_modifiers_details");
    // let restaurantReceipts = localStorage.getItem("restaurants_receipt")
    //   ? null
    //   : localStorage.getItem("restaurants_receipt");
    // if (users.length > 0 && restaurantsDetails.length > 0) {
      // console.log("Restaurant is found..");
      //if found
      //   this.menuCategories = JSON.parse(menuCategories).filter(
      //     (restInfo) => restInfo.rest_id == this.restId
      //   );
      //   this.menuModifiers = JSON.parse(menuModifiers).filter(
      //     (restInfo) => restInfo.rest_id == this.restId
      //   );
      //   this.currentMenuModifiersItems = JSON.parse(menuItemsModifiers);
      //   this.currentRestaurantReciept = JSON.parse(restaurantReceipts).filter(
      //     (restInfo) => restInfo.rest_id == this.restId
      //   );
      //   this.currentRestaurantDetails = JSON.parse(restaurantsDetails).filter(
      //     (restInfo) =>
      //       restInfo.id == this.restNum && restInfo.rest_id == this.restId
      //   );
      //   console.table(this.currentRestaurantDetails);
      //   console.table(this.menuCategories);
      //   console.table(this.menuModifiers);
    // }
  },
  computed: {
    ...mapState([
      "__RestaurantId",
      "__RestaurantEmail",
      "__RestaurantRole",
      "__RestaurantName",
      "__MainUrl",
    ]),
    ...mapGetters(["MainUrl"]),
  },
  created() {
    this.fetchPOSData();
  },
  methods: {
    ...mapMutations(["setRestaurantInfo"]),
    logMeOut() {
      //   localStorage.clear();//only clear current orders
      this.userLoggedIn = false;
    },
    async fetchPOSData() {
      console.log("get all data for POS");
      let fd = new FormData();
      fd.append("restId", this.restId);
      fd.append("restNum", this.restNum);
      let rst = await axios.post(this.POSAPI + `?action=fetchPOSData`, fd);
      const resultData = rst.data;
      if (rst.status == 200) {
        console.table(resultData);
        // localStorage.setItem(
        //   "current_rest_data_existing",
        //   JSON.stringify(resultData.current_rest_data_existing)
        // );
        //check if therre is return data, means restaurant is avail and active
        // let currentRes = localStorage.getItem("current_rest_data_existing");
      }
    },
  },
};
</script>

<style lang="scss" scoped></style>
