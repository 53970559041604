<template>
  <div class="home" v-if="!isUserSignUpOrLoggedIn">
    <!-- Show Btns if User didn't Signed Up Or Logged In -->
    <router-link class="btn btn-outline-success my-3" :to="{ name: 'Login' }">
      <faIcons :icon="['fas', 'sign-in-alt']" /> Log In
    </router-link>
    <br />
    <button class="btn btn-outline-primary my-3" @click="signUp()">
      <faIcons :icon="['fas', 'user-plus']" /> Sign Up
    </button>
    <!-- May also view any generic info here -->
  </div>
  <div class="home" v-else>
    <!-- Show Users Data if user already Signed Up Or Logged In -->
    <Navbar />
    <div class="row justify-content-around">
      Welcome {{ capitalizeFirstLetterOfEachWord(uName) }} <br /><br />
      <DashboardPanel />
    </div>
  </div>
</template>

<script>
import Navbar from "@/components/Header/Navbar.vue";
import DashboardPanel from "@/components/Dashboard/DashboardPanel.vue";

export default {
  name: "Home",
  components: {
    Navbar,
    DashboardPanel,
  },
  data() {
    return {
      isUserSignUpOrLoggedIn: false,
      uName: "",
      uEmail: "",
      uRole: "",
      uId: "",
    };
  },
  mounted() {
    let user = localStorage.getItem("current-user-data");
    if (user) {
      this.isUserSignUpOrLoggedIn = true;
      this.uName = JSON.parse(user).name;
      this.uEmail = JSON.parse(user).email;
      this.uRole = JSON.parse(user).role;
      this.uId = JSON.parse(user).id;
      //remember these user roles that was setup on loginPage.vue Component
      /*
        su739154 ==> Only IT Such as Me (sprAdmin)
        ad425174 ==> Owner of the website (admin)
        em187256 ==> Employee of the website (employee)
        cu415856 ==> Customers of the website (customer)

      */
    } else {
      this.$router.push({ name: "Login" });
    }
  },
  methods: {
    logIn() {
      this.$router.push({ name: "Login" });
    },
    signUp() {
      this.$router.push({ name: "SignUp" });
    },
    capitalizeFirstLetterOfEachWord(mySentence) {
      const words = mySentence.split(" ");

      for (let i = 0; i < words.length; i++) {
        words[i] = words[i][0].toUpperCase() + words[i].substr(1);
      }

      mySentence = words.join(" ");
      return mySentence;
    },
  },
};
</script>
