<template>
  <div class="view-account-info">
    <form method="post" action="#" enctype="multipart/form-data">
      <div class="row g-3 align-items-center mb-3">
        <div class="col-auto d-block mx-auto">
          <h3>Change Your Password</h3>
        </div>
      </div>
      <div class="row g-3 align-items-center mb-3">
        <div class="col-auto d-block mx-auto">
          <div class="form-floating w300" :class="{ 'form-data-error': userPasswordErr }">
            <input type="password" id="userPasswordInput" placeholder="New password is"
              @keyup="validateUserPassword($event)" @change="validateUserPassword($event)" class="form-control w300"
              v-model.trim="userPassword" autocomplete />
            <label for="userPasswordInput">
              <faIcons :icon="['fas', 'key']" /> New password is
            </label>
            <span class="error-feedback" v-if="userPasswordErr">
              {{ userPasswordMsg }}
            </span>
          </div>
        </div>
      </div>
      <div class="row d-grid gap-2 w300 col-auto d-block mx-auto m-1">
        <button class="btn btn-outline-success" @click.prevent="changeUserPassword()" v-if="changePasswordBtn">
          <faIcons :icon="['fas', 'key']" /> Change Password
        </button>
      </div>
      <div class="row d-grid gap-2 w300 col-auto d-block mx-auto m-2">
        <div class="alert alert-success" role="alert" v-if="resultSuccess">
          {{ resultSuccessMsg }}
        </div>
        <div class="alert alert-danger" role="alert" v-if="resultErr">
          {{ resultErrMsg }}
        </div>
      </div>
      <div class="row d-grid gap-2 w300 col-auto d-block mx-auto m-1">
        <button class="btn btn-outline-primary" @click.prevent="this.$router.go(-1)">
          <faIcons :icon="['fas', 'undo']" /> Go Back
        </button>
      </div>
    </form>
  </div>
</template>

<script>
import axios from "axios";
import { mapState, mapGetters } from "vuex";
export default {
  name: "AccountPageUpdate",
  data() {
    return {
      user: {
        uId: "",
        uUserId: "",
        uPassword: "",
      },
      //password
      userPassword: "",
      userPasswordErr: false,
      userPasswordMsg: "",
      isuserPasswordValidated: false,

      resultSuccess: false,
      resultSuccessMsg: "",
      resultErr: false,
      resultErrMsg: "",
      apiMainUrl: this.$store.getters.MainUrl, //call global variable
      changePasswordBtn: true,
    };
  },
  mounted() {
    let user = localStorage.getItem("current-user-data");
    if (user) {
      this.user.uId = JSON.parse(user).id;
      this.user.uUserId = JSON.parse(user).user_id;
      this.user.uPassword = JSON.parse(user).password;
    } else {
      this.$router.push({ name: "Login" });
    }
  },
  computed: {
    ...mapState(["__MainUrl"]),
    ...mapGetters(["MainUrl"]),
  },
  methods: {
    validateUserPassword(e) {
      let val = e.target.value;
      this.validateUserPasswordInput(val);
    },
    validateUserPasswordInput(val) {
      console.log("validate user pass: " + val);
      let passIs = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[!@#$%^&*]).{8,}$/;
      if (val == "") {
        this.userPasswordErr = true;
        this.isuserPasswordValidated = false;
        this.userPasswordMsg = "Must Enter User Password ..";
      } else if (val !== "" && !passIs.test(val)) {
        this.userPasswordErr = true;
        this.isuserPasswordValidated = false;
        this.userPasswordMsg =
          "Compose at least 8 Chars of lowercase Letters, upperrcase Letters, Numbers and !@#$%^&*";
      } else if (val == this.user.uPassword) {
        this.userPasswordErr = true;
        this.isuserPasswordValidated = false;
        this.userPasswordMsg =
          "You must enter a different password that was never used in this account.";
      } else {
        this.userPasswordErr = false;
        this.isuserPasswordValidated = true;
        this.userPasswordMsg = "";
        this.resultErrMsg = "";
        this.resultErr = false;
      }
    },
    addZero(i) {
      if (i < 10) {
        i = "0" + i;
      }
      return i;
    },
    currentTime() {
      let today = new Date();
      let start_date =
        today.getFullYear() +
        "-" +
        (today.getMonth() + 1) +
        "-" +
        today.getDate() +
        " " +
        this.addZero(today.getHours()) +
        ":" +
        this.addZero(today.getMinutes()) +
        ":" +
        this.addZero(today.getSeconds());
      return start_date;
    },
    async changeUserPassword() {
      this.resultErr = false;
      this.resultErrMsg = "";
      this.resultSuccess = true;
      this.resultSuccessMsg =
        "Please wait while we're updating your password...";
      this.validateUserPasswordInput(this.userPassword);
      if (this.isuserPasswordValidated) {
        //show Result
        this.changePasswordBtn = false;
        let fd = new FormData();
        fd.append("userId", this.user.uUserId);
        fd.append("i", this.user.uId);
        fd.append("nPass", this.userPassword);
        fd.append("timeIs", this.currentTime());
        let rst = await axios.post(
          this.apiMainUrl + `?action=changePassword`,
          fd
        );
        console.log(rst);
        const resultData = rst.data;
        if (rst.status == 200) {
          if (resultData.error) {
            console.warn("Connection Error Happened on updating your account");
            this.resultErrMsg = resultData.message;
            this.resultErr = true;
            this.resultSuccessMsg = "";
            this.resultSuccess = false;
            this.changePasswordBtn = true;
          } else {
            this.changePasswordBtn = false;
            console.log("Good Job, you have updated your password..");
            //if everything is okay
            this.resultErrMsg = "";
            this.resultErr = false;
            this.resultSuccess = true;
            this.resultSuccessMsg = resultData.message;
            localStorage.setItem(
              "current-user-data",
              JSON.stringify(resultData.current_user_data[0])
            );
            setTimeout(() => {
              this.$router.push({
                name: "Account",
              });
            }, 2000);
          }
        }
      } else {
        this.changePasswordBtn = true;
        this.validateUserPasswordInput(this.userPassword);
        this.resultSuccessMsg = "";
        this.resultSuccess = false;

        this.resultErrMsg = "Must enter a new password";
        this.resultErr = true;
      }
    },
  },
};
</script>

<style scoped>
.form-control:disabled,
.form-control[readonly] {
  background-color: #ffffff;
}

.noResize {
  resize: none;
}
</style>
