<template>
  <div class="container">
    <h1 class="text-center">Reset Your Password</h1>
    <div>
      <!-- Reset User Password -->
      <form v-if="is_DB_Connected" method="post" action="#" enctype="multipart/form-data">
        <div class="row g-3 align-items-center mb-3">
          <div class="col-auto d-block mx-auto">
            <div class="form-floating" :class="{ 'form-data-error': userEmailErr }">
              <input autofocus type="email" id="userEmailInput" placeholder="Enter Your Email" class="form-control w300"
                v-model.trim="userEmail" />
              <label for="userEmailInput">
                <faIcons :icon="['fas', 'envelope']" /> Enter Your Email
              </label>
              <span class="error-feedback" v-if="userEmailErr">
                {{ userEmailMsg }}
              </span>
            </div>
          </div>
        </div>
        <div class="row d-grid gap-2 w300 col-auto d-block mx-auto mb-3">
          <button class="btn btn-outline-primary" @click.prevent="resetUserPassword()" v-if="resetUserPasswordBtn">
            <faIcons :icon="['fas', 'redo']" /> Reset My Password
          </button>
        </div>
        <div class="row d-grid gap-2 w300 col-auto d-block mx-auto mb-3">
          <div class="alert alert-success" role="alert" v-if="resultSuccess" v-html="resultSuccessMsg"></div>
          <div class="alert alert-danger" role="alert" v-if="resultErr" v-html="resultErrMsg"></div>
        </div>
        <div class="row d-grid gap-2 w300 col-auto d-block mx-auto mb-3">
          <!-- <hr class="custom-hr-150" /> -->
          <router-link class="btn btn-outline-secondary" :to="{ name: 'Login' }">
            <faIcons :icon="['fas', 'user-tie']" /> Have an account, login now
          </router-link>
        </div>
      </form>
      <div v-else>
        <div role="alert" class="alert alert-danger fade show">
          Server's Connection is down
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import { mapState, mapMutations, mapGetters } from "vuex";
export default {
  name: "LoginPage",
  data() {
    return {
      userEmail: "",
      userEmailErr: false,
      userEmailMsg: "",
      isuserEmailValidated: false,
      resultSuccess: false,
      resultSuccessMsg: "",
      resultErr: false,
      resultErrMsg: "",
      image: "",
      is_DB_Connected: true,
      db_connection_msg: "",
      // apiMainUrl: "http://localhost/php/login-system-php-vue-2021/myData.php",
      apiMainUrl: this.$store.getters.MainUrl, //call global variable
      resetUserPasswordBtn: true,
    };
  },
  mounted() {
    this.dbConnection();
    //if user is already logged in, can't visit this page
    let user = localStorage.getItem("current-user-data");
    if (user) {
      this.$router.push({ name: "Home" });
    }
  },
  computed: {
    ...mapState([
      "__UserId",
      "__UserEmail",
      "__UserRole",
      "__UserName",
      "__MainUrl",
    ]),
    ...mapGetters(["MainUrl"]),
  },
  methods: {
    ...mapMutations(["setUserInfo"]),
    async dbConnection() {
      let res = await axios.post(this.apiMainUrl);
      const resData = res.data;
      this.is_DB_Connected = resData.is_db_connected;
      this.db_connection_msg = resData.connection_msg;
    },
    validateUserEmail(e) {
      let val = e.target.value;
      this.validateUserEmailInput(val);
    },
    validateUserEmailInput(val) {
      //   let emailIs = /^\w+([-]?\w+)*@\w+([-]?\w+)*(\w{2,3})+$/.test(val);
      //   let emailIs = /^\w+([-]?\w+)*@\w+([-]?\w+)*(\.\w{2,3})+$/.test(val);
      let emailIs = /\w+([-+.']\w+)*@\w+([-.]\w+)*\.\w+([-.]\w+)*/.test(val);
      if (val == "") {
        this.userEmailErr = true;
        this.isuserEmailValidated = false;
        this.userEmailMsg = "Must Enter User Email";
      } else if (!emailIs && val != "") {
        this.userEmailErr = true;
        this.isuserEmailValidated = false;
        this.userEmailMsg = "Must Enter Valid Email";
      } else {
        this.userEmailErr = false;
        this.isuserEmailValidated = true;
        this.userEmailMsg = "";
      }
    },
    addZero(i) {
      if (i < 10) {
        i = "0" + i;
      }
      return i;
    },
    async resetUserPassword() {
      this.resultErr = false;
      this.resultErrMsg = "";
      this.resultSuccess = true;
      this.resultSuccessMsg =
        "Please wait while we're sending your request...";
      this.validateUserEmailInput(this.userEmail);
      if (this.isuserEmailValidated) {
        this.resetUserPasswordBtn = false;
        //show Result
        let fd = new FormData();
        fd.append("email", this.userEmail);
        let today = new Date();
        let start_date =
          today.getFullYear() +
          "-" +
          (today.getMonth() + 1) +
          "-" +
          today.getDate() +
          " " +
          this.addZero(today.getHours()) +
          ":" +
          this.addZero(today.getMinutes()) +
          ":" +
          this.addZero(today.getSeconds());
        let end_date =
          today.getFullYear() +
          "-" +
          (today.getMonth() + 1) +
          "-" +
          (today.getDate() + 1) +
          " " +
          this.addZero(today.getHours()) +
          ":" +
          this.addZero(today.getMinutes()) +
          ":" +
          this.addZero(today.getSeconds());
        // let start_date =
        //   today.getFullYear() +
        //   "-" +
        //   (today.getMonth() + 1) +
        //   "-" +
        //   today.getDate() +
        //   " " +
        //   today.getHours() +
        //   ":" +
        //   today.getMinutes() +
        //   ":" +
        //   today.getSeconds();
        // let end_date =
        //   today.getFullYear() +
        //   "-" +
        //   (today.getMonth() + 1) +
        //   "-" +
        //   (today.getDate() + 1) +
        //   " " +
        //   today.getHours() +
        //   ":" +
        //   today.getMinutes() +
        //   ":" +
        //   today.getSeconds();
        fd.append("timeStart", start_date);
        fd.append("timeEnd", end_date);
        let rst = await axios.post(
          this.apiMainUrl + `?action=resetPasswordRequest`,
          fd
        );
        console.log(rst);
        const resultData = rst.data;
        if (rst.status == 200) {
          if (resultData.error) {
            console.warn("Error Happened on Back-End");
            this.resultErrMsg = resultData.message;
            this.resultErr = true;
            this.resultSuccessMsg = "";
            this.resultSuccess = false;
            this.resetUserPasswordBtn = true;
          } else {
            this.resetUserPasswordBtn = false;
            console.log("Validated Successfully");
            //if everything is okay
            this.resultErrMsg = "";
            this.resultErr = false;
            this.resultSuccess = true;
            this.resultSuccessMsg = resultData.message;
            // localStorage.setItem(
            //   "current-user-data",
            //   JSON.stringify(resultData.current_user_data[0])
            // );
            // setTimeout(() => {
            //   this.$router.push({
            //     name: "Home",
            //   });
            // }, 2000);
          }
        }
      } else {
        this.resetUserPasswordBtn = true;
        this.validateUserEmailInput(this.userEmail);
        this.resultSuccessMsg = "";
        this.resultSuccess = false;

        this.resultErrMsg = "Must Enter Correct Data";
        this.resultErr = true;
      }
    },
  },
};
</script>

<style>
.w300 {
  width: 300px !important;
}

.w150 {
  width: 150px !important;
}

.h125 {
  height: 125px !important;
}

.form-data-error {
  color: red;
}

.blueInc {
  color: #0d6efd !important;
}

.form-data-error input,
.form-data-error textarea {
  border-color: red;
}

.error-feedback {
  padding-left: 3px;
  font-size: 0.9rem;
}

.customFileField {
  padding-left: 24px !important;
  padding-top: 38px !important;
  padding-bottom: 10px !important;
  height: 75px !important;
}

.custom-hr-150 {
  box-sizing: content-box;
  height: 0;
  overflow: visible;
  width: 155px !important;
  margin: auto !important;
}
</style>
