<template>
  <div>
    <div class="container" v-if="showOrderData">
      <div
        v-if="doesUrlLookGood"
        class="col-auto d-block mx-auto col-12 col-sm-5 main-order-select-date-custom"
      >
        <div class="m-3 text-center d-print-none">
          <button class="btn btn-primary" @click="goBackOrders()">
            <faIcons
              class="d-print-none pointerHand"
              :icon="['fas', 'store']"
            />
            Orders
          </button>
          &nbsp;
          <button class="btn btn-primary" @click="goBackOrderReports()">
            <faIcons
              class="d-print-none pointerHand"
              :icon="['fas', 'file-invoice']"
            />
            Reports
          </button>
          &nbsp; &nbsp;
          <button class="btn btn-secondary" @click="printSection2('print')">
            <faIcons
              class="d-print-none pointerHand"
              :icon="['fas', 'print']"
            />
            Reciept
          </button>
        </div>
        <div class="alert alert-info">
          <div>
            Customer Name: {{ capitalizeFisrtLetterOfEachWord(custName) }}
          </div>
          <div v-if="currentOrderInDetails.cust_phone != ''">
            Customer Phone:
            {{ formatPhoneNumber(currentOrderInDetails.cust_phone) }}
          </div>
        </div>
        <div id="print">
          <h3 class="text-bold text-center">
            {{ capitalizeFisrtLetterOfEachWord(businessName) }}
          </h3>
          <div class="text-center">
            <p>{{ capitalizeFisrtLetterOfEachWord(pickUpAddress) }}</p>
            <p>{{ formatPhoneNumber(pickUpPhoneNumber) }}</p>
          </div>
          <hr class="bg-info" />
          <h3 class="text-center" v-if="currentOrderInDetails.has_refund == 1">
            Refund Reciept
          </h3>
          <h3 class="text-center" v-else>Order Reciept</h3>
          <div class="clearfix"></div>
          <p class="float-start text-bold">
            {{
              new Date(
                this.currentOrderInDetails.order_placed_on
              ).toLocaleDateString()
            }}
            {{
              removeSecondsFromTime(
                new Date(
                  this.currentOrderInDetails.order_placed_on
                ).toLocaleTimeString()
              )
            }}
          </p>
          <p class="float-end text-bold">#{{ orderSequenceNumber }}</p>
          <div class="clearfix"></div>
          <br />
          <p
            class="d-flex text-bold"
            v-if="
              this.currentOrderInDetails
                .is_order_custom_date_and_time_or_right_away == 'custom'
            "
          >
            Pick Up
            {{ formatDateFormulas(this.currentOrderInDetails.custom_date) }}
            {{
              timeFormat12Hours(
                removeSecondsFromTime(this.currentOrderInDetails.custom_time)
              )
            }}
          </p>
          <br />
          <div class="clearfix"></div>
          <div
            class="d-flex justify-content-center d-print-none"
            v-if="currentOrderInDetails.has_refund == 1"
          >
            <div class="alert alert-danger biggerFont text-center">
              Cancelled and Refunded ${{
                (
                  parseFloat(currentOrderInDetails.order_subtotal) +
                  parseFloat(currentOrderInDetails.total_tips_added)
                ).toFixed(2)
              }}<br />
              {{
                new Date(
                  this.currentOrderInDetails.cancelled_at
                ).toLocaleDateString()
              }}
              {{
                removeSecondsFromTime(
                  new Date(
                    this.currentOrderInDetails.cancelled_at
                  ).toLocaleTimeString()
                )
              }}
              <span
                class="d-print-none"
                :id="'returnedBy-' + currentOrderInDetails.order_id"
                ><br />{{
                  getUserName(
                    currentOrderInDetails.refunded_by,
                    "returnedBy-" + currentOrderInDetails.order_id
                  )
                }}</span
              >
            </div>
          </div>
          <div class="clearfix"></div>
          <p
            v-if="currentOrderInDetails.has_refund == 1"
            class="text-center redColor"
          ></p>
          <hr
            class="bg-info m-2"
            v-if="currentOrderInDetails.has_refund == 1"
          />
          <div
            v-for="(o, index) in currentRealOrder"
            :key="index"
            class="each-item-ordered m-2"
          >
            <p class="fb">
              {{ capitalizeFisrtLetterOfEachWord(o.name)
              }}<span class="float-end"
                >${{ parseFloat(o.price).toFixed(2) }}</span
              >
            </p>
            <div class="custom-order m-2" v-if="o.isCustom">
              <div v-for="option in o.options" :key="option">
                <p class="custom-name">
                  {{ capitalizeFisrtLetterOfEachWord(option.name) }}
                  <span class="custom-price float-end" v-if="option.price > 0"
                    >+${{ option.price }}</span
                  >
                  <span class="custom-price float-end" v-else>
                    &nbsp;&nbsp;
                  </span>
                </p>
                <div class="clearfix"></div>
              </div>
              <div class="clearfix"></div>
              <p
                class="custom-name float-end total-end"
                v-if="o.total_item_cost > o.price"
              >
                Total ${{ o.total_item_cost }}
              </p>
              <div class="clearfix"></div>
            </div>
            <div class="clearfix"></div>
            <br />
          </div>
          <div class="clearfix"></div>
          <!-- <p
            class="text-bold alert alert-danger"
            v-if="currentOrderInDetails.order_instructions != ''"
          >
            Special Instruction <br />{{
              currentOrderInDetails.order_instructions
            }}
          </p> -->
          <div class="clearfix"></div>
          <hr class="bg-info d-print-none" />
          <div>
            <p class="fb mb5">
              Sales
              <span class="float-end"
                >${{ currentOrderInDetails.order_total }}</span
              >
            </p>
            <!-- <p>Service <span class="float-end">${{ 1.49 }}</span></p> -->
            <p class="fb mb5">
              Taxes ({{ currentOrderInDetails.taxes_percentage }}%)<span
                class="float-end"
                >${{ currentOrderInDetails.order_taxes_calculated }}</span
              >
            </p>
            <!-- <p class="fb mb5" v-if="tips > 0">
              Tips<span class="float-end">${{ parseFloat(tips).toFixed(2) }}</span>
            </p> -->
            <p class="fb mb5">
              Subtotal
              <span class="float-end"
                >$<span id="final-price">{{
                  (
                    parseFloat(currentOrderInDetails.order_subtotal) +
                    parseFloat(tips)
                  ).toFixed(2)
                }}</span></span
              >
            </p>
          </div>
          <hr class="bg-info d-print-none" />
          <p class="fb mb5 text-center d-print-none">
            Trans ID {{ currentOrderInDetails.trans_id }}
          </p>
          <p
            class="fb mb5 text-center"
            v-if="currentOrderInDetails.has_refund == 1"
          >
            Sorry for the Inconvenience,
            {{ capitalizeFisrtLetterOfEachWord(custName) }}!
          </p>
          <p class="fb mb5 text-center" v-else>
            Thank you for your order,
            {{ capitalizeFisrtLetterOfEachWord(custName) }}!
          </p>
        </div>
      </div>
      <div class="container-fluid" v-else>
        <div class="d-flex aligns-items-center justify-content-center">
          <div class="m-5 alert alert-danger">
            Make sure you have the right online order page.
          </div>
        </div>
      </div>
    </div>
    <div class="container-fluid" v-else>
      <div class="d-flex aligns-items-center justify-content-center">
        <div class="m-5 alert alert-danger">
          This order has is no longer available!
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
export default {
  name: "viewEachOrderDetails",
  data() {
    return {
      isOrderPlaced: this.$store.state.__IsOrderPlaced,
      addedMeal: this.$store.state.__AddedMealName,
      restId: this.$route.params.restId,
      currentOrderId: this.$route.params.orderNo,
      currentOrder: [],
      OrdersAPI: this.$store.getters.OrdersAPI,
      pickUpAddress: "",
      pickUpPhoneNumber: "",
      businessName: "",
      custName: "",
      orderData: "",
      custom_order_date_or_now: "",
      custEmail: "",
      showOrderData: true,
      showCustomerData: false,
      tips: 0,
      customerUrl: this.$store.getters.CustomerUrl,
      orderSequenceNumber: 0,
      custom_date_time: "",
      currentRealOrder: [],
      currentOrderInDetails: [],
      doesUrlLookGood: true,
    };
  },
  mounted() {
    this.canUserAccessPageNow();
  },
  methods: {
    formatPhoneNumber(number) {
      //remove . + -  from any number
      let cleaned = ("" + number).replace(/\D/g, "");
      let match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
      if (match) {
        return "(" + match[1] + ") " + match[2] + "-" + match[3];
      }
      return null;
    },
    async getUserName(userId, appendTo) {
      let fd = new FormData();
      fd.append("userId", userId);
      let rst = await axios.post(
        this.OrdersAPI + `?action=currentUserName`,
        fd
      );
      if (rst.status == 200) {
        const resultData = rst.data;
        if (!resultData.error) {
          console.log("current user name is " + resultData.user_name_is);
          let name = resultData.user_name_is;
          document.getElementById(appendTo).innerHTML =
            "<br />By " + this.capitalizeFisrtLetterOfEachWord(name);
        }
      }
    },
    canUserAccessPageNow() {
      let currentLoggedInUserAccessData = localStorage.getItem(
        "current_logged_user_access_data"
      );
      if (currentLoggedInUserAccessData === null) {
        console.log("no log in");
        this.canUserAccessThis = false;
      } else {
        //now only admin or manager
        let currentLoggedInUserData = JSON.parse(currentLoggedInUserAccessData);
        if (
          currentLoggedInUserData[0].user_role == "admn" ||
          currentLoggedInUserData[0].user_role == "mngr" ||
          currentLoggedInUserData[0].user_role == "cmngr"
        ) {
          console.log(
            "current user logged is " + currentLoggedInUserData[0].user_role
          );
          this.getCurrentOrderDetails(this.restId, this.currentOrderId);
          //   this.showOrderData = true;
        }
      }
    },
    printSection(id) {
      // Get HTML to print from element
      const prtHtml = document.getElementById(id).innerHTML;
      // Get all stylesheets HTML
      let stylesHtml = "";
      for (const node of [
        ...document.querySelectorAll('link[rel="stylesheet"], style'),
      ]) {
        stylesHtml += node.outerHTML;
      }
      // Open the print window
      const WinPrint = window.open(
        "",
        "",
        "left=0,top=0,width=800,height=900,toolbar=0,scrollbars=0,status=0"
      );

      WinPrint.document.write(`<!DOCTYPE html>
          <html>
          <head>
              ${stylesHtml}
          </head>
          <body>
              ${prtHtml}
          </body>
          </html>`);

      WinPrint.document.close();
      WinPrint.focus();
      WinPrint.print();
      WinPrint.close();
    },
    printSection2(id) {
      var restorepage = document.body.innerHTML;
      var printcontent = document.getElementById(id).innerHTML;
      document.body.innerHTML = printcontent;
      window.print();
      document.body.innerHTML = restorepage;
      setTimeout(() => {
        // window.open("", "_self").close();
        location.reload();
        // this.$router.push({ name: 'MyRestaurantChecks', params: { id: this.restNum, restId: this.restId } });
      }, 2000);
    },
    timeFormat12Hours(time) {
      // Check correct time format and split into components
      time = time
        .toString()
        .match(/^([01]\d|2[0-3])(:)([0-5]\d)(:[0-5]\d)?$/) || [time];

      if (time.length > 1) {
        // If time format correct
        time = time.slice(1); // Remove full string match value
        time[5] = +time[0] < 12 ? " AM" : " PM"; // Set AM/PM
        time[0] = +time[0] % 12 || 12; // Adjust hours
      }
      return time.join(""); // return adjusted time or original string
    },
    removeSecondsFromTime(timeIs) {
      //assuming time in 12:34:00 AM/PM format
      return timeIs.replace(/(\d{1,2}:\d{2}):\d{2}/, "$1");
    },
    capitalizeFisrtLetterOfEachWord(text) {
      return text
        .toLowerCase()
        .split(" ")
        .map((s) => s.charAt(0).toUpperCase() + s.substring(1))
        .join(" ");
    },
    displayCustomDateTime(dateIs, timeIs) {
      const [year, month, day] = dateIs.split("-");
      const result = [month, day, year].join("/");
      let n = result + " at " + this.timeFormat12Hours(timeIs);
      return n;
    },
    // displayCustomTime(dateIs, timeIs) {
    //   if (dateIs != "") {
    //     const [year, month, day] = dateIs.split("-");
    //     const result = [month, day, year].join("/");
    //     let n = result + " at " + this.timeFormat12Hours(timeIs);
    //     return n;
    //   } else {
    //     return this.timeFormat12Hours(timeIs);
    //   }
    // },
    goBackOrderReports() {
      this.$router.push({
        name: "ViewOrdersReports",
        params: { restId: this.restId },
      });
    },
    goBackOrders() {
      this.$router.push({
        name: "ViewOrders",
        params: { restId: this.restId },
      });
    },
    formatDateFormulas(dateIs) {
      const [year, month, day] = dateIs.split("-");
      const result = [month, day, year].join("/");
      let n = result;
      return n;
    },
    async getCurrentOrderDetails(restId, orderNum) {
      console.log(
        `find current order ${orderNum} for current restaurant ${restId}`
      );
      let fd = new FormData();
      fd.append("restId", restId);
      fd.append("orderNum", orderNum);
      let rst = await axios.post(
        this.customerUrl + `?action=viewCurrentOrderDetailsToCustomer`,
        fd
      );
      console.log(rst);
      const resultData = rst.data;
      if (rst.status == 200) {
        console.table(resultData);
        if (resultData.error) {
          console.log("this order was not found");
        } else {
          console.log("this order was found");
          localStorage.setItem(
            "current_order_details",
            JSON.stringify(resultData.current_order_details)
          );
          localStorage.setItem(
            "current_restaurants_business_data",
            JSON.stringify(resultData.current_restaurants_business_data)
          );
          // show current order details
          // let currentRestaurants = localStorage.getItem(
          //   "current_customer_order"
          // );
          // this.currentOrder = JSON.parse(currentRestaurants);
          //   let currentOrderData = localStorage.getItem("current_customer_order");
          //   let currentOrder = JSON.parse(currentOrderData);
          let currentRestaurantsData = JSON.parse(
            localStorage.getItem("current_restaurants_business_data")
          );
          if (
            resultData.current_order_details.length < 1 ||
            resultData.current_restaurants_business_data.length < 1
          ) {
            console.log("current Order Details does not exist");
            this.doesUrlLookGood = false;
          } else {
            this.doesUrlLookGood = true;
            console.log("current Order Details does exist");
            console.log("hello");
            this.pickUpAddress = currentRestaurantsData[0].rest_address;
            this.pickUpPhoneNumber = currentRestaurantsData[0].rest_phone;
            this.businessName = currentRestaurantsData[0].rest_name;
            // this.tips = currentOrder.tips_added;

            let currentOrderDetails = localStorage.getItem(
              "current_order_details"
            );
            this.currentOrderInDetails = JSON.parse(currentOrderDetails)[0];
            this.orderSequenceNumber = JSON.parse(currentOrderDetails)[0].id;
            let customDate = JSON.parse(currentOrderDetails)[0].custom_date;
            const [year, month, day] = customDate.split("-");
            const result = [month, day, year].join("/");
            let n = result;
            // let currentDateIs = new Date(
            //   new Date().getTime() - new Date().getTimezoneOffset() * 60000
            // )
            //   .toISOString()
            //   .split("T")[0];
            console.log("Custom Date is " + customDate);
            console.log("Custom Date is " + n);
            console.log("Custom Date Function is " + new Date(customDate));
            console.log("Today's Date is " + new Date().toLocaleDateString());
            console.log(
              "Today's Date is " +
                new Date().toLocaleString("en-US", {
                  year: "numeric",
                  month: "2-digit",
                  day: "2-digit",
                })
            );
            // let currentDayIs = new Date().toLocaleDateString("en-US", {
            //   year: "numeric",
            //   month: "2-digit",
            //   day: "2-digit",
            // });
            // let newCustomDate = new Date(customDate).toLocaleDateString("en-US", {
            //   year: "numeric",
            //   month: "2-digit",
            //   day: "2-digit",
            // });

            this.showOrderData = true;
            console.log("Order reciept can be accessed");
            this.custName = JSON.parse(currentOrderDetails)[0].cust_name;
            this.tips = parseFloat(
              this.currentOrderInDetails.total_tips_added
            ).toFixed(2);
            if (
              this.currentOrderInDetails
                .is_order_custom_date_and_time_or_right_away == "custom"
            ) {
              this.custom_date_time = this.displayCustomDateTime(
                this.currentOrderInDetails.custom_date,
                this.currentOrderInDetails.custom_time
              );
              // new Date(this.currentOrderInDetails.custom_date).toDateString() +
              // " at " +
              // this.timeFormat12Hours(this.currentOrderInDetails.custom_time);
            }
            this.orderData =
              this.currentOrderInDetails
                .is_order_custom_date_and_time_or_right_away == "now"
                ? "in 15 minutes"
                : "on " + this.custom_date_time;
            console.log("total tips paid is " + this.tips);
            console.table(JSON.parse(currentOrderDetails));
            let currentCustomerOrderIs =
              JSON.parse(currentOrderDetails)[0].customer_order;
            console.table(currentCustomerOrderIs);
            let currentOrderNewData = [];
            currentOrderNewData.push(currentCustomerOrderIs);
            let newDataOrderItemDetails = JSON.parse(currentOrderNewData); //this will hold order details
            console.table(newDataOrderItemDetails);
            this.currentRealOrder = newDataOrderItemDetails;
            // console.log("Order Name " + newDataOrderItemDetails[1].name);
            // if (currentDayIs <= n) {
            // } else {
            //   this.showOrderData = false;
            //   console.log("you can't view this order");
            // }
          }
        }
      }
    },
  },
};
</script>

<style>
.addressStyle {
  font-style: italic;
  padding: 0;
  margin: 0;
  line-height: 0.5;
}

.itemDataDetails {
  padding: 10px;
  margin-bottom: 10px;
  border: 10px solid #eee;
}

.itemDataDetails img {
  width: 320px;
  height: 215px;
  margin-bottom: 10px;
}

.bw {
  overflow-wrap: break-word;
}

.darkred {
  color: darkred;
}

.order-placed {
  padding: 10px;
}

.order-placed h2 {
  color: green;
}

.addressInfo {
  display: block;
  font-style: italic;
  font-weight: bold;
}

.card-header {
  background-color: #008c5d !important;
  font-weight: bold;
}

.mb5 {
  margin-bottom: 5px;
}

.text-bold {
  font-weight: bold;
}

.card-header a,
.card-header a:hover,
.card-header a:visited,
.card-header a:active,
.card-header a:focus {
  color: white;
  display: block;
  text-align: left;
  border: none;
}

.card-header .btn-check:focus + .btn,
.card-header .btn:focus {
  box-shadow: none !important;
}

.fb {
  font-weight: bold;
}

.custom-order {
  margin-bottom: 10px;
}

.custom-name {
  padding-left: 20px;
  margin-bottom: -5px;
  clear: both;
  margin-top: -14px;
}

.total-end {
  font-weight: bold;
  margin-top: 5px;
}

.slidecontainer {
  width: 100%;
}

.slider {
  -webkit-appearance: none;
  width: 100%;
  height: 15px;
  border-radius: 5px;
  background: #d3d3d3;
  outline: none;
  opacity: 0.7;
  -webkit-transition: 0.2s;
  transition: opacity 0.2s;
}

.slider:hover {
  opacity: 1;
}

.slider::-webkit-slider-thumb {
  -webkit-appearance: none;
  appearance: none;
  width: 25px;
  height: 25px;
  border-radius: 50%;
  background: #04aa6d;
  cursor: pointer;
}

.slider::-moz-range-thumb {
  width: 25px;
  height: 25px;
  border-radius: 50%;
  background: #04aa6d;
  cursor: pointer;
}

.btns-danger,
.btns-danger:hover,
.btns-danger:active {
  color: red !important;
  background-color: white !important;
  border-color: red !important;
  font-weight: bold !important;
}

.redColor {
  color: red;
}

@media print {
  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  p,
  div,
  body,
  hr,
  .redColor,
  button,
  .btn,
  .biggerFont,
  .alert-danger,
  .btns-danger {
    font-size: 10pt;
    color: black !important;
    font-weight: bold !important;
    font-family: monospace !important;
    font-variant-caps: normal !important;
    font-optical-sizing: auto !important;
    font-style: normal !important;
    font-variant: proportional-nums !important;
    background-color: white !important;
  }

  hr {
    display: flex;
    margin-bottom: 3px;
    height: 0;
  }

  .btns-danger,
  .btns-danger:hover,
  .btns-danger:active {
    color: black !important;
    background-color: white !important;
    border-color: black !important;
    font-weight: bold !important;
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    font-size: 15pt;
    font-family: monospace !important;
  }

  .alert-danger {
    background-color: none;
    border-radius: 5px;
    border: 1px solid #e2e2e2;
  }

  .each-order-item {
    border: none !important;
  }
}
</style>
