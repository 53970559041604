<template>
  <div class="modal-dialog modal-dialog-scrollable">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title text-success">Update Current Hours</h5>
        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
      </div>
      <div class="modal-body itemDataDetails">
        <p class="fb text-center">
          {{ this.capitalizeFisrtLetterOfEachWord(userName) }}
          | {{ userId }}
        </p>
        <div class="form-floating m-2" :class="datePickerUpdateError ? 'error-data' : ''">
          <input type="datetime-local" class="form-control" placeholder="Starting Date & Time"
            :aria-label="'startingDateUpdate-' + id" :aria-describedby="'startingDateUpdate-' + id"
            v-model="currentStartingUpdateDateAndTime" @change="updateCurrentUserHoursCheck()"
            @input="updateCurrentUserHoursCheck()" @keyup="updateCurrentUserHoursCheck()" />
          <label :for="'startingDateUpdate-' + id">
            <faIcons :icon="['fas', 'calendar']" />
            Starting Date & Time
          </label>
        </div>
        <div class="form-floating m-2" :class="datePickerUpdateError ? 'error-data' : ''">
          <input type="datetime-local" class="form-control" placeholder="Ending Date & Time"
            :aria-label="'endingDateUpdate-' + id" :aria-describedby="'endingDateUpdate-' + id"
            v-model="currentEndingUpdateDateAndTime" @change="updateCurrentUserHoursCheck(id, userId)"
            @input="updateCurrentUserHoursCheck(id, userId)" @keyup="updateCurrentUserHoursCheck(id, userId)" />
          <label :for="'endingDateUpdate-' + id">
            <faIcons :icon="['fas', 'calendar']" />
            Ending Date & Time
          </label>
        </div>
      </div>
      <div class="modal-footer d-flex">
        <button class="btn btn-primary" data-bs-dismiss="modal" aria-label="Close">
          Never mind
        </button>
        <button class="btn btn-success" :id="'btn-' + id" :class="disaledUpdateButton ? '' : 'disabled'"
          @click="updateCurrentUserHours()">
          Update Hours
        </button>
      </div>
      <div class="alert alert-danger m-3" v-if="datePickerUpdateError">
        {{ datePickerUpdateMsg }}
      </div>
      <div class="alert alert-success m-3" v-if="datePickerUpdateSuccess">
        {{ datePickerUpdateMsg }}
      </div>
    </div>
  </div>
</template>
<script>
import axios from "axios";
export default {
  name: "UpdateCurrentUserTimsheet",
  data() {
    return {
      disaledUpdateButton: true,
      restId: this.$route.params.restId,
      id: "", //current user access id
      currentUserId: "", //current user access id
      updateBy: "", //who is updating this hours
      OrdersAPI: this.$store.getters.OrdersAPI,
      datePickerUpdateError: false,
      datePickerUpdateSuccess: false,
      currentEndingUpdateDateAndTime: "",
      currentStartingUpdateDateAndTime: "",
      name: "",
      datePickerUpdateMsg: "",
    };
  },
  //getCurrentUsersTimeSheets is a function that will return new updated hours localstorage
  props: {
    userId: [String, Number],
    userName: String,
    start: String,
    end: String,
    eachRow: [String, Number],
    getCurrentUsersTimeSheets: Function,
  },
  mounted() {
    this.updateBy = JSON.parse(
      localStorage.getItem("current_user_access_id")
    ).id;
    this.id = this.eachRow;
    this.currentUserId = this.userId;
    this.currentStartingUpdateDateAndTime = this.start;
    this.currentEndingUpdateDateAndTime = this.end;
    this.name = this.userName;
  },
  methods: {
    capitalizeFisrtLetterOfEachWord(text) {
      return text
        .toLowerCase()
        .split(" ")
        .map((s) => s.charAt(0).toUpperCase() + s.substring(1))
        .join(" ");
    },
    updateCurrentUserHoursCheck() {
      if (
        this.currentEndingUpdateDateAndTime == "" ||
        this.currentStartingUpdateDateAndTime == ""
      ) {
        this.datePickerUpdateError = true;
        this.datePickerUpdateMsg = "Both dates must be selected";
      } else if (
        new Date(this.currentEndingUpdateDateAndTime) <=
        new Date(this.currentStartingUpdateDateAndTime)
      ) {
        this.datePickerUpdateError = true;
        this.datePickerUpdateMsg = "Starting date has to be less ending date";
      } else if (this.userId == "" || this.updateBy == "") {
        this.datePickerUpdateError = true;
        this.datePickerUpdateMsg = "Something is wrong, refresh your page";
      } else {
        this.datePickerUpdateError = false;
        this.datePickerUpdateMsg = "";
      }
    },
    async updateCurrentUserHours() {
      this.datePickerUpdateError = false;
      this.datePickerUpdateSuccess = true;
      this.datePickerUpdateMsg = "Please wait while we're updating current hours...";;
      this.updateCurrentUserHoursCheck();
      if (this.datePickerUpdateError) {
        this.updateCurrentUserHoursCheck();
      } else {
        let fd = new FormData();
        fd.append("restId", this.restId);
        fd.append("addedBy", this.updateBy);
        fd.append("accessId", this.currentUserId);
        fd.append(
          "start",
          this.currentStartingUpdateDateAndTime
            .replace("T", " ")
            .replace("Z", "")
        );
        fd.append(
          "end",
          this.currentEndingUpdateDateAndTime.replace("T", " ").replace("Z", "")
        );
        fd.append("id", this.id);
        let rst = await axios.post(
          this.OrdersAPI + `?action=updateCurrentUserHours`,
          fd
        );
        const resultData = rst.data;
        if (rst.status == 200) {
          console.table(resultData);
          if (resultData.error) {
            console.log("There are errors");
            this.datePickerUpdateError = true;
            this.datePickerUpdateSuccess = false;
            this.datePickerUpdateMsg = resultData.message;
          } else {
            var currentTimesheet = JSON.parse(
              localStorage.getItem("current_users_timesheet")
            );
            for (let i = 0; i < currentTimesheet.length; i++) {
              if (
                currentTimesheet[i].id === this.id &&
                currentTimesheet[i].rest_id === this.restId
              ) {
                currentTimesheet[i].time_in =
                  this.currentStartingUpdateDateAndTime;
                currentTimesheet[i].time_in_by = this.updateBy;
                currentTimesheet[i].time_out =
                  this.currentEndingUpdateDateAndTime;
                currentTimesheet[i].time_out_by = this.updateBy;
                currentTimesheet[i].is_logged_out = 1;
              }
            }
            localStorage.setItem(
              "current_users_timesheet",
              JSON.stringify(currentTimesheet)
            );
            let newUsersTimesheet = JSON.parse(
              localStorage.getItem("current_users_timesheet")
            );
            this.getCurrentUsersTimeSheets(newUsersTimesheet); //return this method to parent function
            this.datePickerUpdateError = false;
            this.datePickerUpdateSuccess = true;
            this.datePickerUpdateMsg =
              "Awesome, new hours updated is/are " +
              (
                Math.abs(
                  new Date(this.currentEndingUpdateDateAndTime) -
                  new Date(this.currentStartingUpdateDateAndTime)
                ) / 3600000
              ).toFixed(2);
            this.disaledUpdateButton = false;
            setTimeout(() => {
              this.disaledUpdateButton = true;
              this.datePickerUpdateMsg = "";
              //disabled all errors and success messages after 5 seconds
              this.datePickerUpdateSuccess = false;
              this.datePickerUpdateError = false;
            }, 5000);
          }
        }
      }
    },
  },
};
</script>

<style>

</style>
