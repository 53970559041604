<template>
  <div class="container-fluid">
    <router-view/>
  </div>
</template>

<style lang="scss">
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
}
@font-face {
  font-family: 'numbers';
  // src: url('https://order.hooksfishnchips.com/fonts/micr-encoding.regular.ttf') format('truetype');
  src: url('./fonts/micr-encoding.regular.ttf') format('truetype');
}
[v-cloak] > * {
  display: none;
}
[v-cloak]::before {
  content: "Loading…";
}
</style>
