<template>
  <div>
    <div
      class="modal fade"
      tabindex="-1"
      id="customerOrderModal"
      aria-labelledby="customerOrderModal"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-dialog-scrollable">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">Current Order</h5>
            <button
              type="button"
              class="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            ></button>
          </div>
          <div class="modal-body itemDataDetails" id="showOrderDetails">
            <div v-if="showOrderData">
              <div
                v-for="(o, index) in currentOrder.order"
                :key="index"
                class="each-item-ordered"
              >
                <p class="fb">
                  <faIcons
                    class="text-danger cursorPointer"
                    :icon="['fas', 'times']"
                    @click="removeItemFromOrder(index, o.total_item_cost)"
                  />
                  {{ capitalizeFisrtLetterOfEachWord(o.name)
                  }}<span class="float-end">{{ number_format(o.price) }}</span>
                </p>
                <div class="custom-order" v-if="o.isCustom">
                  <div v-for="option in o.options" :key="option">
                    <p class="custom-name">
                      {{ capitalizeFisrtLetterOfEachWord(option.name) }}
                      <span
                        class="custom-price float-end"
                        v-if="option.price > 0"
                        >+{{ number_format(option.price) }}</span
                      >
                      <span class="custom-price float-end" v-else>
                        &nbsp;&nbsp;
                      </span>
                    </p>
                    <div class="clearfix"></div>
                  </div>
                  <div class="clearfix"></div>
                  <p
                    class="custom-name float-end total-end"
                    v-if="o.total_item_cost > o.price"
                  >
                    Total {{ number_format(o.total_item_cost) }}
                  </p>
                  <div class="clearfix"></div>
                </div>
                <!-- <button
                  class="btn btn-outline-danger float-end"
                  @click="removeItemFromOrder(index, o.total_item_cost)"
                >
                  <faIcons :icon="['fas', 'times']" /> Remove
                </button> -->
                <div class="clearfix"></div>
                <hr class="bg-info" />
              </div>
              <div class="clearfix"></div>
              <div>
                <p class="fb mb5">
                  Sales
                  <span class="float-end">{{
                    number_format(currentOrder.total)
                  }}</span>
                </p>
                <!-- <p>Service <span class="float-end">${{ 1.49 }}</span></p> -->
                <p class="fb mb5">
                  Taxes ({{ currentOrder.taxes_percentage }}%)<span
                    class="float-end"
                    >{{ number_format(currentOrder.taxes) }}</span
                  >
                </p>
                <!-- <p class="fb mb5">
                  Tips<span class="float-end">{{
                    number_format(parseFloat(tips).toFixed(2))
                  }}</span>
                </p> -->
                <p class="fb mb5">
                  Subtotal
                  <span class="float-end"
                    ><span id="final-price">{{
                      number_format(
                        (
                          parseFloat(currentOrder.sub_total) + parseFloat(tips)
                        ).toFixed(2)
                      )
                    }}</span></span
                  >
                </p>
                <div class="mb-3" v-if="currentOrder.total > 0">
                  <!-- <CustomerAndOrderNotesDetails /> -->
                  <hr class="bg-info" />
                  <!-- 
                  <div class="d-grid gap-3">
                      <button class="btn btn-success float-start" data-bs-toggle="modal"
                                            data-bs-target="#customerAndOrderNotesDetails" type="button"
                                            :disabled="!this.$store.state.__CartTotal > 0 ">
                                            View my order
                                        </button> 
                  </div>
                                        -->
                </div>
              </div>
              <!-- <div class="d-grid gap-3">
                <div class="slidecontainer">
                  <input
                    type="range"
                    min="0"
                    step="0.5"
                    max="100"
                    v-model="tips"
                    @input="addTips($event)"
                    @change="addTips($event)"
                    class="slider"
                    id="myRange"
                    :disabled="
                      this.$store.state.__CartTotal == 0 ||
                      this.$store.state.__CartTotal == '0.00'
                    "
                  />
                  <p class="text-muted text-center text-small">
                    <small
                      >Tips Added
                      <span id="demo">{{
                        number_format(parseFloat(tips).toFixed(2))
                      }}</span></small
                    >
                    &nbsp;
                    <small>Slide right/left to change</small>
                  </p>
                </div>
              </div> -->
              <div class="d-grid gap-3">
                <button
                  class="btn btn-success btn-block"
                  type="button"
                  :disabled="
                    this.$store.state.__CartTotal == 0 ||
                    this.$store.state.__CartTotal == '0.00'
                  "
                  @click="showCustomerDataModal()"
                >
                  <faIcons :icon="['fas', 'dollar-sign']" /> Proceed to pay
                </button>
              </div>
            </div>
            <div v-if="showCustomerData" class="d-grid gap-3">
              <div class="col-auto d-block mx-auto">
                <button
                  class="btn btn-outline-primary btn-block w300"
                  type="button"
                  id="goBackButtonCartPayment"
                  :disabled="!this.$store.state.__CartTotal > 0"
                  @click="showCustomerOrderDataModal()"
                >
                  <faIcons :icon="['fas', 'arrow-left']" /> Review my order
                </button>
              </div>
              <div class="d-grid gap-3">
                <CustomerAndOrderNotesDetails />
              </div>
            </div>
          </div>
          <div id="showOrderPaymentSuccessMessage" class="d-none">
            <div class="order-placed">
              <h2 class="text-center">Your order is placed</h2>
              <div>
                <p class="biggerCheck text-center">
                  <faIcons :icon="['fas', 'check-double']" />
                </p>
              </div>
              <p id="orderPaymentNumIs"></p>
              <p id="orderPaymentTransNumIs"></p>
              <!-- <p>
                Pick Up Location <br />
                <span class="addressInfo">{{ pickUpAddress }}</span>
              </p>
              <p>
                Phone Number <br />
                <span class="addressInfo">{{ pickUpPhoneNumber }}</span>
              </p> -->
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// import CustomerAndOrderNotesDetails from "./CustomerAndOrderNotesDetails.vue";
import CustomerAndOrderNotesDetails from "@/components/OnlineOrders/CustomerAndOrderNotesDetails.vue";
export default {
  name: "CustomerOrderModal",
  data() {
    return {
      isOrderPlaced: this.$store.state.__IsOrderPlaced,
      addedMeal: this.$store.state.__AddedMealName,
      currentOrder: [],
      pickUpAddress: "",
      pickUpPhoneNumber: "",
      showOrderData: true,
      showCustomerData: false,
      tips: 0,
    };
  },
  components: {
    CustomerAndOrderNotesDetails,
  },
  mounted() {
    // show current order details
    let currentRestaurants = localStorage.getItem("current_customer_order");
    this.currentOrder = JSON.parse(currentRestaurants);
    let currentOrderData = localStorage.getItem("current_customer_order");
    let currentRestaurantsData = localStorage.getItem(
      "current_restaurants_data_public"
    );
    let currentOrder = JSON.parse(currentOrderData);
    let currentRestaurant = JSON.parse(currentRestaurantsData).filter(
      (restInfo) => restInfo.rest_id == currentOrder.restId
    );
    console.table(currentRestaurant);
    console.log("hello");
    this.pickUpAddress = currentRestaurant[0].rest_address;
    this.pickUpPhoneNumber = currentRestaurant[0].rest_phone;
    // var currentOrderArr = JSON.parse(
    //   localStorage.getItem("current_customer_order")
    // );
    // currentOrderArr["tips_added"] = "1.00";
    // let script001 = document.createElement('link');
    // script001.setAttribute('rel', 'stylesheet');
    // script001.setAttribute('href', 'https://cdnjs.cloudflare.com/ajax/libs/twitter-bootstrap/4.1.3/css/bootstrap.min.css');
    // document.head.appendChild(script001);
    // let script01 = document.createElement("script");
    // script01.setAttribute("src", "https://code.jquery.com/jquery-3.3.1.min.js");
    // document.head.appendChild(script01);
    // // let script0 = document.createElement('script');
    // // script0.setAttribute('src', 'https://cdnjs.cloudflare.com/ajax/libs/popper.js/1.14.3/umd/popper.min.js');
    // // document.head.appendChild(script0);
    // // let script2 = document.createElement('script');
    // // script2.setAttribute('src', 'https://stackpath.bootstrapcdn.com/bootstrap/4.1.3/js/bootstrap.min.js');
    // // document.head.appendChild(script2);
    // let script1 = document.createElement("script");
    // script1.setAttribute("src", "https://checkout.stripe.com/checkout.js");
    // document.head.appendChild(script1);
    // let payFunction = document.createElement("script");
    // payFunction.setAttribute("src", "http://localhost/php/Resturant-POS-PHP-VUE-MODULE/checkout.js");
    // document.head.appendChild(payFunction);
  },
  methods: {
    addTips(e) {
      let myTips = parseFloat(e.target.value).toFixed(2);
      console.log("Tips added for this order is " + myTips);
      var currentOrderArr = JSON.parse(
        localStorage.getItem("current_customer_order")
      );
      currentOrderArr["tips_added"] = myTips;
      localStorage.setItem(
        "current_customer_order",
        JSON.stringify(currentOrderArr)
      );
    },
    showCustomerDataModal() {
      this.showOrderData = false;
      this.showCustomerData = true;
    },
    showCustomerOrderDataModal() {
      this.showOrderData = true;
      this.showCustomerData = false;
    },
    capitalizeFisrtLetterOfEachWord(text) {
      return text
        .toLowerCase()
        .split(" ")
        .map((s) => s.charAt(0).toUpperCase() + s.substring(1))
        .join(" ");
    },
    number_format(amount) {
      const price = amount;
      // Format the price above to USD, INR, EUR using their locales.
      let dollarUS = Intl.NumberFormat("en-US", {
        style: "currency",
        currency: "USD",
      });
      return dollarUS.format(price);
    },
    removeItemFromOrder(i, orderTotal) {
      // let i = parseFloat(i);
      var currentOrderArr = JSON.parse(
        localStorage.getItem("current_customer_order")
      );
      console.log("You are removing order #" + i);
      console.log(parseFloat(orderTotal).toFixed(2));
      //remove item from
      let valueToRemove = [this.currentOrder.order[i]];
      this.currentOrder.order = this.currentOrder.order.filter(
        (element) => !valueToRemove.includes(element)
      );
      let valueToRemove2 = [currentOrderArr["order"][i]];
      currentOrderArr["order"] = this.currentOrder.order.filter(
        (element) => !valueToRemove2.includes(element)
      );
      // this.currentOrder.order.splice(this.currentOrder.order[i], 1);
      // currentOrderArr["order"].splice(currentOrderArr["order"][i], 1);
      //update total, taxes calculated and subtotal
      let totalPrice = (
        parseFloat(currentOrderArr["total"]) - parseFloat(orderTotal)
      ).toFixed(2);
      currentOrderArr["total"] = parseFloat(totalPrice).toFixed(2);
      this.currentOrder.total = parseFloat(totalPrice).toFixed(2);
      let totalTaxes = (
        (parseFloat(totalPrice) *
          parseFloat(currentOrderArr["taxes_percentage"])) /
        100
      ).toFixed(2);
      currentOrderArr["taxes"] = parseFloat(totalTaxes).toFixed(2);
      this.currentOrder.taxes = parseFloat(totalTaxes).toFixed(2);
      let subTotalPirce = (
        parseFloat(totalTaxes) + parseFloat(totalPrice)
      ).toFixed(2);
      currentOrderArr["sub_total"] = parseFloat(subTotalPirce).toFixed(2);
      this.currentOrder.sub_total = parseFloat(subTotalPirce).toFixed(2);
      this.$store.state.__CartTotal = parseFloat(totalPrice).toFixed(2);
      localStorage.setItem(
        "current_customer_order",
        JSON.stringify(currentOrderArr)
      );
    },
  },
};
</script>

<style>
.itemDataDetails {
  padding: 10px;
  margin-bottom: 10px;
  border: 10px solid #eee;
}

.itemDataDetails img {
  width: 320px;
  height: 215px;
  margin-bottom: 10px;
}

.bw {
  overflow-wrap: break-word;
}

.darkred {
  color: darkred;
}

.order-placed {
  padding: 10px;
}

.order-placed h2 {
  color: green;
}

.addressInfo {
  display: block;
  font-style: italic;
  font-weight: bold;
}

.card-header {
  background-color: #008c5d !important;
  font-weight: bold;
}

.mb5 {
  margin-bottom: 5px;
}

.card-header a,
.card-header a:hover,
.card-header a:visited,
.card-header a:active,
.card-header a:focus {
  color: white;
  display: block;
  text-align: left;
  border: none;
}

.card-header .btn-check:focus + .btn,
.card-header .btn:focus {
  box-shadow: none !important;
}

.fb {
  font-weight: bold;
}

.custom-order {
  margin-bottom: 10px;
}

.custom-name {
  padding-left: 20px;
  margin-bottom: -5px;
  clear: both;
  margin-top: -14px;
}

.total-end {
  font-weight: bold;
  margin-top: 5px;
}

.slidecontainer {
  width: 100%;
}

/* .slider {
  -webkit-appearance: none;
  width: 100%;
  height: 15px;
  border-radius: 5px;
  background: #d3d3d3;
  outline: none;
  opacity: 0.7;
  -webkit-transition: 0.2s;
  transition: opacity 0.2s;
} */
.slider {
  -webkit-appearance: none;
  height: 10px !important;
  border-radius: 5px;
  /* background: #2f7e01; */
  background: #04aa6d !important;
  outline: none;
  opacity: 0.7;
  transition: opacity 0.2s;
}

.slider:hover {
  opacity: 1;
}

.slider::-webkit-slider-thumb {
  -webkit-appearance: none;
  appearance: none;
  width: 25px;
  height: 25px;
  border-radius: 50%;
  background: #2f7e01 !important;
  cursor: pointer;
}

.biggerCheck {
  font-size: 3em;
  color: green;
}

.slider::-moz-range-thumb {
  width: 25px;
  height: 25px;
  border-radius: 50%;
  background: #04aa6d;
  cursor: pointer;
}

.cursorPointer {
  cursor: pointer;
}
</style>
