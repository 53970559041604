<template>
  <div class="container-fluid">
    <Navbar />
    <nav style="--bs-breadcrumb-divider: '>'" aria-label="breadcrumb">
      <ol class="breadcrumb">
        <li class="breadcrumb-item">
          <router-link tag="li" :to="{ name: 'MyRestaurants' }">
            <a>My Restaurant(s)</a>
          </router-link>
        </li>
        <li v-if="this.isRestExists" class="breadcrumb-item active" aria-current="page">
          <router-link tag="li" :to="{
  name: 'CurrentRestaurantMenu',
  params: { id: restNum, restId: restId },
}">
            Menu
          </router-link>
        </li>
        <li v-if="this.isRestExists" class="breadcrumb-item active" aria-current="page">
          Menu Settings
        </li>
      </ol>
    </nav>
    <div v-if="this.isRestExists" class="add-new-restaurant-page">
      <p>
        {{ userName }}
        <faIcons :icon="['fas', 'map-pin']" />
        {{ userAddress }}
      </p>
      <!-- Add new category -->
      <form method="post" action="#" enctype="multipart/form-data">
        <div class="row g-3 align-items-center mb-3">
          <div class="col-auto d-block mx-auto">
            <h3 class="bgBlue">Online Orders</h3>
            <div class="form-floating" :class="{
  'form-data-error': isMenuActiveErr,
  'form-data-success': isMenuActiveSuccess,
}">
              <div class="form-check form-switch w300">
                <input class="form-check-input" type="checkbox" id="flexSwitchCheckChecked"
                  @change="ActivateOrDeactivateOnlineOrders()" v-model.trim="restAcceptingOrderStatus"
                  v-if="acceptOrdersBtn" />
                <label class="form-check-label" for="flexSwitchCheckChecked">Is restaurant Accepting Online
                  Orders?</label>
              </div>
            </div>
            <div class="row d-grid gap-2 w300 col-auto d-block mx-auto mb-3">
              <div class="alert alert-success" role="alert" v-if="isMenuActiveSuccess">
                {{ isMenuActiveMsg }}
              </div>
              <div class="alert alert-danger" role="alert" v-if="isMenuActiveErr">
                {{ isMenuActiveMsg }}
              </div>
            </div>
          </div>
        </div>
        <div class="row g-3 align-items-center mb-3">
          <div class="col-auto d-block mx-auto">
            <h3 class="bgBlue">Sales Taxes</h3>
            <div class="form-floating" :class="{ 'form-data-error': catPriceErr }">
              <input type="number" min="0" id="userNameInput" placeholder="Sales Taxes % is" class="form-control w300"
                @keyup="validateCategoryPrice($event)" @change="validateCategoryPrice($event)"
                v-model.trim="catPrice" />
              <label for="userNameInput">
                <faIcons :icon="['fas', 'sitemap']" /> Sales Taxes % is
              </label>
              <span class="error-feedback" v-if="catPriceErr">
                {{ catPriceMsg }}
              </span>
            </div>
          </div>
        </div>
        <div class="row d-grid gap-2 w300 col-auto d-block mx-auto mb-2">
          <button class="btn btn-outline-success" @click.prevent="AddNewCategory()" v-if="signUpBtn">
            <faIcons :icon="['fas', 'sitemap']" /> Update Taxes
          </button>
        </div>
        <div class="row d-grid gap-2 w300 col-auto d-block mx-auto mb-3">
          <div class="alert alert-success" role="alert" v-if="resultSuccess">
            {{ resultSuccessMsg }}
          </div>
          <div class="alert alert-danger" role="alert" v-if="resultErr">
            {{ resultErrMsg }}
          </div>
        </div>
        <div class="row d-grid gap-2 w300 col-auto d-block mx-auto mb-3">
          <router-link class="btn btn-outline-primary" :to="{
  name: 'CurrentRestaurantMenu',
  params: { id: restNum, restId: restId },
}">
            <faIcons :icon="['fas', 'undo']" /> Never mind, go back
          </router-link>
        </div>
      </form>
      <!--Show online order hours-->
      <div class="row g-3 align-items-center mb-1">
        <div class="col-auto d-block mx-auto">
          <h3 class="bgBlue mb-3">Online Order Hours</h3>
          <div class="form-floating" :class="{
  'form-data-error': hasMondayErr,
}">
            <div class="form-check form-switch w300">
              <input class="form-check-input" type="checkbox" id="hasMonday" @change="hasMondayCheck()"
                @enter="hasMondayCheck()" v-model.trim="hasMonday" />
              <label class="form-check-label" for="hasMonday">Monday</label>
            </div>
            <div class="row g-3 p4">
              <div class="col-auto p4">
                <span class="form-floating w150">
                  <input type="time" placeholder="Starts" class="form-control w150" id="monStartingAt"
                    @change="hasMondayCheck()" @enter.prevent="hasMondayCheck()" v-model.trim="mondayStart" />
                  <label for="monStartingAt">Staring</label>
                </span>
              </div>
              <div class="col-auto p4">
                <span class="form-floating w150">
                  <input type="time" placeholder="Ends" class="form-control w150" id="monEndingAt"
                    @change="hasMondayCheck()" @enter="hasMondayCheck()" v-model.trim="mondayEnds" />
                  <label for="monEndingAt">Ending</label>
                </span>
              </div>
            </div>
          </div>
          <div class="row d-grid gap-2 w300 col-auto d-block mx-auto mb-1">
            <div class="alert alert-danger" role="alert" v-if="hasMondayErr">
              {{ hasMondayMsg }}
            </div>
          </div>
        </div>
      </div>
      <div class="row g-3 align-items-center mb-1">
        <div class="col-auto d-block mx-auto">
          <div class="form-floating" :class="{
  'form-data-error': hasTuesdayErr,
}">
            <div class="form-check form-switch w300">
              <input class="form-check-input" type="checkbox" id="hasTuesday" @change="hasTuesdayCheck()"
                @enter="hasTuesdayCheck()" v-model.trim="hasTuesday" />
              <label class="form-check-label" for="hasTuesday">Tuesday</label>
            </div>
            <div class="row g-3 p4">
              <div class="col-auto p4">
                <span class="form-floating w150">
                  <input type="time" placeholder="Starts" class="form-control w150" id="tueStartingAt"
                    v-model.trim="tuesdayStart" @change="hasTuesdayCheck()" @enter="hasTuesdayCheck()" />
                  <label for="tueStartingAt">Staring</label>
                </span>
              </div>
              <div class="col-auto p4">
                <span class="form-floating w150">
                  <input type="time" placeholder="Ends" class="form-control w150" id="tueEndingAt"
                    v-model.trim="tuesdayEnds" @change="hasTuesdayCheck()" @enter="hasTuesdayCheck()" />
                  <label for="tueEndingAt">Ending</label>
                </span>
              </div>
            </div>
          </div>
          <div class="row d-grid gap-2 w300 col-auto d-block mx-auto mb-1">
            <div class="alert alert-danger" role="alert" v-if="hasTuesdayErr">
              {{ hasTuesdayMsg }}
            </div>
          </div>
        </div>
      </div>
      <div class="row g-3 align-items-center mb-1">
        <div class="col-auto d-block mx-auto">
          <div class="form-floating" :class="{
  'form-data-error': hasWednesdayErr,
}">
            <div class="form-check form-switch w300">
              <input class="form-check-input" type="checkbox" id="hasWednesday" @change="hasWednesdayCheck()"
                @enter="hasWednesdayCheck()" v-model.trim="hasWednesday" />
              <label class="form-check-label" for="hasWednesday">Wednesday</label>
            </div>
            <div class="row g-3 p4">
              <div class="col-auto p4">
                <span class="form-floating w150">
                  <input type="time" placeholder="Starts" class="form-control w150" id="wedStartingAt"
                    v-model.trim="wednesdayStart" @change="hasWednesdayCheck()" @enter="hasWednesdayCheck()" />
                  <label for="wedStartingAt">Staring</label>
                </span>
              </div>
              <div class="col-auto p4">
                <span class="form-floating w150">
                  <input type="time" placeholder="Ends" class="form-control w150" id="wedEndingAt"
                    v-model.trim="wednesdayEnds" @change="hasWednesdayCheck()" @enter="hasWednesdayCheck()" />
                  <label for="wedEndingAt">Ending</label>
                </span>
              </div>
            </div>
          </div>
          <div class="row d-grid gap-2 w300 col-auto d-block mx-auto mb-1">
            <div class="alert alert-danger" role="alert" v-if="hasWednesdayErr">
              {{ hasWednesdayMsg }}
            </div>
          </div>
        </div>
      </div>
      <div class="row g-3 align-items-center mb-1">
        <div class="col-auto d-block mx-auto">
          <div class="form-floating" :class="{
  'form-data-error': hasThursdayErr,
}">
            <div class="form-check form-switch w300">
              <input class="form-check-input" type="checkbox" id="hasThursday" @change="hasThursdayCheck()"
                @enter="hasThursdayCheck()" v-model.trim="hasThursday" />
              <label class="form-check-label" for="hasThursday">Thursday</label>
            </div>
            <div class="row g-3 p4">
              <div class="col-auto p4">
                <span class="form-floating w150">
                  <input type="time" placeholder="Starts" class="form-control w150" v-model.trim="thursdayStart"
                    @change="hasThursdayCheck()" @enter="hasThursdayCheck()" />
                  <label for="wedStartingAt">Staring</label>
                </span>
              </div>
              <div class="col-auto p4">
                <span class="form-floating w150">
                  <input type="time" placeholder="Ends" class="form-control w150" id="wedEndingAt"
                    v-model.trim="thursdayEnds" @change="hasThursdayCheck()" @enter="hasThursdayCheck()" />
                  <label for="wedEndingAt">Ending</label>
                </span>
              </div>
            </div>
          </div>
          <div class="row d-grid gap-2 w300 col-auto d-block mx-auto mb-1">
            <div class="alert alert-danger" role="alert" v-if="hasThursdayErr">
              {{ hasThursdayMsg }}
            </div>
          </div>
        </div>
      </div>
      <div class="row g-3 align-items-center mb-1">
        <div class="col-auto d-block mx-auto">
          <div class="form-floating" :class="{
  'form-data-error': hasFridayErr,
}">
            <div class="form-check form-switch w300">
              <input class="form-check-input" type="checkbox" id="hasFriday" @change="hasFridayCheck()"
                @enter="hasFridayCheck()" v-model.trim="hasFriday" />
              <label class="form-check-label" for="hasFriday">Friday</label>
            </div>
            <div class="row g-3 p4">
              <div class="col-auto p4">
                <span class="form-floating w150">
                  <input type="time" placeholder="Starts" class="form-control w150" v-model.trim="fridayStart"
                    id="friStartingAt" @change="hasFridayCheck()" @enter="hasFridayCheck()" />
                  <label for="friStartingAt">Staring</label>
                </span>
              </div>
              <div class="col-auto p4">
                <span class="form-floating w150">
                  <input type="time" placeholder="Ends" class="form-control w150" id="friEndingAt"
                    v-model.trim="fridayEnds" @change="hasFridayCheck()" @enter="hasFridayCheck()" />
                  <label for="friEndingAt">Ending</label>
                </span>
              </div>
            </div>
          </div>
          <div class="row d-grid gap-2 w300 col-auto d-block mx-auto mb-1">
            <div class="alert alert-danger" role="alert" v-if="hasFridayErr">
              {{ hasFridayMsg }}
            </div>
          </div>
        </div>
      </div>
      <div class="row g-3 align-items-center mb-1">
        <div class="col-auto d-block mx-auto">
          <div class="form-floating" :class="{
  'form-data-error': hasSaturdayErr,
}">
            <div class="form-check form-switch w300">
              <input class="form-check-input" type="checkbox" id="hasSaturday" @change="hasSaturdayCheck()"
                @enter="hasSaturdayCheck()" v-model.trim="hasSaturday" />
              <label class="form-check-label" for="hasSaturday">Saturday</label>
            </div>
            <div class="row g-3 p4">
              <div class="col-auto p4">
                <span class="form-floating w150">
                  <input type="time" placeholder="Starts" class="form-control w150" v-model.trim="saturdayStart"
                    id="satStartingAt" @change="hasSaturdayCheck()" @enter="hasSaturdayCheck()" />
                  <label for="satStartingAt">Staring</label>
                </span>
              </div>
              <div class="col-auto p4">
                <span class="form-floating w150">
                  <input type="time" placeholder="Ends" class="form-control w150" id="satEndingAt"
                    v-model.trim="saturdayEnds" @change="hasSaturdayCheck()" @enter="hasSaturdayCheck()" />
                  <label for="satEndingAt">Ending</label>
                </span>
              </div>
            </div>
          </div>
          <div class="row d-grid gap-2 w300 col-auto d-block mx-auto mb-1">
            <div class="alert alert-danger" role="alert" v-if="hasSaturdayErr">
              {{ hasSaturdayMsg }}
            </div>
          </div>
        </div>
      </div>
      <div class="row g-3 align-items-center mb-1">
        <div class="col-auto d-block mx-auto">
          <div class="form-floating" :class="{
  'form-data-error': hasSundayErr,
}">
            <div class="form-check form-switch w300">
              <input class="form-check-input" type="checkbox" id="hasSunday" @change="hasSundayCheck()"
                @enter="hasSundayCheck()" v-model.trim="hasSunday" />
              <label class="form-check-label" for="hasSunday">Sunday</label>
            </div>
            <div class="row g-3 p4">
              <div class="col-auto p4">
                <span class="form-floating w150">
                  <input type="time" placeholder="Starts" class="form-control w150" id="sunStartingAt"
                    v-model.trim="sundayStart" @change="hasSundayCheck()" @enter="hasSundayCheck()" />
                  <label for="sunStartingAt">Staring</label>
                </span>
              </div>
              <div class="col-auto p4">
                <span class="form-floating w150">
                  <input type="time" placeholder="Ends" class="form-control w150" v-model.trim="sundayEnds"
                    id="sunEndingAt" @change="hasSundayCheck()" @enter="hasSundayCheck()" />
                  <label for="sunEndingAt">Ending</label>
                </span>
              </div>
            </div>
          </div>
          <div class="row d-grid gap-2 w300 col-auto d-block mx-auto mb-1">
            <div class="alert alert-danger" role="alert" v-if="hasSundayErr">
              {{ hasSundayMsg }}
            </div>
          </div>
        </div>
      </div>
      <div class="row d-grid gap-2 w300 col-auto d-block mx-auto mb-3">
        <button class="btn btn-outline-success" @click.prevent="updateOnlineHours()" v-if="onlineHoursBtn">
          <faIcons :icon="['fas', 'sitemap']" /> Update Online Hours
        </button>
      </div>
      <div class="row d-grid gap-2 w300 col-auto d-block mx-auto mb-3">
        <div class="alert alert-success" role="alert" v-if="onlineHoursResultSuccess">
          {{ onlineHoursResultSuccessMsg }}
        </div>
        <div class="alert alert-danger" role="alert" v-if="onlineHoursResultErr">
          {{ onlineHoursResultErrMsg }}
        </div>
      </div>
    </div>
    <div v-else>
      <!-- Show error if no restaurant matches this Criteria -->
      <div class="alert alert-dark" role="alert">
        <p>
          No restaurant found. Make sure you click in the right restaurant name.
        </p>
      </div>
      <router-link tag="li" :to="{ name: 'MyRestaurants' }">
        <a class="btn btn-success">Go back to my restaurant(s)</a>
      </router-link>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import { mapState, mapMutations, mapGetters } from "vuex";
import Navbar from "@/components/Header/Navbar.vue";
export default {
  name: "CurrentRestaurantSettings",
  components: {
    Navbar,
  },
  data() {
    return {
      user: {
        uId: "",
        userId: "",
        uName: "",
        uEmail: "",
        uPassword: "",
        uMemberSince: "",
        uAddress: "",
        uPhone: "",
        uRole: "",
        uRoleIs: "",
      },
      restAcceptingOrderStatus: "",
      //cat
      catPrice: "",
      catPriceErr: false,
      catPriceMsg: "",
      iscatPriceValidated: false,
      //is my restaurant accepting online orders
      isMenuActive: "",
      isMenuActiveErr: false,
      isMenuActiveSuccess: false,
      isMenuActiveMsg: "Restaurant is now accepting online orders",
      isMenuActiveValidated: false,
      //Restaurant Info from URL
      restId: this.$route.params.restId,
      restNum: this.$route.params.id,
      //Monday
      hasMonday: "",
      mondayStart: "",
      mondayEnds: "",
      hasMondayErr: false,
      hasMondayMsg: "",
      hasMondayValidated: false,
      //Tuesday
      hasTuesday: "",
      tuesdayStart: "",
      tuesdayEnds: "",
      hasTuesdayErr: false,
      hasTuesdayMsg: "",
      hasTuesdayValidated: false,
      //Wednesday
      hasWednesday: "",
      wednesdayStart: "",
      wednesdayEnds: "",
      hasWednesdayErr: false,
      hasWednesdayMsg: "",
      hasWednesdayValidated: false,
      //Thursday
      hasThursday: "",
      thursdayStart: "",
      thursdayEnds: "",
      hasThursdayErr: false,
      hasThursdayMsg: "",
      hasThursdayValidated: false,
      //Friday
      hasFriday: "",
      fridayStart: "",
      fridayEnds: "",
      hasFridayErr: false,
      hasFridayMsg: "",
      hasFridayValidated: false,
      //Saturday
      hasSaturday: "",
      saturdayStart: "",
      saturdayEnds: "",
      hasSaturdayErr: false,
      hasSaturdayMsg: "",
      hasSaturdayValidated: false,
      //Sunday
      hasSunday: "",
      sundayStart: "",
      sundayEnds: "",
      hasSundayErr: false,
      hasSundayMsg: "",
      hasSundayValidated: false,
      //address
      userAddress: "",
      userAddressErr: false,
      userAddressMsg: "",
      isuserAddressValidated: false,
      //phone
      userPhone: "",
      userPhoneErr: false,
      userPhoneMsg: "",
      isuserPhoneValidated: false,
      //name
      userName: "",
      userNameErr: false,
      userNameMsg: "",
      isuserNameValidated: false,
      //show result
      resultSuccess: false,
      resultSuccessMsg: "",
      resultErr: false,
      resultErrMsg: "",
      //show onlnie hours result
      onlineHoursResultSuccess: false,
      onlineHoursResultSuccessMsg: "",
      onlineHoursResultErr: false,
      onlineHoursResultErrMsg: "",
      image: "",
      is_DB_Connected: "",
      db_connection_msg: "",
      // apiMainUrl: "http://localhost/php/login-system-php-vue-2021/myData.php",
      apiMainUrl: this.$store.getters.MainUrl, //call global variable
      signUpBtn: true,
      acceptOrdersBtn: true,
      onlineHoursBtn: true,
      isRestExists: false,
    };
  },
  mounted() {
    let user = localStorage.getItem("current-user-data");
    let currentRestaurants = localStorage.getItem("current_restaurants_data");
    let currentRestaurant = JSON.parse(currentRestaurants).filter(
      (restInfo) =>
        restInfo.id == this.restNum && restInfo.rest_id == this.restId
    );
    let current_restaurants_online_hours = localStorage.getItem(
      "current_restaurants_online_hours"
    );
    let current_restaurants_online_hours_current_rest = JSON.parse(
      current_restaurants_online_hours
    ).filter((restInfo) => restInfo.rest_id == this.restId);
    let currentRestaurantsSettings = localStorage.getItem(
      "current_restaurants_settings"
    );
    let currentRestaurantSettings = JSON.parse(
      currentRestaurantsSettings
    ).filter((restInfo) => restInfo.rest_id == this.restId);
    if (user && currentRestaurants) {
      //set rest info
      if (currentRestaurant.length > 0) {
        //if restaurant have info
        console.table(current_restaurants_online_hours);
        if (current_restaurants_online_hours_current_rest.length > 0) {
          //here add all hours and days that are open for online orders
          if (current_restaurants_online_hours_current_rest[0].is_mon == 1) {
            this.hasMonday = true;
            this.mondayStart =
              current_restaurants_online_hours_current_rest[0].mon_start;
            this.mondayEnds =
              current_restaurants_online_hours_current_rest[0].mon_end;
          } else {
            this.hasMonday = false;
            this.mondayStart = "";
            this.mondayEnds = "";
          }
          if (current_restaurants_online_hours_current_rest[0].is_tue == 1) {
            this.hasTuesday = true;
            this.tuesdayStart =
              current_restaurants_online_hours_current_rest[0].tue_start;
            this.tuesdayEnds =
              current_restaurants_online_hours_current_rest[0].tue_end;
          } else {
            this.hasTuesday = false;
            this.tuesdayStart = "";
            this.tuesdayEnds = "";
          }
          if (current_restaurants_online_hours_current_rest[0].is_wed == 1) {
            this.hasWednesday = true;
            this.wednesdayStart =
              current_restaurants_online_hours_current_rest[0].wed_start;
            this.wednesdayEnds =
              current_restaurants_online_hours_current_rest[0].wed_end;
          } else {
            this.hasWednesday = false;
            this.wednesdayStart = "";
            this.wednesdayEnds = "";
          }
          if (current_restaurants_online_hours_current_rest[0].is_thu == 1) {
            this.hasThursday = true;
            this.thursdayStart =
              current_restaurants_online_hours_current_rest[0].thu_start;
            this.thursdayEnds =
              current_restaurants_online_hours_current_rest[0].thu_end;
          } else {
            this.hasThursday = false;
            this.thursdayStart = "";
            this.thursdayEnds = "";
          }
          if (current_restaurants_online_hours_current_rest[0].is_fri == 1) {
            this.hasFriday = true;
            this.fridayStart =
              current_restaurants_online_hours_current_rest[0].fri_start;
            this.fridayEnds =
              current_restaurants_online_hours_current_rest[0].fri_end;
          } else {
            this.hasFriday = false;
            this.fridayStart = "";
            this.fridayEnds = "";
          }
          if (current_restaurants_online_hours_current_rest[0].is_sat == 1) {
            this.hasSaturday = true;
            this.saturdayStart =
              current_restaurants_online_hours_current_rest[0].sat_start;
            this.saturdayEnds =
              current_restaurants_online_hours_current_rest[0].sat_end;
          } else {
            this.hasSaturday = false;
            this.saturdayStart = "";
            this.saturdayEnds = "";
          }
          if (current_restaurants_online_hours_current_rest[0].is_sun == 1) {
            this.hasSunday = true;
            this.sundayStart =
              current_restaurants_online_hours_current_rest[0].sun_start;
            this.sundayEnds =
              current_restaurants_online_hours_current_rest[0].sun_end;
          } else {
            this.hasSunday = false;
            this.sundayStart = "";
            this.sundayEnds = "";
          }
          console.table(current_restaurants_online_hours_current_rest);
        } else {
          console.log(
            "current rest does not have an online order hours setup yet!"
          );
        }
        console.log(currentRestaurant);
        this.isRestExists = true;
        this.catPrice = currentRestaurantSettings[0].taxes;
        if (currentRestaurantSettings[0].accept_online_orders == "1") {
          this.restAcceptingOrderStatus = true;
        } else {
          this.restAcceptingOrderStatus = false;
        }
        this.userPhone = currentRestaurant[0].rest_phone;
        this.userName = currentRestaurant[0].rest_name;
        this.userAddress = currentRestaurant[0].rest_address;
        //get users info
        this.user.uId = JSON.parse(user).id;
        this.user.userId = JSON.parse(user).user_id;
        this.user.uName = JSON.parse(user).name;
        this.user.uEmail = JSON.parse(user).email;
        this.user.uPassword = JSON.parse(user).password;
        this.user.uMemberSince = JSON.parse(user).member_since;
        this.user.uAddress = JSON.parse(user).full_address;
        this.user.uPhone = JSON.parse(user).phone_number;
        this.user.uRole = JSON.parse(user).role;
        //if role isn't manager or admin, redirect
        if (currentRestaurant[0].active == 0) {
          //if restaurant isn't active
          this.$router.push({ name: "MyRestaurants" });
        } else if (
          JSON.parse(user).role !== "mngr" &&
          JSON.parse(user).role !== "admn"
        ) {
          this.$router.push({ name: "Login" });
        }
      }
    } else {
      this.$router.push({ name: "Login" });
    }
  },
  computed: {
    ...mapState([
      "__RestaurantId",
      "__RestaurantEmail",
      "__RestaurantRole",
      "__RestaurantName",
      "__MainUrl",
    ]),
    ...mapGetters(["MainUrl"]),
  },
  methods: {
    ...mapMutations(["setRestaurantInfo"]),
    //validate Restaurant Name
    validateCategoryPrice(e) {
      let val = e.target.value;
      this.validateCategoryPriceInput(val);
    },
    validateCategoryName(e) {
      let val = e.target.value;
      this.validateCategoryNameInput(val);
    },
    // validateMenuActive(e) {
    //   let val = e.target.value;
    //   console.log(val);
    //   this.validateMenuActiveInput(val);
    // },
    validateMenuActiveInput() {
      if (this.restAcceptingOrderStatus == true) {
        this.isMenuActiveErr = false;
        this.isMenuActiveSuccess = true;
        this.isMenuActiveValidated = true;
        this.isMenuActiveMsg = "Restaurant is now accepting online orders";
        console.log("rest is accepting online orders");
        // this.restAcceptingOrderStatus = true;
      } else {
        console.log("rest is NOT accepting online orders");
        //if 0 => means its false
        this.isMenuActiveErr = true;
        this.isMenuActiveSuccess = false;
        this.isMenuActiveValidated = true;
        this.isMenuActiveMsg =
          "Restaurant is no longer accepting online orders";
        // this.restAcceptingOrderStatus = false;
      }
    },
    validateCategoryNameInput(val) {
      if (val == "") {
        this.catPriceErr = true;
        this.iscatPriceValidated = false;
        this.catPriceMsg = "Must enter taxes. Enter 0 if not charing taxes ..";
      } else {
        this.catPriceErr = false;
        this.iscatPriceValidated = true;
        this.catPriceMsg = "";
      }
    },
    validateCategoryPriceInput(val) {
      // let priceValidation = '^\d+(?:[.,]\d+)*$';
      // let priceValidationRegex  = /^\d+(?:\.\d{0,2})$/;
      let priceValidationRegex = /^[0-9]\d*(((,\d{3}){1})?(\.\d{0,4})?)$/;
      let priceValidation = priceValidationRegex.test(val);
      console.log("Is Price Value Correct: " + priceValidation);
      if (val == "") {
        //if empty
        this.catPriceErr = true;
        this.iscatPriceValidated = false;
        this.catPriceMsg = "Must enter taxes. Enter 0 if not charing taxes ..";
      } else if (!priceValidation) {
        // not any of these forms 65.00, 1.00
        this.catPriceErr = true;
        this.iscatPriceValidated = false;
        this.catPriceMsg = "Price Format 15.40, 0.50 or 1.00 ..";
      } else {
        this.catPriceErr = false;
        this.iscatPriceValidated = true;
        this.catPriceMsg = "";
      }
    },
    hasMondayCheck() {
      console.log("is monday active: " + this.hasMonday);
      /*
        hasMonday: "",
        mondayStart: "",
        mondayEnds: "",
        hasMondayErr: false,
        hasMondayMsg: "",
        hasMondayValidated: false,
      */
      if (this.hasMonday) {
        //if selected
        if (this.mondayEnds.length < 1 || this.mondayStart.length < 1) {
          //if empty
          this.hasMondayMsg = "Must enter start and ending time";
          this.hasMondayErr = true;
          this.hasMondayValidated = false;
        } else if (this.mondayEnds <= this.mondayStart) {
          // if ending time < starting time
          this.hasMondayMsg = "Ending time has to be less than starting time";
          this.hasMondayErr = true;
          this.hasMondayValidated = false;
        } else {
          this.hasMondayMsg = "";
          this.hasMondayErr = false;
          this.hasMondayValidated = true;
        }
      } else {
        this.hasMondayMsg = "";
        this.hasMondayErr = false;
        this.hasMondayValidated = true;
      }
    },
    hasTuesdayCheck() {
      console.log("is tuesday active: " + this.hasTuesday);
      if (this.hasTuesday) {
        //if selected
        if (this.tuesdayEnds.length < 1 || this.tuesdayStart.length < 1) {
          //if empty
          this.hasTuesdayMsg = "Must enter start and ending time";
          this.hasTuesdayErr = true;
          this.hasTuesdayValidated = false;
        } else if (this.tuesdayEnds <= this.tuesdayStart) {
          // if ending time < starting time
          this.hasTuesdayMsg = "Ending time has must less than starting time";
          this.hasTuesdayErr = true;
          this.hasTuesdayValidated = false;
        } else {
          this.hasTuesdayMsg = "";
          this.hasTuesdayErr = false;
          this.hasTuesdayValidated = true;
        }
      } else {
        this.hasTuesdayMsg = "";
        this.hasTuesdayErr = false;
        this.hasTuesdayValidated = true;
      }
    },
    hasWednesdayCheck() {
      console.log("is wednesday active: " + this.hasWednesday);
      if (this.hasWednesday) {
        //if selected
        if (this.wednesdayEnds.length < 1 || this.wednesdayStart.length < 1) {
          //if empty
          this.hasWednesdayMsg = "Must enter start and ending time";
          this.hasWednesdayErr = true;
          this.hasWednesdayValidated = false;
        } else if (this.wednesdayEnds <= this.wednesdayStart) {
          // if ending time < starting time
          this.hasWednesdayMsg = "Ending time must be less than starting time";
          this.hasWednesdayErr = true;
          this.hasWednesdayValidated = false;
        } else {
          this.hasWednesdayMsg = "";
          this.hasWednesdayErr = false;
          this.hasWednesdayValidated = true;
        }
      } else {
        this.hasWednesdayMsg = "";
        this.hasWednesdayErr = false;
        this.hasWednesdayValidated = true;
      }
    },
    hasThursdayCheck() {
      console.log("is thursday active: " + this.hasThursday);
      if (this.hasThursday) {
        //if selected
        if (this.thursdayEnds.length < 1 || this.thursdayStart.length < 1) {
          //if empty
          this.hasThursdayMsg = "Must enter start and ending time";
          this.hasThursdayErr = true;
          this.hasThursdayValidated = false;
        } else if (this.thursdayEnds <= this.thursdayStart) {
          // if ending time < starting time
          this.hasThursdayMsg = "Ending time must be less than starting time";
          this.hasThursdayErr = true;
          this.hasThursdayValidated = false;
        } else {
          this.hasThursdayMsg = "";
          this.hasThursdayErr = false;
          this.hasThursdayValidated = true;
        }
      } else {
        this.hasThursdayMsg = "";
        this.hasThursdayErr = false;
        this.hasThursdayValidated = true;
      }
    },
    hasFridayCheck() {
      console.log("is friday active: " + this.hasFriday);
      if (this.hasFriday) {
        //if selected
        if (this.fridayEnds.length < 1 || this.fridayStart.length < 1) {
          //if empty
          this.hasFridayMsg = "Must enter start and ending time";
          this.hasFridayErr = true;
          this.hasFridayValidated = false;
        } else if (this.fridayEnds <= this.fridayStart) {
          // if ending time < starting time
          this.hasFridayMsg = "Ending time must be less than starting time";
          this.hasFridayErr = true;
          this.hasFridayValidated = false;
        } else {
          this.hasFridayMsg = "";
          this.hasFridayErr = false;
          this.hasFridayValidated = true;
        }
      } else {
        this.hasFridayMsg = "";
        this.hasFridayErr = false;
        this.hasFridayValidated = true;
      }
    },
    hasSaturdayCheck() {
      console.log("is saturday active: " + this.hasSaturday);
      if (this.hasSaturday) {
        //if selected
        if (this.saturdayEnds.length < 1 || this.saturdayStart.length < 1) {
          //if empty
          this.hasSaturdayMsg = "Must enter start and ending time";
          this.hasSaturdayErr = true;
          this.hasSaturdayValidated = false;
        } else if (this.saturdayEnds <= this.saturdayStart) {
          // if ending time < starting time
          this.hasSaturdayMsg = "Ending time must be less than starting time";
          this.hasSaturdayErr = true;
          this.hasSaturdayValidated = false;
        } else {
          this.hasSaturdayMsg = "";
          this.hasSaturdayErr = false;
          this.hasSaturdayValidated = true;
        }
      } else {
        this.hasSaturdayMsg = "";
        this.hasSaturdayErr = false;
        this.hasSaturdayValidated = true;
      }
    },
    hasSundayCheck() {
      console.log("is sunday active: " + this.hasSundaThurs);
      if (this.hasSunday) {
        //if selected
        if (this.sundayEnds.length < 1 || this.sundayStart.length < 1) {
          //if empty
          this.hasSundayMsg = "Must enter start and ending time";
          this.hasSundayErr = true;
          this.hasSundayValidated = false;
        } else if (this.sundayEnds <= this.sundayStart) {
          // if ending time < starting time
          this.hasSundayMsg = "Starting time must be less than ending time";
          this.hasSundayErr = true;
          this.hasSundayValidated = false;
        } else {
          this.hasSundayMsg = "";
          this.hasSundayErr = false;
          this.hasSundayValidated = true;
        }
      } else {
        this.hasSundayMsg = "";
        this.hasSundayErr = false;
        this.hasSundayValidated = true;
      }
    },
    async AddNewCategory() {
      this.resultErr = false;
      this.resultErrMsg = "";
      this.resultSuccess = true;
      this.resultSuccessMsg =
        "Please wait while we're updating your tax rate...";
      this.validateCategoryPriceInput(this.catPrice);
      if (this.iscatPriceValidated) {
        this.signUpBtn = false;
        //show Result
        let fd = new FormData();
        fd.append("userId", this.user.userId);
        fd.append("catPrice", parseFloat(this.catPrice)); //don't round
        fd.append("restId", this.restId);
        // fd.append("restNum", this.restNum);
        let rst = await axios.post(
          this.apiMainUrl + `?action=CurrentRestaurantTaxesSettings`,
          fd
        );
        console.log(rst);
        const resultData = rst.data;
        if (rst.status == 200) {
          if (resultData.error) {
            console.warn(
              "Connection Error Happened on upating restaurant taxes"
            );
            this.resultErrMsg = resultData.message;
            this.resultErr = true;
            this.resultSuccessMsg = "";
            this.resultSuccess = false;
            this.signUpBtn = true;
          } else {
            this.signUpBtn = false;
            console.log("New Modifier has been added..");
            //if everything is okay
            this.resultErrMsg = "";
            this.resultErr = false;
            this.resultSuccess = true;
            var currentRestaurantSettingsData = JSON.parse(
              localStorage.getItem("current_restaurants_settings")
            );
            for (let i = 0; i < currentRestaurantSettingsData.length; i++) {
              if (currentRestaurantSettingsData[i].rest_id === this.restId) {
                currentRestaurantSettingsData[i].taxes = parseFloat(
                  this.catPrice
                ).toFixed(3);
              }
            }
            localStorage.setItem(
              "current_restaurants_settings",
              JSON.stringify(currentRestaurantSettingsData)
            );
            //https://stackoverflow.com/questions/35963412/append-data-to-localstorage-object
            this.resultSuccessMsg = resultData.message;
            //redirect to current restaurant page
            setTimeout(() => {
              this.signUpBtn = true;
              this.resultErrMsg = "";
              this.resultErr = false;
              this.resultSuccessMsg = "";
              this.resultSuccess = false;
            }, 2000);
          }
        }
      } else {
        this.signUpBtn = true;
        this.validateCategoryNameInput(this.catPrice);
        this.resultSuccessMsg = "";
        this.resultSuccess = false;

        this.resultErrMsg = "Must enter all required information";
        this.resultErr = true;
      }
    },
    async updateOnlineHours() {
      this.resultErr = false;
      this.resultErrMsg = "";
      this.resultSuccess = true;
      this.resultSuccessMsg =
        "Please wait while we're updating your online hours...";
      /*
        onlineHoursResultSuccess: false,
        onlineHoursResultSuccessMsg: "",
        onlineHoursResultErr: false,
        onlineHoursResultErrMsg: "",
      */
      // this.validateCategoryPriceInput(this.catPrice);
      let mon = "";
      let tue = "";
      let wed = "";
      let thu = "";
      let fri = "";
      let sat = "";
      let sun = "";
      if (this.hasMonday) {
        mon = 1;
        this.hasMondayCheck();
      } else {
        mon = 0;
        this.hasMondayValidated = true;
      }
      if (this.hasTuesday) {
        tue = 1;
        this.hasTuesdayCheck();
      } else {
        tue = 0;
        this.hasTuesdayValidated = true;
      }
      if (this.hasWednesday) {
        wed = 1;
        this.hasWednesdayCheck();
      } else {
        wed = 0;
        this.hasWednesdayValidated = true;
      }
      if (this.hasThursday) {
        thu = 1;
        this.hasThursdayCheck();
      } else {
        thu = 0;
        this.hasThursdayValidated = true;
      }
      if (this.hasFriday) {
        fri = 1;
        this.hasFridayCheck();
      } else {
        fri = 0;
        this.hasFridayValidated = true;
      }
      if (this.hasSaturday) {
        sat = 1;
        this.hasSaturdayCheck();
      } else {
        sat = 0;
        this.hasSaturdayValidated = true;
      }
      if (this.hasSunday) {
        sun = 1;
        this.hasSundayCheck();
      } else {
        sun = 0;
        this.hasSundayValidated = true;
      }
      if (
        this.hasMondayValidated &&
        this.hasTuesdayValidated &&
        this.hasWednesdayValidated &&
        this.hasThursdayValidated &&
        this.hasFridayValidated &&
        this.hasSaturdayValidated &&
        this.hasSundayValidated
      ) {
        this.onlineHoursBtn = false;
        //show Result
        let fd = new FormData();
        fd.append("userId", this.user.userId);
        fd.append("restId", this.restId);
        fd.append("is_sat", sat);
        fd.append("sat_start", this.saturdayStart);
        fd.append("sat_end", this.saturdayEnds);
        fd.append("is_sun", sun);
        fd.append("sun_start", this.sundayStart);
        fd.append("sun_end", this.sundayEnds);
        fd.append("is_mon", mon);
        fd.append("mon_start", this.mondayStart);
        fd.append("mon_end", this.mondayEnds);
        fd.append("is_tue", tue);
        fd.append("tue_start", this.tuesdayStart);
        fd.append("tue_end", this.tuesdayEnds);
        fd.append("is_wed", wed);
        fd.append("wed_start", this.wednesdayStart);
        fd.append("wed_end", this.wednesdayEnds);
        fd.append("is_thu", thu);
        fd.append("thu_start", this.thursdayStart);
        fd.append("thu_end", this.thursdayEnds);
        fd.append("is_fri", fri);
        fd.append("fri_start", this.fridayStart);
        fd.append("fri_end", this.fridayEnds);
        // fd.append("restNum", this.restNum);
        let rst = await axios.post(
          this.apiMainUrl + `?action=UpdateRestaurantOnlineHours`,
          fd
        );
        console.log(rst);
        const resultData = rst.data;
        if (rst.status == 200) {
          if (resultData.error) {
            console.warn(
              "Connection Error Happened on upating restaurant online hours"
            );
            this.onlineHoursResultErrMsg = resultData.message;
            this.resultonlineHoursResultErr = true;
            this.onlineHoursResultSuccessMsg = "";
            this.onlineHoursResultSuccess = false;
            this.onlineHoursBtn = true;
          } else {
            this.onlineHoursBtn = false;
            console.log("Online Hours for Current Resturant Is Updated..");
            //if everything is okay
            this.onlineHoursResultErrMsg = "";
            this.onlineHoursResultErr = false;
            this.onlineHoursResultSuccess = true;
            localStorage.setItem(
              "current_restaurants_online_hours",
              JSON.stringify(resultData.current_restaurants_online_hours)
            );
            //https://stackoverflow.com/questions/35963412/append-data-to-localstorage-object
            this.onlineHoursResultSuccessMsg = resultData.message;
            //redirect to current restaurant page
            setTimeout(() => {
              this.onlineHoursBtn = true;
              this.onlineHoursResultErrMsg = "";
              this.onlineHoursResultErr = false;
              this.onlineHoursResultSuccessMsg = "";
              this.onlineHoursResultSuccess = false;
            }, 4000);
          }
        }
      } else {
        this.onlineHoursBtn = true;
        // this.validateCategoryNameInput(this.catPrice);
        this.onlineHoursResultSuccessMsg = "";
        this.onlineHoursResultSuccess = false;

        this.onlineHoursResultErrMsg = "Must enter all required information";
        this.onlineHoursResultErr = true;
      }
    },
    async ActivateOrDeactivateOnlineOrders() {
      this.resultErr = false;
      this.resultErrMsg = "";
      this.resultSuccess = true;
      this.resultSuccessMsg =
        "Please wait while we're updtaing your online availability...";
      this.acceptOrdersBtn = false;
      //show Result
      let fd = new FormData();
      fd.append("userId", this.user.userId);
      if (this.restAcceptingOrderStatus) {
        fd.append("isRestAcceptingOrders", 1);
      } else {
        fd.append("isRestAcceptingOrders", 0);
      }
      fd.append("restId", this.restId);
      // fd.append("restNum", this.restNum);
      let rst = await axios.post(
        this.apiMainUrl + `?action=ActivateOrDeactivateOnlineOrders`,
        fd
      );
      const resultData = rst.data;
      if (rst.status == 200) {
        if (resultData.error) {
          console.warn(
            "Connection Error Happened on upating restaurant accepting orders"
          );
          this.isMenuActiveErr = true;
          this.isMenuActiveMsg = resultData.message;
          this.isMenuActiveSuccess = false;
          this.isMenuActiveValidated = true;
          console.log(
            "something went wrong on assigning current rest. to accept/not online orders"
          );
          this.acceptOrdersBtn = true;
        } else {
          this.acceptOrdersBtn = false;
          console.log("assigning current rest. to accept/not online orders..");
          //if everything is okay
          var currentRestaurantSettingsData = JSON.parse(
            localStorage.getItem("current_restaurants_settings")
          );
          if (this.restAcceptingOrderStatus == true) {
            this.isMenuActiveErr = false;
            this.isMenuActiveSuccess = true;
            this.isMenuActiveValidated = true;
            this.isMenuActiveMsg = resultData.message;
            setTimeout(() => {
              this.acceptOrdersBtn = true;
              this.isMenuActiveErr = false;
              this.isMenuActiveSuccess = false;
              this.isMenuActiveValidated = false;
              this.isMenuActiveMsg = "";
            }, 4000);
          } else {
            this.isMenuActiveErr = true;
            this.isMenuActiveSuccess = false;
            this.isMenuActiveValidated = true;
            this.isMenuActiveMsg = resultData.message;
            setTimeout(() => {
              this.acceptOrdersBtn = true;
              this.isMenuActiveErr = false;
              this.isMenuActiveSuccess = false;
              this.isMenuActiveValidated = false;
              this.isMenuActiveMsg = "";
            }, 4000);
          }
          for (let i = 0; i < currentRestaurantSettingsData.length; i++) {
            if (currentRestaurantSettingsData[i].rest_id === this.restId) {
              if (this.restAcceptingOrderStatus) {
                currentRestaurantSettingsData[i].accept_online_orders = 1;
              } else {
                currentRestaurantSettingsData[i].accept_online_orders = 0;
              }
            }
          }
          localStorage.setItem(
            "current_restaurants_settings",
            JSON.stringify(currentRestaurantSettingsData)
          );
          //redirect to current restaurant page
          // setTimeout(() => {
          //   this.acceptOrdersBtn = true;
          //   this.resultErrMsg = "";
          //   this.resultErr = false;
          //   this.resultSuccessMsg = "";
          //   this.resultSuccess = false;
          // }, 2000);
        }
      }
    },
  },
};
</script>
<style lang="scss" scoped>
$navColor: #3f51b6;

.w150 {
  width: 300px !important;
  min-width: 300px !important;
}

.form-data-success {
  color: darkgreen;
}

nav a {
  text-decoration: none;
  color: $navColor;
  font-weight: bold;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

.w100 {
  width: 100px;
}

.bgBlue {
  background-color: #0d6efd;
  color: white;
  padding: 10px;
  border-radius: 7px;
}

.addBorder {
  border-bottom: 1px solid #ced4da;
}

.p4 {
  padding: 4px;
}
</style>
