<template>
  <div class="container-fluid">
    <Navbar />
    <nav style="--bs-breadcrumb-divider: '>'" aria-label="breadcrumb">
      <ol class="breadcrumb">
        <li class="breadcrumb-item">
          <router-link tag="li" :to="{ name: 'Home' }">
            <a>Home</a>
          </router-link>
        </li>
        <li class="breadcrumb-item active" aria-current="page">View Users</li>
      </ol>
    </nav>
    <div class="add-new-restaurant-page">
      <h1 class="text-center">Current Users ({{ users_dataArr.length }})</h1>
      <!-- 
        deactivate button now
        allColumns: true,
        userName: false,
        userPhone: false,
        userPassword: false,
        userEmail: false,
        userAddress: false,
        userRegisteredOn: false,
        userActive: false,
        userId: false,
        userUserId: false,
        userRole: false,
        userIP: false,
        userActions: false,
      -->
      <div class="form-check form-check-inline">
        <input class="form-check-input" type="checkbox" id="userName" v-model="userName" />
        <label class="form-check-label" for="userName">Name</label>
      </div>
      <div class="form-check form-check-inline">
        <input class="form-check-input" type="checkbox" id="userPhone" v-model="userPhone" />
        <label class="form-check-label" for="userPhone">Phone</label>
      </div>
      <div class="form-check form-check-inline">
        <input class="form-check-input" type="checkbox" id="userEmail" v-model="userEmail" />
        <label class="form-check-label" for="userEmail">Email</label>
      </div>
      <div class="form-check form-check-inline">
        <input class="form-check-input" type="checkbox" id="userPassword" v-model="userPassword" />
        <label class="form-check-label" for="userPassword">Password</label>
      </div>
      <div class="form-check form-check-inline">
        <input class="form-check-input" type="checkbox" id="userRegisteredOn" v-model="userRegisteredOn" />
        <label class="form-check-label" for="userRegisteredOn">Registered On</label>
      </div>
      <div class="form-check form-check-inline">
        <input class="form-check-input" type="checkbox" id="userActive" v-model="userActive" />
        <label class="form-check-label" for="userActive">Active</label>
      </div>
      <div class="form-check form-check-inline">
        <input class="form-check-input" type="checkbox" id="userUserId" v-model="userUserId" />
        <label class="form-check-label" for="userUserId">User ID</label>
      </div>
      <div class="form-check form-check-inline">
        <input class="form-check-input" type="checkbox" id="userId" v-model="userId" />
        <label class="form-check-label" for="userId">Access ID</label>
      </div>
      <div class="form-check form-check-inline">
        <input class="form-check-input" type="checkbox" id="userRole" v-model="userRole" />
        <label class="form-check-label" for="userRole">Role</label>
      </div>
      <div class="form-check form-check-inline">
        <input class="form-check-input" type="checkbox" id="userIP" v-model="userIP" />
        <label class="form-check-label" for="userIP">IP</label>
      </div>
      <div class="form-check form-check-inline">
        <input class="form-check-input" type="checkbox" id="userActions" v-model="userActions" />
        <label class="form-check-label" for="userActions">Actions</label>
      </div>
      <div class="table-responsive">
        <table class="table">
          <thead class="table-success">
            <tr>
              <th class="header" scope="col" v-if="userName">Name</th>
              <th class="header" scope="col" v-if="userPhone">Phone</th>
              <th class="header" scope="col" v-if="userEmail">Email</th>
              <th class="header" scope="col" v-if="userPassword">Password</th>
              <th class="header" scope="col" v-if="userAddress">Address</th>
              <th class="header" scope="col" v-if="userId">ID</th>
              <th class="header" scope="col" v-if="userUserId">User ID</th>
              <th class="header" scope="col" v-if="userRole">Role</th>
              <th class="header" scope="col" v-if="userRegisteredOn">Since</th>
              <th class="header" scope="col" v-if="userActive">Active</th>
              <th class="header" scope="col" v-if="userIP">IP</th>
              <th class="header" scope="col" v-if="userActions">Actions</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(eachUser, i) in users_dataArr" :key="i">
              <td v-if="userName">
                {{ capitalizeFisrtLetterOfEachWord(eachUser.name) }}
              </td>
              <td v-if="userPhone">
                {{ formatPhoneNumber(eachUser.phone_number) }}
              </td>
              <td v-if="userEmail">
                {{ capitalizeFisrtLetterOfEachWord(eachUser.email) }}
              </td>
              <td v-if="userPassword">{{ eachUser.password }}</td>
              <td v-if="userAddress">
                {{ capitalizeFisrtLetterOfEachWord(eachUser.full_address) }}
              </td>
              <td v-if="userId">{{ eachUser.access_id }}</td>
              <td v-if="userUserId">{{ eachUser.user_id }}</td>
              <td v-if="userRole">
                <span v-if="eachUser.role == 'mngr'">Owner</span>
                <span v-else-if="eachUser.role == 'admn'">IT</span>
                <span v-else-if="eachUser.role == 'empl'">Employee</span>
                <span v-else-if="eachUser.role == 'cstmr'">Customer</span>
                <span v-else-if="eachUser.role == 'cmngr'">Co Manager</span>
              </td>
              <td v-if="userRegisteredOn">
                {{ new Date(eachUser.member_since).toLocaleDateString() }}
              </td>
              <td v-if="userActive" :class="eachUser.active == 1 ? 'text-success' : 'text-danger'">
                {{ eachUser.active == 1 ? "Yes" : "No" }}
              </td>
              <td v-if="userIP">{{ eachUser.ip }}</td>
              <td v-if="userActions">
                <button v-if="eachUser.active == 1" class="btn btn-danger" :id="eachUser.user_id" @click="
                  deactivateUser(
                    eachUser.access_id,
                    eachUser.user_id,
                    eachUser.email,
                    eachUser.name
                  )
                ">
                  Deactivate
                </button>
                <button v-if="eachUser.active == 0" class="btn btn-success" :id="eachUser.user_id" @click="
                  activateUser(
                    eachUser.access_id,
                    eachUser.user_id,
                    eachUser.email,
                    eachUser.name
                  )
                ">
                  Activate
                </button>
                <p :id="eachUser.access_id"></p>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import { mapState, mapMutations, mapGetters } from "vuex";
import Navbar from "@/components/Header/Navbar.vue";
export default {
  name: "ViewAllUsers",
  components: {
    Navbar,
  },
  data() {
    return {
      user: {
        uId: "",
        userId: "",
        uName: "",
        uEmail: "",
        uPassword: "",
        uMemberSince: "",
        uAddress: "",
        uPhone: "",
        uRole: "",
        uRoleIs: "",
      },
      //   allColumns: true,
      userName: true,
      userPhone: true,
      userPassword: false,
      userEmail: true,
      userAddress: false,
      userRegisteredOn: false,
      userActive: false,
      userId: false,
      userUserId: false,
      userRole: false,
      userIP: false,
      userActions: false,
      users_dataArr: [],
      is_DB_Connected: "",
      db_connection_msg: "",
      apiMainUrl: this.$store.getters.MainUrl, //call global variable
    };
  },
  mounted() {
    let user = localStorage.getItem("current-user-data");
    if (user != null) {
      this.user.uId = JSON.parse(user).id;
      this.user.userId = JSON.parse(user).user_id;
      this.user.uRole = JSON.parse(user).role;
      if (JSON.parse(user).role !== "admn") {
        this.$router.push({ name: "Login" });
      } else {
        let users_data = localStorage.getItem("users_data");
        if (users_data != null) {
          this.users_dataArr = JSON.parse(users_data);
        }
      }
    } else {
      this.$router.push({ name: "Login" });
    }
  },
  computed: {
    ...mapState([
      "__RestaurantId",
      "__RestaurantEmail",
      "__RestaurantRole",
      "__RestaurantName",
      "__MainUrl",
    ]),
    ...mapGetters(["MainUrl"]),
  },
  methods: {
    ...mapMutations(["setRestaurantInfo"]),
    capitalizeFisrtLetterOfEachWord(text) {
      return text
        .toLowerCase()
        .split(" ")
        .map((s) => s.charAt(0).toUpperCase() + s.substring(1))
        .join(" ");
    },
    formatPhoneNumber(number) {
      //remove . + -  from any number
      let cleaned = ("" + number).replace(/\D/g, "");
      let match = "";
      if (number.length == 10) {
        match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
      } else if (number.length == 9) {
        match = cleaned.match(/^(\d{3})(\d{3})(\d{3})$/);
      } else if (number.length == 11) {
        match = cleaned.match(/^(\d{3})(\d{4})(\d{4})$/);
      } else if (number.length == 8) {
        match = cleaned.match(/^(\d{2})(\d{3})(\d{3})$/);
      }
      if (match) {
        return "(" + match[1] + ") " + match[2] + "-" + match[3];
      }
      return null;
    },
    async deactivateUser(accessId, userId, userEmail, userName) {
      document.getElementById(userId).classList.add("disabled");
      document.getElementById(accessId).innerHTML =
        "<span class='text-success fw-bold'>" + "Loading..." + "</span>";
      let fd = new FormData();
      fd.append("accessId", accessId);
      fd.append("userId", userId);
      fd.append("userEmail", userEmail);
      fd.append("userName", userName);
      fd.append("currentUserId", this.user.userId);
      let rst = await axios.post(
        this.apiMainUrl + `?action=deactivateUser`,
        fd
      );
      console.log(rst);
      const resultData = rst.data;
      if (rst.status == 200) {
        if (resultData.error) {
          document.getElementById(userId).classList.remove("disabled");
          console.warn("Error deactivating current user");
          document.getElementById(accessId).innerHTML =
            "<span class='text-danger fw-bold'>" +
            resultData.message +
            "</span>";
        } else {
          document.getElementById(userId).classList.add("disabled");
          console.log("You have deactivated current user");
          document.getElementById(accessId).innerHTML =
            '<span class="text-success fw-bold">' +
            resultData.message +
            "</span>";
          //resultMsg.innerHTML = resultData.message;
          localStorage.setItem(
            "users_data",
            JSON.stringify(resultData.users_data)
          );
          this.users_dataArr = JSON.parse(localStorage.getItem("users_data"));
          setTimeout(() => {
            document.getElementById(accessId).innerHTML = "";
          }, 20000);
        }
      } else {
        document.getElementById(accessId).innerHTML =
          "<span class='text-danger fw-bold'>Something is wrong.</span>";
        document.getElementById(userId).classList.remove("disabled");
      }
    },
    async activateUser(accessId, userId, userEmail, userName) {
      document.getElementById(accessId).innerHTML =
        "<span class='text-success fw-bold'>" + "Loading..." + "</span>";
      document.getElementById(userId).classList.add("disabled");
      //   let resultMsg = document.getElementById(accessId);
      let fd = new FormData();
      fd.append("accessId", accessId);
      fd.append("userId", userId);
      fd.append("userEmail", userEmail);
      fd.append("userName", userName);
      fd.append("currentUserId", this.user.userId);
      let rst = await axios.post(this.apiMainUrl + `?action=activateUser`, fd);
      console.log(rst);
      const resultData = rst.data;
      if (rst.status == 200) {
        if (resultData.error) {
          document.getElementById(userId).classList.remove("disabled");
          console.warn("Error activating current user");
          document.getElementById(accessId).innerHTML =
            "<span class='text-danger fw-bold'>" +
            resultData.message +
            "</span>";
        } else {
          document.getElementById(userId).classList.add("disabled");
          console.log("You have activated current user");
          document.getElementById(accessId).innerHTML =
            "<span class='text-success fw-bold'>" +
            resultData.message +
            "</span>";
          //resultMsg.innerHTML = resultData.message;
          localStorage.setItem(
            "users_data",
            JSON.stringify(resultData.users_data)
          );
          this.users_dataArr = JSON.parse(localStorage.getItem("users_data"));
          setTimeout(() => {
            document.getElementById(accessId).innerHTML = "";
          }, 2000);
        }
      } else {
        document.getElementById(userId).classList.remove("disabled");
        document.getElementById(accessId).innerHTML =
          "<span class='text-danger fw-bold'>Something is wrong.</span>";
      }
    },
  },
};
</script>
