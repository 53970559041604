<template>
  <div class="container-fluid" v-if="doesRestDataExists">
    <div v-if="!canUserAccessThis">
      <div class="d-flex aligns-items-center justify-content-center">
        <div class="m-5">
          <div
            class="form-floating"
            :class="{ 'form-data-error': currentUserAccessCodeIsErr }"
          >
            <input
              autofocus
              type="number"
              min="0"
              id="userPhoneInput"
              placeholder="currentUserAccessCodeIsErr"
              @keydown="
                if (
                  $event.key === '.' ||
                  $event.key === '+' ||
                  $event.key === '-' ||
                  $event.key === 'e'
                ) {
                  $event.preventDefault();
                }
              "
              @input="validateUserAccessCode($event)"
              @change="validateUserAccessCode($event)"
              class="form-control w300"
              v-model.trim="currentUserAccessCodeIs"
            />
            <label for="userPhoneInput">
              <faIcons :icon="['fas', 'key']" /> Access Code
            </label>
            <span class="error-feedback" v-if="currentUserAccessCodeIsErr">{{
              currentUserAccessCodeIsMsg
            }}</span>
          </div>
        </div>
      </div>
      <div class="d-flex aligns-items-center justify-content-center m-3">
        <button
          class="btn btn-outline-success"
          @click.prevent="canUserAccessCurrentPage()"
          v-if="this.btnEnabled"
        >
          <faIcons :icon="['fas', 'hourglass-start']" /> Punch In/Out</button
        >&nbsp;
        <button class="btn btn-outline-primary" type="button" @click="goBack()">
          <faIcons :icon="['fas', 'store']" /> Home
        </button>
      </div>
    </div>
    <div
      v-if="canUserAccessThis"
      class="row justify-content-around main-order-select-date-custom"
    >
      <div>
        <nav class="navbar navbar-expand-sm navbar-light bg-ligh">
          <div class="container-fluid">
            <span class="navbar-brand">
              <faIcons :icon="['fas', 'user']" />
              {{
                capitalizeFisrtLetterOfEachWord(
                  currentLoggedInUserData[0].user_name
                    .split(" ")
                    .slice(0, 1)
                    .join(" ")
                )
              }}
            </span>
            <button
              class="navbar-toggler"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#mynavbar"
            >
              <span class="navbar-toggler-icon"></span>
            </button>
            <div class="collapse navbar-collapse" id="mynavbar">
              <ul class="navbar-nav me-auto">
                <li class="nav-item">
                  <a
                    class="nav-link report-button"
                    href="javascript:void(0)"
                    @click="showTimesheet()"
                    v-if="
                      currentLoggedInUserData[0].user_role == 'admn' ||
                      currentLoggedInUserData[0].user_role == 'mngr' ||
                      currentLoggedInUserData[0].user_role == 'cmngr'
                    "
                    >Timesheet</a
                  >
                </li>
                <li class="nav-item">
                  <a
                    class="nav-link report-button"
                    href="javascript:void(0)"
                    @click="showOrders()"
                    v-if="
                      currentLoggedInUserData[0].user_role == 'admn' ||
                      currentLoggedInUserData[0].user_role == 'mngr' ||
                      currentLoggedInUserData[0].user_role == 'cmngr'
                    "
                    >Orders</a
                  >
                </li>
                <li class="nav-item">
                  <a
                    href="javascript:void(0)"
                    class="nav-link redColor fb"
                    @click="clearLogin()"
                    >Exit</a
                  >
                </li>
              </ul>
              <form class="d-flex">
                <span class="nav-link fb font-size-1-25-em">{{
                  timestamp
                }}</span>
              </form>
            </div>
          </div>
        </nav>
      </div>
      <div class="box">
        <div v-if="showErrorForTimeInOut">
          <div class="text-center redColor alert alert-danger bet_time_error">
            Internal Error Happened, refresh the page!
          </div>
        </div>
        <div v-if="currentLoggedInUserData[0].user_role != 'admn'">
          <div
            v-if="isUserLoggedInOrOut.length > 0"
            @click="
              isUserLoggedInOrOut[0].is_logged_out == 0
                ? timeOutUser(restId, UserAccessCodeIs)
                : timeInUser(restId, UserAccessCodeIs)
            "
          >
            <a
              v-if="isUserLoggedInOrOut[0].is_logged_out == 0"
              href="javascript:void(0)"
              class="btn btn-lg btns-danger fb bet_time"
            >
              <faIcons :icon="['fas', 'hourglass-end']" /> Time Out
            </a>
            <a
              v-if="isUserLoggedInOrOut[0].is_logged_out == 1"
              href="javascript:void(0)"
              class="btn btn-lg btns-success fb bet_time"
            >
              <faIcons :icon="['fas', 'hourglass-start']" /> Time In
            </a>
          </div>
          <div @click="timeInUser(restId, UserAccessCodeIs)" v-else>
            <a
              href="javascript:void(0)"
              class="btn btn-lg btns-success fb bet_time"
            >
              <faIcons :icon="['fas', 'hourglass-start']" /> Time In!
            </a>
          </div>
        </div>
        <div
          @click="timeAllUsersOut(restId, UserAccessCodeIs)"
          v-if="
            currentLoggedInUserData[0].user_role == 'admn' ||
            currentLoggedInUserData[0].user_role == 'mngr' ||
            currentLoggedInUserData[0].user_role == 'cmngr'
          "
        >
          <a
            href="javascript:void(0)"
            class="btn btn-lg btns-danger fb bet_time_left"
          >
            <faIcons :icon="['fas', 'hourglass-end']" /> Punch All Out
          </a>
        </div>
      </div>
    </div>
  </div>
  <div class="container-fluid" v-else>
    <div class="d-flex aligns-items-center justify-content-center">
      <div class="m-5 alert alert-danger">
        Make sure you have the right online order page.
      </div>
    </div>
  </div>
</template>
<script>
import axios from "axios";
export default {
  name: "TimeInOut",
  data() {
    return {
      showErrorForTimeInOut: false,
      reasonToCancelOrder: "Customer no longer wants this order",
      currentLoggedInUserData: "",
      currentUserAccessCodeIs: "",
      currentUserAccessCodeIsErr: false,
      isUserAccessCodeValidated: false,
      btnEnabled: false,
      currentUserAccessCodeIsMsg: "",
      canUserAccessThis: false,
      showPickedUp: false,
      showInProgress: true,
      showReadyOrders: false,
      showAllOrders: false,
      restId: this.$route.params.restId,
      currentOrder: [],
      pickUpAddress: "",
      pickUpPhoneNumber: "",
      isCompletedClicked: false,
      isActiveClicked: false,
      isReadyClicked: false,
      isCancelledClicked: false,
      businessName: "",
      custName: "",
      orderData: "",
      custom_order_date_or_now: "",
      custEmail: "",
      showOrderData: true,
      showCustomerData: false,
      tips: 0,
      OrdersAPI: this.$store.getters.OrdersAPI,
      orderSequenceNumber: 0,
      custom_date_time: "",
      currentRealOrder: [],
      currentOrderInDetails: [],
      getNewOrders: null,
      doesRestDataExists: true,
      UserAccessCodeIs: "",
      todaysDateIs: new Date(
        new Date().getTime() - new Date().getTimezoneOffset() * 60000
      )
        .toISOString()
        .split("T")[0],
      viewOrderStartingDate: new Date(
        new Date().getTime() - new Date().getTimezoneOffset() * 60000
      )
        .toISOString()
        .split("T")[0],
      viewOrderEndingDate: new Date(
        new Date().getTime() - new Date().getTimezoneOffset() * 60000
      )
        .toISOString()
        .split("T")[0],
      timestamp: "",
      isUserLoggedInOrOut: [],
      currentRestDetailsArr: [],
    };
  },
  mounted() {
    // localStorage.clear();
    // console.table(currentLoggedInUserAccessData);
    // this.viewOrders(this.restId);
    this.doesThisRestExist();
    console.log("Current Date is " + this.getCurrentDate());
    console.log("Current Time is " + this.getCurrentTime());
    console.log("Full date format " + this.fullDateFormated());
    console.log(
      "Add 15 minutes to current time " +
        this.addHoursToDateAndReturnTimeOnly(new Date(), 0.25)
    );
    console.log(
      "Current Time in 24 Hours Format " + this.getCurrentTime24HoursFormat()
    );
    let currentRestDetails = localStorage.getItem("current_rest_details");
    if (currentRestDetails === null) {
      console.log("no rest data existing...");
    } else {
      console.log("current rest data");
      console.table(currentRestDetails);
    }
    if (localStorage.getItem("current_user_access_id") != null) {
      this.UserAccessCodeIs = JSON.parse(
        localStorage.getItem("current_user_access_id")
      ).id;
      this.isUserLoggedInOrOut = JSON.parse(
        localStorage.getItem("time_in_and_out")
      );
    }
    let currentLoggedInUserAccessData = localStorage.getItem(
      "current_logged_user_access_data"
    );
    if (currentLoggedInUserAccessData === null) {
      this.canUserAccessThis = false;
    } else {
      this.currentLoggedInUserData = JSON.parse(currentLoggedInUserAccessData);
      console.table(currentLoggedInUserAccessData);
      this.canUserAccessThis = true;
    }
  },
  beforeUnmount() {
    // clearInterval(this.getNewOrders);
    // if (this.canUserAccessThis) {
    // }
  },
  created() {
    this.viewNewOrders();
    setInterval(this.getNow, 1000);
  },
  methods: {
    clearLogin() {
      this.currentUserAccessCodeIs = "";
      //clear localstorage
      localStorage.removeItem("current_logged_user_access_data");
      setInterval(this.canUserAccessPageNow, 100);
      //   this.$router.push({ name: "ViewOrders",  params: { restId: this.restId }});
      localStorage.clear();
      //   this.canUserAccessPageNow();
      setTimeout(() => {
        this.$router.push({
          name: "ViewOrdersOrTimeInAndOut",
          params: { restId: this.restId },
        });
      }, 1000);
    },
    number_format(amount) {
      const price = amount;
      // Format the price above to USD, INR, EUR using their locales.
      let dollarUS = Intl.NumberFormat("en-US", {
        style: "currency",
        currency: "USD",
      });
      return dollarUS.format(price);
    },
    removeSecondsFromTime(timeIs) {
      //assuming time in 12:34:00 AM/PM format
      return timeIs.replace(/(\d{1,2}:\d{2}):\d{2}/, "$1");
    },
    showReports() {
      this.$router.push({
        name: "ViewOrdersReports",
        params: { restId: this.restId },
      });
    },
    showTimesheet() {
      this.$router.push({
        name: "ViewUsersTimesheets",
        params: { restId: this.restId },
      });
    },
    showOrders() {
      this.$router.push({
        name: "ViewOrders",
        params: { restId: this.restId },
      });
    },
    async getUserNameForEachOrder(userId, appendTo) {
      let fd = new FormData();
      fd.append("userId", userId);
      let rst = await axios.post(
        this.OrdersAPI + `?action=currentUserName`,
        fd
      );
      if (rst.status == 200) {
        const resultData = rst.data;
        if (!resultData.error) {
          // console.log("current user name is " + resultData.user_name_is);
          let name = resultData.user_name_is;
          // console.log("order is cancelled by "+name);
          document.getElementById(appendTo).innerText =
            this.capitalizeFisrtLetterOfEachWord(name);
        }
      }
    },
    getCurrentDateAndTime() {
      let current = new Date();
      let cDate =
        current.getFullYear() +
        "-" +
        (current.getMonth() + 1) +
        "-" +
        current.getDate();
      let cTime =
        current.getHours() +
        ":" +
        current.getMinutes() +
        ":" +
        current.getSeconds();
      let dateTime = cDate + " " + cTime;
      return dateTime;
    },
    async timeInUser(restId, accessId) {
      let fd = new FormData();
      fd.append("restId", restId);
      fd.append("accessId", accessId);
      fd.append("timeIn", this.getCurrentDateAndTime());
      let rst = await axios.post(this.OrdersAPI + `?action=userTimeIn`, fd);
      if (rst.status == 200) {
        const resultData = rst.data;
        if (!resultData.error) {
          // console.log("current user name is " + resultData.user_name_is);
          localStorage.setItem(
            "time_in_and_out",
            JSON.stringify(resultData.time_in_and_out)
          );
          this.isUserLoggedInOrOut = JSON.parse(
            JSON.stringify(resultData.time_in_and_out)
          );
          this.showErrorForTimeInOut = false;
          console.log("logged in successfully");
        } else {
          this.showErrorForTimeInOut = true;
          console.log("error time in");
        }
      }
    },
    async timeOutUser(restId, accessId) {
      let fd = new FormData();
      fd.append("restId", restId);
      fd.append("accessId", accessId);
      fd.append("id", this.isUserLoggedInOrOut[0].id);
      fd.append("timeOut", this.getCurrentDateAndTime());
      let rst = await axios.post(this.OrdersAPI + `?action=userTimeOut`, fd);
      if (rst.status == 200) {
        const resultData = rst.data;
        if (!resultData.error) {
          // localStorage.setItem(
          //   "time_in_and_out",
          //   JSON.stringify(resultData.time_in_and_out)
          // );
          // this.isUserLoggedInOrOut = JSON.parse(
          //   JSON.stringify(resultData.time_in_and_out)
          // );
          console.log("logged out successfully");
          this.showErrorForTimeInOut = false;
          this.clearLogin();
        } else {
          this.showErrorForTimeInOut = true;
          console.log("error timing out");
        }
      }
    },
    async timeAllUsersOut(restId, accessId) {
      let fd = new FormData();
      fd.append("restId", restId);
      fd.append("accessId", accessId);
      fd.append("timeOut", this.getCurrentDateAndTime());
      let rst = await axios.post(
        this.OrdersAPI + `?action=timeAllUsersOut`,
        fd
      );
      if (rst.status == 200) {
        const resultData = rst.data;
        if (!resultData.error) {
          console.log("logged out successfully");
          this.showErrorForTimeInOut = false;
          this.clearLogin();
        } else {
          console.log("error timing out");
          this.showErrorForTimeInOut = true;
        }
      }
    },
    async canUserAccessCurrentPage() {
      this.btnEnabled = false;
      console.log(this.restId);
      console.log(this.currentUserAccessCodeIs);
      let currentUserIdArr = { id: this.currentUserAccessCodeIs };
      localStorage.setItem(
        "current_user_access_id",
        JSON.stringify(currentUserIdArr)
      );
      this.UserAccessCodeIs = this.currentUserAccessCodeIs;
      // console.log(`find all orders for current restaurant ${restId}`);
      if (this.isUserAccessCodeValidated) {
        let fd = new FormData();
        fd.append("restId", this.restId);
        fd.append("accessId", this.currentUserAccessCodeIs);
        fd.append("todaysDateIs", this.todaysDateIs);
        let rst = await axios.post(
          this.OrdersAPI + `?action=canAccessThisPage`,
          fd
        );
        console.log(rst);
        const resultData = rst.data;
        if (rst.status == 200) {
          console.table(resultData);
          if (resultData.error) {
            console.log("this user has an error");
            this.currentUserAccessCodeIsErr = true;
            this.isUserAccessCodeValidated = false;
            this.btnEnabled = false;
            this.currentUserAccessCodeIsMsg = resultData.message;
          } else {
            this.btnEnabled = false;
            if (resultData.is_order_updated) {
              console.log("user name is " + resultData.userNameIs);
            }
            //now set a localstorage to save logged in user data
            localStorage.removeItem("current_logged_user_access_data");
            let loggedInUserId = [];
            loggedInUserId.push({
              user_name: resultData.userNameIs,
              user_id: resultData.userIdIs,
              user_role: resultData.userRoleIs,
            });
            localStorage.setItem(
              "current_logged_user_access_data",
              JSON.stringify(loggedInUserId)
            );
            localStorage.setItem(
              "time_in_and_out",
              JSON.stringify(resultData.setTimeInOut)
            );
            this.isUserLoggedInOrOut = JSON.parse(
              JSON.stringify(resultData.setTimeInOut)
            );
          }
        }
      } else {
        this.btnEnabled = false;
        this.currentUserAccessCodeIsErr = true;
        this.isUserAccessCodeValidated = false;
        this.currentUserAccessCodeIsMsg = "Refresh the page";
      }
    },
    currentCompletedOrders() {
      let currentOrderDetails = localStorage.getItem("current_orders");
      console.log("View Current Active Orders");
      this.isCancelledClicked = false;
      this.isCompletedClicked = true;
      this.isActiveClicked = false;
      this.isReadyClicked = false;
      let currentOrders = JSON.parse(currentOrderDetails).filter(
        (each) =>
          each.is_order_cancelled == 0 &&
          each.is_order_confirmed == 1 &&
          each.is_order_picked_up == 1
      );
      this.currentOrderInDetails = currentOrders
        .sort(function (a, b) {
          return (
            new Date(b.custom_date + " " + b.custom_time) -
            new Date(a.custom_date + " " + a.custom_time)
          );
        })
        .reverse();
    },
    currentReadyOrders() {
      let currentOrderDetails = localStorage.getItem("current_orders");
      console.log("View Current Active Orders");
      this.isCancelledClicked = false;
      this.isCompletedClicked = false;
      this.isActiveClicked = false;
      this.isReadyClicked = true;
      let currentOrders = JSON.parse(currentOrderDetails).filter(
        (each) =>
          each.is_order_cancelled == 0 &&
          each.is_order_confirmed == 1 &&
          each.is_order_picked_up == 0
      );
      this.currentOrderInDetails = currentOrders
        .sort(function (a, b) {
          return (
            new Date(b.custom_date + " " + b.custom_time) -
            new Date(a.custom_date + " " + a.custom_time)
          );
        })
        .reverse();
    },
    currentActiveOrders() {
      let currentOrderDetails = localStorage.getItem("current_orders");
      console.log("View Current Active Orders");
      this.isCancelledClicked = false;
      this.isCompletedClicked = false;
      this.isActiveClicked = true;
      this.isReadyClicked = false;
      let currentOrders = JSON.parse(currentOrderDetails).filter(
        (each) =>
          each.is_order_cancelled == 0 &&
          each.is_order_confirmed == 0 &&
          each.is_order_picked_up == 0
      );
      //sort by time
      this.currentOrderInDetails = currentOrders
        .sort(function (a, b) {
          return (
            new Date(b.custom_date + " " + b.custom_time) -
            new Date(a.custom_date + " " + a.custom_time)
          );
        })
        .reverse();
    },
    currentCancelledOrders() {
      let currentOrderDetails = localStorage.getItem("current_orders");
      console.log("View Current Active Orders");
      this.isCancelledClicked = true;
      this.isCompletedClicked = false;
      this.isActiveClicked = false;
      this.isReadyClicked = false;
      let currentOrders = JSON.parse(currentOrderDetails).filter(
        (each) => each.is_order_cancelled == 1
      );
      //sort by time
      this.currentOrderInDetails = currentOrders
        .sort(function (a, b) {
          return (
            new Date(b.custom_date + " " + b.custom_time) -
            new Date(a.custom_date + " " + a.custom_time)
          );
        })
        .reverse();
    },
    async doesThisRestExist() {
      let fd = new FormData();
      fd.append("restId", this.restId);
      let rst = await axios.post(
        this.OrdersAPI + `?action=isRestaurantExist`,
        fd
      );
      const resultData = rst.data;
      if (rst.status == 200) {
        console.table(resultData);
        localStorage.setItem(
          "current_rest_data_existing",
          JSON.stringify(resultData.current_rest_data_existing)
        );
        //check if therre is return data, means restaurant is avail and active
        let currentRes = localStorage.getItem("current_rest_data_existing");
        if (JSON.parse(currentRes).length > 0 || this.pickUpAddress != "") {
          this.doesRestDataExists = true;
          console.log("this restaurant does exists");
        } else {
          console.log("this restaurant does NOT exists");
          this.doesRestDataExists = false;
        }
      }
    },

    async refundOrder(
      orderNo,
      chargeId,
      userId,
      amount,
      restId,
      showResultId,
      currentBtn,
      last4Digits
    ) {
      document.getElementById(currentBtn).setAttribute("disabled", "");
      let fd = new FormData();
      fd.append("orderNo", orderNo);
      fd.append("chargeId", chargeId);
      fd.append("restId", restId);
      fd.append("amount", amount);
      fd.append("userId", userId);
      fd.append("last4", last4Digits);
      fd.append("cancelationReason", this.reasonToCancelOrder);
      let rst = await axios.post(this.OrdersAPI + `?action=refundOrder`, fd);
      const resultData = rst.data;
      if (rst.status == 200) {
        console.log(resultData);
        if (resultData.error) {
          document.getElementById(currentBtn).removeAttribute("disabled");
          document.getElementById(showResultId).innerHTML =
            "<div class='m-5 alert alert-warning'>" +
            resultData.message +
            "</div>";
        } else {
          document.getElementById(currentBtn).setAttribute("disabled", "");
          document.getElementById(showResultId).innerHTML =
            "<div class='m-5 alert alert-success'>" +
            resultData.message +
            "</div>";
          // let ele = document.getElementsByTagName("body");
          document.body.classList.remove("modal-open");
          document.getElementById("cancelOrderModal" + orderNo).remove();
          document.body.removeAttribute("style");
          const boxes = document.querySelectorAll(".modal-backdrop");
          boxes.forEach((box) => {
            box.remove();
          });
        }
      } else {
        document.getElementById(currentBtn).removeAttribute("disabled");
        console.log(resultData);
        document.getElementById(showResultId).innerHTML =
          "<div class='m-5 alert alert-warning'>" +
          resultData.message +
          "</div>";
      }
    },
    displayCustomDate(dateIs) {
      const currentDate = new Date(dateIs).toLocaleDateString();
      const currentTime = new Date(dateIs).toLocaleTimeString();
      console.log(currentDate);
      console.log(currentTime);
      // const [year, month, day] = currentDate.split("-");
      // const result = [month, day, year].join("/");
      let n = currentDate + " at " + this.timeFormat12Hours(currentTime);
      return n;
    },
    validateUserAccessCode(e) {
      let val = e.target.value;
      this.validateUserAccessCodeInput(val);
    },
    goBack() {
      this.$router.push({
        name: "ViewOrdersOrTimeInAndOut",
        params: { restId: this.restId },
      });
    },
    validateUserAccessCodeInput(val) {
      // let userPhoneRegex = /^\d{10}$/;
      console.log("User Code: " + val);
      if (val == "") {
        this.currentUserAccessCodeIsErr = true;
        this.isUserAccessCodeValidated = false;
        this.currentUserAccessCodeIsMsg = "Must Enter User Code ..";
        this.btnEnabled = false;
      } else if (/^\d+$/.test(val) == false) {
        //only numbers from 0-9 no digits, + - * / .
        this.btnEnabled = false;
        this.currentUserAccessCodeIsErr = true;
        this.isUserAccessCodeValidated = false;
        this.currentUserAccessCodeIsMsg = "Numbers Only";
      } else {
        this.btnEnabled = true;
        this.currentUserAccessCodeIsErr = false;
        this.isUserAccessCodeValidated = true;
        this.currentUserAccessCodeIsMsg = "";
      }
    },
    getNow() {
      const today = new Date();
      let hour = today.getHours();
      let amOrPm = hour < 12 ? " AM" : " PM";
      if (hour == 0) {
        hour = 12;
      }
      if (hour > 12) {
        hour = hour - 12;
      }
      if (hour < 10) {
        hour = "0" + hour;
      }
      let minute = today.getMinutes();
      if (minute < 10) {
        minute = "0" + minute;
      }
      // add a zero in front of numbers<10
      // minute = this.checkTime(minute);
      let sec = today.getSeconds();
      if (sec < 10) {
        sec = "0" + sec;
      }
      let time = hour + ":" + minute + ":" + sec + amOrPm;
      this.timestamp = time;
    },
    checkTime(i) {
      if (i < 10) {
        i = "0" + i;
      }
      return i;
    },
    UpdateTimer() {
      this.Timer.innerHTML = this.TotalMins + ":" + this.secs;
    },
    viewNewOrders() {
      //first find if user is not logged in
      this.getNewOrders = setInterval(() => {
        let currentLoggedInUserAccessData = localStorage.getItem(
          "current_logged_user_access_data"
        );
        if (currentLoggedInUserAccessData === null) {
          console.log("no log in");
          this.canUserAccessThis = false;
          // this.currentUserAccessCodeIs = "";
          // this.
        } else {
          console.log("searching for new orders...");
          console.log("user is logged in");
          this.canUserAccessThis = true;
          this.currentLoggedInUserData = JSON.parse(
            currentLoggedInUserAccessData
          );
        }
      }, 1000); //run this every 2 minutes
    },
    formatPhoneNumber(number) {
      //remove . + -  from any number
      let cleaned = ("" + number).replace(/\D/g, "");
      let match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
      if (match) {
        return "(" + match[1] + ") " + match[2] + "-" + match[3];
      }
      return null;
    },
    timeFormat12Hours(time) {
      // Check correct time format and split into components
      time = time
        .toString()
        .match(/^([01]\d|2[0-3])(:)([0-5]\d)(:[0-5]\d)?$/) || [time];

      if (time.length > 1) {
        // If time format correct
        time = time.slice(1); // Remove full string match value
        time[5] = +time[0] < 12 ? " AM" : " PM"; // Set AM/PM
        time[0] = +time[0] % 12 || 12; // Adjust hours
      }
      return time.join(""); // return adjusted time or original string
    },
    getCurrentDate() {
      // let currentDate = new Date().toJSON().slice(0, 10); // 10/26/2022 or use below
      let currentDate = new Date().toLocaleDateString();
      return currentDate;
    },
    getCurrentTime() {
      let currentTime = new Date().toLocaleTimeString();
      return currentTime;
    },
    addMinutesToCurrentTime(addMins) {
      let currentTime = new Date();
      return currentTime.setMinutes(currentTime.getMinutes() + addMins);
    },
    addHoursToDateAndReturnTimeOnly(objDate, intHours) {
      var numberOfMlSeconds = objDate.getTime();
      var addMlSeconds = intHours * 60 * 60 * 1000;
      var newDateObj = new Date(numberOfMlSeconds + addMlSeconds); //here it return full date with time added
      return newDateObj.toLocaleTimeString("en-US", {
        hour12: false,
      }); //here only returns time added to current time
    },
    fullDateFormated() {
      return new Date().toLocaleString("en-US", {
        hour12: false,
        year: "numeric",
        month: "2-digit",
        day: "2-digit",
        hour: "2-digit",
        minute: "2-digit",
        second: "2-digit",
      });
    },
    getCurrentTime24HoursFormat() {
      let currentTime = new Date().toLocaleTimeString("en-US", {
        hour12: false,
      });
      return currentTime;
    },
    displayCustomDateTime(dateIs, timeIs) {
      if (dateIs != "") {
        const [year, month, day] = dateIs.split("-");
        const result = [month, day, year].join("/");
        let n = result + " at " + this.timeFormat12Hours(timeIs);
        return n;
      } else {
        return this.timeFormat12Hours(timeIs);
      }
    },
    async viewOrders(restId) {
      console.log(`find all orders for current restaurant ${restId}`);
      let fd = new FormData();
      fd.append("restId", restId);
      fd.append("startDate", this.viewOrderStartingDate);
      fd.append("endDate", this.viewOrderEndingDate);
      let rst = await axios.post(this.OrdersAPI + `?action=viewOrders`, fd);
      console.log(rst);
      const resultData = rst.data;
      if (rst.status == 200) {
        console.table(resultData);
        if (resultData.error) {
          console.log("this order was not found");
        } else {
          console.log("this order was found");
          localStorage.setItem(
            "current_orders",
            JSON.stringify(resultData.current_orders)
          );
          localStorage.setItem(
            "current_rest_details",
            JSON.stringify(resultData.current_rest_details)
          );
          // show current order details
          let currentRestaurants = JSON.parse(
            localStorage.getItem("current_rest_details")
          );
          if (currentRestaurants.length >= 1) {
            console.table(currentRestaurants);
            this.pickUpAddress = currentRestaurants[0].rest_address;
            this.pickUpPhoneNumber = currentRestaurants[0].rest_phone;
            this.businessName = currentRestaurants[0].rest_name;
          }
          let current_orders = localStorage.getItem("current_orders");
          this.currentOrder = JSON.parse(current_orders);
          console.table(current_orders);

          let currentOrderDetails = localStorage.getItem("current_orders");
          console.table(currentOrderDetails);
          //this.currentOrderInDetails = JSON.parse(currentOrderDetails);
          let currentActiveOrders = JSON.parse(currentOrderDetails).filter(
            (each) =>
              each.is_order_confirmed == 0 && each.is_order_cancelled == 0
          );
          //sort by time
          this.currentOrderInDetails = currentActiveOrders
            .sort(function (a, b) {
              return (
                new Date(b.custom_date + " " + b.custom_time) -
                new Date(a.custom_date + " " + a.custom_time)
              );
            })
            .reverse();
        }
      }
    },
    //should get current logged in id to check if he owns current restaurant
    async orderReadyOrPickedUp(orderId, type) {
      // console.log(`find all orders for current restaurant ${restId}`);
      let fd = new FormData();
      fd.append("restId", this.restId);
      fd.append("orderId", orderId);
      fd.append("type", type);
      let rst = await axios.post(
        this.OrdersAPI + `?action=readyOrPickedUp`,
        fd
      );
      console.log(rst);
      const resultData = rst.data;
      if (rst.status == 200) {
        console.table(resultData);
        if (resultData.error) {
          console.log("this order has error");
        } else {
          if (resultData.is_order_updated) {
            console.log("this order status is updated");
          }
          // let result = resultData;
          // show current order details
        }
      }
    },
    capitalizeFisrtLetterOfEachWord(text) {
      return text
        .toLowerCase()
        .split(" ")
        .map((s) => s.charAt(0).toUpperCase() + s.substring(1))
        .join(" ");
    },
    printSection(id) {
      // Get HTML to print from element
      const prtHtml = document.getElementById(id).innerHTML;
      // Get all stylesheets HTML
      let stylesHtml = "";
      for (const node of [
        ...document.querySelectorAll('link[rel="stylesheet"], style'),
      ]) {
        stylesHtml += node.outerHTML;
      }
      // Open the print window
      const WinPrint = window.open(
        "",
        "",
        "left=0,top=0,width=800,height=900,toolbar=0,scrollbars=0,status=0"
      );

      WinPrint.document.write(`<!DOCTYPE html>
          <html>
          <head>
              ${stylesHtml}
          </head>
          <body>
              ${prtHtml}
          </body>
          </html>`);

      WinPrint.document.close();
      WinPrint.focus();
      WinPrint.print();
      WinPrint.close();
    },
  },
};
</script>

<style scoped>
/* @import "https://cdn.jsdelivr.net/npm/bootstrap-print-css/css/bootstrap-print.min.css"; */
@media print {
  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  p,
  div,
  body,
  hr,
  .redColor,
  button,
  .btn,
  .biggerFont,
  .alert-danger,
  .btns-danger {
    font-size: 10pt;
    color: black !important;
    font-weight: bold !important;
    font-family: monospace !important;
    font-variant-caps: normal !important;
    font-optical-sizing: auto !important;
    font-style: normal !important;
    font-variant: proportional-nums !important;
    background-color: white !important;
  }

  hr {
    height: 0;
  }

  .btns-danger,
  .btns-danger:hover,
  .btns-danger:active {
    color: black !important;
    background-color: white !important;
    border-color: black !important;
    font-weight: bold !important;
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    font-size: 15pt;
    font-family: monospace !important;
  }

  .alert-danger {
    background-color: none;
    border-radius: 5px;
    border: 1px solid #e2e2e2;
  }

  .each-order-item {
    border: none !important;
  }
}

.each-order-title {
  background-color: #f8f8f8 !important;
  padding: 8px;
  font-weight: bold;
  width: 100%;
  overflow: auto !important;
  color: #02027e !important;
}

.marginTop10 {
  margin-top: 14px !important;
}

.custom-bg {
  position: relative;
  padding: 2px 0 0 0;
}

.custom-header-left {
  position: absolute;
  top: -32px;
}

.custom-header-right {
  position: absolute;
  right: 0;
  top: -32px;
  font-weight: bold !important;
  background-color: white !important;
}

.addBorder {
  background-color: beige;
  border: 1px solid black;
  padding: 8px;
  font-weight: bold;
  width: 90%;
  margin: 18px auto;
  border-radius: 5px;
}

.addressStyle {
  font-style: italic;
  padding: 0;
  margin: 0;
  line-height: 0.5;
}

.form-data-error {
  color: red;
}

.form-data-error input,
.form-data-error textarea {
  border-color: red;
}

.itemDataDetails {
  padding: 10px;
  margin-bottom: 10px;
  border: 10px solid #eee;
}

.each-order-item {
  border: 1px solid #000203;
  padding: 6px;
  margin: 23px 0;
}

.itemDataDetails img {
  width: 320px;
  height: 215px;
  margin-bottom: 10px;
}

.bw {
  overflow-wrap: break-word;
}

.darkred {
  color: darkred;
}

.redColor {
  color: red !important;
}

.order-placed {
  padding: 10px;
}

.order-placed h2 {
  color: green;
}

.addressInfo {
  display: block;
  font-style: italic;
  font-weight: bold;
}

.card-header {
  background-color: #008c5d !important;
  font-weight: bold;
}

.mb5 {
  margin-bottom: 5px;
}

.text-bold {
  font-weight: bold;
}

.card-header a,
.card-header a:hover,
.card-header a:visited,
.card-header a:active,
.card-header a:focus {
  color: white;
  display: block;
  text-align: left;
  border: none;
}

.card-header .btn-check:focus + .btn,
.card-header .btn:focus {
  box-shadow: none !important;
}

.navbar {
  padding-top: 0px !important;
  padding-bottom: 5px !important;
}

.fb {
  font-weight: bold;
}

.custom-order {
  margin-bottom: 10px;
}

.btns-success,
.btns-success:hover,
.btns-success:active {
  color: #21b023;
  background-color: #fff;
  border-color: #21b023;
  font-weight: bold;
}

.biggerFont {
  font-size: 1.5em;
  padding: 0;
}

.btns-orange,
.btns-orange:hover,
.btns-orange:active {
  color: #ff5722 !important;
  background-color: #fff;
  border-color: #ff5722 !important;
  font-weight: bold;
}

.btns-primary,
.btns-primary:hover,
.btns-primary:active {
  color: blue;
  background-color: white;
  border-color: blue;
  font-weight: bold;
}

.btns-danger,
.btns-danger:hover,
.btns-danger:active {
  color: red;
  background-color: white;
  border-color: red;
  font-weight: bold;
}

.color-356f71 {
  color: #356f71;
}

.custom-name {
  padding-left: 20px;
  margin-bottom: -5px;
  clear: both;
  margin-top: -14px;
}

.total-end {
  font-weight: bold;
  margin-top: 5px;
}

.slidecontainer {
  width: 100%;
}

.slider {
  -webkit-appearance: none;
  width: 100%;
  height: 15px;
  border-radius: 5px;
  background: #d3d3d3;
  outline: none;
  opacity: 0.7;
  -webkit-transition: 0.2s;
  transition: opacity 0.2s;
}

.slider:hover {
  opacity: 1;
}

.slider::-webkit-slider-thumb {
  -webkit-appearance: none;
  appearance: none;
  width: 25px;
  height: 25px;
  border-radius: 50%;
  background: #04aa6d;
  cursor: pointer;
}

.slider::-moz-range-thumb {
  width: 25px;
  height: 25px;
  border-radius: 50%;
  background: #04aa6d;
  cursor: pointer;
}

.pointerHand {
  cursor: pointer;
}

.greenColor {
  color: #00c904 !important;
}

.mainHeader {
  box-sizing: border-box;
  color: red;
  width: 100%;
  margin: 0;
}

.colorRed {
  color: red;
}

.custom-button {
  background-color: white;
}

.greenColor {
  color: #21b023 !important;
}

.yellowColor {
  color: #ffff00;
}

.blueColor {
  color: #0000ff;
}

.exit-button,
.report-button,
.search-button {
  font-weight: bold;
}

.exit-button {
  color: #d20000;
}

.search-button {
  color: #3668a3;
}

.report-button {
  color: #3668a3 !important;
}

.borderRed {
  border-color: red !important;
  background-color: red !important;
  color: white !important;
}

.box {
  background-color: white;
  /* border-top: 2px solid #21b023; */
  height: 150px;
  position: fixed;
  bottom: 0;
  padding: 0;
  z-index: 1000;
}

.bet_time {
  z-index: 1001;
  position: absolute;
  bottom: 50px;
  right: 10px;
  padding: 8px;
}

.bet_time_left {
  z-index: 1001;
  position: absolute;
  bottom: 50px;
  left: 10px;
  padding: 8px;
}

.bet_time_error {
  position: fixed;
  bottom: 50px;
  width: 100%;
  padding: 8px;
}

.blink {
  border-color: red;
  animation: blink-animation 1s steps(5, start) infinite;
  -webkit-animation: blink-animation 1s steps(5, start) infinite;
}

@keyframes blink-animation {
  to {
    visibility: hidden;
  }
}

@-webkit-keyframes blink-animation {
  to {
    visibility: hidden;
  }
}

.font-size-1-25-em {
  font-size: 1.25em;
}

@media only screen and (max-width: 600px) {
  .biggerFont {
    font-size: 1em;
  }
}
</style>
