<template>
  <div class="container">
    <Navbar />
    <nav style="--bs-breadcrumb-divider: '>'" aria-label="breadcrumb">
      <ol class="breadcrumb">
        <li class="breadcrumb-item">
          <router-link tag="li" :to="{ name: 'Home' }">
            <a>Home</a>
          </router-link>
        </li>
        <li class="breadcrumb-item">
          <router-link tag="li" :to="{ name: 'MyRestaurants' }">
            <a>My Restaurant(s)</a>
          </router-link>
        </li>
        <li v-if="this.isRestActive" class="breadcrumb-item active" aria-current="page">
          {{ this.userName }}
        </li>
        <li v-if="this.isRestActive" class="breadcrumb-item active" aria-current="page">
          Deactivate
        </li>
      </ol>
    </nav>
    <div v-if="this.isRestActive" class="add-new-restaurant-page">
      <!-- Add new restaurant -->
      <form method="post" action="#" enctype="multipart/form-data">
        <div class="row g-3 align-items-center mb-3">
          <div class="col-auto d-block mx-auto">
            <div class="form-floating" :class="{ 'form-data-error': userNameErr }">
              <input type="text" id="userNameInput" placeholder="Restaurant Name" class="form-control w300"
                @keyup="validateRestaurantName($event)" @change="validateRestaurantName($event)" v-model.trim="userName"
                disabled />
              <label for="userNameInput">
                <faIcons :icon="['fas', 'user-circle']" /> Restaurant
                Name
              </label>
              <span class="error-feedback" v-if="userNameErr">
                {{ userNameMsg }}
              </span>
            </div>
          </div>
        </div>

        <div class="row g-3 align-items-center mb-3">
          <div class="col-auto d-block mx-auto">
            <div class="form-floating" :class="{ 'form-data-error': userPhoneErr }">
              <input type="number" min="0" id="userPhoneInput" placeholder="Restaurant Phone"
                @keyup="validateRestaurantPhone($event)" @change="validateRestaurantPhone($event)"
                class="form-control w300" v-model.trim="userPhone" disabled />
              <label for="userPhoneInput">
                <faIcons :icon="['fas', 'phone']" /> Restaurant Phone
              </label>
              <span class="error-feedback" v-if="userPhoneErr">
                {{ userPhoneMsg }}
              </span>
            </div>
          </div>
        </div>

        <div class="row g-3 align-items-center mb-3">
          <div class="col-auto d-block mx-auto">
            <div class="form-floating" :class="{ 'form-data-error': userAddressErr }">
              <textarea id="userAddressInput" placeholder="Restaurant Full Address"
                @input="validateRestaurantAddress($event)" class="form-control w300 h100 noResize"
                v-model.trim="userAddress" disabled />
              <label for="userAddressInput">
                <faIcons :icon="['fas', 'address-book']" /> Restaurant
                Address
              </label>
              <span class="error-feedback" v-if="userAddressErr">
                {{ userAddressMsg }}
              </span>
            </div>
          </div>
        </div>
        <div class="row d-grid gap-2 w300 col-auto d-block mx-auto mb-3">
          <div id="deleteCuurentRestaurant" class="form-text">
            <faIcons :icon="['fas', 'exclamation-triangle']" /> You will no
            longer view current restaurant once deactivated. To reactivate,
            contact Admin!
          </div>
          <button class="btn btn-outline-danger" @click.prevent="DeactivateCurrentRestaurant()" v-if="signUpBtn">
            <faIcons :icon="['fas', 'trash']" /> Deactivate Restaurant
          </button>
        </div>
        <div class="row d-grid gap-2 w300 col-auto d-block mx-auto mb-3">
          <div class="alert alert-success" role="alert" v-if="resultSuccess">
            {{ resultSuccessMsg }}
          </div>
          <div class="alert alert-danger" role="alert" v-if="resultErr">
            {{ resultErrMsg }}
          </div>
        </div>
        <div class="row d-grid gap-2 w300 col-auto d-block mx-auto mb-3">
          <router-link class="btn btn-outline-primary" :to="{ name: 'MyRestaurants' }">
            <faIcons :icon="['fas', 'undo']" /> Never mind, go back
          </router-link>
        </div>
      </form>
    </div>
    <div v-else-if="!this.isRestActive && this.canAccess">
      <div class="alert alert-dark" role="alert">
        <p>This restaurant is already deactivated!</p>
      </div>
      <router-link tag="li" :to="{ name: 'MyRestaurants' }">
        <a class="btn btn-success">Go back to my restaurant(s)</a>
      </router-link>
    </div>
    <div v-if="!this.canAccess">
      <div class="alert alert-dark" role="alert">
        <p>You are not allowed to access this information!</p>
      </div>
      <router-link tag="li" :to="{ name: 'MyRestaurants' }">
        <a class="btn btn-success">Go back to my restaurant(s)</a>
      </router-link>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import { mapState, mapMutations, mapGetters } from "vuex";
import Navbar from "@/components/Header/Navbar.vue";
export default {
  name: "DeactivateRestaurant",
  components: {
    Navbar,
  },
  data() {
    return {
      user: {
        uId: "",
        userId: "",
        uName: "",
        uEmail: "",
        uPassword: "",
        uMemberSince: "",
        uAddress: "",
        uPhone: "",
        uRole: "",
        uRoleIs: "",
      },
      //Restaurant Info from URL
      restId: this.$route.params.restId,
      restNum: this.$route.params.id,
      //address
      userAddress: "",
      userAddressErr: false,
      userAddressMsg: "",
      isuserAddressValidated: false,
      //phone
      userPhone: "",
      userPhoneErr: false,
      userPhoneMsg: "",
      isuserPhoneValidated: false,
      //name
      userName: "",
      userNameErr: false,
      userNameMsg: "",
      isuserNameValidated: false,

      resultSuccess: false,
      resultSuccessMsg: "",
      resultErr: false,
      resultErrMsg: "",
      image: "",
      is_DB_Connected: "",
      db_connection_msg: "",
      // apiMainUrl: "http://localhost/php/login-system-php-vue-2021/myData.php",
      apiMainUrl: this.$store.getters.MainUrl, //call global variable
      signUpBtn: true,
      isRestActive: false,
      canAccess: true,
    };
  },
  mounted() {
    let user = localStorage.getItem("current-user-data");
    let currentRestaurants = localStorage.getItem("current_restaurants_data");
    let currentRestaurant = JSON.parse(currentRestaurants).filter(
      (restInfo) =>
        restInfo.id == this.restNum && restInfo.rest_id == this.restId
    );
    console.log(currentRestaurant);
    if (user && currentRestaurants) {
      //restuarant must be active [1], in order to deactivate it [0]
      if (currentRestaurant.length > 0 && currentRestaurant[0].active == 1) {
        this.isRestActive = true;
        this.userPhone = currentRestaurant[0].rest_phone;
        this.userName = currentRestaurant[0].rest_name;
        this.userAddress = currentRestaurant[0].rest_address;
      } else if (
        currentRestaurant.length > 0 &&
        currentRestaurant[0].active == 0
      ) {
        this.isRestActive = false;
        this.userPhone = currentRestaurant[0].rest_phone;
        this.userName = currentRestaurant[0].rest_name;
        this.userAddress = currentRestaurant[0].rest_address;
      } else {
        this.canAccess = false;
      }
      //get users info
      this.user.uId = JSON.parse(user).id;
      this.user.userId = JSON.parse(user).user_id;
      this.user.uName = JSON.parse(user).name;
      this.user.uEmail = JSON.parse(user).email;
      this.user.uPassword = JSON.parse(user).password;
      this.user.uMemberSince = JSON.parse(user).member_since;
      this.user.uAddress = JSON.parse(user).full_address;
      this.user.uPhone = JSON.parse(user).phone_number;
      this.user.uRole = JSON.parse(user).role;
      //on manager or admin can view this
      if (
        JSON.parse(user).role !== "mngr" &&
        JSON.parse(user).role !== "admn"
      ) {
        this.$router.push({ name: "Login" });
      }
    } else {
      this.$router.push({ name: "Login" });
    }
  },
  computed: {
    ...mapState([
      "__RestaurantId",
      "__RestaurantEmail",
      "__RestaurantRole",
      "__RestaurantName",
      "__MainUrl",
    ]),
    ...mapGetters(["MainUrl"]),
  },
  methods: {
    ...mapMutations(["setRestaurantInfo"]),
    async DeactivateCurrentRestaurant() {
      this.resultErr = false;
      this.resultErrMsg = "";
      this.resultSuccess = true;
      this.resultSuccessMsg =
        "Please wait while we're deactivating your restaurant...";
      this.signUpBtn = false;
      //show Result
      let fd = new FormData();
      fd.append("userId", this.user.userId);
      fd.append("restId", this.restId);
      fd.append("restNum", this.restNum);
      let rst = await axios.post(
        this.apiMainUrl + `?action=DeactivateCurrentRestaurant`,
        fd
      );
      console.log(rst);
      const resultData = rst.data;
      if (rst.status == 200) {
        if (resultData.error) {
          console.warn("Connection Error Happened on Adding New Restaurant");
          this.resultErrMsg = resultData.message;
          this.resultErr = true;
          this.resultSuccessMsg = "";
          this.resultSuccess = false;
          this.signUpBtn = true;
        } else {
          this.signUpBtn = false;
          console.log("Current resrautant has been deactivated successfully!");
          //if everything is okay
          this.resultErrMsg = "";
          this.resultErr = false;
          this.resultSuccess = true;
          //set restaurant to local host: current_restaurants_data
          localStorage.setItem(
            "current_restaurants_data",
            JSON.stringify(resultData.current_restaurants_data)
          );
          //redirect only if customer
          this.resultSuccessMsg = this.userName + " " + resultData.message;
          setTimeout(() => {
            this.$router.push({ name: "MyRestaurants" });
            console.log("Restaurant Name: " + this.userName);
          }, 2000);
        }
      }
    },
  },
};
</script>
<style lang="scss" scoped>
$navColor: #3f51b6;

nav a {
  text-decoration: none;
  color: $navColor;
  font-weight: bold;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

.form-control[data-v-09cd4e5a]:disabled,
.form-control[readonly][data-v-09cd4e5a] {
  background-color: #ffffff;
}

.noResize[data-v-09cd4e5a] {
  resize: none;
}

.form-control:disabled,
.form-control[readonly] {
  background-color: #fff;
}
</style>
