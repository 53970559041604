<template>
    <div class="container-fluid" v-if="doesRestDataExists">
        <div v-if="!canUserAccessThis">
            <div class="d-flex aligns-items-center justify-content-center m-3">
                <button class="btn btn-outline-success" @click="viewOrders()">
                    <faIcons :icon="['fas', 'eye']" /> View Orders
                </button>&nbsp;
                <button class="btn btn-outline-primary" type="button" @click="showTimeInAndOut()">
                    <faIcons :icon="['fas', 'hourglass-start']" /> Punch In & Out
                </button>
            </div>
        </div>
    </div>
    <div class="container-fluid" v-else>
        <div class="d-flex aligns-items-center justify-content-center">
            <div class="m-5 alert alert-danger">
                Make sure you have the right online order page.
            </div>
        </div>
    </div>
</template>
<script>
import axios from "axios";
export default {
    name: "ViewOrdersOrTimeInAndOut",
    data() {
        return {
            showErrorForTimeInOut: false,
            reasonToCancelOrder: "Customer no longer wants this order",
            currentLoggedInUserData: "",
            currentUserAccessCodeIs: "",
            currentUserAccessCodeIsErr: false,
            isUserAccessCodeValidated: false,
            btnEnabled: false,
            currentUserAccessCodeIsMsg: "",
            canUserAccessThis: false,
            showPickedUp: false,
            showInProgress: true,
            showReadyOrders: false,
            showAllOrders: false,
            restId: this.$route.params.restId,
            currentOrder: [],
            pickUpAddress: "",
            pickUpPhoneNumber: "",
            isCompletedClicked: false,
            isActiveClicked: false,
            isReadyClicked: false,
            isCancelledClicked: false,
            businessName: "",
            custName: "",
            orderData: "",
            custom_order_date_or_now: "",
            custEmail: "",
            showOrderData: true,
            showCustomerData: false,
            tips: 0,
            OrdersAPI: this.$store.getters.OrdersAPI,
            orderSequenceNumber: 0,
            custom_date_time: "",
            currentRealOrder: [],
            currentOrderInDetails: [],
            getNewOrders: null,
            doesRestDataExists: true,
            UserAccessCodeIs: "",
            todaysDateIs: new Date(
                new Date().getTime() - new Date().getTimezoneOffset() * 60000
            )
                .toISOString()
                .split("T")[0],
            viewOrderStartingDate: new Date(
                new Date().getTime() - new Date().getTimezoneOffset() * 60000
            )
                .toISOString()
                .split("T")[0],
            viewOrderEndingDate: new Date(
                new Date().getTime() - new Date().getTimezoneOffset() * 60000
            )
                .toISOString()
                .split("T")[0],
            timestamp: "",
            isUserLoggedInOrOut: [],
            currentRestDetailsArr: [],
        };
    },
    mounted() {
        this.doesThisRestExist();
    },
    methods: {
        clearLogin() {
            this.currentUserAccessCodeIs = "";
            //clear localstorage
            localStorage.removeItem("current_logged_user_access_data");
            localStorage.clear();
        },
        number_format(amount) {
            const price = amount;
            // Format the price above to USD, INR, EUR using their locales.
            let dollarUS = Intl.NumberFormat("en-US", {
                style: "currency",
                currency: "USD",
            });
            return dollarUS.format(price);
        },
        removeSecondsFromTime(timeIs) {
            //assuming time in 12:34:00 AM/PM format
            return timeIs.replace(/(\d{1,2}:\d{2}):\d{2}/, "$1");
        },
        showReports() {
            this.$router.push({
                name: "ViewOrdersReports",
                params: { restId: this.restId },
            });
        },
        showTimeInAndOut() {
            this.$router.push({
                name: "TimeInOut",
                params: { restId: this.restId },
            });
        },
        viewOrders() {
            this.$router.push({
                name: "ViewOrders",
                params: { restId: this.restId },
            });
        },
        async doesThisRestExist() {
            let fd = new FormData();
            fd.append("restId", this.restId);
            let rst = await axios.post(
                this.OrdersAPI + `?action=isRestaurantExist`,
                fd
            );
            const resultData = rst.data;
            if (rst.status == 200) {
                console.table(resultData);
                localStorage.setItem(
                    "current_rest_data_existing",
                    JSON.stringify(resultData.current_rest_data_existing)
                );
                //check if therre is return data, means restaurant is avail and active
                let currentRes = localStorage.getItem("current_rest_data_existing");
                if (JSON.parse(currentRes).length > 0 || this.pickUpAddress != "") {
                    this.doesRestDataExists = true;
                    console.log("this restaurant does exists");
                } else {
                    console.log("this restaurant does NOT exists");
                    this.doesRestDataExists = false;
                }
            }
        },
    },
};
</script>
  
<style scoped>
/* @import "https://cdn.jsdelivr.net/npm/bootstrap-print-css/css/bootstrap-print.min.css"; */
@media print {

    h1,
    h2,
    h3,
    h4,
    h5,
    h6,
    p,
    div,
    body,
    hr,
    .redColor,
    button,
    .btn,
    .biggerFont,
    .alert-danger,
    .btns-danger {
        font-size: 10pt;
        color: black !important;
        font-weight: bold !important;
        font-family: monospace !important;
        font-variant-caps: normal !important;
        font-optical-sizing: auto !important;
        font-style: normal !important;
        font-variant: proportional-nums !important;
        background-color: white !important;
    }

    hr {
        height: 0;
    }

    .btns-danger,
    .btns-danger:hover,
    .btns-danger:active {
        color: black !important;
        background-color: white !important;
        border-color: black !important;
        font-weight: bold !important;
    }

    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
        font-size: 15pt;
        font-family: monospace !important;
    }

    .alert-danger {
        background-color: none;
        border-radius: 5px;
        border: 1px solid #e2e2e2;
    }

    .each-order-item {
        border: none !important;
    }
}

.each-order-title {
    background-color: #f8f8f8 !important;
    padding: 8px;
    font-weight: bold;
    width: 100%;
    overflow: auto !important;
    color: #02027e !important;
}

.marginTop10 {
    margin-top: 14px !important;
}

.custom-bg {
    position: relative;
    padding: 2px 0 0 0;
}

.custom-header-left {
    position: absolute;
    top: -32px;
}

.custom-header-right {
    position: absolute;
    right: 0;
    top: -32px;
    font-weight: bold !important;
    background-color: white !important;
}

.addBorder {
    background-color: beige;
    border: 1px solid black;
    padding: 8px;
    font-weight: bold;
    width: 90%;
    margin: 18px auto;
    border-radius: 5px;
}

.addressStyle {
    font-style: italic;
    padding: 0;
    margin: 0;
    line-height: 0.5;
}

.form-data-error {
    color: red;
}

.form-data-error input,
.form-data-error textarea {
    border-color: red;
}

.itemDataDetails {
    padding: 10px;
    margin-bottom: 10px;
    border: 10px solid #eee;
}

.each-order-item {
    border: 1px solid #000203;
    padding: 6px;
    margin: 23px 0;
}

.itemDataDetails img {
    width: 320px;
    height: 215px;
    margin-bottom: 10px;
}

.bw {
    overflow-wrap: break-word;
}

.darkred {
    color: darkred;
}

.redColor {
    color: red !important;
}

.order-placed {
    padding: 10px;
}

.order-placed h2 {
    color: green;
}

.addressInfo {
    display: block;
    font-style: italic;
    font-weight: bold;
}

.card-header {
    background-color: #008c5d !important;
    font-weight: bold;
}

.mb5 {
    margin-bottom: 5px;
}

.text-bold {
    font-weight: bold;
}

.card-header a,
.card-header a:hover,
.card-header a:visited,
.card-header a:active,
.card-header a:focus {
    color: white;
    display: block;
    text-align: left;
    border: none;
}

.card-header .btn-check:focus+.btn,
.card-header .btn:focus {
    box-shadow: none !important;
}

.navbar {
    padding-top: 0px !important;
    padding-bottom: 5px !important;
}

.fb {
    font-weight: bold;
}

.custom-order {
    margin-bottom: 10px;
}

.btns-success,
.btns-success:hover,
.btns-success:active {
    color: #21b023;
    background-color: #fff;
    border-color: #21b023;
    font-weight: bold;
}

.biggerFont {
    font-size: 1.5em;
    padding: 0;
}

.btns-orange,
.btns-orange:hover,
.btns-orange:active {
    color: #ff5722 !important;
    background-color: #fff;
    border-color: #ff5722 !important;
    font-weight: bold;
}

.btns-primary,
.btns-primary:hover,
.btns-primary:active {
    color: blue;
    background-color: white;
    border-color: blue;
    font-weight: bold;
}

.btns-danger,
.btns-danger:hover,
.btns-danger:active {
    color: red;
    background-color: white;
    border-color: red;
    font-weight: bold;
}

.color-356f71 {
    color: #356f71;
}

.custom-name {
    padding-left: 20px;
    margin-bottom: -5px;
    clear: both;
    margin-top: -14px;
}

.total-end {
    font-weight: bold;
    margin-top: 5px;
}

.slidecontainer {
    width: 100%;
}

.slider {
    -webkit-appearance: none;
    width: 100%;
    height: 15px;
    border-radius: 5px;
    background: #d3d3d3;
    outline: none;
    opacity: 0.7;
    -webkit-transition: 0.2s;
    transition: opacity 0.2s;
}

.slider:hover {
    opacity: 1;
}

.slider::-webkit-slider-thumb {
    -webkit-appearance: none;
    appearance: none;
    width: 25px;
    height: 25px;
    border-radius: 50%;
    background: #04aa6d;
    cursor: pointer;
}

.slider::-moz-range-thumb {
    width: 25px;
    height: 25px;
    border-radius: 50%;
    background: #04aa6d;
    cursor: pointer;
}

.pointerHand {
    cursor: pointer;
}

.greenColor {
    color: #00c904 !important;
}

.mainHeader {
    box-sizing: border-box;
    color: red;
    width: 100%;
    margin: 0;
}

.colorRed {
    color: red;
}

.custom-button {
    background-color: white;
}

.greenColor {
    color: #21b023 !important;
}

.yellowColor {
    color: #ffff00;
}

.blueColor {
    color: #0000ff;
}

.exit-button,
.report-button,
.search-button {
    font-weight: bold;
}

.exit-button {
    color: #d20000;
}

.search-button {
    color: #3668a3;
}

.report-button {
    color: #3668a3 !important;
}

.borderRed {
    border-color: red !important;
    background-color: red !important;
    color: white !important;
}

.box {
    background-color: white;
    border-top: 2px solid #21b023;
    height: 43px;
    position: fixed;
    bottom: 0;
    padding: 0;
    z-index: 1000;
}

.bet_time {
    z-index: 1001;
    position: absolute;
    bottom: 4px;
    right: 10px;
    padding: 3px;
}

.bet_time_left {
    z-index: 1001;
    position: absolute;
    bottom: 4px;
    left: 10px;
    padding: 3px;
}

.bet_time_error {
    position: fixed;
    bottom: 27px;
    width: 100%;
    padding: 5px;
}

.blink {
    border-color: red;
    animation: blink-animation 1s steps(5, start) infinite;
    -webkit-animation: blink-animation 1s steps(5, start) infinite;
}

@keyframes blink-animation {
    to {
        visibility: hidden;
    }
}

@-webkit-keyframes blink-animation {
    to {
        visibility: hidden;
    }
}

.font-size-1-25-em {
    font-size: 1.25em;
}

@media only screen and (max-width: 600px) {
    .biggerFont {
        font-size: 1em;
    }
}
</style>
  