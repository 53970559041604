<template>
  <div class="change-user-pass">
    <Navbar />
    <ViewMyRestaurants />
  </div>
</template>

<script>
import Navbar from "@/components/Header/Navbar.vue";
import ViewMyRestaurants from "@/components/MyRestaurant/ViewMyRestaurants.vue";
export default {
  name: "MyRestaurants",
  components: {
    ViewMyRestaurants,
    Navbar,
  },
};
</script>
