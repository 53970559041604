<template>
  <div class="container">
    <div class="my-restaurant-page">
      <div class="main-restaurant-table">
        <!-- View all resturants here -->
        <h1 class="text-center" v-if="currentRestaurantsArr.length > 0">
          View Location(s)
        </h1>
        <div class="modal fade" id="termsAndCondition">
          <TermsAndConditions />
        </div>
        <div v-if="currentRestaurantsArr.length > 0" class="row justify-content-around">
          <div v-for="i in currentRestaurantsArr" :key="i" class="col-12 col-sm-12 col-md-5 each-category-list">
            <div>
              <h3 class="catName">{{ capitalizeFisrtLetterOfEachWord(i.rest_name) }}</h3>
              <p class="addressDisplay">
                <faIcons :icon="['fas', 'map-pin']" />
                {{ capitalizeFisrtLetterOfEachWord(i.rest_address) }}
              </p>
              <p class="addressDisplay">
                <faIcons :icon="['fas', 'phone-alt']" />
                <a :href="'tel:' + i.rest_phone">{{
    " " + formatPhoneNumber(i.rest_phone)
}}</a>
              </p>
              <div class="d-flex justify-content-evenly">
                <span class="text-start" v-if="i.active == 1">
                  <router-link :to="{
  name: 'EachRestaurantMenu',
  params: { restId: i.rest_id },
}">
                    <button class="btn btn-outline-secondary">
                      <faIcons :icon="['fas', 'fish']" /> Order for Pickup
                    </button>
                  </router-link>
                </span>
              </div>
            </div>
          </div>
        </div>
        <div v-else class="d-flex justify-content-evenly m-4">
          <div class="alert alert-warning" role="alert">
            <faIcons :icon="['fas', 'store-alt']" /> Loading restaurants locations...
          </div>
        </div>
        <div class="row d-grid gap-2 w300 col-auto d-block mx-auto mb-3 m-2">
          <button class="btn btn-outline-link blueInc" type="button" data-bs-toggle="modal"
            data-bs-target="#termsAndCondition">
            <faIcons :icon="['fas', 'gavel']" /> Pickup order terms & conditions
          </button>
        </div>
        <!-- <div class="row d-grid gap-2 w300 col-auto d-none mx-auto mb-3">
          <router-link class="btn btn-outline-secondary" :to="{ name: 'Login' }">
            <faIcons :icon="['fas', 'store']" /> Have an account!
          </router-link>
        </div> -->
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import TermsAndConditions from "@/components/TermsAndConditions/TermsAndConditions.vue";
// import { capitalize } from "vue";
import { mapState, mapGetters } from "vuex";
export default {
  name: "ViewAllRestaurants",
  data() {
    return {
      currentRestaurantsArr: [],
    };
  },
  components: {
    TermsAndConditions,
  },
  computed: {
    ...mapState(["__OnlineUrl"]),
    ...mapGetters(["OnlineUrl"]),
  },
  mounted() {
    this.viewAllRestaurants();
    // this.canCustomerMakeAnOnlineOrder();
  },
  methods: {
    canCustomerMakeAnOnlineOrder(restId) {
      // const weekday = [
      //     "Sunday",
      //     "Monday",
      //     "Tuesday",
      //     "Wednesday",
      //     "Thursday",
      //     "Friday",
      //     "Saturday",
      //   ];
      const getDayFromArr = [
        "is_sun",
        "is_mon",
        "is_tue",
        "is_wed",
        "is_thu",
        "is_fri",
        "is_sat",
      ];
      const startingTimeArr = [
        "sun_start",
        "mon_start",
        "tue_start",
        "wed_start",
        "thu_start",
        "fri_start",
        "sat_start",
      ];
      const endingTimeArr = [
        "sun_end",
        "mon_end",
        "tue_end",
        "wed_end",
        "thu_end",
        "fri_end",
        "sat_end",
      ];
      //Extract day from the selected date, then check min/max order time
      const currentSelectedDate = new Date();
      // let currentSelectedDate = new Date(
      //     new Date().getTime() - new Date().getTimezoneOffset() * 60000
      //   )
      //     .toISOString()
      //     .split("T")[0];
      console.log("today's date is " + currentSelectedDate);
      var time =
        (currentSelectedDate.getHours() < 10 ? "0" : "") +
        currentSelectedDate.getHours() +
        ":" +
        (currentSelectedDate.getMinutes() < 10 ? "0" : "") +
        currentSelectedDate.getMinutes() +
        ":" +
        (currentSelectedDate.getSeconds() < 10 ? "0" : "") +
        currentSelectedDate.getSeconds();
      let dayToSearchFor = getDayFromArr[currentSelectedDate.getDay()];
      let startingTimeIs = startingTimeArr[currentSelectedDate.getDay()];
      let endingTimeIs = endingTimeArr[currentSelectedDate.getDay()];
      console.log("Today's date number is : " + currentSelectedDate.getDay());
      console.log("Today's date is : " + dayToSearchFor);
      console.log("Today's date starting at : " + startingTimeIs);
      console.log("Current time is : " + time);
      console.log("Today's date ending at : " + endingTimeIs);
      let currentRestHours = localStorage.getItem(
        "current_restaurants_online_hours_public"
      );
      let currentRestHoursData = JSON.parse(currentRestHours).filter(
        (restInfo) => restInfo.rest_id == restId
      );
      let currentRestPayment = localStorage.getItem(
        "current_restaurants_payment_data"
      );
      let currentRestPaymentData = JSON.parse(currentRestPayment).filter(
        (restInfo) => restInfo.rest_id == restId
      );
      console.table(currentRestHoursData);
      console.table(currentRestPaymentData);
      console.log("length of my payment data " + currentRestPaymentData.length);
      let isRestOpenOnSelectedDay = currentRestHoursData[0][dayToSearchFor];
      let startingTime = currentRestHoursData[0][startingTimeIs];
      let endingTime = currentRestHoursData[0][endingTimeIs];
      console.log("Restaurant Starting Time is " + startingTime);
      console.log("Restaurant Ending Time is " + endingTime);
      if (isRestOpenOnSelectedDay == 1) {
        if (time >= startingTime && time <= endingTime) {
          console.log("customer can order now");
          if (currentRestPaymentData.length > 0) {
            if (currentRestPaymentData[0].publish_key != "") {
              console.log(
                "secret key is " + currentRestPaymentData[0].stripe_secret_key
              );
              console.log(
                "business is open today, and you can start order now."
              );
              // const todayDay = new Date();
              let todayDay = new Date(
                new Date().getTime() - new Date().getTimezoneOffset() * 60000
              )
                .toISOString()
                .split("T")[0];
              // let pickedDate = new Date(this.customDatePicker);
              if (todayDay == this.customDatePicker) {
                console.log("Customer like to order a late order for today 1");
                console.log("Customer like to order a late order for today 2");
              } else {
                console.log("Customer like to have a future order 1");
                console.log("Customer like to have a future order 2");
              }
              console.log("current date is " + todayDay);
              this.canCustomerOrderNow = true;
              this.orderStartingTime = startingTime;
              this.orderEndingTime = endingTime;
            } else {
              console.log(
                "business is open today, but no publish key is setup yet."
              );
              this.canCustomerOrderNow = false;
              this.orderStartingTime = startingTime;
              this.orderEndingTime = endingTime;
            }
          } else {
            console.log(
              "business is open today, but payment is not setup yet."
            );
            this.canCustomerOrderNow = false;
            this.orderStartingTime = startingTime;
            this.orderEndingTime = endingTime;
          }
        } else {
          console.log("business is open today, but not at this current time");
          this.canCustomerOrderNow = false;
          this.orderStartingTime = startingTime;
          this.orderEndingTime = endingTime;
        }
      } else {
        console.log("business is not open today");
        this.canCustomerOrderNow = false;
        this.orderStartingTime = startingTime;
        this.orderEndingTime = endingTime;
      }
    },
    formatPhoneNumber(number) {
      //remove . + -  from any number
      let cleaned = ("" + number).replace(/\D/g, "");
      let match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
      if (match) {
        return "(" + match[1] + ") " + match[2] + "-" + match[3];
      }
      return null;
    },
    capitalizeFisrtLetterOfEachWord(text) {
      return text
        .toLowerCase()
        .split(" ")
        .map((s) => s.charAt(0).toUpperCase() + s.substring(1))
        .join(" ");
    },
    async viewAllRestaurants() {
      let res = await axios.post(this.OnlineUrl + `?action=getAllRestaurants`);
      const resultData = res.data;
      console.log(resultData);
      localStorage.setItem(
        "current_restaurants_data_public",
        JSON.stringify(resultData.current_restaurants_data_public)
      );
      localStorage.setItem(
        "current_restaurants_settings_public",
        JSON.stringify(resultData.current_restaurants_settings_public)
      );
      //show all current user categories
      localStorage.setItem(
        "current_user_menu_categories_data_public",
        JSON.stringify(resultData.current_user_menu_categories_data_public)
      );
      //show all current user menu modifiers
      localStorage.setItem(
        "current_user_menu_modifiers_data_public",
        JSON.stringify(resultData.current_user_menu_modifiers_data_public)
      );
      //show all current user menu modifiers Items
      localStorage.setItem(
        "current_user_menu_modifiers_items_data_public",
        JSON.stringify(resultData.current_user_menu_modifiers_items_data_public)
      );
      localStorage.setItem(
        "current_user_menu_items_data_public",
        JSON.stringify(resultData.current_user_menu_items_data_public)
      );
      localStorage.setItem(
        "current_restaurants_online_hours_public",
        JSON.stringify(resultData.current_restaurants_online_hours_public)
      );
      localStorage.setItem(
        "current_restaurants_payment_data",
        JSON.stringify(resultData.current_restaurants_payment_data)
      );
      let currentRestaurants = localStorage.getItem(
        "current_restaurants_data_public"
      );
      this.currentRestaurantsArr = JSON.parse(currentRestaurants);
    },
  },
};
</script>
<style lang="scss" scoped>
$navColor: #3f51b6;

a {
  text-decoration: none;
  color: $navColor;
  font-weight: bold;
}

.each-category-list {
  padding: 15px;
  border-radius: 5px;
  box-shadow: 2px 2px 4px 0px #525a5a;
  margin: 8px;
}

.addressDisplay {
  white-space: pre-wrap;
}

.lightColor {
  margin-top: -1rem;
  margin-bottom: 1rem;
  font-size: 0.875em;
  color: #6c757d;
}

.each-category-list {
  padding: 16px;
  border-radius: 0;
  box-shadow: 2px 2px 4px 0px #525a5a;
  margin: 8px;
  border-radius: 5px;
  margin-bottom: 20px;
}

.catName {
  color: #ad0505;
}
</style>
