<template>
    <div>
      <div class="row justify-content-around">
        <div class="col-12 col-sm-3 each-category-list">
          <h3>Restaurants</h3>
          <ul class="list-group list-group-flush">
            <li class="list-group-item">
              <div class="ms-2 me-auto">
                <div class="fw-bold linkElement">
                  <router-link tag="li" :to="{ name: 'ViewAllRestaurants' }">
                    <a
                      ><faIcons :icon="['fas', 'home']" /> Online Order(s) Menu</a
                    >
                  </router-link>
                </div>
              </div>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </template>
  
  <script>
  export default {
    name: "CoManagerDashboard",
  };
  </script>
  
  <style lang="scss" scoped>
  $blueColor: #3f51b6;
  a {
    text-decoration: none;
    color: $blueColor;
  }
  h3 {
    color: darkcyan;
  }
  .linkElement {
    color: $blueColor;
    cursor: pointer;
  }
  .each-category-list {
    padding: 15px;
    border-radius: 5px;
    box-shadow: 2px 2px 4px 0px #525a5a;
  }
  </style>
  