import { createStore } from "vuex";
// import axios from "axios";
const state = {
  // __UserId Captialize First Letter for generic data
  __UserId: "",
  __Price: "",
  __UserEmail: "",
  __UserRole: "",
  __UserName: "",
  __CartTotal: 0,
  __IsOrderPlaced: false,
  __AddedMealName: "",
  __CurrentCustomerName: "",
  __CurrentCustomerEmail: "",
  __CurrentCustomerOrderNotes: "",
  //C:\xampp\htdocs\php\POS-SYSTEM-BACKUP-STRIPE\API\POS\index.php
  // __MainUrl: "http://localhost/php/Resturant-POS-PHP-VUE-MODULE/API/Dashboard/",
  // __CustomerUrl:
  //   "http://localhost/php/Resturant-POS-PHP-VUE-MODULE/API/Customer/",
  // __OrdersAPI: "http://localhost/php/Resturant-POS-PHP-VUE-MODULE/API/Orders/",
  // __OnlineUrl: "http://localhost/php/Resturant-POS-PHP-VUE-MODULE/online.php",
  // __MainUrl: "https://order.hooksfishnchips.com/API/Dashboard/",
  // __CustomerUrl:
  //   "https://order.hooksfishnchips.com/API/Customer/",
  // __OrdersAPI: "https://order.hooksfishnchips.com/API/Orders/",
  // __OnlineUrl: "https://order.hooksfishnchips.com/online.php",
  __MainUrl: "https://hooksmn.com/API/Dashboard/",
  __CustomerUrl:
  "https://hooksmn.com/API/Customer/",
  __OrdersAPI: "https://hooksmn.com/API/Orders/",
  __OnlineUrl: "https://hooksmn.com/online.php",
  // __POSAPI: "http://localhost/php/Resturant-POS-PHP-VUE-MODULE/API/POS/",
  __POSAPI: "http://localhost/php/Hooks-API/API/POS/index.php",
};
//change state values
const mutations = {
  /*
    Mutation should never have asynchrounous code,
    It only should have Sync code
  */
  //set these data when user logs in
  setUserInfo(state, payload) {
    state.__UserName = payload.name;
    state.__UserId = payload.id;
    state.__UserRole = payload.role;
    state.__UserEmail = payload.email;
  },
  //reset these data when logged in user has changed or logged out
  reSetUserInfo(state) {
    state.__UserName = "";
    state.__UserId = "";
    state.__UserRole = "";
    state.__UserEmail = "";
  },
};
/* we can dispatch mutations */
const actions = {};
//getters very much returns data from the state
const getters = {
  MainUrl(state) {
    return state.__MainUrl;
  },
  CartTotal(state) {
    return state.__CartTotal;
  },
  OnlineUrl(state) {
    return state.__OnlineUrl;
  },
  CustomerUrl(state) {
    return state.__CustomerUrl;
  },
  OrdersAPI(state) {
    return state.__OrdersAPI;
  },
  POSAPI(state) {
    return state.__POSAPI;
  },
};
export default createStore({
  state,
  mutations,
  actions,
  getters,
  modules: {},
});
