<template>
  <div class="container">
    <Navbar />
    <nav style="--bs-breadcrumb-divider: '>'" aria-label="breadcrumb">
      <ol class="breadcrumb">
        <li class="breadcrumb-item">
          <router-link tag="li" :to="{ name: 'MyRestaurants' }">
            <a>My Restaurant(s)</a>
          </router-link>
        </li>
        <li v-if="this.isRestExists" class="breadcrumb-item active" aria-current="page">
          <router-link tag="li" :to="{
            name: 'CurrentRestaurantMenu',
            params: { id: restNum, restId: restId },
          }">
            {{ this.userName }}
          </router-link>
        </li>
        <li v-if="this.isRestExists" class="breadcrumb-item active" aria-current="page">
          {{ this.modifierName }} Modifier
        </li>
        <li v-if="this.isRestExists" class="breadcrumb-item active" aria-current="page">
          Update Item
        </li>
      </ol>
    </nav>
    <div v-if="this.isRestExists" class="add-new-restaurant-page">
      <p>
        {{ userName }}
        <faIcons :icon="['fas', 'map-pin']" />
        {{ userAddress }}
      </p>
      <!-- Add new category -->
      <form method="post" action="#" enctype="multipart/form-data">
        <div class="row d-grid gap-2 w300 col-auto d-block mx-auto mb-2">
          <h3 class="title-header">{{ this.modifierName }}</h3>
        </div>
        <div class="row g-3 align-items-center mb-3">
          <div class="col-auto d-block mx-auto">
            <div class="form-floating" :class="{ 'form-data-error': catNameErr }">
              <input type="text" id="userNameInput" placeholder="Modifier Item Name" class="form-control w300"
                @keyup="validateCategoryName($event)" @change="validateCategoryName($event)" v-model.trim="catName" />
              <label for="userNameInput">
                <faIcons :icon="['fas', 'sitemap']" /> Modifier Item
                Name
              </label>
              <span class="error-feedback" v-if="catNameErr">
                {{ catNameMsg }}
              </span>
            </div>
          </div>
        </div>
        <div class="row g-3 align-items-center mb-3">
          <div class="col-auto d-block mx-auto">
            <div class="form-floating" :class="{ 'form-data-error': catPriceErr }">
              <input type="number" min="0" id="userPriceInput" placeholder="Modifier Item Price"
                class="form-control w300" @keyup="validateCategoryPrice($event)" @change="validateCategoryPrice($event)"
                v-model.trim="catPrice" />
              <label for="userPriceInput">
                <faIcons :icon="['fas', 'sitemap']" /> Modifier Item
                Price
              </label>
              <span class="error-feedback" v-if="catPriceErr">
                {{ catPriceMsg }}
              </span>
            </div>
          </div>
        </div>
        <div class="row d-grid gap-2 w300 col-auto d-block mx-auto mb-2">
          <button class="btn btn-outline-success" @click.prevent="UpdateCurrentModifierItem()" v-if="signUpBtn">
            <faIcons :icon="['fas', 'sitemap']" /> Update Modifier Item
          </button>
        </div>
        <div class="row d-grid gap-2 w300 col-auto d-block mx-auto mb-3">
          <div class="alert alert-success" role="alert" v-if="resultSuccess">
            {{ resultSuccessMsg }}
          </div>
          <div class="alert alert-danger" role="alert" v-if="resultErr">
            {{ resultErrMsg }}
          </div>
        </div>
        <div class="row d-grid gap-2 w300 col-auto d-block mx-auto mb-3">
          <router-link class="btn btn-outline-primary" :to="{
            name: 'CurrentRestaurantMenu',
            params: { id: restNum, restId: restId },
          }">
            <faIcons :icon="['fas', 'undo']" /> Never mind, go back
          </router-link>
        </div>
      </form>
    </div>
    <div v-else>
      <!-- Show error if no restaurant matches this Criteria -->
      <div class="alert alert-dark" role="alert">
        <p>
          No restaurant found. Make sure you click in the right restaurant name.
        </p>
      </div>
      <router-link tag="li" :to="{ name: 'MyRestaurants' }">
        <a class="btn btn-success">Go back to my restaurant(s)</a>
      </router-link>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import { mapState, mapMutations, mapGetters } from "vuex";
import Navbar from "@/components/Header/Navbar.vue";
export default {
  name: "UpdateMenuModifierItem",
  components: {
    Navbar,
  },
  data() {
    return {
      user: {
        uId: "",
        userId: "",
        uName: "",
        uEmail: "",
        uPassword: "",
        uMemberSince: "",
        uAddress: "",
        uPhone: "",
        uRole: "",
        uRoleIs: "",
      },
      modifierName: "",
      //cat
      catName: "",
      catNameErr: false,
      catNameMsg: "",
      iscatNameValidated: false,
      //price
      catPrice: "",
      catPriceErr: false,
      catPriceMsg: "",
      iscatPriceValidated: false,
      //Restaurant Info from URL
      restId: this.$route.params.restId,
      restNum: this.$route.params.id,
      modifierId: this.$route.params.modifierId,
      modifierItemId: this.$route.params.modifierItemId,
      //address
      userAddress: "",
      userAddressErr: false,
      userAddressMsg: "",
      isuserAddressValidated: false,
      //phone
      userPhone: "",
      userPhoneErr: false,
      userPhoneMsg: "",
      isuserPhoneValidated: false,
      //name
      userName: "",
      userNameErr: false,
      userNameMsg: "",
      isuserNameValidated: false,

      resultSuccess: false,
      resultSuccessMsg: "",
      resultErr: false,
      resultErrMsg: "",
      image: "",
      is_DB_Connected: "",
      db_connection_msg: "",
      // apiMainUrl: "http://localhost/php/login-system-php-vue-2021/myData.php",
      apiMainUrl: this.$store.getters.MainUrl, //call global variable
      signUpBtn: true,
      isRestExists: false,
    };
  },
  mounted() {
    let user = localStorage.getItem("current-user-data");
    let currentRestaurants = localStorage.getItem("current_restaurants_data");
    let currentRestaurant = JSON.parse(currentRestaurants).filter(
      (restInfo) =>
        restInfo.id == this.restNum && restInfo.rest_id == this.restId
    );
    let currentModifiers = localStorage.getItem(
      "current_user_menu_modifiers_data"
    );
    let currentModifiersItems = localStorage.getItem(
      "current_user_menu_modifiers_items_data"
    );
    let currentModifiersName = JSON.parse(currentModifiers).filter(
      (restInfo) =>
        restInfo.group_name_rand_id == this.modifierId &&
        restInfo.rest_id == this.restId
    );
    let currentModifiersItem = JSON.parse(currentModifiersItems).filter(
      (restInfo) =>
        restInfo.group_items_names_rand_id == this.modifierItemId &&
        restInfo.rest_id == this.restId
    );
    if (user != null && currentRestaurants != null) {
      //set rest info
      if (currentRestaurant.length > 0 && currentModifiersItem.length > 0) {
        console.table(currentModifiersName);
        this.modifierName = currentModifiersName[0].group_name;
        console.log(
          "Current Modifier Name is: " + currentModifiersName[0].group_name
        );
        this.catName = currentModifiersItem[0].item_name;
        this.catPrice = currentModifiersItem[0].item_price;
        console.log(currentRestaurant);
        this.isRestExists = true;
        this.userPhone = currentRestaurant[0].rest_phone;
        this.userName = currentRestaurant[0].rest_name;
        this.userAddress = currentRestaurant[0].rest_address;
        //get users info
        this.user.uId = JSON.parse(user).id;
        this.user.userId = JSON.parse(user).user_id;
        this.user.uName = JSON.parse(user).name;
        this.user.uEmail = JSON.parse(user).email;
        this.user.uPassword = JSON.parse(user).password;
        this.user.uMemberSince = JSON.parse(user).member_since;
        this.user.uAddress = JSON.parse(user).full_address;
        this.user.uPhone = JSON.parse(user).phone_number;
        this.user.uRole = JSON.parse(user).role;
        //if role isn't manager or admin, redirect
        if (currentRestaurant[0].active == 0) {
          //if restaurant isn't active
          this.$router.push({ name: "MyRestaurants" });
        } else if (
          JSON.parse(user).role !== "mngr" &&
          JSON.parse(user).role !== "admn"
        ) {
          this.$router.push({ name: "Login" });
        }
      }
    } else {
      this.$router.push({ name: "Login" });
    }
  },
  computed: {
    ...mapState([
      "__RestaurantId",
      "__RestaurantEmail",
      "__RestaurantRole",
      "__RestaurantName",
      "__MainUrl",
    ]),
    ...mapGetters(["MainUrl"]),
  },
  methods: {
    ...mapMutations(["setRestaurantInfo"]),
    //validate Restaurant Name
    validateCategoryName(e) {
      let val = e.target.value;
      this.validateCategoryNameInput(val);
    },
    validateCategoryPrice(e) {
      let val = e.target.value;
      this.validateCategoryPriceInput(val);
    },
    validateCategoryNameInput(val) {
      if (val == "") {
        this.catNameErr = true;
        this.iscatNameValidated = false;
        this.catNameMsg = "Must Enter Modifier Name ..";
      } else {
        this.catNameErr = false;
        this.iscatNameValidated = true;
        this.catNameMsg = "";
      }
    },
    validateCategoryPriceInput(val) {
      // let priceValidation = '^\d+(?:[.,]\d+)*$';
      // let priceValidationRegex  = /^\d+(?:\.\d{0,2})$/;
      let priceValidationRegex = /^[0-9]\d*(((,\d{3}){1})?(\.\d{0,2})?)$/;
      let priceValidation = priceValidationRegex.test(val);
      console.log("Is Price Value Correct: " + priceValidation);
      if (val == "") {
        //if empty
        this.catPriceErr = true;
        this.iscatPriceValidated = false;
        this.catPriceMsg = "Must Enter Modifier Price ..";
      } else if (!priceValidation) {
        // not any of these forms 65.00, 1.00
        this.catPriceErr = true;
        this.iscatPriceValidated = false;
        this.catPriceMsg = "Price Format 15.40, 0.50 or 1.00 ..";
      } else {
        this.catPriceErr = false;
        this.iscatPriceValidated = true;
        this.catPriceMsg = "";
      }
    },
    async UpdateCurrentModifierItem() {
      this.resultErr = false;
      this.resultErrMsg = "";
      this.resultSuccess = true;
      this.resultSuccessMsg =
        "Please wait while we're updating current modifier item...";
      this.validateCategoryNameInput(this.catName);
      this.validateCategoryPriceInput(this.catPrice);
      if (this.iscatNameValidated && this.iscatPriceValidated) {
        this.signUpBtn = false;
        //show Result
        let fd = new FormData();
        fd.append("userId", this.user.userId);
        fd.append("catName", this.catName);
        fd.append("catPrice", parseFloat(this.catPrice).toFixed(2));
        fd.append("restId", this.restId);
        fd.append("modifierId", this.modifierId);
        fd.append("modifierItemId", this.modifierItemId);
        let rst = await axios.post(
          this.apiMainUrl + `?action=UpdateMenuModifierItem`,
          fd
        );
        console.log(rst);
        const resultData = rst.data;
        if (rst.status == 200) {
          if (resultData.error) {
            console.warn(
              "Connection Error Happened on Adding New Modifier Item"
            );
            this.resultErrMsg = resultData.message;
            this.resultErr = true;
            this.resultSuccessMsg = "";
            this.resultSuccess = false;
            this.signUpBtn = true;
          } else {
            this.signUpBtn = false;
            console.log("New Modifier Item has been added..");
            //if everything is okay
            this.resultErrMsg = "";
            this.resultErr = false;
            this.resultSuccess = true;
            //now get current menu modifier item, then push a new object to it and last set a new localhost
            var currentModifiersItems = JSON.parse(
              localStorage.getItem("current_user_menu_modifiers_items_data")
            );
            for (let i = 0; i < currentModifiersItems.length; i++) {
              if (
                currentModifiersItems[i].group_items_names_rand_id ===
                this.modifierItemId
              ) {
                currentModifiersItems[i].item_name = this.catName;
                currentModifiersItems[i].item_price = this.catPrice;
              }
            }
            localStorage.setItem(
              "current_user_menu_modifiers_items_data",
              JSON.stringify(currentModifiersItems)
            );
            this.resultSuccessMsg = resultData.message;
            //redirect to current restaurant page
            setTimeout(() => {
              this.$router.push({
                name: "CurrentRestaurantMenu",
                params: { id: this.restNum, restId: this.restId },
              });
              console.log("Restaurant Name: " + this.userName);
            }, 2000);
          }
        }
      } else {
        this.signUpBtn = true;
        this.validateCategoryNameInput(this.catName);
        this.validateCategoryNameInput(this.catPrice);
        this.resultSuccessMsg = "";
        this.resultSuccess = false;

        this.resultErrMsg = "Must enter all required information";
        this.resultErr = true;
      }
    },
  },
};
</script>
<style lang="scss" scoped>
$navColor: #3f51b6;

nav a {
  text-decoration: none;
  color: $navColor;
  font-weight: bold;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.title-header {
  background-color: #46a7fd;
  padding: 10px;
  font-size: 1.25em;
  color: white;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}
</style>
