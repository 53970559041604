<template>
  <div>
    <div class="container-fluid" v-if="doesRestDataExists">
      <div v-if="!canUserAccessThis">
        <div
          v-if="
            currentLoggedInUserData.length > 1 &&
            (currentLoggedInUserData[0].user_role == 'admn' ||
              currentLoggedInUserData[0].user_role == 'mngr' ||
              currentLoggedInUserData[0].user_role == 'cmngr')
          "
        >
          <div class="d-flex aligns-items-center justify-content-center">
            <div class="m-5">
              <div
                class="form-floating"
                :class="{ 'form-data-error': currentUserAccessCodeIsErr }"
              >
                <input
                  autofocus
                  type="number"
                  min="0"
                  id="userPhoneInput"
                  placeholder="currentUserAccessCodeIsErr"
                  @keydown="
                    if ($event.key === '.') {
                      $event.preventDefault();
                    }
                  "
                  @input="validateUserAccessCode($event)"
                  @change="validateUserAccessCode($event)"
                  class="form-control w300"
                  v-model.trim="currentUserAccessCodeIs"
                />
                <label for="userPhoneInput">
                  <faIcons :icon="['fas', 'key']" />Access Code
                </label>
                <span
                  class="error-feedback"
                  v-if="currentUserAccessCodeIsErr"
                  >{{ currentUserAccessCodeIsMsg }}</span
                >
              </div>
            </div>
          </div>
          <div class="d-flex aligns-items-center justify-content-center m-3">
            <button
              class="btn btn-outline-success"
              @click.prevent="canUserAccessCurrentPage()"
              v-if="this.btnEnabled"
            >
              <faIcons :icon="['fas', 'eye']" />View Page
            </button>
          </div>
        </div>
        <div
          v-else
          class="d-flex aligns-items-center justify-content-center m-3"
        >
          <div>
            <div class="alert alert-danger">You don't access this page.</div>
          </div>
        </div>
      </div>
      <div
        v-if="canUserAccessThis"
        class="row justify-content-around main-order-select-date-custom"
      >
        <!-- <h5 class="mainHeader text-bold text-center mb-2">
                  <span class="color-356f71 float-start">
                  </span>
              </h5> -->
        <div class="mainHeader m-2">
          <div class="row g-3 align-items-center">
            <div
              class="col-auto d-block mx-auto col-12 col-sm-12 main-order-select-date-custom"
            >
              <div class="float-end form-check form-check-inline">
                <div class="input-group">
                  <button class="btn btn-danger" @click="clearLogin()">
                    Exit Now,
                    {{
                      capitalizeFisrtLetterOfEachWord(
                        currentLoggedInUserData[0].user_name
                          .split(" ")
                          .slice(0, 1)
                          .join(" ")
                      )
                    }}!
                  </button>
                </div>
              </div>
              <h4 class="float-start blackColor">
                <faIcons :icon="['fas', 'user']" />
                {{
                  capitalizeFisrtLetterOfEachWord(
                    currentLoggedInUserData[0].user_name
                      .split(" ")
                      .slice(0, 1)
                      .join(" ")
                  )
                }}
              </h4>
              <div class="clearfix"></div>
            </div>
          </div>
        </div>
        <div class="row col-12 col-sm-9 col-lg-9 col-xl-9">
          <button class="btn btn-primary mb-2" @click="goToMainOrdersPage()">
            <faIcons
              class="d-print-none pointerHand"
              :icon="['fas', 'store']"
            />
            Back to Orders
          </button>
          &nbsp;
          <div class="text-center">
            <button
              class="d-print-none btn btns-success"
              type="button"
              data-bs-toggle="modal"
              data-bs-target="#addHoursModal"
            >
              <faIcons :icon="['fas', 'calendar-day']" /> Add Hours
            </button>
            &nbsp;
            <button
              class="btn btn-secondary"
              @click="printSection2('timesheetReport')"
            >
              <faIcons
                class="d-print-none pointerHand"
                :icon="['fas', 'print']"
              />
              Print
            </button>
            &nbsp;
            <button class="btn btn-secondary" @click="exportToPDF()">
              <faIcons
                class="d-print-none pointerHand"
                :icon="['fas', 'file-pdf']"
              />
              Export
            </button>
          </div>
          <div class="clearfix"></div>
          <div class="form-check form-check-inline mt-3">
            <div class="input-group">
              <div class="form-floating">
                <select
                  id="reasonToCancelThisOrder"
                  placeholder="Select Users"
                  class="form-select w300 m-2"
                  v-model.trim="reasonToCancelOrder"
                  aria-label="reasonToCancelThisOrder"
                  @change="viewNewOrdersForMultipleDates()"
                >
                  <option value="all">All Users</option>
                  <option
                    v-for="eachUser in currentUsersNames"
                    :key="eachUser"
                    :value="eachUser.access_id"
                  >
                    {{ capitalizeFisrtLetterOfEachWord(eachUser.name) }}
                    <span v-if="eachUser.role == 'empl'"> - Employee</span>
                    <span v-else-if="eachUser.role == 'mngr'"> - Manager</span>
                    <span v-else-if="eachUser.role == 'cmngr'">
                      - Co Manager</span
                    >
                  </option>
                </select>
                <label for="reasonToCancelThisOrder">
                  <faIcons :icon="['fas', 'users']" />
                  Select Users
                </label>
              </div>
              <div
                class="form-floating w300 m-2"
                :class="datePickerError ? 'error-data' : ''"
              >
                <input
                  type="date"
                  class="form-control"
                  placeholder="Starting Date"
                  v-model="viewOrderStartingDate"
                  id="startingDate"
                  aria-label="startingDate"
                  aria-describedby="startingDate"
                  @change="viewNewOrdersForMultipleDates()"
                  @input="viewNewOrdersForMultipleDates()"
                  @keyup="viewNewOrdersForMultipleDates()"
                />
                <label for="startingDate">
                  <faIcons :icon="['fas', 'calendar-day']" />
                  Starting Date
                </label>
              </div>
              <div
                class="form-floating w300 m-2"
                :class="datePickerError ? 'error-data' : ''"
              >
                <input
                  type="date"
                  class="form-control"
                  placeholder="Ending Date"
                  v-model="viewOrderEndingDate"
                  id="endingDate"
                  aria-label="endingDate"
                  aria-describedby="endingDate"
                  @change="viewNewOrdersForMultipleDates()"
                  @input="viewNewOrdersForMultipleDates()"
                  @keyup="viewNewOrdersForMultipleDates()"
                />
                <label for="endingDate">
                  <faIcons :icon="['fas', 'calendar-day']" />
                  Ending Date
                </label>
              </div>
              <div class="form-floating w300 m-2">
                <button
                  class="btn btn-primary mb-2"
                  :class="showViewTimeHours ? '' : 'disabled'"
                  @click="currentUsersTimeSheet()"
                >
                  <faIcons
                    class="d-print-none pointerHand"
                    :icon="['fas', 'calendar-alt']"
                  />
                  Get Time
                </button>
              </div>
            </div>
            <div class="alert alert-danger mt-2" v-if="datePickerError">
              {{ datePickerMsg }}
            </div>
          </div>
          <div class="clearfix"></div>
          <div id="detailedReport" ref="document">
            <div>
              <h1 class="">
                {{ capitalizeFisrtLetterOfEachWord(businessName) }}
              </h1>
              <address>
                {{ capitalizeFisrtLetterOfEachWord(pickUpAddress) }}<br />
                {{ formatPhoneNumber(pickUpPhoneNumber) }}
              </address>
              <br />
              <h3>View Users Timesheet</h3>
            </div>
            <div class="text-center">
              <p v-if="viewOrderStartingDate == viewOrderEndingDate">
                Date period
                {{ formatDateFormulas(viewOrderStartingDate) }}
              </p>
              <p v-else>
                Date period
                {{ formatDateFormulas(viewOrderStartingDate) }}
                to
                {{ formatDateFormulas(viewOrderEndingDate) }}
              </p>
            </div>
            <div class="addHoursModalTemplate">
              <div
                class="modal fade"
                tabindex="-1"
                id="addHoursModal"
                aria-labelledby="addHoursModal"
                aria-hidden="true"
              >
                <div class="modal-dialog modal-dialog-scrollable">
                  <div class="modal-content">
                    <div class="modal-header">
                      <h5 class="modal-title greenColor">Add New User Hours</h5>
                      <button
                        type="button"
                        class="btn-close"
                        data-bs-dismiss="modal"
                        aria-label="Close"
                      ></button>
                    </div>
                    <div class="modal-body itemDataDetails">
                      <div
                        class="form-floating m-2"
                        :class="datePickerAddError ? 'error-data' : ''"
                      >
                        <select
                          id="reasonToCancelThisOrder"
                          placeholder="Select Users"
                          class="form-select"
                          v-model.trim="getCurrentUserId"
                          aria-label="getCurrentUserId"
                          @change="addNewUserTimesheetCheck()"
                          @input="addNewUserTimesheetCheck()"
                          @keyup="addNewUserTimesheetCheck()"
                        >
                          <option value="">Select One User</option>
                          <option
                            v-for="eachUser in currentUsersNames"
                            :key="eachUser"
                            :value="eachUser.access_id"
                          >
                            {{ capitalizeFisrtLetterOfEachWord(eachUser.name) }}
                            <span v-if="eachUser.role == 'empl'">
                              - Employee</span
                            >
                            <span v-if="eachUser.role == 'mngr'">
                              - Manager</span
                            >
                            <span v-if="eachUser.role == 'cmngr'">
                              - Co Manager</span
                            >
                          </option>
                        </select>
                        <label for="getCurrentUserId">
                          <faIcons :icon="['fas', 'users']" />
                          Select One User
                        </label>
                      </div>
                      <div
                        class="form-floating m-2"
                        :class="datePickerAddError ? 'error-data' : ''"
                      >
                        <input
                          type="datetime-local"
                          class="form-control"
                          placeholder="Starting Date"
                          id="startingDateAdd"
                          aria-label="startingDateAdd"
                          aria-describedby="startingDateAdd"
                          v-model="currentStartingDateAndTimeIs"
                          @change="addNewUserTimesheetCheck()"
                          @input="addNewUserTimesheetCheck()"
                          @keyup="addNewUserTimesheetCheck()"
                        />
                        <label for="startingDateAdd">
                          <faIcons :icon="['fas', 'calendar-day']" />
                          Starting Date & Time
                        </label>
                      </div>
                      <div
                        class="form-floating m-2"
                        :class="datePickerAddError ? 'error-data' : ''"
                      >
                        <input
                          type="datetime-local"
                          class="form-control"
                          placeholder="Ending Date"
                          id="endingDateAdd"
                          aria-label="endingDateAdd"
                          aria-describedby="endingDateAdd"
                          v-model="currentEndingDateAndTimeIs"
                          @change="addNewUserTimesheetCheck()"
                          @input="addNewUserTimesheetCheck()"
                          @keyup="addNewUserTimesheetCheck()"
                        />
                        <label for="endingDateAdd">
                          <faIcons :icon="['fas', 'calendar-day']" />
                          Ending Date & Time
                        </label>
                      </div>
                    </div>
                    <div class="modal-footer d-flex">
                      <button
                        class="btn btn-primary"
                        data-bs-dismiss="modal"
                        aria-label="Close"
                      >
                        Never mind
                      </button>
                      <button
                        class="btn btn-success"
                        :class="this.disaledAddButton ? '' : 'disabled'"
                        id="btn-add-new-user"
                        @click="addNewUserTimesheetNow()"
                      >
                        <faIcons :icon="['fas', 'calendar-day']" /> Add Hours
                      </button>
                    </div>
                    <div id="showHoursAddResult" class="m-2"></div>
                  </div>
                </div>
              </div>
            </div>
            <div
              v-for="(eachUserTimeSheet, ii) in currentUsersTimeSheets"
              :key="ii + '-' + eachUserTimeSheet"
            >
              <div
                class="modal fade"
                tabindex="-1"
                :id="'deleteHoursModal-' + eachUserTimeSheet.id"
                :aria-labelledby="'deleteHoursModal-' + eachUserTimeSheet.id"
                aria-hidden="true"
              >
                <div class="modal-dialog modal-dialog-scrollable">
                  <div class="modal-content">
                    <div class="modal-header">
                      <h5 class="modal-title text-danger">
                        Remove Current Hours
                      </h5>
                      <button
                        type="button"
                        class="btn-close"
                        data-bs-dismiss="modal"
                        aria-label="Close"
                      ></button>
                    </div>
                    <div class="modal-body itemDataDetails">
                      <p class="fb text-center">
                        {{
                          capitalizeFisrtLetterOfEachWord(
                            currentUsersNames.filter(
                              (val) =>
                                val.access_id == eachUserTimeSheet.access_id
                            )[0].name
                          )
                        }}
                        | {{ eachUserTimeSheet.access_id }}
                      </p>
                      <p class="fb">
                        {{
                          new Date(
                            eachUserTimeSheet.time_in
                          ).toLocaleDateString()
                        }}
                        {{
                          removeSecondsFromTime(
                            new Date(
                              eachUserTimeSheet.time_in
                            ).toLocaleTimeString()
                          )
                        }}
                        -
                        <span v-if="eachUserTimeSheet.is_logged_out == 1">
                          {{
                            new Date(
                              eachUserTimeSheet.time_out
                            ).toLocaleDateString()
                          }}
                          {{
                            removeSecondsFromTime(
                              new Date(
                                eachUserTimeSheet.time_out
                              ).toLocaleTimeString()
                            )
                          }}
                        </span>
                        <span v-else>....</span>
                      </p>
                      <div class="form-floating m-2">
                        <input
                          type="datetime-local"
                          disabled
                          class="form-control"
                          :value="eachUserTimeSheet.time_in"
                          placeholder="Starting Date & Time"
                        />
                        <label>
                          <faIcons :icon="['fas', 'calendar']" />
                          Starting Date & Time
                        </label>
                      </div>
                      <div class="form-floating m-2">
                        <input
                          type="datetime-local"
                          disabled
                          class="form-control"
                          :value="eachUserTimeSheet.time_out"
                          placeholder="Ending Date & Time"
                        />
                        <label>
                          <faIcons :icon="['fas', 'calendar']" />
                          Ending Date & Time
                        </label>
                      </div>
                    </div>
                    <div class="modal-footer d-flex">
                      <button
                        class="btn btn-primary"
                        data-bs-dismiss="modal"
                        aria-label="Close"
                      >
                        Never mind
                      </button>
                      <button
                        class="btn btn-danger"
                        :id="'btn-' + eachUserTimeSheet.id"
                        @click="
                          deleteCurrentHours(
                            eachUserTimeSheet.id,
                            eachUserTimeSheet.access_id
                          )
                        "
                      >
                        Remove Hours
                      </button>
                    </div>
                    <div
                      :id="'showHoursDeleteResult-' + eachUserTimeSheet.id"
                    ></div>
                  </div>
                </div>
              </div>
              <!-- <div class="deleteHoursModalTemplate">
              </div> -->
              <div
                class="modal fade"
                tabindex="-1"
                :id="'updateHoursModal-' + eachUserTimeSheet.id"
                :aria-labelledby="'updateHoursModal-' + eachUserTimeSheet.id"
                aria-hidden="true"
              >
                <UpdateCurrentUserTimesheet
                  :getCurrentUsersTimeSheets="getCurrentUsersTimeSheets"
                  :eachRow="eachUserTimeSheet.id"
                  :userId="eachUserTimeSheet.access_id"
                  :start="eachUserTimeSheet.time_in"
                  :end="eachUserTimeSheet.time_out"
                  :userName="
                    capitalizeFisrtLetterOfEachWord(
                      currentUsersNames.filter(
                        (val) => val.access_id == eachUserTimeSheet.access_id
                      )[0].name
                    )
                  "
                />
              </div>
            </div>
            <div class="table-responsive">
              <table class="table">
                <thead class="table-success">
                  <tr>
                    <th class="header" scope="col">Name</th>
                    <th class="header" scope="col">ID</th>
                    <th class="header" scope="col" v-if="showOrderTime">
                      Title
                    </th>
                    <th class="header" scope="col">Date In</th>
                    <th class="header" scope="col">Date Out</th>
                    <th class="header" scope="col">Hours</th>
                    <th class="header" scope="col" v-if="showOrderTime">
                      In By
                    </th>
                    <th class="header" scope="col" v-if="showOrderTime">
                      Out By
                    </th>
                    <th class="header" scope="col" v-if="showOrderTime">
                      Actions
                    </th>
                  </tr>
                </thead>
                <tbody v-if="currentUsersTimeSheets.length > 0">
                  <tr
                    v-for="(eachUserTimeSheet, ii) in currentUsersTimeSheets"
                    :key="ii + '-' + eachUserTimeSheet"
                  >
                    <td :class="'user-' + eachUserTimeSheet.access_id">
                      {{
                        capitalizeFisrtLetterOfEachWord(
                          currentUsersNames.filter(
                            (val) =>
                              val.access_id == eachUserTimeSheet.access_id
                          )[0].name
                        )
                      }}
                    </td>
                    <td>
                      {{ eachUserTimeSheet.access_id }}
                    </td>
                    <td v-if="showOrderTime">
                      <span
                        v-if="
                          currentUsersNames.filter(
                            (val) =>
                              val.access_id == eachUserTimeSheet.access_id
                          )[0].role == 'mngr'
                        "
                      >
                        Owner
                      </span>
                      <span
                        v-if="
                          currentUsersNames.filter(
                            (val) =>
                              val.access_id == eachUserTimeSheet.access_id
                          )[0].role == 'admn'
                        "
                      >
                        IT
                      </span>
                      <span
                        v-else-if="
                          currentUsersNames.filter(
                            (val) =>
                              val.access_id == eachUserTimeSheet.access_id
                          )[0].role == 'cmngr'
                        "
                      >
                        Co Manager
                      </span>
                      <span
                        v-else-if="
                          currentUsersNames.filter(
                            (val) =>
                              val.access_id == eachUserTimeSheet.access_id
                          )[0].role == 'empl'
                        "
                      >
                        Employee
                      </span>
                    </td>
                    <td>
                      {{
                        new Date(eachUserTimeSheet.time_in).toLocaleDateString()
                      }}
                      <span class="fb">
                        {{
                          removeSecondsFromTime(
                            new Date(
                              eachUserTimeSheet.time_in
                            ).toLocaleTimeString()
                          )
                        }}
                      </span>
                    </td>
                    <td>
                      <span v-if="eachUserTimeSheet.is_logged_out == 1">
                        {{
                          new Date(
                            eachUserTimeSheet.time_out
                          ).toLocaleDateString()
                        }}
                        <span class="fb">
                          {{
                            removeSecondsFromTime(
                              new Date(
                                eachUserTimeSheet.time_out
                              ).toLocaleTimeString()
                            )
                          }}
                        </span>
                      </span>
                      <span v-else>----</span>
                    </td>
                    <td>
                      <span v-if="eachUserTimeSheet.is_logged_out == 0"
                        >----</span
                      >
                      <span v-else>
                        {{
                          (
                            Math.abs(
                              new Date(eachUserTimeSheet.time_in) -
                                new Date(eachUserTimeSheet.time_out)
                            ) / 3600000
                          ).toFixed(2)
                        }}
                      </span>
                    </td>
                    <td v-if="showOrderTime">
                      <span v-if="eachUserTimeSheet.is_logged_out == 0">
                        ----
                      </span>
                      <span v-else-if="eachUserTimeSheet.is_logged_out == 1">
                        <span
                          v-if="
                            eachUserTimeSheet.time_in_by ==
                            eachUserTimeSheet.access_id
                          "
                        >
                          {{
                            capitalizeFisrtLetterOfEachWord(
                              currentUsersNames
                                .filter(
                                  (val) =>
                                    val.access_id ==
                                    eachUserTimeSheet.time_in_by
                                )[0]
                                .name.split(" ")
                                .slice(0, 1)
                                .join(" ")
                            )
                          }}
                        </span>
                        <span
                          v-else
                          :id="
                            'getUserName-' +
                            ii +
                            ii +
                            '-' +
                            eachUserTimeSheet.time_in_by
                          "
                        >
                          <span>
                            <!-- {{
                              getUserName(
                                eachUserTimeSheet.time_in_by,
                                "getUserName-" +
                                  ii +
                                  ii +
                                  "-" +
                                  eachUserTimeSheet.time_in_by
                              )
                            }} -->
                            {{ eachUserTimeSheet.time_in_by }}
                          </span>
                        </span>
                      </span>
                    </td>
                    <td v-if="showOrderTime">
                      <span v-if="eachUserTimeSheet.is_logged_out == 0">
                        ----
                      </span>
                      <span v-else-if="eachUserTimeSheet.is_logged_out == 1">
                        <span
                          v-if="
                            eachUserTimeSheet.time_out_by ==
                            eachUserTimeSheet.access_id
                          "
                        >
                          {{
                            capitalizeFisrtLetterOfEachWord(
                              currentUsersNames
                                .filter(
                                  (val) =>
                                    val.access_id ==
                                    eachUserTimeSheet.time_out_by
                                )[0]
                                .name.split(" ")
                                .slice(0, 1)
                                .join(" ")
                            )
                          }}
                        </span>
                        <span
                          v-else
                          :id="
                            'getUserName-' +
                            ii +
                            '-' +
                            eachUserTimeSheet.time_out_by
                          "
                        >
                          <span>
                            <!-- {{
                              getUserName(
                                eachUserTimeSheet.time_out_by,
                                "getUserName-" +
                                  ii +
                                  "-" +
                                  eachUserTimeSheet.time_out_by
                              )
                            }} -->
                            {{ eachUserTimeSheet.time_out_by }}
                          </span>
                        </span>
                      </span>
                    </td>
                    <td v-if="showOrderTime">
                      <button
                        class="d-print-none btn btns-success"
                        type="button"
                        data-bs-toggle="modal"
                        :data-bs-target="
                          '#updateHoursModal-' + eachUserTimeSheet.id
                        "
                      >
                        <faIcons :icon="['fas', 'edit']" />
                      </button>
                      &nbsp;
                      <button
                        class="d-print-none btn btns-danger"
                        type="button"
                        data-bs-toggle="modal"
                        :data-bs-target="
                          '#deleteHoursModal-' + eachUserTimeSheet.id
                        "
                      >
                        <faIcons :icon="['fas', 'trash']" />
                      </button>
                      <!-- <div class="updateHoursModalTemplate">
                      </div> -->
                    </td>
                  </tr>
                </tbody>
                <tfoot>
                  <tr v-if="currentUsersTimeSheets.length > 0">
                    <td class="fb">Total Hours: {{ totalHours }}</td>
                  </tr>
                </tfoot>
              </table>
            </div>
          </div>
        </div>
        <div
          class="d-none d-print-block col-12 col-sm-12 col-lg-12 col-xl-12"
          id="timesheetReport"
          v-if="currentUsersTimeSheets.length > 0"
        >
          <div class="text-center">
            <h1>
              {{ capitalizeFisrtLetterOfEachWord(businessName) }}
            </h1>
            <address>
              {{ capitalizeFisrtLetterOfEachWord(pickUpAddress) }}<br />
              {{ formatPhoneNumber(pickUpPhoneNumber) }}
            </address>
            <br />
            <h3>User(s) Timesheet Report</h3>
          </div>
          <div class="text-center">
            <p>
              {{ new Date(viewOrderStartingDate).toLocaleDateString() }}
              to
              {{ new Date(viewOrderEndingDate).toLocaleDateString() }}
            </p>
          </div>
          <!-- Get only unique name and id -->
          <p
            v-for="(
              eachUserTimeSheet, ii
            ) in currentUsersTimeSheetsWithUniqueData"
            :key="ii + '-' + eachUserTimeSheet"
          >
            <faIcons :icon="['fas', 'user']" />
            {{
              capitalizeFisrtLetterOfEachWord(
                currentUsersNames.filter(
                  (val) => val.access_id == eachUserTimeSheet.access_id
                )[0].name
              )
            }}
            - {{ eachUserTimeSheet.access_id }} -
            <span
              v-if="
                currentUsersNames.filter(
                  (val) => val.access_id == eachUserTimeSheet.access_id
                )[0].role == 'mngr'
              "
            >
              Owner
            </span>
            <span
              v-if="
                currentUsersNames.filter(
                  (val) => val.access_id == eachUserTimeSheet.access_id
                )[0].role == 'admn'
              "
            >
              IT
            </span>
            <span
              v-else-if="
                currentUsersNames.filter(
                  (val) => val.access_id == eachUserTimeSheet.access_id
                )[0].role == 'cmngr'
              "
            >
              Co Manager
            </span>
            <span
              v-else-if="
                currentUsersNames.filter(
                  (val) => val.access_id == eachUserTimeSheet.access_id
                )[0].role == 'empl'
              "
            >
              Employee
            </span>
          </p>

          <table class="table">
            <thead style="position: sticky; top: 0" class="table-success">
              <tr>
                <th
                  class="header"
                  scope="col"
                  v-if="reasonToCancelOrder == 'all'"
                >
                  ID
                </th>
                <th class="header" scope="col">Date In</th>
                <th class="header" scope="col">Date Out</th>
                <th class="header" scope="col">Hours</th>
              </tr>
            </thead>
            <tbody v-if="currentUsersTimeSheets.length > 0">
              <tr
                v-for="(eachUserTimeSheet, ii) in currentUsersTimeSheets"
                :key="ii + '-' + eachUserTimeSheet"
              >
                <td v-if="reasonToCancelOrder == 'all'">
                  {{ eachUserTimeSheet.access_id }}
                </td>
                <td>
                  {{ new Date(eachUserTimeSheet.time_in).toLocaleDateString() }}
                  <span class="fb">
                    {{
                      removeSecondsFromTime(
                        new Date(eachUserTimeSheet.time_in).toLocaleTimeString()
                      )
                    }}
                  </span>
                </td>
                <td>
                  <span v-if="eachUserTimeSheet.is_logged_out == 1">
                    {{
                      new Date(eachUserTimeSheet.time_out).toLocaleDateString()
                    }}
                    <span class="fb">
                      {{
                        removeSecondsFromTime(
                          new Date(
                            eachUserTimeSheet.time_out
                          ).toLocaleTimeString()
                        )
                      }}
                    </span>
                  </span>
                  <span v-else>----</span>
                </td>
                <td>
                  <span v-if="eachUserTimeSheet.is_logged_out == 0">----</span>
                  <span v-else>
                    {{
                      (
                        Math.abs(
                          new Date(eachUserTimeSheet.time_in) -
                            new Date(eachUserTimeSheet.time_out)
                        ) / 3600000
                      ).toFixed(2)
                    }}
                  </span>
                </td>
              </tr>
            </tbody>
            <tfoot>
              <tr v-if="currentUsersTimeSheets.length > 0">
                <td colspan="4" class="fb">Total Hours: {{ totalHours }}</td>
              </tr>
            </tfoot>
          </table>
        </div>
        <div
          v-else
          class="d-none d-print-block col-12 col-sm-12 col-lg-12 col-xl-12"
          id="timesheetReport"
        >
          <div class="text-center">
            <h1 class="">
              {{ capitalizeFisrtLetterOfEachWord(businessName) }}
            </h1>
            <address>
              {{ capitalizeFisrtLetterOfEachWord(pickUpAddress) }}<br />
              {{ formatPhoneNumber(pickUpPhoneNumber) }}
            </address>
            <br />
            <h3>User(s) Timesheet Report</h3>
          </div>
          <p>No records found for these dates:</p>
          <p>
            {{ new Date(currentStartingDateAndTimeIs).toLocaleDateString() }}
            to
            {{ new Date(currentEndingDateAndTimeIs).toLocaleDateString() }}
          </p>
        </div>
      </div>
    </div>
    <div class="container-fluid" v-else>
      <div class="d-flex aligns-items-center justify-content-center">
        <div class="m-5 alert alert-danger">
          Make sure you have the right online order page.
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import UpdateCurrentUserTimesheet from "./UpdateCurrentUserTimesheet.vue";
import axios from "axios";
//import pdf
import jsPDF from "jspdf";
// import html2canvas from 'html2canvas';
// import html2pdf from "html2pdf.js";
import * as html2pdf from "html2pdf.js";
import * as html2canvas from "html2canvas";
export default {
  name: "ViewUsersTimesheets",
  components: {
    UpdateCurrentUserTimesheet,
  },
  data() {
    return {
      disaledAddButton: true,
      showViewTimeHours: true,
      currentStartingDateAndTimeIs: new Date().toJSON().slice(0, 19), //current crrent date and time - datetime-local
      currentEndingDateAndTimeIs: new Date().toJSON().slice(0, 19), //current crrent date and time - datetime-local
      datePickerUpdateError: false,
      getCurrentUserId: "",
      datePickerAddError: false,
      isCustomDate: false,
      todaysDate: new Date(
        new Date().getTime() - new Date().getTimezoneOffset() * 60000
      )
        .toISOString()
        .split("T")[0],
      amountArr: [],
      datePickerError: false,
      salesTotal: 0.0,
      salesTips: 0.0,
      salesTaxes: 0.0,
      salesRefunds: 0.0,
      salesSubtotal: 0.0,
      totalHours: 0.0,
      showCustomer: true,
      showOrderNO: true,
      showAddressPrint: false,
      showOrderTime: true,
      OrdersAPI: this.$store.getters.OrdersAPI,
      reasonToCancelOrder: "all",
      currentLoggedInUserData: "",
      currentUserAccessCodeIs: "",
      currentUserAccessCodeIsErr: false,
      isUserAccessCodeValidated: false,
      btnEnabled: false,
      currentUserAccessCodeIsMsg: "",
      canUserAccessThis: false,
      showPickedUp: false,
      showInProgress: true,
      showReadyOrders: false,
      showAllOrders: false,
      restId: this.$route.params.restId,
      currentOrder: [],
      pickUpAddress: "",
      pickUpPhoneNumber: "",
      currentUsersAccessIds: [],
      currentUsersNames: [],
      currentUsersTimeSheets: [],
      currentUsersTimeSheetsWithUniqueData: [],
      businessName: "",
      custName: "",
      orderData: "",
      custom_order_date_or_now: "",
      custEmail: "",
      showOrderData: true,
      showCustomerData: false,
      tips: 0,
      currentEndingUpdateDateAndTime: "",
      currentStartingUpdateDateAndTime: "",
      // OrdersAPI: this.$store.getters.OrdersAPI,
      orderSequenceNumber: 0,
      custom_date_time: "",
      currentRealOrder: [],
      currentOrderInDetails: [],
      getNewOrders: null,
      doesRestDataExists: true,
      userExit: false,
      todaysDateIs: new Date(
        new Date().getTime() - new Date().getTimezoneOffset() * 60000
      )
        .toISOString()
        .split("T")[0],
      viewOrderStartingDate: new Date(
        new Date().getTime() - new Date().getTimezoneOffset() * 60000
      )
        .toISOString()
        .split("T")[0],
      viewOrderEndingDate: new Date(
        new Date().getTime() - new Date().getTimezoneOffset() * 60000
      )
        .toISOString()
        .split("T")[0],
      timestamp: "",
      flash: "",
    };
  },
  mounted() {
    // console.table(currentLoggedInUserAccessData);
    // this.viewOrders(this.restId);
    // this.doesThisRestExist();
    this.getAllUsersInfo();
    this.currentUsersTimeSheet();
    console.log("Current Date is " + this.getCurrentDate());
    console.log("Current Time is " + this.getCurrentTime());
    console.log("Full date format " + this.fullDateFormated());
    console.log(
      "Add 15 minutes to current time " +
        this.addHoursToDateAndReturnTimeOnly(new Date(), 0.25)
    );
    console.log(
      "Current Time in 24 Hours Format " + this.getCurrentTime24HoursFormat()
    );
    // console.table(currentUserData);
    // console.table(currentUserData.length);
    let currentRestDetails = localStorage.getItem("current_rest_details");
    this.doesThisRestExist();
    if (currentRestDetails === null) {
      console.log("no rest data existing...");
    } else {
      console.log("current rest data");
      console.table(currentRestDetails);
    }

    let currentLoggedInUserAccessData = localStorage.getItem(
      "current_logged_user_access_data"
    );
    if (currentLoggedInUserAccessData === null) {
      this.canUserAccessThis = false;
    } else {
      this.currentLoggedInUserData = JSON.parse(currentLoggedInUserAccessData);
      console.table(currentLoggedInUserAccessData);
      this.canUserAccessThis = true;
      this.canUserAccessPageNow();
    }
  },
  beforeUnmount() {
    clearInterval(this.getNewOrders);
  },
  created() {
    // if(userExit ==)
    setInterval(this.canUserAccessPageNow, 3000); //every 3 minutes
    // this.canUserAccessPageNow;
    this.viewNewOrders();
    // this.canUserAccessCurrentPage();
  },
  methods: {
    //this methods will be passed from child UpdateCurrentUsersTimesheet to this component
    getCurrentUsersTimeSheets(newCurrentTimeSheet) {
      this.currentUsersTimeSheets = newCurrentTimeSheet;
      //if you need to sort out the results
      // this.currentUsersTimeSheets = newCurrentTimeSheet.sort(function (a, b) {
      //     return (
      //       new Date(b.custom_date + " " + b.custom_time) -
      //       new Date(a.custom_date + " " + a.custom_time)
      //     );
      //   })
      //   .reverse();
      this.currentUsersTimeSheetsWithUniqueDataFunction();
    },
    currentUsersTimeSheetsWithUniqueDataFunction() {
      const key = "access_id";
      const arrayUniqueByKey = [
        ...new Map(
          this.currentUsersTimeSheets.map((item) => [item[key], item])
        ).values(),
      ];
      console.table(arrayUniqueByKey);
      this.currentUsersTimeSheetsWithUniqueData = arrayUniqueByKey;
    },
    calculateTotalHours() {
      //current_users_timesheet
      let getTimesheetArr = JSON.parse(
        localStorage.getItem("current_users_timesheet")
      );
      let myTotal = [];
      getTimesheetArr.forEach((v) => {
        if (v.is_logged_out == 1) {
          //calculate only logged out users
          myTotal.push(
            Math.abs(
              parseFloat(new Date(v.time_in) - new Date(v.time_out)) / 3600000
            )
          );
        }
        // myTotal = parseFloat(
        //   parseFloat(myTotal.toFixed(2)) +
        //     Math.abs(
        //       parseFloat(new Date(v.time_in) - new Date(v.time_out)) / 3600000
        //     ).toFixed(2)
        // );
      });
      let sum = 0;
      myTotal.forEach((x) => {
        sum += x;
      });
      console.table("Total hours array", myTotal);
      console.table("my total hours is ", sum);
      this.totalHours = parseFloat(sum).toFixed(2);
    },
    async getAccessUserName(id, appendTo) {
      console.log(id);
      console.log(appendTo);
      let currentUsersNamesArr = localStorage.getItem(
        "current_users_names_and_ids"
      );
      let myArr = JSON.parse(currentUsersNamesArr);
      let newVal = myArr.filter((val) => val.access_id == id);
      console.table(newVal);
      console.log("Current user name is " + newVal[0].name);
      setTimeout(() => {
        let addNameArr = document.getElementsByClassName(appendTo).length;
        if (addNameArr > 0) {
          console.log("yes current user names is/are : ", addNameArr);
          for (let index = 0; index < addNameArr.length; index++) {
            document.getElementsByClassName(appendTo)[index].textContent =
              newVal[0].name;
          }
        }
      }, 150);
    },
    async getAllUsersInfo() {
      let fd = new FormData();
      fd.append("restId", this.restId);
      let rst = await axios.post(
        this.OrdersAPI + `?action=getAllUsersInfo`,
        fd
      );
      const resultData = rst.data;
      if (rst.status == 200) {
        console.table(resultData);
        localStorage.setItem(
          "current_users_names_and_ids",
          JSON.stringify(resultData.current_users_names_and_ids)
        );
        //check if therre is return data, means restaurant is avail and active
        this.currentUsersNames = JSON.parse(
          localStorage.getItem("current_users_names_and_ids")
        );
      }
    },
    async deleteCurrentHours(i, id) {
      let fd = new FormData();
      fd.append("restId", this.restId);
      fd.append("i", i);
      fd.append("id", id);
      fd.append(
        "by",
        JSON.parse(localStorage.getItem("current_user_access_id")).id
      );
      let rst = await axios.post(
        this.OrdersAPI + `?action=deleteCurrentHours`,
        fd
      );
      const resultData = rst.data;
      if (rst.status == 200) {
        console.table(resultData);
        if (resultData.error) {
          console.log("There are errors");
          document.getElementById("showHoursDeleteResult-" + i).innerHTML =
            "<div class='m-2 alert alert-danger'>" +
            resultData.message +
            "</div>";
        } else {
          document.getElementById("showHoursDeleteResult-" + i).innerHTML =
            "<div class='m-2 alert alert-success'>" +
            resultData.message +
            "</div>";
          let currentTimesheetIs = JSON.parse(
            localStorage.getItem("current_users_timesheet")
          );
          let currentTimesheetUpdated = currentTimesheetIs.filter(function (
            item
          ) {
            console.log(item);
            return item.id != i;
          });
          localStorage.setItem(
            "current_users_timesheet",
            JSON.stringify(currentTimesheetUpdated)
          );
          this.currentUsersTimeSheets = JSON.parse(
            localStorage.getItem("current_users_timesheet")
          );
          this.currentUsersTimeSheetsWithUniqueDataFunction();
          //Then close current modal
          document.body.classList.remove("modal-open");
          document.getElementById("deleteHoursModal-" + i).remove();
          document.body.removeAttribute("style");
          const boxes = document.querySelectorAll(".modal-backdrop");
          boxes.forEach((box) => {
            box.remove();
          });
        }
      }
    },
    async currentUsersTimeSheet() {
      if (!this.datePickerError) {
        this.showViewTimeHours = false;
        //disable button for few seconds
        let fd = new FormData();
        fd.append("restId", this.restId);
        fd.append("start", this.viewOrderStartingDate);
        fd.append("end", this.viewOrderEndingDate);
        fd.append("allOrCustomUser", this.reasonToCancelOrder);
        let rst = "";
        if (this.reasonToCancelOrder == "all") {
          rst = await axios.post(
            this.OrdersAPI + `?action=currentUsersTimeSheet`,
            fd
          );
          console.log(
            "You are selecting timesheet for all users",
            this.reasonToCancelOrder
          );
        } else {
          rst = await axios.post(
            this.OrdersAPI + `?action=currentUsersTimeSheet`,
            fd
          );
          console.log(
            "You are selecting timesheet for ",
            this.reasonToCancelOrder
          );
        }
        const resultData = rst.data;
        if (rst.status == 200) {
          this.showViewTimeHours = true;
          console.table(resultData);
          localStorage.setItem(
            "current_users_timesheet",
            JSON.stringify(resultData.current_users_timesheet)
          );
          //check if therre is return data, means restaurant is avail and active
          this.currentUsersTimeSheets = JSON.parse(
            localStorage.getItem("current_users_timesheet")
          );
          this.currentUsersTimeSheetsWithUniqueDataFunction();
          this.calculateTotalHours();
        }
      } else {
        this.showViewTimeHours = true;
        this.viewNewOrdersForMultipleDates();
      }
    },
    formatPhoneNumber(number) {
      //remove . + -  from any number
      let cleaned = ("" + number).replace(/\D/g, "");
      let match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
      if (match) {
        return "(" + match[1] + ") " + match[2] + "-" + match[3];
      }
      return null;
    },
    canUserAccessPageNow() {
      let currentLoggedInUserAccessData = localStorage.getItem(
        "current_logged_user_access_data"
      );
      if (currentLoggedInUserAccessData === null) {
        console.log("no log in");
        this.canUserAccessThis = false;
      } else {
        //now only admin or manager
        this.currentLoggedInUserData = JSON.parse(
          currentLoggedInUserAccessData
        );
        if (
          this.currentLoggedInUserData[0].user_role == "admn" ||
          this.currentLoggedInUserData[0].user_role == "mngr" ||
          this.currentLoggedInUserData[0].user_role == "cmngr"
        ) {
          console.log(
            "current user logged is " +
              this.currentLoggedInUserData[0].user_role
          );
          this.canUserAccessThis = true; // admin or manager only access report page
          this.currentUserAccessCodeIsErr = false;
          this.isUserAccessCodeValidated = true;
          this.currentUserAccessCodeIsMsg = "";
          this.btnEnabled = false;
        } else {
          //this user employee can't access this page
          this.currentUserAccessCodeIsErr = true;
          this.isUserAccessCodeValidated = false;
          this.currentUserAccessCodeIsMsg = "You can't access this page!";
          this.btnEnabled = true;
          console.log(
            "current user logged is " +
              this.currentLoggedInUserData[0].user_role
          );
          this.canUserAccessThis = false;
        }
      }
      //   setInterval(() => {
      //   }, 1000);
    },
    goToMainOrdersPage() {
      this.$router.push({
        name: "ViewOrders",
        params: { restId: this.restId },
      });
    },
    viewNewOrdersForOneDate() {
      //make both days equal
      this.viewOrderEndingDate = this.viewOrderStartingDate;
      this.viewOrders(this.restId);
    },
    viewNewOrdersForMultipleDates() {
      //make both days equal
      // this.viewOrderEndingDate = this.viewOrderStartingDate;
      if (this.viewOrderEndingDate == "" || this.viewOrderStartingDate == "") {
        console.log("Dates can't be empty");
        this.datePickerError = true;
        this.datePickerMsg = "Both dates must be selected";
      } else if (
        new Date(this.viewOrderStartingDate) >
        new Date(this.viewOrderEndingDate)
      ) {
        console.log("Ending date has to be less starting date");
        console.log("starting date: " + this.viewOrderStartingDate);
        console.log("ending date: " + this.viewOrderEndingDate);
        this.datePickerError = true;
        this.datePickerMsg = "Ending date has to be less starting date";
      } else {
        this.datePickerError = false;
        this.datePickerMsg = "";
        // this.currentUsersTimeSheet();
      }
    },
    async addNewUserTimesheetNow() {
      // this.disaledAddButton = true;
      this.addNewUserTimesheetCheck();
      if (this.datePickerAddError) {
        this.addNewUserTimesheetCheck();
        // this.disaledAddButton = false;
      } else {
        // this.disaledAddButton = true;
        let showHoursAddResult = document.getElementById("showHoursAddResult");
        let fd = new FormData();
        fd.append("restId", this.restId);
        fd.append(
          "addedBy",
          JSON.parse(localStorage.getItem("current_user_access_id")).id
        );
        fd.append("start", this.currentStartingDateAndTimeIs);
        fd.append("end", this.currentEndingDateAndTimeIs);
        fd.append("accessId", this.getCurrentUserId);
        try {
          let rst = await axios.post(
            this.OrdersAPI + `?action=addNewUserTimesheetNow`,
            fd
          );
          const resultData = rst.data;
          if (rst.status == 200) {
            console.table(resultData);
            if (resultData.error) {
              console.log("There are errors");
              this.datePickerAddError = true;
              showHoursAddResult.innerHTML =
                "<div class='alert alert-danger'>" +
                resultData.message +
                "</div>";
              // this.disaledAddButton = false;
            } else {
              showHoursAddResult.innerHTML =
                "<div class='alert alert-success'>Awesome, Total hours added " +
                (
                  Math.abs(
                    new Date(this.currentEndingDateAndTimeIs) -
                      new Date(this.currentStartingDateAndTimeIs)
                  ) / 3600000
                ).toFixed(2) +
                "</div>";
              this.disaledAddButton = false;
              setTimeout(() => {
                //set user to empty
                this.getCurrentUserId = "";
                //enable button again
                this.disaledAddButton = true;
                showHoursAddResult.innerHTML =
                  "<div class='alert alert-success'>You can add more if you like..</div>";
              }, 5000);
            }
          }
        } catch (error) {
          console.log("API Connection Error... Reconnecting..");
          console.log(error);
          setTimeout(() => {
            this.addNewUserTimesheetNow();
          }, "2000");
        }
      }
    },
    addNewUserTimesheetCheck() {
      let showHoursAddResult = document.getElementById("showHoursAddResult");
      if (
        this.currentEndingDateAndTimeIs == "" ||
        this.currentStartingDateAndTimeIs == ""
      ) {
        this.datePickerAddError = true;
        showHoursAddResult.innerHTML =
          "<div class='alert alert-danger'>Both dates must be selected</div>";
      } else if (
        new Date(this.currentEndingDateAndTimeIs) <=
        new Date(this.currentStartingDateAndTimeIs)
      ) {
        this.datePickerAddError = true;
        showHoursAddResult.innerHTML =
          "<div class='alert alert-danger'>Starting date has to be less ending date</div>";
      } else if (this.getCurrentUserId == "") {
        this.datePickerAddError = true;
        showHoursAddResult.innerHTML =
          "<div class='alert alert-danger'>Must select user name.</div>";
      } else {
        this.datePickerAddError = false;
        showHoursAddResult.innerHTML = "";
        // this.addNewUserTimesheetNow();
      }
    },
    updateCurrentUserHoursCheck(inputId, accessIdIs) {
      this.currentEndingUpdateDateAndTime = document.getElementById(
        "endingDateUpdate-" + inputId
      ).value;
      this.currentStartingUpdateDateAndTime = document.getElementById(
        "startingDateUpdate-" + inputId
      ).value;
      let showHoursAddResult = document.getElementById(
        "showHoursUpdateResult-" + inputId
      );
      if (
        this.currentEndingUpdateDateAndTime == "" ||
        this.currentStartingUpdateDateAndTime == ""
      ) {
        this.datePickerUpdateError = true;
        showHoursAddResult.innerHTML =
          "<div class='alert alert-danger m-3'>Both dates must be selected</div>";
      } else if (
        new Date(this.currentEndingUpdateDateAndTime) <=
        new Date(this.currentStartingUpdateDateAndTime)
      ) {
        this.datePickerUpdateError = true;
        showHoursAddResult.innerHTML =
          "<div class='alert alert-danger m-3'>Starting date has to be less ending date</div>";
      } else if (accessIdIs == "") {
        this.datePickerAddError = true;
        showHoursAddResult.innerHTML =
          "<div class='alert alert-danger m-3'>Something is wrong, refresh your page</div>";
      } else {
        this.datePickerUpdateError = false;
        showHoursAddResult.innerHTML = "";
      }
    },
    salesTotalFun(getData) {
      console.log("get sales total for " + getData);
      let currentRes = this.currentOrder;
      console.table(currentRes);
      let total = 0.0;
      for (let i = 0; i < this.currentOrder.length; i++) {
        if (this.currentOrder[i][getData] != "") {
          //don't count empty values
          total = (
            parseFloat(total) + parseFloat(this.currentOrder[i][getData])
          ).toFixed(2);
          console.table("Total Order is " + this.currentOrder[i][getData]);
        }
      }
      return parseFloat(total).toFixed(2);
    },
    subtotalFun() {
      let orderTotal = this.salesTotalFun("order_total");
      let orderTips = this.salesTotalFun("total_tips_added");
      let orderTaxes = this.salesTotalFun("order_taxes_calculated");
      let orderRefund = this.salesTotalFun("amount_refunded");
      let subtotal = (
        parseFloat(orderTotal) +
        parseFloat(orderTips) +
        parseFloat(orderTaxes) -
        parseFloat(orderRefund)
      ).toFixed(2);
      return parseFloat(subtotal).toFixed(2);
    },
    exportToPDF() {
      this.showAddressPrint = true;
      this.showCustomer = false;
      this.showOrderNO = false;
      this.showOrderTime = false;
      setTimeout(() => {
        //https://pspdfkit.com/blog/2022/how-to-generate-a-pdf-with-vuejs/
        //https://www.youtube.com/watch?v=V0gMajxp990
        //good explanation for https://www.youtube.com/watch?v=zmJV6VN2h2E
        var element = document.getElementById("detailedReport");
        var opt = {
          margin: 10, //margin is better when printing
          filename:
            this.businessName +
            " timesheet report - from " +
            this.viewOrderStartingDate +
            " to " +
            this.viewOrderEndingDate +
            ".pdf",
          image: { type: "jpeg", quality: 0.98 },
          html2canvas: {
            scale: 2,
            logging: true,
            dpi: 600,
            letterRendering: true,
          },
          // pagebreak: { mode: ['avoid-all', 'css', 'legacy'] },
          // pagebreak: { before: '.beforeClass', after: ['#after1', '#after2'], avoid: 'img' },
          // pagebreak: { mode: "avoid-all", before: "#page2el" },
          jsPDF: { unit: "mm", format: "a4", orientation: "portrait" },
        };
        html2pdf().set(opt).from(element).save();
        // html2pdf(document.getElementById("detailedReport"), {
        //     margin: 1,
        //     filename: "i-was-html.pdf",
        // });
        // this.showCustomer = true;
        // this.showOrderNO = true;
        this.showCustomer = true;
        this.showOrderNO = true;
        this.showOrderTime = true;
      }, 2000);
    },
    downLoadPdf() {
      setTimeout(() => {
        let pdfRef = this.$refs.detailedReport;
        html2canvas(pdfRef).then((canvas) => {
          let pdfImage = canvas.toDataURL();
          let pdf = new jsPDF("landscape");
          // let width = doc.internal.pageSize.getWidth();
          // let height = doc.internal.pageSize.getHeight();
          pdf.addImage(pdfImage, "PNG", 20, 20);
          pdf.save("commande.pdf");
        });
      }, 2000);
      // this.showCustomer = true;
      // this.showOrderNO = true;
    },
    number_format(amount) {
      const price = amount;
      // Format the price above to USD, INR, EUR using their locales.
      let dollarUS = Intl.NumberFormat("en-US", {
        style: "currency",
        currency: "USD",
      });
      return dollarUS.format(price);
    },
    clearLogin() {
      this.currentUserAccessCodeIs = "";
      //clear localstorage
      localStorage.removeItem("current_logged_user_access_data");
      setInterval(this.canUserAccessPageNow, 100);
      //   this.$router.push({ name: "ViewOrders",  params: { restId: this.restId }});
      localStorage.clear();
      //   this.canUserAccessPageNow();
      setTimeout(() => {
        this.$router.push({
          name: "ViewOrders",
          params: { restId: this.restId },
        });
      }, 1000);
    },
    async getUserName(accessId, appendTo) {
      let fd = new FormData();
      fd.append("accessId", accessId);
      let rst = await axios.post(
        this.OrdersAPI + `?action=getUserNameByAccessCode`,
        fd
      );
      // setTimeout(() => {
      //   document.getElementById(appendTo).innerText = "";
      // }, 3000);
      // document.getElementById(appendTo).innerText = ""; //make it clear till you get names
      if (rst.status == 200) {
        const resultData = rst.data;
        if (!resultData.error) {
          console.log("current user name is " + resultData.user_name_is);
          // let name = resultData.user_name_is;
          //   if (typeof document.getElementById(appendTo) != "undefined") {
          document.getElementById(appendTo).innerText = accessId;
          // this.capitalizeFisrtLetterOfEachWord(name)
          //   .split(" ")
          //   .slice(0, 1)
          //   .join(" ");
        }
      }
    },
    removeSecondsFromTime(timeIs) {
      //assuming time in 12:34:00 AM/PM format
      return timeIs.replace(/(\d{1,2}:\d{2}):\d{2}/, "$1");
    },
    async canUserAccessCurrentPage() {
      this.btnEnabled = false;
      console.log(this.restId);
      console.log(this.currentUserAccessCodeIs);
      // console.log(`find all orders for current restaurant ${restId}`);
      if (this.isUserAccessCodeValidated) {
        let fd = new FormData();
        fd.append("restId", this.restId);
        fd.append("accessId", this.currentUserAccessCodeIs);
        let rst = await axios.post(
          this.OrdersAPI + `?action=canAccessThisPage`,
          fd
        );
        console.log(rst);
        const resultData = rst.data;
        if (rst.status == 200) {
          console.table(resultData);
          if (resultData.error) {
            console.log("this user has an error");
            this.currentUserAccessCodeIsErr = true;
            this.isUserAccessCodeValidated = false;
            this.btnEnabled = false;
            this.currentUserAccessCodeIsMsg = resultData.message;
          } else {
            this.btnEnabled = false;
            if (resultData.is_order_updated) {
              console.log("user name is " + resultData.userNameIs);
            }
            //now set a localstorage to save logged in user data
            localStorage.removeItem("current_logged_user_access_data");
            let loggedInUserId = [];
            loggedInUserId.push({
              user_name: resultData.userNameIs,
              user_id: resultData.userIdIs,
              user_role: resultData.userRoleIs,
            });
            localStorage.setItem(
              "current_logged_user_access_data",
              JSON.stringify(loggedInUserId)
            );
            // this.viewOrders(this.restId);
            // this.canUserAccessThis = true;
          }
        }
      } else {
        this.btnEnabled = false;
        this.currentUserAccessCodeIsErr = true;
        this.isUserAccessCodeValidated = false;
        this.currentUserAccessCodeIsMsg = "Refresh the page";
      }
    },
    async doesThisRestExist() {
      let fd = new FormData();
      fd.append("restId", this.restId);
      let rst = await axios.post(
        this.OrdersAPI + `?action=isRestaurantExist`,
        fd
      );
      const resultData = rst.data;
      if (rst.status == 200) {
        console.table(resultData);
        localStorage.setItem(
          "current_rest_data_existing",
          JSON.stringify(resultData.current_rest_data_existing)
        );
        //check if therre is return data, means restaurant is avail and active
        let currentRes = localStorage.getItem("current_rest_data_existing");
        if (JSON.parse(currentRes).length > 0 || this.pickUpAddress != "") {
          this.doesRestDataExists = true;
          console.log("this restaurant does exists");
        } else {
          console.log("this restaurant does NOT exists");
          this.doesRestDataExists = false;
        }
      }
    },
    displayCustomDate(dateIs) {
      const currentDate = new Date(dateIs).toLocaleDateString();
      const currentTime = new Date(dateIs).toLocaleTimeString();
      console.log(currentDate);
      console.log(currentTime);
      // const [year, month, day] = currentDate.split("-");
      // const result = [month, day, year].join("/");
      let n = currentDate + " at " + this.timeFormat12Hours(currentTime);
      return n;
    },
    validateUserAccessCode(e) {
      let val = e.target.value;
      this.validateUserAccessCodeInput(val);
    },
    validateUserAccessCodeInput(val) {
      // let userPhoneRegex = /^\d{10}$/;
      console.log("User Code: " + val);
      if (val == "") {
        this.currentUserAccessCodeIsErr = true;
        this.isUserAccessCodeValidated = false;
        this.currentUserAccessCodeIsMsg = "Must Enter User Code ..";
        this.btnEnabled = false;
      } else if (/^\d+$/.test(val) == false) {
        //only numbers from 0-9 no digits, + - * / .
        this.btnEnabled = false;
        this.currentUserAccessCodeIsErr = true;
        this.isUserAccessCodeValidated = false;
        this.currentUserAccessCodeIsMsg = "Numbers Only";
      } else {
        this.btnEnabled = true;
        this.currentUserAccessCodeIsErr = false;
        this.isUserAccessCodeValidated = true;
        this.currentUserAccessCodeIsMsg = "";
      }
    },
    getNow() {
      const today = new Date();
      let hour = today.getHours();
      let amOrPm = hour < 12 ? " AM" : " PM";
      if (hour == 0) {
        hour = 12;
      }
      if (hour > 12) {
        hour = hour - 12;
      }
      if (hour < 10) {
        hour = "0" + hour;
      }
      let minute = today.getMinutes();
      if (minute < 10) {
        minute = "0" + minute;
      }
      // add a zero in front of numbers<10
      // minute = this.checkTime(minute);
      let sec = today.getSeconds();
      if (sec < 10) {
        sec = "0" + sec;
      }
      let time = hour + ":" + minute + ":" + sec + amOrPm;
      this.timestamp = time;
    },
    checkTime(i) {
      if (i < 10) {
        i = "0" + i;
      }
      return i;
    },
    UpdateTimer() {
      this.Timer.innerHTML = this.TotalMins + ":" + this.secs;
    },
    viewNewOrders() {
      let currentLoggedInUserAccessData = localStorage.getItem(
        "current_logged_user_access_data"
      );
      this.amountArr = [];
      //   this.getNewOrders = setInterval(() => {
      //     if (currentLoggedInUserAccessData === null) {
      //       console.log("no log in");
      //       this.canUserAccessThis = false;
      //     } else {
      //       //now only admin or manager
      //       this.currentLoggedInUserData = JSON.parse(
      //         currentLoggedInUserAccessData
      //       );
      //       if (
      //         this.currentLoggedInUserData[0].user_role == "admn" ||
      //         this.currentLoggedInUserData[0].user_role == "mngr" ||
      // currentLoggedInUserData[0].user_role == "cmngr"
      //       ) {
      //         this.canUserAccessThis = true; // admin or manager only access report page
      //       } else {
      //         this.canUserAccessThis = false;
      //       }
      //     }
      //   }, 1000); //run this every 2 minutes

      if (currentLoggedInUserAccessData === null) {
        console.log("user is not logged in");
        this.canUserAccessThis = false;
      } else {
        console.log("searching for new orders...");
        console.log("user is logged in");
        this.canUserAccessThis = true;
        this.currentLoggedInUserData = JSON.parse(
          currentLoggedInUserAccessData
        );
        //if current date
        if (
          this.checkIfTodayIsGreaterThanCustomDate(
            this.viewOrderStartingDate
          ) == false
        ) {
          //if false, show todays order
          if (
            this.currentLoggedInUserData[0].user_role == "admn" ||
            this.currentLoggedInUserData[0].user_role == "mngr" ||
            this.currentLoggedInUserData[0].user_role == "cmngr"
          ) {
            console.log("Current logged in user is accessible");
            this.amountArr.length = 0; //empty amount total
            this.viewOrders(this.restId);
            console.log(
              "page will show todays order " +
                this.checkIfTodayIsGreaterThanCustomDate(
                  this.viewOrderStartingDate
                )
            );
          } else {
            console.log("Current logged in user is not accessible");
          }
        } else {
          this.amountArr.length = 0; //empty amount total
          console.log("page will not show any orders.. only new date order");
        }
      }
    },
    timeFormat12Hours(time) {
      // Check correct time format and split into components
      time = time
        .toString()
        .match(/^([01]\d|2[0-3])(:)([0-5]\d)(:[0-5]\d)?$/) || [time];

      if (time.length > 1) {
        // If time format correct
        time = time.slice(1); // Remove full string match value
        time[5] = +time[0] < 12 ? " AM" : " PM"; // Set AM/PM
        time[0] = +time[0] % 12 || 12; // Adjust hours
      }
      return time.join(""); // return adjusted time or original string
    },
    AddMinutesToDate(date, minutes) {
      return new Date(date.getTime() + minutes * 60000);
    },
    DateFormat(date) {
      var days = date.getDate();
      var year = date.getFullYear();
      var month = date.getMonth() + 1;
      var hours = date.getHours();
      var minutes = date.getMinutes();
      minutes = minutes < 10 ? "0" + minutes : minutes;
      var strTime =
        days + "/" + month + "/" + year + "/ " + hours + ":" + minutes;
      return strTime;
    },
    getCurrentDate() {
      // let currentDate = new Date().toJSON().slice(0, 10); // 10/26/2022 or use below
      let currentDate = new Date().toLocaleDateString();
      return currentDate;
    },
    getCurrentTime() {
      let currentTime = new Date().toLocaleTimeString();
      return currentTime;
    },
    addMinutesToCurrentTime(addMins) {
      let currentTime = new Date();
      return currentTime.setMinutes(currentTime.getMinutes() + addMins);
    },
    addHoursToDateAndReturnTimeOnly(objDate, intHours) {
      var numberOfMlSeconds = objDate.getTime();
      var addMlSeconds = intHours * 60 * 60 * 1000;
      var newDateObj = new Date(numberOfMlSeconds + addMlSeconds); //here it return full date with time added
      return newDateObj.toLocaleTimeString("en-US", {
        hour12: false,
      }); //here only returns time added to current time
    },
    fullDateFormated() {
      return new Date().toLocaleString("en-US", {
        hour12: false,
        year: "numeric",
        month: "2-digit",
        day: "2-digit",
        hour: "2-digit",
        minute: "2-digit",
        second: "2-digit",
      });
    },
    getCurrentTime24HoursFormat() {
      let currentTime = new Date().toLocaleTimeString("en-US", {
        hour12: false,
      });
      return currentTime;
    },
    displayCustomDateTime(dateIs, timeIs) {
      if (dateIs != "") {
        const [year, month, day] = dateIs.split("-");
        const result = [month, day, year].join("/");
        let n = result + " at " + this.timeFormat12Hours(timeIs);
        return n;
      } else {
        return this.timeFormat12Hours(timeIs);
      }
    },
    formatDateFormulas(dateIs) {
      const [year, month, day] = dateIs.split("-");
      const result = [month, day, year].join("/");
      let n = result;
      return n;
    },
    checkIfTodayIsGreaterThanCustomDate(customDate) {
      let currentDayIs = new Date().toLocaleDateString("en-US", {
        year: "numeric",
        month: "2-digit",
        day: "2-digit",
      });
      let [year, month, day] = customDate.split("-");
      let result = [month, day, year].join("/");
      if (currentDayIs > result) {
        return true;
      } else {
        return false;
      }
    },
    isEndingDateBiggerThanStartingDate(startDate, endDate) {
      let start = new Date(startDate).toLocaleDateString("en-US", {
        year: "numeric",
        month: "2-digit",
        day: "2-digit",
      });
      let [year, month, day] = start.split("-");
      let startResult = [month, day, year].join("/");
      let end = new Date(endDate).toLocaleDateString("en-US", {
        year: "numeric",
        month: "2-digit",
        day: "2-digit",
      });
      let [year2, month2, day2] = end.split("-");
      let endResult = [month2, day2, year2].join("/");
      if (endResult < startResult) {
        return true;
      } else {
        return false;
      }
    },
    async viewOrders(restId) {
      console.log(`find all orders for current restaurant ${restId}`);
      let fd = new FormData();
      fd.append("restId", restId);
      fd.append("startDate", this.viewOrderStartingDate);
      fd.append("endDate", this.viewOrderEndingDate);
      let rst = await axios.post(this.OrdersAPI + `?action=viewOrders`, fd);
      console.log(rst);
      const resultData = rst.data;
      if (rst.status == 200) {
        console.table(resultData);
        if (resultData.error) {
          console.log("this order was not found");
        } else {
          console.log("this order was found");
          localStorage.setItem(
            "current_orders",
            JSON.stringify(resultData.current_orders)
          );
          localStorage.setItem(
            "current_rest_details",
            JSON.stringify(resultData.current_rest_details)
          );
          // show current order details
          let currentRestaurants = JSON.parse(
            localStorage.getItem("current_rest_details")
          );
          if (currentRestaurants.length >= 1) {
            console.table(currentRestaurants);
            this.pickUpAddress = currentRestaurants[0].rest_address;
            this.pickUpPhoneNumber = currentRestaurants[0].rest_phone;
            this.businessName = currentRestaurants[0].rest_name;
          }
          let current_orders = localStorage.getItem("current_orders");
          this.currentOrder = JSON.parse(current_orders);
          console.table(current_orders);

          let currentOrderDetails = localStorage.getItem("current_orders");
          console.table(currentOrderDetails);
          this.currentOrderInDetails = JSON.parse(currentOrderDetails);
        }
      }
    },
    flashBorder(elmId, stopFlash) {
      var elm = document.getElementById(elmId);
      if (stopFlash == "true") {
        elm.style.border = "";
        console.log("flashing 0");
        clearInterval(this.flash);
      } else {
        console.log("flashing 1");
        var borderPattern = false;
        let setBorder;
        setBorder = function () {
          if (borderPattern) {
            console.log("flashing 2");
            borderPattern = false;
            elm.style.border = "solid red";
            elm.style.borderWidth = "3px";
          } else {
            console.log("flashing 3");
            borderPattern = true;
            elm.style.border = "solid blue";
            elm.style.borderWidth = "3px";
          }
        };
        this.flash = setInterval(setBorder, 100);
      }
    },
    //should get current logged in id to check if he owns current restaurant
    async orderReadyOrPickedUp(orderId, type) {
      // console.log(`find all orders for current restaurant ${restId}`);
      let fd = new FormData();
      fd.append("restId", this.restId);
      fd.append("orderId", orderId);
      fd.append("type", type);
      let rst = await axios.post(
        this.OrdersAPI + `?action=readyOrPickedUp`,
        fd
      );
      console.log(rst);
      const resultData = rst.data;
      if (rst.status == 200) {
        console.table(resultData);
        if (resultData.error) {
          console.log("this order has error");
        } else {
          if (resultData.is_order_updated) {
            console.log("this order status is updated");
          }
          // let result = resultData;
          // show current order details
        }
      }
    },
    capitalizeFisrtLetterOfEachWord(text) {
      return text
        .toLowerCase()
        .split(" ")
        .map((s) => s.charAt(0).toUpperCase() + s.substring(1))
        .join(" ");
    },
    printSection2(id) {
      var restorepage = document.body.innerHTML;
      var printcontent = document.getElementById(id).innerHTML;
      document.body.innerHTML = printcontent;
      window.print();
      document.body.innerHTML = restorepage;
      setTimeout(() => {
        // window.open("", "_self").close();
        location.reload();
        // this.$router.push({ name: 'ViewUsersTimesheets', params: { restId: this.restId } });
      }, 2000);
    },
    printSection(id) {
      // Get HTML to print from element
      const prtHtml = document.getElementById(id).innerHTML;
      // Get all stylesheets HTML
      let stylesHtml = "";
      for (const node of [
        ...document.querySelectorAll('link[rel="stylesheet"], style'),
      ]) {
        stylesHtml += node.outerHTML;
      }
      // Open the print window
      const WinPrint = window.open(
        "",
        "",
        "left=0,top=0,width=800,height=900,toolbar=0,scrollbars=0,status=0"
      );

      WinPrint.document.write(`<!DOCTYPE html>
            <html>
            <head>
                ${stylesHtml}
            </head>
            <body>
                ${prtHtml}
            </body>
            </html>`);

      WinPrint.document.close();
      WinPrint.focus();
      WinPrint.print();
      WinPrint.close();
    },
  },
};
</script>

<style scoped>
/* @import "https://cdn.jsdelivr.net/npm/bootstrap-print-css/css/bootstrap-print.min.css"; */
@media print {
  h1,
  td,
  tr,
  h2,
  h3,
  h4,
  h5,
  h6,
  p,
  div,
  body,
  hr,
  .alert-danger {
    font-size: 10pt;
    color: black;
    font-weight: bold;
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    font-size: 15pt;
  }

  .alert-danger {
    background-color: none;
    border-radius: 5px;
    border: 1px solid #e2e2e2;
  }

  .each-order-item {
    border: none !important;
  }
}

.form-control:disabled,
.form-control[readonly] {
  background-color: #ffffff;
}

.blink {
  border-color: red;
  animation: blink-animation 1s steps(5, start) infinite;
  -webkit-animation: blink-animation 1s steps(5, start) infinite;
}

@keyframes blink-animation {
  to {
    visibility: hidden;
  }
}

@-webkit-keyframes blink-animation {
  to {
    visibility: hidden;
  }
}

.each-order-title {
  background-color: #f8f8f8 !important;
  padding: 8px;
  font-weight: bold;
  width: 100%;
  overflow: auto !important;
  color: #02027e !important;
}

.marginTop10 {
  margin-top: 14px !important;
}

.custom-bg {
  position: relative;
  padding: 2px 0 0 0;
}

.custom-header-left {
  position: absolute;
  top: -32px;
}

.custom-header-right {
  position: absolute;
  right: 0;
  top: -32px;
  font-weight: bold !important;
  background-color: white !important;
}

.addBorder {
  background-color: beige;
  border: 1px solid black;
  padding: 8px;
  font-weight: bold;
  width: 90%;
  margin: 18px auto;
  border-radius: 5px;
}

.addressStyle {
  font-style: italic;
  padding: 0;
  margin: 0;
  line-height: 0.5;
}

.form-data-error {
  color: red;
}

.form-data-error input,
.form-data-error textarea {
  border-color: red;
}

.itemDataDetails {
  padding: 10px;
  margin-bottom: 10px;
  border: 10px solid #eee;
}

.each-order-item {
  border: 1px solid #000203;
  padding: 6px;
  margin: 23px 0;
}

.itemDataDetails img {
  width: 320px;
  height: 215px;
  margin-bottom: 10px;
}

.bw {
  overflow-wrap: break-word;
}

.darkred {
  color: darkred;
}

.redColor {
  color: red !important;
}

.order-placed {
  padding: 10px;
}

.order-placed h2 {
  color: green;
}

.addressInfo {
  display: block;
  font-style: italic;
  font-weight: bold;
}

.card-header {
  background-color: #008c5d !important;
  font-weight: bold;
}

.mb5 {
  margin-bottom: 5px;
}

.text-bold {
  font-weight: bold;
}

.card-header a,
.card-header a:hover,
.card-header a:visited,
.card-header a:active,
.card-header a:focus {
  color: white;
  display: block;
  text-align: left;
  border: none;
}

.card-header .btn-check:focus + .btn,
.card-header .btn:focus {
  box-shadow: none !important;
}

.fb {
  font-weight: bold;
}

.custom-kitchen-order {
  margin-bottom: 10px !important;
}

.btns-success,
.btns-success:hover,
.btns-success:active {
  color: #21b023;
  background-color: #fff;
  border-color: #21b023;
  font-weight: bold;
}

.biggerFont {
  font-size: 1.5em;
  padding: 0;
}

.btns-orange,
.btns-orange:hover,
.btns-orange:active {
  color: #ff5722 !important;
  background-color: #fff;
  border-color: #ff5722 !important;
  font-weight: bold;
}

.btns-primary,
.btns-primary:hover,
.btns-primary:active {
  color: blue;
  background-color: white;
  border-color: blue;
  font-weight: bold;
}

.btns-danger,
.btns-danger:hover,
.btns-danger:active {
  color: red;
  background-color: white;
  border-color: red;
  font-weight: bold;
}

.color-356f71 {
  color: #356f71;
}

.custom-name {
  padding-left: 20px;
  margin-bottom: -5px;
  clear: both;
  margin-top: -14px;
}

.total-end {
  font-weight: bold;
  margin-top: 5px;
}

.slidecontainer {
  width: 100%;
}

.slider {
  -webkit-appearance: none;
  width: 100%;
  height: 15px;
  border-radius: 5px;
  background: #d3d3d3;
  outline: none;
  opacity: 0.7;
  -webkit-transition: 0.2s;
  transition: opacity 0.2s;
}

.slider:hover {
  opacity: 1;
}

.slider::-webkit-slider-thumb {
  -webkit-appearance: none;
  appearance: none;
  width: 25px;
  height: 25px;
  border-radius: 50%;
  background: #04aa6d;
  cursor: pointer;
}

.slider::-moz-range-thumb {
  width: 25px;
  height: 25px;
  border-radius: 50%;
  background: #04aa6d;
  cursor: pointer;
}

.pointerHand {
  cursor: pointer;
}

.greenColor {
  color: #00c904;
}

.mainHeader {
  box-sizing: border-box;
  color: red;
  width: 100%;
  margin: 0;
  border-bottom: 2px solid #356f71;
  padding-bottom: 10px;
}

.colorRed {
  color: red;
}

.custom-button {
  background-color: white;
}

.greenColor {
  color: #21b023;
}

.yellowColor {
  color: #ffff00;
}

.blueColor {
  color: #0000ff;
}

.fontSizeBigger {
  font-size: 1.75em;
}

.error-data {
  color: red;
}

.error-data input,
.error-data textarea {
  border-color: red;
}

.borderRed {
  border-color: red !important;
  background-color: red !important;
  color: white !important;
}

.blackColor {
  color: black;
}

/* .modal-backdrop {
  z-index: -1;
} */
/* .modal-backdrop {
  z-index: -1;
} */
/*
.modal {
  background-color: rgba(10, 10, 10, 0.45);
} 
*/
</style>
