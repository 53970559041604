<template>
  <div class="container">
    <Navbar />
    <nav style="--bs-breadcrumb-divider: '>'" aria-label="breadcrumb">
      <ol class="breadcrumb">
        <li class="breadcrumb-item">
          <router-link tag="li" :to="{ name: 'MyRestaurants' }">
            <a>My Restaurant(s)</a>
          </router-link>
        </li>
        <li v-if="this.isRestExists" class="breadcrumb-item active" aria-current="page">
          {{ this.businessName }}
        </li>
        <li v-if="this.isRestExists" class="breadcrumb-item active" aria-current="page">
          View Payment Report(s)
        </li>
      </ol>
    </nav>
    <div v-if="this.isRestExists" class="add-new-restaurant-page">
      <button class="btn btn-outline-secondary" v-if="(currentRestaurantPaymentRecords.length > 0)"
        @click="printSection('printPage')">
        <faIcons :icon="['fas', 'print']" /> Report(s)
      </button>&nbsp;
      <router-link :to="{
        name: 'CreatePaymentRecord',
        params: { id: restNum, restId: restId },
      }">
        <button class="btn btn-outline-success">
          <faIcons :icon="['fas', 'dollar-sign']" /> Create Payment
        </button>
      </router-link>
      <div id="printPage">
        <h3>
          {{ capitalizeFisrtLetterOfEachWord(businessName) }}
        </h3>
        <p class="text-muted">
          <faIcons :icon="['fas', 'map-marker']" /> {{ businessAddress }}
        </p>
        <p class="text-muted">View All Payment Record(s) ({{ currentRestaurantPaymentRecords.length }})</p>
        <div class="table-responsive">
          <table class="table">
            <thead class="table-success">
              <tr>
                <th class="header" scope="col">#</th>
                <th class="header" scope="col">Record ID</th>
                <th class="header" scope="col">Start</th>
                <th class="header" scope="col">End</th>
                <th class="header" scope="col">Total</th>
                <th class="header" scope="col">Notes</th>
                <th class="header" scope="col">Deposit Date</th>
                <th class="header" scope="col">Active</th>
                <th class="header d-print-none" scope="col">Actions</th>
              </tr>
            </thead>
            <tbody v-if="currentRestaurantPaymentRecords.length > 0">
              <tr v-for="(each, ii) in currentRestaurantPaymentRecords" :key="(ii + '-' + each)">
                <td>{{ each.id }}</td>
                <td>{{ each.record_id }}</td>
                <td>{{ formatDateFormulas(each.start) }}</td>
                <td>{{ formatDateFormulas(each.end) }}</td>
                <td>{{ number_format(each.total) }}</td>
                <td>{{ capitalizeFisrtLetterOfEachWord(each.notes) }}</td>
                <td>{{ new Date(each.added_on).toLocaleDateString() }}</td>
                <td>
                  <span class="text-success">{{ (each.active == 1 ? 'Active' : '') }}</span>
                  <span class="text-danger">{{ (each.active == 0 ? 'Deleted' : '') }}</span>
                </td>
                <td class="d-print-none">
                  <router-link :to="{
                    name: 'UpdatePaymentRecord',
                    params: { id: each.record_id, restId: each.rest_id },
                  }">
                    <button class="btn btn-outline-success">
                      <faIcons :icon="['fas', 'edit']" /> Update
                    </button>
                  </router-link>&nbsp;<router-link :to="{
                    name: 'DeletePaymentRecord',
                    params: { id: each.record_id, restId: each.rest_id },
                  }">
                    <button class="btn btn-outline-danger">
                      <faIcons :icon="['fas', 'trash']" /> Delete
                    </button>
                  </router-link>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>

    </div>
    <div v-else>
      <!-- Show error if no restaurant matches this Criteria -->
      <div class="alert alert-dark" role="alert">
        <p>
          <faIcons :icon="['fas', 'store']" /> No restaurant found. Make sure you click in the right restaurant name.
        </p>
      </div>
      <router-link tag="li" :to="{ name: 'MyRestaurants' }">
        <a class="btn btn-success">
          <faIcons :icon="['fas', 'store']" /> My restaurant(s)
        </a>
      </router-link>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import { mapState, mapMutations, mapGetters } from "vuex";
import Navbar from "@/components/Header/Navbar.vue";
export default {
  name: "MyPaymentRecord",
  components: {
    Navbar,
  },
  data() {
    return {
      user: {
        uId: "",
        userId: "",
        uName: "",
        uEmail: "",
        uPassword: "",
        uMemberSince: "",
        uAddress: "",
        uPhone: "",
        uRole: "",
        uRoleIs: "",
      },
      restAcceptingOrderStatus: "",
      //cat
      catPrice: "",
      catPriceErr: false,
      catPriceMsg: "",
      iscatPriceValidated: false,
      //is my restaurant accepting online orders
      isMenuActive: "",
      isMenuActiveErr: false,
      isMenuActiveSuccess: false,
      isMenuActiveMsg: "Restaurant is now accepting online orders",
      isMenuActiveValidated: false,
      //Restaurant Info from URL
      restId: this.$route.params.restId,
      restNum: this.$route.params.id,
      //phone
      end: new Date(
        new Date().getTime() - new Date().getTimezoneOffset() * 60000
      )
        .toISOString()
        .split("T")[0],
      endErr: false,
      endMsg: "",
      isendValidated: false,
      //name
      start: new Date(
        new Date().getTime() - new Date().getTimezoneOffset() * 60000
      )
        .toISOString()
        .split("T")[0],
      startErr: false,
      startMsg: "",
      isstartValidated: false,
      //email
      notes: "",
      notesErr: false,
      notesMsg: "",
      isnotesValidated: false,
      //address
      totalPayment: "",
      totalPaymentErr: false,
      totalPaymentMsg: "",
      istotalPaymentValidated: false,
      //show result
      resultSuccess: false,
      resultSuccessMsg: "",
      resultErr: false,
      resultErrMsg: "",
      //show onlnie hours result
      onlineHoursResultSuccess: false,
      onlineHoursResultSuccessMsg: "",
      onlineHoursResultErr: false,
      onlineHoursResultErrMsg: "",
      image: "",
      is_DB_Connected: "",
      db_connection_msg: "",
      // apiMainUrl: "http://localhost/php/login-system-php-vue-2021/myData.php",
      apiMainUrl: this.$store.getters.MainUrl, //call global variable
      signUpBtn: true,
      acceptOrdersBtn: true,
      onlineHoursBtn: true,
      isRestExists: true,
      businessPhone: "",
      businessName: "",
      businessAddress: "",
      restaurant_added_by: "",
      currentRestaurantPaymentRecords: [],
    };
  },
  mounted() {
    let user = localStorage.getItem("current-user-data");
    let currentRestaurants = localStorage.getItem("current_restaurants_data");
    let currentRestaurantPaymentRecord = localStorage.getItem("created_payment_records");
    // let restaurant_bank_details = localStorage.getItem(
    //     "restaurant_bank_details"
    // );
    let currentRestaurant = JSON.parse(currentRestaurants).filter(
      (restInfo) =>
        restInfo.id == this.restNum && restInfo.rest_id == this.restId
    );
    this.currentRestaurantPaymentRecords = JSON.parse(currentRestaurantPaymentRecord).filter(
      (restInfo) => restInfo.rest_id == this.restId
    );
    // let currentRestaurantBankDetails = JSON.parse(
    //     restaurant_bank_details
    // ).filter((restInfo) => restInfo.rest_id == this.restId);
    // console.table(JSON.parse(restaurant_bank_details));
    // console.table(currentRestaurantBankDetails);
    // console.log("Length of rest found: ", currentRestaurantBankDetails.length);
    if (currentRestaurant.length > 0) {
      // console.table(currentRestaurantBankDetails.routing_number);
      //set rest info
      this.businessPhone = currentRestaurant[0].rest_phone;
      this.businessName = currentRestaurant[0].rest_name;
      this.businessAddress = currentRestaurant[0].rest_address;
      this.restaurant_added_by = currentRestaurant[0].added_by;
      this.isRestExists = true;
      // this.end = currentRestaurantBankDetails[0].routing_number;
      // this.notes = this.capitalizeFisrtLetterOfEachWord(
      //     currentRestaurantBankDetails[0].bank_name
      // );
      // this.totalPayment = currentRestaurantBankDetails[0].ein_number;
      // this.start = currentRestaurantBankDetails[0].bank_number;
      //get users info
      this.user.uId = JSON.parse(user).id;
      this.user.userId = JSON.parse(user).user_id;
      this.user.uName = JSON.parse(user).name;
      this.user.uEmail = JSON.parse(user).email;
      this.user.uPassword = JSON.parse(user).password;
      this.user.uMemberSince = JSON.parse(user).member_since;
      this.user.uAddress = JSON.parse(user).full_address;
      this.user.uPhone = JSON.parse(user).phone_number;
      this.user.uRole = JSON.parse(user).role;
      //if role isn't manager or admin, redirect
      if (
        JSON.parse(user).role !== "admn"
      ) {
        this.$router.push({ name: "MyRestaurants" });
      }
    } else {
      this.$router.push({ name: "MyRestaurants" });
    }
  },
  computed: {
    ...mapState([
      "__RestaurantId",
      "__RestaurantEmail",
      "__RestaurantRole",
      "__RestaurantName",
      "__MainUrl",
    ]),
    ...mapGetters(["MainUrl"]),
  },
  methods: {
    ...mapMutations(["setRestaurantInfo"]),
    capitalizeFisrtLetterOfEachWord(text) {
      return text
        .toLowerCase()
        .split(" ")
        .map((s) => s.charAt(0).toUpperCase() + s.substring(1))
        .join(" ");
    },
    printSection(id) {
      // Get HTML to print from element
      const prtHtml = document.getElementById(id).innerHTML;
      // Get all stylesheets HTML
      let stylesHtml = "";
      for (const node of [
        ...document.querySelectorAll('link[rel="stylesheet"], style'),
      ]) {
        stylesHtml += node.outerHTML;
      }
      // Open the print window
      const WinPrint = window.open(
        "",
        "",
        "left=0,top=0,width=800,height=900,toolbar=0,scrollbars=0,status=0"
      );

      WinPrint.document.write(`<!DOCTYPE html>
            <html>
            <head>
                ${stylesHtml}
            </head>
            <body>
                ${prtHtml}
            </body>
            </html>`);

      WinPrint.document.close();
      WinPrint.focus();
      WinPrint.print();
      WinPrint.close();
    },
    formatDateFormulas(dateIs) {
      const [year, month, day] = dateIs.split("-");
      const result = [month, day, year].join("/");
      let n = result;
      return n;
    },
    formatPhoneNumber(number) {
      //remove . + -  from any number
      let cleaned = ("" + number).replace(/\D/g, "");
      let match = "";
      if (number.length == 10) {
        match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
      } else if (number.length == 9) {
        match = cleaned.match(/^(\d{3})(\d{3})(\d{3})$/);
      } else if (number.length == 11) {
        match = cleaned.match(/^(\d{3})(\d{4})(\d{4})$/);
      } else if (number.length == 8) {
        match = cleaned.match(/^(\d{2})(\d{3})(\d{3})$/);
      }
      if (match) {
        return "(" + match[1] + ") " + match[2] + "-" + match[3];
      }
      return null;
    },
    number_format(amount) {
      const price = amount;
      // Format the price above to USD, INR, EUR using their locales.
      let dollarUS = Intl.NumberFormat("en-US", {
        style: "currency",
        currency: "USD",
      });
      return dollarUS.format(price);
    },
    validatestart(e) {
      let val = e.target.value;
      this.validatestartInput(val);
    },
    validatestartInput(val) {
      if (val == "") {
        this.startErr = true;
        this.isstartValidated = false;
        this.startMsg = "Choose starting date!";
      } else if (val != "" && this.end < this.start) {
        this.endErr = true;
        this.isstartValidated = false;
        this.startMsg = "Select same/higher starting date";
      } else {
        this.endErr = false;
        this.isstartValidated = true;
        this.startMsg = "";
      }
    },
    validateend(e) {
      let val = e.target.value;
      this.validateendInput(val);
    },
    validateendInput(val) {
      if (val == "") {
        this.endErr = true;
        this.isendValidated = false;
        this.endMsg = "Choose ending date!";
      } else if (val != "" && this.end < this.start) {
        this.endErr = true;
        this.isendValidated = false;
        this.endMsg = "Select same/higher ending date";
      } else {
        this.endErr = false;
        this.isendValidated = true;
        this.endMsg = "";
      }
    },
    validatenotes(e) {
      let val = e.target.value;
      this.validatenotesInput(val);
    },
    validatenotesInput(val) {
      if (val == "") {
        this.notesErr = false;
        this.isnotesValidated = true;
        this.notesMsg = "";
      }
    },
    validatetotalPayment(e) {
      let val = e.target.value;
      this.validatetotalPaymentInput(val);
    },
    validatetotalPaymentInput(val) {
      if (val == "") {
        this.totalPaymentErr = true;
        this.istotalPaymentValidated = false;
        this.totalPaymentMsg = "Enter total payment amount";
      } else {
        this.totalPaymentErr = false;
        this.istotalPaymentValidated = true;
        this.totalPaymentMsg = "";
      }
    },
    async createPaymentReport() {
      this.validatestartInput(this.start);
      this.validateendInput(this.end);
      // this.validatenotesInput(this.notes);
      this.validatetotalPaymentInput(this.totalPayment);
      if (
        this.istotalPaymentValidated &&
        this.isstartValidated &&
        this.isendValidated
      ) {
        this.resultErrMsg = "";
        this.resultErr = false;
        this.resultSuccess = true;
        this.resultSuccessMsg = "Wait while we're creating a payment report...";
        this.signUpBtn = false;
        //show Result
        let fd = new FormData();
        fd.append("start", this.start);
        fd.append("end", this.end);
        fd.append("notes", this.notes);
        fd.append("totalPayment", parseFloat(this.totalPayment).toFixed(2));
        fd.append("userId", this.user.userId);
        fd.append("restId", this.restId);
        fd.append("restAddedBy", this.restaurant_added_by);
        let rst = await axios.post(
          this.apiMainUrl + `?action=createPaymentReport`,
          fd
        );
        console.log(rst);
        const resultData = rst.data;
        if (rst.status == 200) {
          if (resultData.error) {
            console.warn("Connection Error Happened on CREATING new report");
            this.resultErrMsg = resultData.message;
            this.resultErr = true;
            this.resultSuccessMsg = "";
            this.resultSuccess = false;
            this.signUpBtn = true;
          } else {
            this.signUpBtn = false;
            console.log("Good Job, you have Created new report...");
            //if everything is okay
            this.resultErrMsg = "";
            this.resultErr = false;
            this.resultSuccess = true;
            this.resultSuccessMsg = resultData.message;
            //set restaurant to local host: current_restaurants_data
            localStorage.setItem(
              "created_payment_records",
              JSON.stringify(resultData.created_payment_records)
            );
            //redirect only if customer
            setTimeout(() => {
              //   this.$router.push({ name: "MyRestaurants" });
              console.log("Bank Name is updated: " + this.notes);
            }, 2000);
          }
        }
      } else {
        this.signUpBtn = true;
        this.validatestartInput(this.start);
        this.validateendInput(this.end);
        this.validatetotalPaymentInput(this.totalPayment);
        // this.validatenotesInput(this.notes);
        this.resultSuccessMsg = "";
        this.resultSuccess = false;
        this.resultErrMsg = "Must fill in all information";
        this.resultErr = true;
      }
    },
  },
};
</script>
<style lang="scss" scoped>
$navColor: #3f51b6;

.form-data-success {
  color: darkgreen;
}

.smallerSize {
  font-size: 0.75em;
}

nav a {
  text-decoration: none;
  color: $navColor;
  font-weight: bold;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

.w100 {
  width: 100px;
}

.bgBlue {
  background-color: #0d6efd;
  color: white;
  padding: 10px;
  border-radius: 7px;
}

.addBorder {
  border-bottom: 1px solid #ced4da;
}

.p4 {
  padding: 4px;
}
</style>
