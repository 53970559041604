<template>
  <div class="container">
    <Navbar />
    <nav style="--bs-breadcrumb-divider: '>'" aria-label="breadcrumb">
      <ol class="breadcrumb">
        <li class="breadcrumb-item">
          <router-link tag="li" :to="{ name: 'Home' }">
            <a>Home</a>
          </router-link>
        </li>
        <li class="breadcrumb-item">
          <router-link tag="li" :to="{ name: 'MyRestaurants' }">
            <a>My Restaurant(s)</a>
          </router-link>
        </li>
        <li v-if="this.isRestExists" class="breadcrumb-item active" aria-current="page">
          {{ this.userName }}
        </li>
        <li v-if="this.isRestExists" class="breadcrumb-item active" aria-current="page">
          Update
        </li>
      </ol>
    </nav>
    <div v-if="this.isRestExists" class="add-new-restaurant-page">
      <!-- Add new restaurant -->
      <form method="post" action="#" enctype="multipart/form-data">
        <div class="row g-3 align-items-center mb-3">
          <div class="col-auto d-block mx-auto">
            <div class="form-floating" :class="{ 'form-data-error': userNameErr }">
              <input type="text" id="userNameInput" placeholder="Restaurant Name" class="form-control w300"
                @keyup="validateRestaurantName($event)" @change="validateRestaurantName($event)"
                v-model.trim="userName" />
              <label for="userNameInput">
                <faIcons :icon="['fas', 'user-circle']" /> Restaurant
                Name
              </label>
              <span class="error-feedback" v-if="userNameErr">
                {{ userNameMsg }}
              </span>
            </div>
          </div>
        </div>

        <div class="row g-3 align-items-center mb-3">
          <div class="col-auto d-block mx-auto">
            <div class="form-floating" :class="{ 'form-data-error': userPhoneErr }">
              <input type="number" min="0" id="userPhoneInput" placeholder="Restaurant Phone" @keydown="
                if (
                $event.key === '.' ||
                $event.key === '+' ||
                $event.key === '-'
              ) {
                $event.preventDefault();
              }
              " @keyup="validateRestaurantPhone($event)" @change="validateRestaurantPhone($event)"
                class="form-control w300" v-model.trim="userPhone" />
              <label for="userPhoneInput">
                <faIcons :icon="['fas', 'phone']" /> Restaurant Phone
              </label>
              <span class="error-feedback" v-if="userPhoneErr">
                {{ userPhoneMsg }}
              </span>
            </div>
          </div>
        </div>

        <div class="row g-3 align-items-center mb-3">
          <div class="col-auto d-block mx-auto">
            <div class="form-floating" :class="{ 'form-data-error': userAddressErr }">
              <textarea id="userAddressInput" placeholder="Restaurant Full Address"
                @input="validateRestaurantAddress($event)" class="form-control w300 h100" v-model.trim="userAddress" />
              <label for="userAddressInput">
                <faIcons :icon="['fas', 'address-book']" /> Restaurant
                Address
              </label>
              <span class="error-feedback" v-if="userAddressErr">
                {{ userAddressMsg }}
              </span>
            </div>
          </div>
        </div>

        <div class="row d-grid gap-2 w300 col-auto d-block mx-auto mb-3">
          <button class="btn btn-outline-success" @click.prevent="UpdateCurrentRestaurant()" v-if="signUpBtn">
            <faIcons :icon="['fas', 'edit']" /> Update Restaurant
          </button>
        </div>
        <div class="row d-grid gap-2 w300 col-auto d-block mx-auto mb-3">
          <div class="alert alert-success" role="alert" v-if="resultSuccess">
            {{ resultSuccessMsg }}
          </div>
          <div class="alert alert-danger" role="alert" v-if="resultErr">
            {{ resultErrMsg }}
          </div>
        </div>
        <div class="row d-grid gap-2 w300 col-auto d-block mx-auto mb-3">
          <router-link class="btn btn-outline-primary" :to="{ name: 'MyRestaurants' }">
            <faIcons :icon="['fas', 'undo']" /> Back to restaurant
          </router-link>
        </div>
      </form>
    </div>
    <div v-else>
      <!-- Show error if no restaurant matches this Criteria -->
      <div class="alert alert-dark" role="alert">
        <p>
          No restaurant found. Make sure you click in the right restaurant name.
        </p>
      </div>
      <router-link tag="li" :to="{ name: 'MyRestaurants' }">
        <a class="btn btn-success">Go back to my restaurant(s)</a>
      </router-link>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import { mapState, mapMutations, mapGetters } from "vuex";
import Navbar from "@/components/Header/Navbar.vue";
export default {
  name: "UpdateCurrentRestaurant",
  components: {
    Navbar,
  },
  data() {
    return {
      user: {
        uId: "",
        userId: "",
        uName: "",
        uEmail: "",
        uPassword: "",
        uMemberSince: "",
        uAddress: "",
        uPhone: "",
        uRole: "",
        uRoleIs: "",
      },
      //Restaurant Info from URL
      restId: this.$route.params.restId,
      restNum: this.$route.params.id,
      //address
      userAddress: "",
      userAddressErr: false,
      userAddressMsg: "",
      isuserAddressValidated: false,
      //phone
      userPhone: "",
      userPhoneErr: false,
      userPhoneMsg: "",
      isuserPhoneValidated: false,
      //name
      userName: "",
      userNameErr: false,
      userNameMsg: "",
      isuserNameValidated: false,

      resultSuccess: false,
      resultSuccessMsg: "",
      resultErr: false,
      resultErrMsg: "",
      image: "",
      is_DB_Connected: "",
      db_connection_msg: "",
      // apiMainUrl: "http://localhost/php/login-system-php-vue-2021/myData.php",
      apiMainUrl: this.$store.getters.MainUrl, //call global variable
      signUpBtn: true,
      isRestExists: false,
    };
  },
  mounted() {
    let user = localStorage.getItem("current-user-data");
    let currentRestaurants = localStorage.getItem("current_restaurants_data");
    let currentRestaurant = JSON.parse(currentRestaurants).filter(
      (restInfo) =>
        restInfo.id == this.restNum && restInfo.rest_id == this.restId
    );
    console.log(currentRestaurant);
    if (user && currentRestaurants) {
      //set rest info
      if (currentRestaurant.length > 0) {
        this.isRestExists = true;
      }
      this.userPhone = currentRestaurant[0].rest_phone;
      this.userName = currentRestaurant[0].rest_name;
      this.userAddress = currentRestaurant[0].rest_address;
      //get users info
      this.user.uId = JSON.parse(user).id;
      this.user.userId = JSON.parse(user).user_id;
      this.user.uName = JSON.parse(user).name;
      this.user.uEmail = JSON.parse(user).email;
      this.user.uPassword = JSON.parse(user).password;
      this.user.uMemberSince = JSON.parse(user).member_since;
      this.user.uAddress = JSON.parse(user).full_address;
      this.user.uPhone = JSON.parse(user).phone_number;
      this.user.uRole = JSON.parse(user).role;
      //if role isn't manager or admin, redirect
      if (currentRestaurant[0].active == 0) {
        //if restaurant isn't active
        this.$router.push({ name: "MyRestaurants" });
      } else if (
        JSON.parse(user).role !== "mngr" &&
        JSON.parse(user).role !== "admn"
      ) {
        this.$router.push({ name: "Login" });
      }
    } else {
      this.$router.push({ name: "Login" });
    }
  },
  computed: {
    ...mapState([
      "__RestaurantId",
      "__RestaurantEmail",
      "__RestaurantRole",
      "__RestaurantName",
      "__MainUrl",
    ]),
    ...mapGetters(["MainUrl"]),
  },
  methods: {
    ...mapMutations(["setRestaurantInfo"]),
    //validate Restaurant Name
    validateRestaurantName(e) {
      let val = e.target.value;
      this.validateRestaurantNameInput(val);
    },
    validateRestaurantNameInput(val) {
      // let userNameRegex = /^[a-zA-Z ]+$/; //allow spaces
      // let userNameRegex = /[^A-Za-z ]/g; //allow spaces, international letters
      // console.log("Is Restaurant Name Validated? " + userNameRegex.test(val));
      if (val == "") {
        this.userNameErr = true;
        this.isuserNameValidated = false;
        this.userNameMsg = "Must Enter Restaurant Name ..";
      } else {
        this.userNameErr = false;
        this.isuserNameValidated = true;
        this.userNameMsg = "";
      }
    },
    //validate Restaurant Name
    validateRestaurantPhone(e) {
      let val = e.target.value;
      this.validateRestaurantPhoneInput(val);
    },
    validateRestaurantPhoneInput(val) {
      // let userPhoneRegex = /^\d{10}$/;
      console.log("Restaurant Phone: " + val);
      if (val == "") {
        this.userPhoneErr = true;
        this.isuserPhoneValidated = false;
        this.userPhoneMsg = "Must Enter Restaurant Phone ..";
      } else if (val !== "" && (val.length > 11 || val.length < 10)) {
        this.userPhoneErr = true;
        this.isuserPhoneValidated = false;
        this.userPhoneMsg = "Must Only Enter 9-11 Digits ..";
      } else {
        this.userPhoneErr = false;
        this.isuserPhoneValidated = true;
        this.userPhoneMsg = "";
      }
    },
    //validate Restaurant Address
    validateRestaurantAddress(e) {
      let val = e.target.value;
      this.validateRestaurantAddressInput(val);
    },
    validateRestaurantAddressInput(val) {
      // let userAddressRegex = /^[a-zA-Z0-9 \n]+$/;
      // console.log("Is Restaurant Address Validated? " + userAddressRegex.test(val));
      console.log("Restaurant Address Is: " + val);
      if (val == "") {
        this.userAddressErr = true;
        this.isuserAddressValidated = false;
        this.userAddressMsg = "Must Enter Restaurant Address ..";
      } else {
        this.userAddressErr = false;
        this.isuserAddressValidated = true;
        this.userAddressMsg = "";
      }
    },
    async UpdateCurrentRestaurant() {
      this.resultErr = false;
      this.resultErrMsg = "";
      this.resultSuccess = true;
      this.resultSuccessMsg =
        "Please wait while we're updating current restaurant data...";
      this.validateRestaurantNameInput(this.userName);
      this.validateRestaurantPhoneInput(this.userPhone);
      this.validateRestaurantAddressInput(this.userAddress);
      if (
        this.isuserNameValidated &&
        this.isuserPhoneValidated &&
        this.isuserAddressValidated
      ) {
        this.signUpBtn = false;
        //show Result
        let fd = new FormData();
        fd.append("userId", this.user.userId);
        fd.append("name", this.userName);
        fd.append("phone", this.userPhone);
        fd.append("address", this.userAddress);
        fd.append("restId", this.restId);
        fd.append("restNum", this.restNum);
        let rst = await axios.post(
          this.apiMainUrl + `?action=UpdateCurrentRestaurant`,
          fd
        );
        console.log(rst);
        const resultData = rst.data;
        if (rst.status == 200) {
          if (resultData.error) {
            console.warn("Connection Error Happened on Adding New Restaurant");
            this.resultErrMsg = resultData.message;
            this.resultErr = true;
            this.resultSuccessMsg = "";
            this.resultSuccess = false;
            this.signUpBtn = true;
          } else {
            this.signUpBtn = false;
            console.log("Good Job, resrautant has been added successfully..");
            //if everything is okay
            this.resultErrMsg = "";
            this.resultErr = false;
            this.resultSuccess = true;
            //set restaurant to local host: current_restaurants_data
            localStorage.setItem(
              "current_restaurants_data",
              JSON.stringify(resultData.current_restaurants_data)
            );
            //redirect only if customer
            this.resultSuccessMsg = resultData.message;
            setTimeout(() => {
              this.$router.push({ name: "MyRestaurants" });
              console.log("Restaurant Name: " + this.userName);
            }, 2000);
          }
        }
      } else {
        this.signUpBtn = true;
        this.validateRestaurantNameInput(this.userName);
        this.validateRestaurantPhoneInput(this.userPhone);
        this.validateRestaurantAddressInput(this.userAddress);
        this.resultSuccessMsg = "";
        this.resultSuccess = false;

        this.resultErrMsg = "Must enter all required information";
        this.resultErr = true;
      }
    },
  },
};
</script>
<style lang="scss" scoped>
$navColor: #3f51b6;

nav a {
  text-decoration: none;
  color: $navColor;
  font-weight: bold;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}
</style>
