<template>
    <div class="container">
        <Navbar />
        <nav style="--bs-breadcrumb-divider: '>'" aria-label="breadcrumb">
            <ol class="breadcrumb">
                <li class="breadcrumb-item">
                    <router-link tag="li" :to="{ name: 'MyRestaurants' }">
                        <a>My Restaurant(s)</a>
                    </router-link>
                </li>
                <li v-if="this.isRestExists" class="breadcrumb-item active" aria-current="page">
                    {{ this.businessName }}
                </li>
                <li v-if="this.isRestExists" class="breadcrumb-item active" aria-current="page">
                    Create
                </li>
            </ol>
        </nav>
        <div v-if="this.isRestExists" class="add-new-restaurant-page">
            <h3>
                <faIcons :icon="['fas', 'house-user']" />
                {{ capitalizeFisrtLetterOfEachWord(businessName) }}
            </h3>
            <p class="text-muted">
                <faIcons :icon="['fas', 'map-marker']" /> {{ businessAddress }}
            </p>
            <p class="text-muted">Create a new payment record/report</p>


            <div class="row g-3 align-items-center mb-3">
                <div class="col-auto d-block mx-auto">
                    <h4 class="text-center">Create Payment Report</h4>
                    <div class="form-floating" :class="{ 'form-data-error': startErr }">
                        <input type="date" id="startInput" placeholder="Starting Date" class="form-control w300"
                            v-model.trim="start" />
                        <label for="startInput">
                            <faIcons :icon="['fas', 'calendar']" /> Starting Date
                        </label>
                        <span class="error-feedback" v-if="startErr">
                            {{ startMsg }}
                        </span>
                    </div>
                </div>
            </div>

            <div class="row g-3 align-items-center mb-3">
                <div class="col-auto d-block mx-auto">
                    <div class="form-floating" :class="{ 'form-data-error': endErr }">
                        <input type="date" id="endInput" placeholder="Ending Date" class="form-control w300"
                            v-model.trim="end" />
                        <label for="endInput">
                            <faIcons :icon="['fas', 'calendar']" /> Ending Date
                        </label>
                        <span class="error-feedback" v-if="endErr">
                            {{ endMsg }}
                        </span>
                    </div>
                </div>
            </div>
            <div class="row g-3 align-items-center mb-3">
                <div class="col-auto d-block mx-auto">
                    <div class="form-floating" :class="{ 'form-data-error': totalPaymentErr }">
                        <input type="text"
                            oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*)\./g, '$1');"
                            id="totalPaymentInput" placeholder="Total Payment Amonut" class="noResize form-control w300"
                            v-model.trim="totalPayment" />
                        <label for="totalPaymentnput">
                            <faIcons :icon="['fas', 'dollar-sign']" /> Total Payment Amonut
                        </label>
                        <span class="d-flex text-muted smallerSize">Amount Ex: 1540.49, 150, 0.00</span>
                        <span class="error-feedback" v-if="totalPaymentErr">
                            {{ totalPaymentMsg }}
                        </span>
                    </div>
                </div>
            </div>
            <div class="row g-3 align-items-center mb-3">
                <div class="col-auto d-block mx-auto">
                    <div class="form-floating" :class="{ 'form-data-error': notesErr }">
                        <input type="text" id="notesInput" placeholder="Add Payment Comment - Optional"
                            class="form-control w300" v-model.trim="notes" />
                        <label for="notesInput">
                            <faIcons :icon="['fas', 'comment-dollar']" /> Add Comment - Optional
                        </label>
                        <span class="error-feedback" v-if="notesErr">
                            {{ notesMsg }}
                        </span>
                    </div>
                </div>
            </div>
            <div class="row d-grid gap-2 w300 col-auto d-block mx-auto m-2">
                <button class="btn btn-outline-success" @click.prevent="createPaymentReport()" v-if="signUpBtn">
                    <faIcons :icon="['fas', 'dollar-sign']" /> Create Payment Report
                </button>
            </div>
            <div class="row d-grid gap-2 w300 col-auto d-block mx-auto m-2">
                <div class="alert alert-success" role="alert" v-if="resultSuccess">
                    {{ resultSuccessMsg }}
                </div>
                <div class="alert alert-danger" role="alert" v-if="resultErr">
                    {{ resultErrMsg }}
                </div>
            </div>
            <div class="row d-grid gap-2 w300 col-auto d-block mx-auto m-2">
                <button class="btn btn-outline-primary" @click.prevent="this.$router.go(-1)">
                    <faIcons :icon="['fas', 'undo']" /> Go Back
                </button>
            </div>
        </div>
        <div v-else>
            <!-- Show error if no restaurant matches this Criteria -->
            <div class="alert alert-dark" role="alert">
                <p>
                    No restaurant found. Make sure you click in the right restaurant name.
                </p>
            </div>
            <router-link tag="li" :to="{ name: 'MyRestaurants' }">
                <a class="btn btn-success">Go back to my restaurant(s)</a>
            </router-link>
        </div>
    </div>
</template>

<script>
import axios from "axios";
import { mapState, mapMutations, mapGetters } from "vuex";
import Navbar from "@/components/Header/Navbar.vue";
export default {
    name: "CreatePaymentRecord",
    components: {
        Navbar,
    },
    data() {
        return {
            user: {
                uId: "",
                userId: "",
                uName: "",
                uEmail: "",
                uPassword: "",
                uMemberSince: "",
                uAddress: "",
                uPhone: "",
                uRole: "",
                uRoleIs: "",
            },
            restAcceptingOrderStatus: "",
            //cat
            catPrice: "",
            catPriceErr: false,
            catPriceMsg: "",
            iscatPriceValidated: false,
            //is my restaurant accepting online orders
            isMenuActive: "",
            isMenuActiveErr: false,
            isMenuActiveSuccess: false,
            isMenuActiveMsg: "Restaurant is now accepting online orders",
            isMenuActiveValidated: false,
            //Restaurant Info from URL
            restId: this.$route.params.restId,
            restNum: this.$route.params.id,
            //phone
            end: new Date(
                new Date().getTime() - new Date().getTimezoneOffset() * 60000
            )
                .toISOString()
                .split("T")[0],
            endErr: false,
            endMsg: "",
            isendValidated: false,
            //name
            start: new Date(
                new Date().getTime() - new Date().getTimezoneOffset() * 60000
            )
                .toISOString()
                .split("T")[0],
            startErr: false,
            startMsg: "",
            isstartValidated: false,
            //email
            notes: "",
            notesErr: false,
            notesMsg: "",
            isnotesValidated: false,
            //address
            totalPayment: "",
            totalPaymentErr: false,
            totalPaymentMsg: "",
            istotalPaymentValidated: false,
            //show result
            resultSuccess: false,
            resultSuccessMsg: "",
            resultErr: false,
            resultErrMsg: "",
            //show onlnie hours result
            onlineHoursResultSuccess: false,
            onlineHoursResultSuccessMsg: "",
            onlineHoursResultErr: false,
            onlineHoursResultErrMsg: "",
            image: "",
            is_DB_Connected: "",
            db_connection_msg: "",
            // apiMainUrl: "http://localhost/php/login-system-php-vue-2021/myData.php",
            apiMainUrl: this.$store.getters.MainUrl, //call global variable
            signUpBtn: true,
            acceptOrdersBtn: true,
            onlineHoursBtn: true,
            isRestExists: true,
            businessPhone: "",
            businessName: "",
            businessAddress: "",
            restaurant_added_by: "",
        };
    },
    mounted() {
        let user = localStorage.getItem("current-user-data");
        let currentRestaurants = localStorage.getItem("current_restaurants_data");
        let currentRestaurant = JSON.parse(currentRestaurants).filter(
            (restInfo) =>
                restInfo.id == this.restNum && restInfo.rest_id == this.restId
        );
        if (currentRestaurant.length > 0) {
            // console.table(currentRestaurantBankDetails.routing_number);
            //set rest info
            this.businessPhone = currentRestaurant[0].rest_phone;
            this.businessName = currentRestaurant[0].rest_name;
            this.businessAddress = currentRestaurant[0].rest_address;
            this.restaurant_added_by = currentRestaurant[0].added_by;
            this.isRestExists = true;
            // this.end = currentRestaurantBankDetails[0].routing_number;
            // this.notes = this.capitalizeFisrtLetterOfEachWord(
            //     currentRestaurantBankDetails[0].bank_name
            // );
            // this.totalPayment = currentRestaurantBankDetails[0].ein_number;
            // this.start = currentRestaurantBankDetails[0].bank_number;
            //get users info
            this.user.uId = JSON.parse(user).id;
            this.user.userId = JSON.parse(user).user_id;
            this.user.uName = JSON.parse(user).name;
            this.user.uEmail = JSON.parse(user).email;
            this.user.uPassword = JSON.parse(user).password;
            this.user.uMemberSince = JSON.parse(user).member_since;
            this.user.uAddress = JSON.parse(user).full_address;
            this.user.uPhone = JSON.parse(user).phone_number;
            this.user.uRole = JSON.parse(user).role;
            //if role isn't manager or admin, redirect
            if (
                JSON.parse(user).role !== "admn"
            ) {
                this.$router.push({ name: "MyRestaurants" });
            }
        } else {
            this.$router.push({ name: "MyRestaurants" });
        }
    },
    computed: {
        ...mapState([
            "__RestaurantId",
            "__RestaurantEmail",
            "__RestaurantRole",
            "__RestaurantName",
            "__MainUrl",
        ]),
        ...mapGetters(["MainUrl"]),
    },
    methods: {
        ...mapMutations(["setRestaurantInfo"]),
        capitalizeFisrtLetterOfEachWord(text) {
            return text
                .toLowerCase()
                .split(" ")
                .map((s) => s.charAt(0).toUpperCase() + s.substring(1))
                .join(" ");
        },
        formatPhoneNumber(number) {
            //remove . + -  from any number
            let cleaned = ("" + number).replace(/\D/g, "");
            let match = "";
            if (number.length == 10) {
                match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
            } else if (number.length == 9) {
                match = cleaned.match(/^(\d{3})(\d{3})(\d{3})$/);
            } else if (number.length == 11) {
                match = cleaned.match(/^(\d{3})(\d{4})(\d{4})$/);
            } else if (number.length == 8) {
                match = cleaned.match(/^(\d{2})(\d{3})(\d{3})$/);
            }
            if (match) {
                return "(" + match[1] + ") " + match[2] + "-" + match[3];
            }
            return null;
        },
        validatestart(e) {
            let val = e.target.value;
            this.validatestartInput(val);
        },
        validatestartInput(val) {
            if (val == "") {
                this.startErr = true;
                this.isstartValidated = false;
                this.startMsg = "Choose starting date!";
            } else if (val != "" && this.end < this.start) {
                this.endErr = true;
                this.isstartValidated = false;
                this.startMsg = "Select same/higher starting date";
            } else {
                this.endErr = false;
                this.isstartValidated = true;
                this.startMsg = "";
            }
        },
        validateend(e) {
            let val = e.target.value;
            this.validateendInput(val);
        },
        validateendInput(val) {
            if (val == "") {
                this.endErr = true;
                this.isendValidated = false;
                this.endMsg = "Choose ending date!";
            } else if (val != "" && this.end < this.start) {
                this.endErr = true;
                this.isendValidated = false;
                this.endMsg = "Select same/higher ending date";
            } else {
                this.endErr = false;
                this.isendValidated = true;
                this.endMsg = "";
            }
        },
        validatenotes(e) {
            let val = e.target.value;
            this.validatenotesInput(val);
        },
        validatenotesInput(val) {
            if (val == "") {
                this.notesErr = false;
                this.isnotesValidated = true;
                this.notesMsg = "";
            }
        },
        validatetotalPayment(e) {
            let val = e.target.value;
            this.validatetotalPaymentInput(val);
        },
        validatetotalPaymentInput(val) {
            if (val == "") {
                this.totalPaymentErr = true;
                this.istotalPaymentValidated = false;
                this.totalPaymentMsg = "Enter total payment amount";
            } else {
                this.totalPaymentErr = false;
                this.istotalPaymentValidated = true;
                this.totalPaymentMsg = "";
            }
        },
        async createPaymentReport() {
            this.validatestartInput(this.start);
            this.validateendInput(this.end);
            // this.validatenotesInput(this.notes);
            this.validatetotalPaymentInput(this.totalPayment);
            if (
                this.istotalPaymentValidated &&
                this.isstartValidated &&
                this.isendValidated
            ) {
                this.resultErrMsg = "";
                this.resultErr = false;
                this.resultSuccess = true;
                this.resultSuccessMsg = "Wait while we're creating a payment report...";
                this.signUpBtn = false;
                //show Result
                let fd = new FormData();
                fd.append("start", this.start);
                fd.append("end", this.end);
                fd.append("notes", this.notes);
                fd.append("totalPayment", parseFloat(this.totalPayment).toFixed(2));
                fd.append("userId", this.user.userId);
                fd.append("restId", this.restId);
                fd.append("restAddedBy", this.restaurant_added_by);
                let rst = await axios.post(
                    this.apiMainUrl + `?action=createPaymentReport`,
                    fd
                );
                console.log(rst);
                const resultData = rst.data;
                if (rst.status == 200) {
                    if (resultData.error) {
                        console.warn("Connection Error Happened on CREATING new report");
                        this.resultErrMsg = resultData.message;
                        this.resultErr = true;
                        this.resultSuccessMsg = "";
                        this.resultSuccess = false;
                        this.signUpBtn = true;
                    } else {
                        this.signUpBtn = false;
                        console.log("Good Job, you have Created new report...");
                        //if everything is okay
                        this.resultErrMsg = "";
                        this.resultErr = false;
                        this.resultSuccess = true;
                        this.resultSuccessMsg = resultData.message;
                        //set restaurant to local host: current_restaurants_data
                        localStorage.setItem(
                            "created_payment_records",
                            JSON.stringify(resultData.created_payment_records)
                        );
                        //redirect only if customer
                        setTimeout(() => {
                            // this.$router.push({ name: "MyPayment" });
                            console.log("Bank Name is updated: " + this.notes);
                        }, 5000);
                    }
                }
            } else {
                this.signUpBtn = true;
                this.validatestartInput(this.start);
                this.validateendInput(this.end);
                this.validatetotalPaymentInput(this.totalPayment);
                // this.validatenotesInput(this.notes);
                this.resultSuccessMsg = "";
                this.resultSuccess = false;
                this.resultErrMsg = "Must fill in all information";
                this.resultErr = true;
            }
        },
    },
};
</script>
<style lang="scss" scoped>
$navColor: #3f51b6;

.form-data-success {
    color: darkgreen;
}

.smallerSize {
    font-size: 0.75em;
}

nav a {
    text-decoration: none;
    color: $navColor;
    font-weight: bold;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

/* Firefox */
input[type="number"] {
    -moz-appearance: textfield;
}

.w100 {
    width: 100px;
}

.bgBlue {
    background-color: #0d6efd;
    color: white;
    padding: 10px;
    border-radius: 7px;
}

.addBorder {
    border-bottom: 1px solid #ced4da;
}

.p4 {
    padding: 4px;
}
</style>
