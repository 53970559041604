<template>
  <div>
    <div class="modal fade" tabindex="-1" :id="'showMenuItem' + currentMenuItem(itemData)[0].item_rand_id"
      :aria-labelledby="currentMenuItem(itemData)[0].item_rand_id" aria-hidden="true">
      <div class="modal-dialog modal-dialog-scrollable">
        <div class="modal-content">
          <div class="modal-header">
            Custom your order
            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
          </div>
          <div class="modal-body itemDataDetails">
            <h4 class="f-bold titleBrown">
              {{ capitalizeFisrtLetterOfEachWord(currentMenuItem(itemData)[0].item_name) }}
              <span class="float-end" v-if="currentMenuItem(itemData)[0].item_price != '0.00'">{{
    number_format(currentMenuItem(itemData)[0].item_price)
}}</span>
            </h4>
            <!-- <img :src="getImageUrl(currentMenuItem(itemData)[0].item_image)" class="rounded d-block mx-auto img-fluid" -->
            <!-- <div v-if="currentMenuItem(itemData)[0].item_image.length > 0">
              <img
                :src="
                  'https://order.hooksfishnchips.com/restaurants/' +
                  currentMenuItem(itemData)[0].item_image
                "
                class="rounded d-block mx-auto img-fluid"
                :title="currentMenuItem(itemData)[0].item_name"
                :alt="currentMenuItem(itemData)[0].item_name"
              />
            </div>
            <div v-else>
              <img
                :src="getImageUrl('no-image.png')"
                class="rounded d-block mx-auto img-fluid"
                :title="currentMenuItem(itemData)[0].item_name"
                :alt="currentMenuItem(itemData)[0].item_name"
              />
            </div> -->
            <p class="text-muted bw text-center">
              {{ currentMenuItem(itemData)[0].item_description.toUpperCase() }}
            </p>
            <div class="clearfix"></div>
            <!-- <hr class="bg-info" /> -->
            <div class="custom-item-menu">
              <div :id="'accordion' + itemData">
                <div class="card mb-3" v-for="(c, index) in currentItemModifiersAre" :key="c.group_name_rand_id">
                  <div class="card-header" v-if="c[0]">
                    <a class="btn" :class="
  currentMenuModifiersIds[0] == c[0].group_name_rand_id
    ? ''
    : 'collapsed'
" data-bs-toggle="collapse" :href="
  '#accordion-' + itemData + '-' + c[0].group_name_rand_id
">
                      <faIcons :icon="['fas', 'caret-right']" />
                      {{ capitalizeFisrtLetterOfEachWord(c[0].group_name) }}
                    </a>
                  </div>
                  <div v-if="c[0]" :id="
  'accordion-' + itemData + '-' + c[0].group_name_rand_id
" class="collapse" :class="
  currentMenuModifiersIds[0] == c[0].group_name_rand_id
    ? 'show'
    : ''
" :data-bs-parent="'#accordion' + itemData">
                    <div class="card-body mb-3">
                      <p v-if="c[0].max_selection == 'all'" class="text-muted">
                        Select all you like:
                      </p>
                      <p v-if="c[0].max_selection != 'all'" class="text-muted">
                        Select only {{ c[0].max_selection }} of below options:
                      </p>
                      <form :id="'form-' + itemData + '-' + c[0].group_name_rand_id">
                        <span v-if="
  selections[index]['options'].length >=
  c[0].max_selection
" @click="
  selections[index]['options'] = [];
resetForm(
  'form-' + itemData + '-' + c[0].group_name_rand_id
);
"><button class="btn btn-outline-primary mb-3" type="reset">
                            Reset
                          </button></span>
                        <div class="form-check form-switch" v-for="e in eachModifierData(c[0].group_name_rand_id)"
                          :key="e.group_items_names_rand_id">
                          <input class="form-check-input" type="checkbox" v-if="c[0].max_selection == 'all'"
                            :id="itemData + '-' + e.group_items_names_rand_id" name="darkmode" :class="
  itemData + '-' + e.group_items_names_rand_id
" @change="
  addToSelectionMaxAll(
    $event,
    index,
    e.item_name,
    e.item_price,
    e.group_items_names_rand_id
  )
" />
                          <input v-else class="form-check-input" type="checkbox"
                            :id="itemData + '-' + e.group_items_names_rand_id" name="darkmode" :class="
  itemData + '-' + e.group_items_names_rand_id
" @change="
  addToSelection(
    index,
    e.item_name,
    e.item_price,
    e.group_items_names_rand_id,
    c[0].max_selection
  )
" :disabled="
  selections[index]['options'].length >=
  c[0].max_selection
" />
                          <label class="form-check-label" :for="itemData + '-' + e.group_items_names_rand_id">{{
    capitalizeFisrtLetterOfEachWord(e.item_name)
}}
                            <span class="darkred" v-if="e.item_price > 0">+ {{ number_format(e.item_price)
}}</span></label>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="mb-3">
              <div class="d-block text-center" v-if="currentMenuItem(itemData)[0].item_price != '0.00'">
                <button class="btn btn-outline-primary" type="button" @click="
  addToMyCartCustom(
    currentMenuItem(itemData)[0].item_name,
    currentMenuItem(itemData)[0].item_price
  )
" data-bs-dismiss="modal" aria-label="Close">
                  <faIcons :icon="['fas', 'cart-plus']" /> Add to Order
                </button>
              </div>
              <div class="d-block text-center"
              v-if="selections.length > 0"
                >
                <button v-if="currentMenuItem(itemData)[0].item_price == '0.00' || selections[0]['options'].length > 0" class="btn btn-outline-primary" type="button" @click="
  addToMyCartCustom(
    currentMenuItem(itemData)[0].item_name,
    currentMenuItem(itemData)[0].item_price
  )
" data-bs-dismiss="modal" aria-label="Close">
                  <faIcons :icon="['fas', 'cart-plus']" /> Add to Order
                </button>
              </div>
              <div class="clearfix"></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "EachItemModifications",
  props: ["itemData", "restId"],
  //   "currentOrder",
  //   "cartTotal",
  //   "isOrderPlaced",
  //   "addedMeal",
  // ],
  data() {
    return {
      isOrderPlaced: this.$store.state.__IsOrderPlaced,
      addedMeal: this.$store.state.__AddedMealName,
      // myCartTotal: this.cartTotal,
      // currentOrderArr: [],
      // myIsOrderPlaced: this.isOrderPlaced,
      // myAddedMeal: this.addedMeal,
      myRestId: this.restId,
      currentMenuModifiersIds: [],
      currentItemModifiersAre: [],
      eachModifiersData: [],
      selections: [],
    };
  },
  mounted() {
    let currentItemData = this.currentMenuItem(this.itemData)[0];
    console.log(currentItemData.modifiers_ids);
    let newModifiersIds = currentItemData.modifiers_ids.split(",");
    console.log(
      "the length for current items modifiers are " + newModifiersIds.length
    );
    console.table(newModifiersIds);
    this.currentMenuModifiersIds = newModifiersIds;
    let newModifiersName = []; //this holds all modifiers assigned to each item

    //console all modications for current item
    let currentMenuModifiers = localStorage.getItem(
      "current_user_menu_modifiers_data_public"
    );
    for (var x = 0; x < newModifiersIds.length; x++) {
      newModifiersName.push(
        JSON.parse(currentMenuModifiers).filter(
          (restInfo) => restInfo.group_name_rand_id == newModifiersIds[x]
        )
      );
      this.selections.push({
        id: newModifiersIds[x],
        options: [],
      });
    }
    console.table(newModifiersName);
    this.currentItemModifiersAre = newModifiersName;
  },
  methods: {
    addToMyCartCustom(name, price) {
      var currentOrderArr = JSON.parse(
        localStorage.getItem("current_customer_order")
      );
      this.$store.state.__AddedMealName = name;
      this.$store.state.__IsOrderPlaced = true;
      setTimeout(() => {
        this.$store.state.__IsOrderPlaced = false;
        console.log("hi");

        //calculate total
        let myOptionsSelectedItems = [];
        let mySelections = this.selections;
        let totalCustomOrder = parseFloat(0);
        for (let i = 0; i < mySelections.length; i++) {
          let myOptions = mySelections[i]["options"];
          for (let m = 0; m < myOptions.length; m++) {
            let myPrice = parseFloat(myOptions[m]["price"]);
            let myName = myOptions[m]["name"];
            totalCustomOrder += myPrice;
            //now add name and price for each selected item to my object
            myOptionsSelectedItems.push({ name: myName, price: myPrice });
          }
        }
        let k = {
          name: name,
          price: parseFloat(price),
          isCustom: true,
          options: myOptionsSelectedItems,
          custom_price: parseFloat(totalCustomOrder),
          total_item_cost: (
            parseFloat(totalCustomOrder) + parseFloat(price)
          ).toFixed(2),
        };
        currentOrderArr["total"] = (
          parseFloat(this.$store.state.__CartTotal) +
          parseFloat(k["total_item_cost"])
        ).toFixed(2);
        currentOrderArr["taxes"] = parseFloat(
          (parseFloat(currentOrderArr["total"]) *
            parseFloat(currentOrderArr["taxes_percentage"])) /
          100
        ).toFixed(2);
        currentOrderArr["sub_total"] = parseFloat(
          parseFloat(currentOrderArr["total"]) +
          parseFloat(currentOrderArr["taxes"])
        ).toFixed(2);
        this.$store.state.__CartTotal = parseFloat(currentOrderArr["total"]);
        currentOrderArr["order"].push(k);
        console.log(currentOrderArr["order"]);
        // currentOrderArr["order"].push(k);
        // currentOrderArr["total"] = parseFloat(currentOrderArr["total"]);
        // currentOrderArr["taxes"] = parseFloat(currentOrderArr["taxes"]);
        // currentOrderArr["sub_total"] = parseFloat(
        //   currentOrderArr["sub_total"]
        // );
        localStorage.setItem(
          "current_customer_order",
          JSON.stringify(currentOrderArr)
        );
      }, 2000); //hold for 3 seconds
      console.log(`type of price is ${typeof Number(price)}`);
      console.log(
        `You are adding this item to your menu ${name} at cost $${price}`
      );
    },
    capitalizeFisrtLetterOfEachWord(text) {
      return text
        .toLowerCase()
        .split(" ")
        .map((s) => s.charAt(0).toUpperCase() + s.substring(1))
        .join(" ");
    },
    number_format(amount) {
      const price = amount;
      // Format the price above to USD, INR, EUR using their locales.
      let dollarUS = Intl.NumberFormat("en-US", {
        style: "currency",
        currency: "USD",
      });
      return dollarUS.format(price);
    },
    resetForm(formId) {
      console.log(formId);
      document.getElementById(formId).reset();
    },
    addToSelection(i, name, price, modifierItemId, max) {
      this.selections[i]["options"].push({
        name: name,
        price: price,
        modifierItemId: modifierItemId,
      });
      console.log(i);
      console.log(name);
      console.log(price);
      console.log(modifierItemId);
      console.log(max);
    },
    addToSelectionMaxAll(e, i, name, price, modifierItemId) {
      if (e.target.checked) {
        console.log("the value of selected item is: " + e.target.checked);
        this.selections[i]["options"].push({
          name: name,
          price: price,
          modifierItemId: modifierItemId,
        });
        console.table(this.selections[i]["options"]);
      } else {
        //current item is not checked
        console.log("current item is not checked");
        let myArr = this.selections[i]["options"].filter(
          (e) => e["modifierItemId"] !== modifierItemId
        );
        this.selections[i]["options"] = myArr;
        console.table(myArr);
      }
      // if (this.selections[i]["options"].length > 0) {
      //   for (let x = 0; x < this.selections[i]["options"].length; x++) {
      //     if (
      //       this.selections[i]["options"][x]["modifierItemId"] == modifierItemId
      //     ) {
      //       //yes this item is already selected
      //       console.log("this item is already selected");
      //     } else {
      //       console.log("this item is not selected yet");
      //       this.selections[i]["options"].push({
      //         name: name,
      //         price: price,
      //         modifierItemId: modifierItemId,
      //       });
      //     }
      //   }
      // } else {
      //   console.log("this item is not yet selected");
      //   this.selections[i]["options"].push({
      //     name: name,
      //     price: price,
      //     modifierItemId: modifierItemId,
      //   });
      // }
      // console.log(i);
      // console.log(name);
      // console.log(price);
      // console.log(modifierItemId);
      // console.log(max);
    },
    convertNumToPrice(num) {
      return new Intl.NumberFormat("en-US", {
        style: "currency",
        currency: "USD",
      }).format(num);
    },
    getImageUrl(imgName) {
      let img = require.context("@/../restaurants/");
      console.log(img);
      return img("./" + imgName);
    },
    currentMenuItem(itemId) {
      let currentData = JSON.parse(
        localStorage.getItem("current_user_menu_items_data_public")
      );
      let newData = currentData.filter(
        (restInfo) => restInfo.item_rand_id == itemId
      );
      return newData;
    },
    eachModifierData(modId) {
      let currentModifierDataIs = localStorage.getItem(
        "current_user_menu_modifiers_items_data_public"
      );
      let data = [];
      data = JSON.parse(currentModifierDataIs).filter(
        (restInfo) => restInfo.group_rand_id == modId
      );
      return data;
    },
  },
};
</script>

<style>
.itemDataDetails {
  padding: 10px;
  margin-bottom: 10px;
  border: 10px solid #eee;
}

.itemDataDetails img {
  width: 320px;
  height: 215px;
  margin-bottom: 10px;
}

.bw {
  overflow-wrap: break-word;
}

.darkred {
  color: darkred;
}

.card-header {
  background-color: #008c5d !important;
  font-weight: bold;
}

.card-header a,
.card-header a:hover,
.card-header a:visited,
.card-header a:active,
.card-header a:focus {
  color: white;
  display: block;
  text-align: left;
  border: none;
}

.card-header .btn-check:focus+.btn,
.card-header .btn:focus {
  box-shadow: none !important;
}
</style>
