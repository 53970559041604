<template>
  <div class="container-fluid">
    <Navbar />
    <nav style="--bs-breadcrumb-divider: '>'" aria-label="breadcrumb">
      <ol class="breadcrumb">
        <li class="breadcrumb-item">
          <router-link tag="li" :to="{ name: 'Home' }">
            <a>Home</a>
          </router-link>
        </li>
        <li class="breadcrumb-item active" aria-current="page">
          Incoming Messages
        </li>
      </ol>
    </nav>
    <div class="add-new-restaurant-page">
      <h1 class="text-center">
        Current Messages ({{ users_messagesArr.length }})
      </h1>
      <div class="form-check form-check-inline">
        <input class="form-check-input" type="checkbox" id="userName" v-model="userName" />
        <label class="form-check-label" for="userName">Name</label>
      </div>
      <div class="form-check form-check-inline">
        <input class="form-check-input" type="checkbox" id="userPhone" v-model="userPhone" />
        <label class="form-check-label" for="userPhone">Phone</label>
      </div>
      <div class="form-check form-check-inline">
        <input class="form-check-input" type="checkbox" id="userEmail" v-model="userEmail" />
        <label class="form-check-label" for="userEmail">Email</label>
      </div>
      <div class="form-check form-check-inline">
        <input class="form-check-input" type="checkbox" id="userAddress" v-model="userAddress" />
        <label class="form-check-label" for="userAddress">Message</label>
      </div>
      <div class="form-check form-check-inline">
        <input class="form-check-input" type="checkbox" id="userRegisteredOn" v-model="userRegisteredOn" />
        <label class="form-check-label" for="userRegisteredOn">Sent On</label>
      </div>
      <div class="form-check form-check-inline">
        <input class="form-check-input" type="checkbox" id="userActive" v-model="userActive" />
        <label class="form-check-label" for="userActive">Active</label>
      </div>
      <div class="form-check form-check-inline">
        <input class="form-check-input" type="checkbox" id="userRole" v-model="userRole" />
        <label class="form-check-label" for="userRole">Subject</label>
      </div>
      <div class="form-check form-check-inline">
        <input class="form-check-input" type="checkbox" id="userIP" v-model="userIP" />
        <label class="form-check-label" for="userIP">IP</label>
      </div>
      <div class="table-responsive">
        <table class="table">
          <thead class="table-success">
            <tr>
              <th class="header" scope="col" v-if="userId">ID</th>
              <th class="header" scope="col" v-if="userName">Name</th>
              <th class="header" scope="col" v-if="userPhone">Phone</th>
              <th class="header" scope="col" v-if="userRole">Subject</th>
              <th class="header" scope="col" v-if="userEmail">Email</th>
              <th class="header" scope="col" v-if="userAddress">Message</th>
              <th class="header" scope="col" v-if="userRegisteredOn">
                Sent On
              </th>
              <th class="header" scope="col" v-if="userIP">IP</th>
              <th class="header" scope="col" v-if="userActive">Active</th>
            </tr>
          </thead>
          <tbody v-if="users_messagesArr.length > 0">
            <tr v-for="(eachUser, i) in users_messagesArr" :key="i">
              <td v-if="userId">
                {{ capitalizeFisrtLetterOfEachWord(eachUser.id) }}
              </td>
              <td v-if="userName">
                {{ capitalizeFisrtLetterOfEachWord(eachUser.name) }}
              </td>
              <td v-if="userPhone">
                {{ formatPhoneNumber(eachUser.phone) }}
              </td>
              <td v-if="userRole">
                {{ eachUser.subject }}
              </td>
              <td v-if="userEmail">
                {{ capitalizeFisrtLetterOfEachWord(eachUser.email) }}
              </td>
              <td v-if="userAddress">
                {{ capitalizeFisrtLetterOfEachWord(eachUser.message) }}
              </td>
              <td v-if="userRegisteredOn">
                {{ new Date(eachUser.sent_on).toLocaleDateString() }}
              </td>
              <td v-if="userIP">{{ eachUser.ip }}</td>
              <td v-if="userActive" :class="eachUser.active == 1 ? 'text-success' : 'text-danger'">
                {{ eachUser.active == 1 ? "Yes" : "No" }}
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
// import axios from "axios";
import { mapState, mapMutations, mapGetters } from "vuex";
import Navbar from "@/components/Header/Navbar.vue";
export default {
  name: "ContactUsMessages",
  components: {
    Navbar,
  },
  data() {
    return {
      user: {
        uId: "",
        userId: "",
        uName: "",
        uEmail: "",
        uPassword: "",
        uMemberSince: "",
        uAddress: "",
        uPhone: "",
        uRole: "",
        uRoleIs: "",
      },
      //   allColumns: true,
      userName: true,
      userPhone: false,
      userEmail: false,
      userAddress: true,
      userRegisteredOn: false,
      userActive: false,
      userId: false,
      userRole: false,
      userIP: false,
      users_messagesArr: [],
      is_DB_Connected: "",
      db_connection_msg: "",
      apiMainUrl: this.$store.getters.MainUrl, //call global variable
    };
  },
  mounted() {
    let user = localStorage.getItem("current-user-data");
    if (user != null) {
      this.user.uId = JSON.parse(user).id;
      this.user.userId = JSON.parse(user).user_id;
      this.user.uRole = JSON.parse(user).role;
      if (JSON.parse(user).role !== "admn") {
        this.$router.push({ name: "Login" });
      } else {
        let users_messages = localStorage.getItem("users_messages");
        if (users_messages != null) {
          this.users_messagesArr = JSON.parse(users_messages);
        }
      }
    } else {
      this.$router.push({ name: "Login" });
    }
  },
  computed: {
    ...mapState([
      "__RestaurantId",
      "__RestaurantEmail",
      "__RestaurantRole",
      "__RestaurantName",
      "__MainUrl",
    ]),
    ...mapGetters(["MainUrl"]),
  },
  methods: {
    ...mapMutations(["setRestaurantInfo"]),
    capitalizeFisrtLetterOfEachWord(text) {
      return text
        .toLowerCase()
        .split(" ")
        .map((s) => s.charAt(0).toUpperCase() + s.substring(1))
        .join(" ");
    },
    formatPhoneNumber(number) {
      //remove . + -  from any number
      let cleaned = ("" + number).replace(/\D/g, "");
      let match = "";
      if (number.length == 10) {
        match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
      } else if (number.length == 9) {
        match = cleaned.match(/^(\d{3})(\d{3})(\d{3})$/);
      } else if (number.length == 11) {
        match = cleaned.match(/^(\d{3})(\d{4})(\d{4})$/);
      } else if (number.length == 8) {
        match = cleaned.match(/^(\d{2})(\d{3})(\d{3})$/);
      }
      if (match) {
        return "(" + match[1] + ") " + match[2] + "-" + match[3];
      }
      return null;
    },
    // async deactivateUser(accessId, userId, userEmail, userName) {
    //   document.getElementById(userId).classList.add("disabled");
    //   document.getElementById(accessId).innerHTML =
    //     "<span class='text-success fw-bold'>" + "Loading..." + "</span>";
    //   let fd = new FormData();
    //   fd.append("accessId", accessId);
    //   fd.append("userId", userId);
    //   fd.append("userEmail", userEmail);
    //   fd.append("userName", userName);
    //   fd.append("currentUserId", this.user.userId);
    //   let rst = await axios.post(
    //     this.apiMainUrl + `?action=deactivateUser`,
    //     fd
    //   );
    //   console.log(rst);
    //   const resultData = rst.data;
    //   if (rst.status == 200) {
    //     if (resultData.error) {
    //       document.getElementById(userId).classList.remove("disabled");
    //       console.warn("Error deactivating current user");
    //       document.getElementById(accessId).innerHTML =
    //         "<span class='text-danger fw-bold'>" +
    //         resultData.message +
    //         "</span>";
    //     } else {
    //       document.getElementById(userId).classList.add("disabled");
    //       console.log("You have deactivated current user");
    //       document.getElementById(accessId).innerHTML =
    //         '<span class="text-success fw-bold">' +
    //         resultData.message +
    //         "</span>";
    //       //resultMsg.innerHTML = resultData.message;
    //       localStorage.setItem(
    //         "users_messages",
    //         JSON.stringify(resultData.users_messages)
    //       );
    //       this.users_messagesArr = JSON.parse(
    //         localStorage.getItem("users_messages")
    //       );
    //       setTimeout(() => {
    //         document.getElementById(accessId).innerHTML = "";
    //       }, 20000);
    //     }
    //   } else {
    //     document.getElementById(accessId).innerHTML =
    //       "<span class='text-danger fw-bold'>Something is wrong.</span>";
    //     document.getElementById(userId).classList.remove("disabled");
    //   }
    // },
    // async activateUser(accessId, userId, userEmail, userName) {
    //   document.getElementById(accessId).innerHTML =
    //     "<span class='text-success fw-bold'>" + "Loading..." + "</span>";
    //   document.getElementById(userId).classList.add("disabled");
    //   //   let resultMsg = document.getElementById(accessId);
    //   let fd = new FormData();
    //   fd.append("accessId", accessId);
    //   fd.append("userId", userId);
    //   fd.append("userEmail", userEmail);
    //   fd.append("userName", userName);
    //   fd.append("currentUserId", this.user.userId);
    //   let rst = await axios.post(this.apiMainUrl + `?action=activateUser`, fd);
    //   console.log(rst);
    //   const resultData = rst.data;
    //   if (rst.status == 200) {
    //     if (resultData.error) {
    //       document.getElementById(userId).classList.remove("disabled");
    //       console.warn("Error activating current user");
    //       document.getElementById(accessId).innerHTML =
    //         "<span class='text-danger fw-bold'>" +
    //         resultData.message +
    //         "</span>";
    //     } else {
    //       document.getElementById(userId).classList.add("disabled");
    //       console.log("You have activated current user");
    //       document.getElementById(accessId).innerHTML =
    //         "<span class='text-success fw-bold'>" +
    //         resultData.message +
    //         "</span>";
    //       //resultMsg.innerHTML = resultData.message;
    //       localStorage.setItem(
    //         "users_messages",
    //         JSON.stringify(resultData.users_messages)
    //       );
    //       this.users_messagesArr = JSON.parse(
    //         localStorage.getItem("users_messages")
    //       );
    //       setTimeout(() => {
    //         document.getElementById(accessId).innerHTML = "";
    //       }, 5000);
    //     }
    //   } else {
    //     document.getElementById(userId).classList.remove("disabled");
    //     document.getElementById(accessId).innerHTML =
    //       "<span class='text-danger fw-bold'>Something is wrong.</span>";
    //   }
    // },
  },
};
</script>
